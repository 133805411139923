import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

function TextArea({ placeholder, ...rest }) {
  return <TextAreaContainer placeholder={placeholder} {...rest} />;
}

const TextAreaContainer = styled.textarea`
  display: block;
  width: 100%;
  height: ${(props) => props.height};
  resize: none;
  border-radius: 5px;
  border: var(--border);
  padding: 14px 19px;
  font-size: 16px;
  text-align: left;
  line-height: 27.47px;
  color: var(--bodyText2);
  @media ${theme.mobile} {
    padding: 14px;
  }
  &::placeholder {
    font-size: 16px;
    color: var(--secondary);
  }
  &:focus {
    outline: none;
    border: 1px solid var(--primary);
  }
  &.fail {
    color: var(--bodyText2);
    border: 1px solid var(--error);
    &:-webkit-autofill {
      -webkit-text-fill-color: var(--bodyText2) !important;
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
    }
  }
  &:disabled {
    background: rgba(133, 133, 133, 0.1);
    ${(props) =>
      props.code &&
      `background-color: rgb(40, 42, 53);
      color: white;
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
      `};
  }
  ${(props) =>
    props.code &&
    `background-color: rgb(40, 42, 53); 
    color: white;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
    `};

  ${(props) =>
    props.resizable &&
    `resize: vertical;
      `};
`;

TextArea.defaultProps = {
  placeholder: '',
  height: '176px',
};

export default TextArea;
