import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Button from 'components/atoms/Button';
import close from 'resources/closeIcon.png';
import TagList from 'components/atoms/TagList';
import TagAutoComplete from './TagAutoComplete';
import UploadContainer from 'components/atoms/UploadContainer';
import UploadedFile from 'components/atoms/UploadedFile';
import theme from 'styles/theme';
import { observer } from 'mobx-react';
import stores from 'stores';
import Profile from 'components/atoms/Profile';
import Loading from 'components/atoms/Loading';
import { addFormdataFiles } from 'utils/addTempSavedFiles';
import { useTranslation } from 'react-i18next';
import { replaceTagsWithNewLine, updateInnerHTML } from './renderContent';

const QuestionPopUp = observer(({ projectId, editQuetion, visible, profile, onClose }) => {
  const { roworkPMStore } = stores;
  const { loading, hideLoading, postRoworkPM, updateRoworkPM, toggleRoworkPMConfirm } =
    roworkPMStore;

  const { t } = useTranslation('main');

  // tag list
  const [inquiryTags, setInquiryTags] = useState('ALL');
  const [tagData, setTagData] = useState([
    { id: 'PROJECT_MANAGEMENT', name: t('PROJECT_MANAGEMENT'), selected: false },
    { id: 'TO_DO', name: t('TO_DO'), selected: false },
    { id: 'CONTRACT', name: t('CONTRACT'), selected: false },
    { id: 'OTHER', name: t('OTHER'), selected: false },
    { id: 'REPORT_BUG', name: t('REPORT_BUG'), selected: false },
  ]);

  useEffect(() => {
    if (tagData.filter((data) => data.selected).length === 0) setInquiryTags('ALL');
  }, [tagData]);

  const tagReset = useCallback(() => {
    setTagData(tagData.map((data) => data && { ...data, selected: false }));
  }, [tagData]);

  // tag click function
  const onTagClick = useCallback(
    (id) => {
      if (id === 'REPORT_BUG') {
        tagReset();
        onClose(id);
      } else {
        setTagData(
          tagData.map((data) =>
            data.id === id && !data.selected
              ? { ...data, selected: true }
              : { ...data, selected: false },
          ),
        );
        setInquiryTags(id);
      }
    },
    [onClose, tagData, tagReset],
  );

  // 자동 추천된 해시태그 선택시 아이디가 일치하는 태그 목록 활성화
  const handleTagAutoSelect = (hashId) => {
    setTagData(
      tagData.map((data) =>
        data.id === hashId ? { ...data, selected: true } : { ...data, selected: false },
      ),
    );
    setInquiryTags(hashId);
  };

  const [inputHashValue, setHashValue] = useState([]);
  const [inputTextValues, setTextValues] = useState([]);

  const getHashValue = useCallback((value) => {
    setHashValue(value);
  }, []);

  const getTextValues = useCallback((value) => {
    setTextValues(value);
  }, []);

  // file upload, delete
  const [files, setFiles] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [materialIdList, setMaterialIdList] = useState([]);
  const [tempSavedFiles, setTempSavedFiles] = useState([]);

  const handleDeleteWithId = (id) => {
    setMaterialList(materialList.filter((file) => file.materialId !== id));
    setMaterialIdList(materialIdList.filter((item) => item !== id));
  };

  const handleDeleteWithIndex = (index) => {
    setFiles(files.filter((_, i) => i !== index));
    setTempSavedFiles(tempSavedFiles.filter((_, i) => i !== index));
  };

  const handleDelete = (deleteId) => {
    if (typeof deleteId !== 'number') handleDeleteWithId(deleteId);
    if (typeof deleteId === 'number') handleDeleteWithIndex(deleteId);
  };

  // 닫기 버튼 클릭 시 처리 함수
  const handleClose = useCallback(() => {
    tagReset();
    setInquiryTags('ALL');
    setHashValue([]);
    setTextValues([]);
    setFiles([]);
    setMaterialList([]);
    setTempSavedFiles([]);
    setMaterialIdList([]);
    onClose();
  }, [onClose, tagReset]);

  const mergeInputValues = useCallback(() => {
    if (inputHashValue.length === 0) return inputTextValues.join('');
    else return updateInnerHTML(inputTextValues.join(''), inputHashValue[1]);
  }, [inputHashValue, inputTextValues]);

  // 확인 버튼 클릭 시 처리 함수
  const onConfirm = useCallback(
    async (e) => {
      e.preventDefault();
      if (inputTextValues.length === 0 || (inputTextValues.length === 1 && !inputTextValues[0]))
        return;
      let success = false;
      if (!editQuetion) {
        let formData = addFormdataFiles(tempSavedFiles);

        formData.append('content', replaceTagsWithNewLine(mergeInputValues()));
        formData.append('inquiryTags', inquiryTags);
        success = await postRoworkPM('clients', projectId, formData);
      }
      if (editQuetion) {
        const data = {
          content: replaceTagsWithNewLine(mergeInputValues()),
          inquiryTags,
          materialIdList,
          tempSavedFiles,
        };

        success = await updateRoworkPM('clients', editQuetion[0].requestId, data);
      }
      if (success) {
        hideLoading();
        handleClose();
        toggleRoworkPMConfirm();
      }
    },
    [
      inputTextValues,
      editQuetion,
      tempSavedFiles,
      mergeInputValues,
      inquiryTags,
      postRoworkPM,
      projectId,
      materialIdList,
      updateRoworkPM,
      hideLoading,
      handleClose,
      toggleRoworkPMConfirm,
    ],
  );

  // 질문 수정 팝업시 초기 set
  const setEditValue = useCallback(() => {
    if (editQuetion) {
      setTagData(
        tagData.map((data) =>
          data.id === editQuetion[0].inquiryTags
            ? { ...data, selected: true }
            : { ...data, selected: false },
        ),
      );
      setInquiryTags(editQuetion[0].inquiryTags);
      setMaterialList(editQuetion[0].materialList);
      setMaterialIdList(editQuetion[0].materialList.map((data) => data.materialId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editQuetion]);

  useEffect(() => {
    setEditValue();
  }, [setEditValue]);

  if (!visible) return null;
  return (
    <Container>
      <DarkBackground />
      <PopupContainer>
        <PopupClose onClick={handleClose}>
          <img src={close} alt="close" />
        </PopupClose>
        <form onSubmit={onConfirm}>
          <PopupContent len={materialList.length + files.length}>
            <ScrollArea>
              <InputWrapper>
                <ProfileWrapper>
                  <Profile profile={profile} profileSize="medium" />
                </ProfileWrapper>
                <InputTextArea>
                  <TagAutoComplete
                    projectId={projectId}
                    editQuetion={editQuetion}
                    inputHashValue={inputHashValue}
                    setHashValue={getHashValue}
                    inputTextValues={inputTextValues}
                    setTextValues={getTextValues}
                    onTagAutoSelect={handleTagAutoSelect}
                    inquiryTags={inquiryTags}
                    placeholder={t('tagAutoCompletePh')}
                  />
                </InputTextArea>
              </InputWrapper>
              <ContentWrapper>
                <TagList data={tagData} onTagClick={onTagClick} />
                <UploadFileWrapper>
                  <div style={{ height: '55px' }}>
                    <UploadContainer
                      onDragDrop={(file) => setFiles(file)}
                      size="flexible"
                      limitFileSize={1.5}
                      top
                    />
                  </div>
                  <GradiantArea len={materialList.length + files.length}>
                    {materialList.map((file, index) => (
                      <UploadedFile
                        key={`matrial-file__${index}`}
                        type="list"
                        file={file}
                        toDelete={handleDelete}
                        style={{ height: 70, marginBottom: 10, marginTop: 10 }}
                      />
                    ))}
                    {files.map((file, index) => (
                      <UploadedFile
                        key={`upload-file__${index}`}
                        type="list"
                        file={file}
                        onSetTempSavedFile={(prev) => setTempSavedFiles(prev)}
                        index={index}
                        toDelete={handleDelete}
                        style={{ height: 70, marginBottom: 10, marginTop: 10 }}
                      />
                    ))}
                  </GradiantArea>
                </UploadFileWrapper>
              </ContentWrapper>
            </ScrollArea>
          </PopupContent>
          <PopupButtonWrap>
            <PopupBtn>
              <Button size="fullWidth" type="submit">
                {loading ? <Loading dark /> : t('submitb')}
              </Button>
            </PopupBtn>
          </PopupButtonWrap>
        </form>
      </PopupContainer>
    </Container>
  );
});

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
`;

const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.3;
`;

const PopupContainer = styled.div`
  position: relative;
  width: 665px;
  min-height: 408px;
  padding: 59px 84px 56px 48px;
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  @media ${theme.mobile} {
    padding: 49px 21px 27px 21px;
  }
`;

const PopupClose = styled.div`
  position: absolute;
  top: 41px;
  right: 50px;
  cursor: pointer;
  @media ${theme.mobile} {
    top: 24px;
    right: 18px;

    img {
      width: 11.82px;
      height: 11.82px;
    }
  }
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: ${(props) => (props.len > 1 ? '430px' : props.len > 0 ? '390px' : '300px')};
  background-color: white;
  @media ${theme.mobile} {
    min-height: ${(props) => (props.len > 1 ? '400px' : props.len > 0 ? '349px' : '278px')};
  }
`;
const ScrollArea = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  @media ${theme.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
const InputWrapper = styled.div`
  display: flex;
`;
const ContentWrapper = styled.div`
  padding-left: 69px;
  @media ${theme.mobile} {
    padding-left: 0;
  }
`;
const ProfileWrapper = styled.div`
  margin-right: 22px;
  @media ${theme.mobile} {
    margin-right: 12px;
  }
`;
const InputTextArea = styled.div`
  width: 100%;
  min-height: 166px;
  display: flex;
  margin-bottom: 20px;
  @media ${theme.mobile} {
    min-height: 100px;
  }
`;

const UploadFileWrapper = styled.div`
  padding: 10px 0px 0 0px;
  position: relative;
  background-color: white;
  @media ${theme.mobile} {
    padding: 7px 0px 0 0px;
  }
`;
const GradiantArea = styled.div`
  ${(props) =>
    props.len > 1 &&
    css`
      :after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 50%;
        bottom: 0;
        background: linear-gradient(to top, white, 20%, rgba(255, 255, 255, 0));
        background-repeat: no-repeat;
        transition: all 500ms ease;
      }
      :hover {
        &:after {
          background-position: 0 200px;
          display: none;
        }
      }
    `}
`;

const PopupButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;
  @media ${theme.mobile} {
    margin: 10px 0 0 0;
  }
`;
const PopupBtn = styled.div`
  width: 168px;
`;

export default QuestionPopUp;
