import Logo from 'components/atoms/Logo';
import styled from 'styled-components';
import NotificationContainer from './NotificationContainer';
import MobileNotificationContainer from './MobileNotificationContainer';
import Button from 'components/atoms/Button';
import theme from 'styles/theme';
import { Mobile, Others, Large } from 'styles/MediaQuery';
import { Link } from 'react-router-dom';
import { analytics } from 'fBase';
import { useTranslation } from 'react-i18next';
import HeaderMenu from './navigation/HeaderMenu';

const Navbar = ({ button, onClick, ...rest }) => {
  return (
    <TopContainer>
      <NavbarContainer {...rest}>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Mobile>
          <MobileNotificationContainer />
        </Mobile>
        <Others>
          <NotificationContainer button={button} onClick={onClick} />
        </Others>
      </NavbarContainer>
      <HeaderMenu />
    </TopContainer>
  );
};

const TopContainer = styled.header`
  position: sticky;
  top: 0;
  z-index: 10;
  background: var(--background);
`;

const NavbarContainer = styled.div`
  position: sticky;
  top: 0;
  height: 72px;
  background: ${(props) => props.background};
  z-index: 11;
  display: flex;
  align-items: center;
  padding: 0 56px;
  @media ${theme.mobile} {
    height: 62px;
    padding: 18px 28px;
    position: relative;
  }
  @media ${theme.mobileS} {
    padding: 18px 20px;
  }
`;
Navbar.defaultProps = {
  background: 'var(--background)',
};
export default Navbar;

export const LoginNavbar = ({ ...rest }) => {
  analytics.setUserId(null);
  const handleClickLogin = () => {
    analytics.logEvent('login');
  };
  const { t } = useTranslation('registration');

  return (
    <TopContainer {...rest}>
      <LoginNavContainer>
        <LogoContainer>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <Large>
            <HeaderMenu />
          </Large>
        </LogoContainer>
        <LoginButtonWrapper>
          <Link to="/login">
            <LoginButton onClick={handleClickLogin}>{t('signIn')}</LoginButton>
          </Link>
          <Link to="/signUp">
            <Others>
              <Button>{t('signUp')}</Button>
            </Others>
            <Mobile>
              <Button size="small">{t('signUp')}</Button>
            </Mobile>
          </Link>
        </LoginButtonWrapper>
      </LoginNavContainer>
    </TopContainer>
  );
};
const LoginNavContainer = styled.div`
  height: 72px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 56px;
  @media ${theme.mobile} {
    box-sizing: border-box;
    height: 42px;
    padding: 18px 28px 0;
    position: relative;
  }
  @media ${theme.mobileS} {
    padding: 18px 20px 0;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  div:nth-child(2) {
    margin-left: 46px;
  }
`;

const LogoWrapper = styled.div`
  height: 19px;
  @media ${theme.mobile} {
    margin-top: -5px;
  }
`;
const LoginButtonWrapper = styled.div`
  display: flex;
`;
const LoginButton = styled.button`
  background-color: transparent;
  padding: 12px 28px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: var(--bodyText2);
  :hover {
    color: var(--primary);
  }
  @media ${theme.mobile} {
    height: 31px;
    padding: 7px 17px;
  }
`;

export const LogoNavbar = () => {
  return (
    <NavbarContainer>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
    </NavbarContainer>
  );
};
