export const addFormdataFiles = (tempSavedFiles) => {
  let formData = new FormData();
  for (let i = 0; i < tempSavedFiles?.length; i++) {
    formData.append(`tempSavedFiles[${i}].fileName`, tempSavedFiles[i].fileName);
    formData.append(`tempSavedFiles[${i}].fileSize`, parseFloat(tempSavedFiles[i].fileSize));
    formData.append(`tempSavedFiles[${i}].fileType`, tempSavedFiles[i].fileType);
    formData.append(`tempSavedFiles[${i}].fileUrl`, tempSavedFiles[i].fileUrl);
    formData.append(`tempSavedFiles[${i}].height`, parseFloat(tempSavedFiles[i].height) || 0);
    formData.append(`tempSavedFiles[${i}].width`, parseFloat(tempSavedFiles[i].width) || 0);
  }
  return formData;
};

export const updateQueryFiles = (tempSavedFiles) => {
  let query = '';
  for (let i = 0; i < tempSavedFiles.length; i++) {
    query += `&tempSavedFiles[${i}].fileName=${tempSavedFiles[i].fileName}`;
    query += `&tempSavedFiles[${i}].fileSize=${parseFloat(tempSavedFiles[i].fileSize)}`;
    query += `&tempSavedFiles[${i}].fileType=${tempSavedFiles[i].fileType}`;
    query += `&tempSavedFiles[${i}].fileUrl=${tempSavedFiles[i].fileUrl}`;
    query += `&tempSavedFiles[${i}].height=${parseFloat(tempSavedFiles[i].height)}`;
    query += `&tempSavedFiles[${i}].width=${parseFloat(tempSavedFiles[i].width)}`;
  }
  return query;
};
