import React from 'react';
import styled, { css } from 'styled-components';

const Row = ({ children, ...props }) => {
  return <RowDiv {...props}>{children}</RowDiv>;
};

const RowDiv = styled.div`
  display: flex;
  ${(props) =>
    props.mt &&
    css`
      margin-top: ${props.mt}px;
    `}
  ${(props) =>
    props.spacebetween &&
    css`
      justify-content: space-between;
    `}
`;

export default Row;
