import React, { Suspense, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { routes, routesGuard, accessRoutes } from 'route/routes';
import AuthRoute from 'route/AuthRoute';
import { observer } from 'mobx-react';
import stores from 'stores';
import LoadingLogo from 'components/molecules/LoadingLogo';
import Error from 'components/templates/Error';
import ErrorHandler from 'api/ErrorHandler';
import FirebaseAnalytics from 'utils/FirebaseAnalytics';
import ChannelService from 'utils/ChannelService';
import { hotjar } from 'react-hotjar';
import RoworkPM from 'components/pages/roworkPM/RoworkPM';
import { throttle } from 'lodash-es';
import { checkRefreshTokenValidity, getAutoRefreshTime } from 'utils/checkTokenValidity';
import { useTranslation } from 'react-i18next';
import { getLanguageCode } from 'utils/LanguageUtil';
// import { isEmpty } from 'lodash-es';

const App = observer(() => {
  const { t, i18n } = useTranslation('landing');

  useEffect(() => {
    const savedLangCode = localStorage.getItem('languageCode');
    if (savedLangCode) {
      i18n.changeLanguage(savedLangCode);
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_MODE === 'production') {
      const initPixel = async () => {
        const { default: ReactPixel } = await import('react-facebook-pixel');
        ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, null, {
          autoConfig: true,
          debug: true,
        });
        ReactPixel.pageView();
        ReactPixel.track('ViewContent');
      };
      initPixel();
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_MODE === 'production') {
      hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SV);
    }
  }, []);

  const { authStore, errorStore } = stores;
  const { refreshToken, getCookie } = authStore;
  const refreshTokenCookie = getCookie('refreshToken');
  const stayLoggedIn = JSON.parse(localStorage.getItem('stayLoggedIn'));
  const loggedIn = JSON.parse(localStorage.getItem('loggedIn'));

  const [loading, setLoading] = useState(false);

  let activityDetectedRef = useRef(false);

  const useActivityDetection = () => {
    const detectActivity = () => {
      activityDetectedRef.current = true;
      // console.log(activityDetectedRef.current);
    };

    const throttledDetectActivity = throttle(detectActivity, 500);

    document.addEventListener('mousemove', throttledDetectActivity);
    document.addEventListener('keydown', throttledDetectActivity);

    const activityResetInterval = setInterval(() => {
      activityDetectedRef.current = false;
      // console.log(activityDetectedRef.current);
    }, 180000);

    return () => {
      document.removeEventListener('mousemove', throttledDetectActivity);
      document.removeEventListener('keydown', throttledDetectActivity);
      clearInterval(activityResetInterval);
    };
  };

  useEffect(() => {
    let isMounted = true;

    if (loggedIn) useActivityDetection();

    let refreshTime = getAutoRefreshTime();
    if (refreshTime === 2147483647) {
      // check max int value
      refreshTime = 2147483647;
    }
    let isRefreshTokenValid = checkRefreshTokenValidity();

    async function refreshTokenAndSetupInterval() {
      try {
        if (loggedIn && refreshTokenCookie) {
          await refreshToken(refreshTokenCookie);

          const intervalId = setInterval(async () => {
            try {
              if (isRefreshTokenValid || stayLoggedIn || activityDetectedRef.current) {
                await refreshToken(refreshTokenCookie);
              } else {
                authStore.signOut('auto');
              }
            } catch (error) {
              console.error('Error refreshing token:', error);
            }
          }, refreshTime); // if bigger than max value(2147483647), run immediately

          return () => {
            clearInterval(intervalId);
            isMounted = false;
          };
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
      } finally {
        if (isMounted) {
          setLoading(true);
        }
      }
    }

    refreshTokenAndSetupInterval();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    const channelTalk = new ChannelService();
    channelTalk.boot({
      pluginKey: process.env.REACT_APP_CHANNEL_IO_KEY,
    });
    // if (!isEmpty(authStore.userInfo)) {
    //   const { id, name, cellphone, email } = authStore.userInfo;
    //   console.log(id, name, cellphone, email);
    //   channelTalk.boot({
    //     pluginKey: process.env.REACT_APP_CHANNEL_IO_KEY,
    //     memberId: id,
    //     profile: {
    //       name,
    //       mobileNumber: cellphone,
    //       email,
    //     },
    //   });
    // } else {
    //   channelTalk.boot({
    //     pluginKey: process.env.REACT_APP_CHANNEL_IO_KEY,
    //   });
    // }
    return () => {
      channelTalk.shutdown();
    };
    // }, [authStore.userInfo]);
  }, []);

  const [errorPopup, setErrorPopUp] = useState(false);

  useEffect(() => {
    // console.log(errorStore.errorCode);
    if (errorStore.errorCode) setErrorPopUp(true);
  }, [errorStore.errorCode]);

  const handleClose = () => {
    setErrorPopUp(false);
    errorStore.setErrorCode('');
  };

  if (loading) {
    return (
      <>
        <Router>
          <FirebaseAnalytics />
          <ErrorHandler visible={errorPopup} onClose={handleClose}>
            <Switch>
              {routes.map((route, i) => (
                <Route
                  exact
                  path={route.path}
                  render={(props) => (
                    <Suspense fallback={<LoadingLogo />}>
                      <route.component {...props} />
                    </Suspense>
                  )}
                  key={`route__${i}`}
                />
              ))}
              {localStorage.getItem('loggedIn') &&
                JSON.parse(localStorage.getItem('loggedIn')) &&
                routesGuard.map((route, i) => (
                  <AuthRoute
                    exact
                    path={route.path}
                    authenticated={
                      localStorage.getItem('loggedIn') &&
                      JSON.parse(localStorage.getItem('loggedIn'))
                    }
                    render={(props) => (
                      <Suspense fallback={<LoadingLogo />}>
                        <route.component {...props} />
                      </Suspense>
                    )}
                    key={`route-user__${i}`}
                  />
                ))}
              {!(
                localStorage.getItem('loggedIn') && JSON.parse(localStorage.getItem('loggedIn'))
              ) &&
                accessRoutes.map((route, i) => (
                  <AuthRoute exact path={route.path} key={`route-access__${i}`} />
                ))}
              <Redirect
                from="/communication/:projectId/:requestId"
                to="/project/:projectId/communication/:requestId"
                component={RoworkPM}
              />
              <Route path="*" component={Error} />
            </Switch>
          </ErrorHandler>
        </Router>
      </>
    );
  } else {
    return (
      <>
        <Router>
          <FirebaseAnalytics />
          <Switch>
            <Route path="/" component={LoadingLogo} />
          </Switch>
        </Router>
      </>
    );
  }
});

export default App;
