import {
  getPricingInfoMenusApi,
  getPricingInfosApi,
  getServiceInfosApi,
  getUsecaseInfoMenusApi,
  getUsecaseInfosApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class ServiceStore {
  activeTab = 0;
  serviceInfos = [];
  pricingInfoMenus = [{}];
  pricingInfos = [];
  usecaseInfoMenus = [{}];
  usecaseInfos = [];
  menuLengths = { first: 0, second: 0, third: 2 };

  constructor() {
    makeAutoObservable(this);
  }

  setActiveTab = (param) => {
    this.activeTab = param;
  };

  getServiceInfos = async () => {
    await getServiceInfosApi()
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.serviceInfos = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getPricingInfoMenus = async () => {
    await getPricingInfoMenusApi()
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.pricingInfoMenus = res.data;
          this.menuLengths.first = res.data.length;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getPricingInfos = async (type) => {
    await getPricingInfosApi(type)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.pricingInfos = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getUsecaseInfoMenus = async () => {
    await getUsecaseInfoMenusApi()
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.usecaseInfoMenus = res.data;
          this.menuLengths.second = res.data.length;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getUsecaseInfos = async (type) => {
    await getUsecaseInfosApi(type)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.usecaseInfos = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const serviceStore = new ServiceStore();

export default serviceStore;
