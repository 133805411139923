import {
  getProjectMemberApi,
  deleteMemberApi,
  deleteInviteApi,
  postInviteProjectMemberApi,
  putChangeUserTypeApi,
  resendInviteApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class MemberStore {
  projectId = '';
  projectMember = [];
  changeUserTypeSuccess = false;

  constructor() {
    makeAutoObservable(this);
  }

  setProjectId = (param) => {
    this.projectId = param;
  };

  setChangeUserTypeSuccess = (param) => {
    this.changeUserTypeSuccess = param;
  };

  getProejctMember = async (projectId) => {
    await getProjectMemberApi(projectId)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.projectMember = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteMember = async (memberId) => {
    await deleteMemberApi(memberId)
      .then((res) => {
        runInAction(() => {
          this.getProejctMember(this.projectId);
          // console.log(res.data);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteInvite = async (inviteId) => {
    await deleteInviteApi(inviteId)
      .then((res) => {
        runInAction(() => {
          this.getProejctMember(this.projectId);
          // console.log(res.data);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  resendInvite = async (inviteId) => {
    await resendInviteApi(inviteId)
      .then((res) => {
        runInAction(() => {
          this.getProejctMember(this.projectId);
          // console.log(res.data);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postInviteProjectMember = async (projectId, body) => {
    let success = false;
    await postInviteProjectMemberApi(projectId, body)
      .then((res) => {
        runInAction(() => {
          if (res.status === 201) {
            success = true;
            this.getProejctMember(this.projectId);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
    return success;
  };

  putChangeUserType = async (memberId, body) => {
    await putChangeUserTypeApi(memberId, body)
      .then((res) => {
        runInAction(() => {
          // console.log(res, 'Put');
          this.getProejctMember(this.projectId);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const memberStore = new MemberStore();

export default memberStore;
