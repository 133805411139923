import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import facebook from 'resources/facebookIcon.png';
import blog from 'resources/blogIcon.png';
import cacaotalk from 'resources/cacaotalkIcon.png';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';

const Footer = () => {
  const { t } = useTranslation('landing');

  const handleSnsIconClick = (link) => {
    window.open(link, '_blank');
  };

  return (
    <FooterWrapper>
      <FooterContainer>
        <div>
          <Company>{t('footer').company}</Company>
          <Address>
            {t('footer').contact} <br />
            {t('footer').tel} <br />
            {t('footer').cRNumber} <br />
            {t('footer').address}
          </Address>
        </div>
        <SnsIconWrapper>
          <h3>
            <Icon
              src={blog}
              alt="blog"
              onClick={() => handleSnsIconClick('https://blog.rowork.com')}
            />
          </h3>
          <h3>
            <Icon
              src={facebook}
              alt="facebook"
              onClick={() => handleSnsIconClick('https://www.facebook.com/rowork.service')}
            />
          </h3>
          <h3>
            <Icon
              src={cacaotalk}
              alt="cacaotalk"
              onClick={() => handleSnsIconClick('https://pf.kakao.com/_hsDxeb')}
            />
          </h3>
        </SnsIconWrapper>
      </FooterContainer>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  height: 277px;
  background: #f5f5f5;
  padding: 88px 57px 0px 57px;
  @media ${theme.mobile} {
    padding: 75px 28px;
  }
`;
const FooterContainer = styled.div`
  max-width: 1222px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
`;
const Company = styled.h2`
  color: var(--grayText);
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  span {
    font-weight: 600;
    letter-spacing: -2px;
  }
`;
const Address = styled.div`
  margin-top: 21px;
  color: var(--grayText);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;
const SnsIconWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const Icon = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  /* ${(props) =>
    props.alt === 'blog' &&
    css`
      opacity: 0.9;
    `} */
`;
export default Footer;
