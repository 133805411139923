import React from 'react';
import styled from 'styled-components';
import searchIcon from 'resources/magnifierBlue.png';
import theme, { fonts } from 'styles/theme';
import { useTranslation } from 'react-i18next';

function SearchInput({ value, ...rest }) {
  const { t } = useTranslation('common');

  return (
    <SearchContainer>
      <InputBox defaultValue={value} placeholder={t('searchInput')} {...rest} />
      <Search src={searchIcon} alt="search" {...rest} />
    </SearchContainer>
  );
}

const SearchContainer = styled.div`
  position: relative;
`;
const InputBox = styled.input`
  width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : '53px')};
  border-radius: 5px;
  box-sizing: border-box;
  border: var(--border);
  box-shadow: var(--borderDropShadow);
  padding: 0px 96px 0px 36px;
  ${fonts.formA}
  color: var(--bodyText2);
  @media ${theme.mobile} {
    padding: 20px 56px 19px 20px;
    ${theme.ellipsis}
  }

  &::placeholder {
    ${fonts.secondary}
    color: var(--grayText2);
  }
  &:focus {
    outline: none;
    border: 1px solid var(--primary);
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: var(--primary) !important;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    box-shadow: 0 0 0 1000px #fff inset;
  }
  &:-webkit-autofill::first-line {
  }

  &.fail {
    color: var(--bodyText2);
    border: 1px solid var(--error);
    &:-webkit-autofill {
      -webkit-text-fill-color: var(--bodyText2) !important;
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
      box-shadow: 0 0 0 1000px #fff inset;
    }
  }
  &:disabled {
    background: #efefef;
  }
`;
const Search = styled.img`
  height: 24px;
  position: absolute;
  top: ${(props) => (props.height ? `${props.height / 3.6}px` : '15px')};
  right: 42px;
  @media ${theme.mobile} {
    right: 22px;
  }
`;

export default SearchInput;
