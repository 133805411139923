import errorStore from 'stores/ErrorStore';

const replaceErrorState = (error, status, code) => {
  if (error.response) {
    const { status } = error.response;
    const { message, code } = error.response.data;
    const { url } = error.response.config;

    window.history.replaceState(
      { errorStatus: status, errorMessage: message, errorCode: code, url: url },
      '',
      window.location.pathname,
    );
    errorStore.setErrorCode(status);
  } else {
    window.history.replaceState(
      { errorStatus: status, errorCode: code },
      '',
      window.location.pathname,
    );
    errorStore.setErrorCode(code);
  }
};

export default replaceErrorState;
