import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import { ReactComponent as FileIcon } from 'resources/fileIcon.svg';
import FormatBytes from 'utils/FormatBytes';
import { ReactComponent as DeleteBtn } from 'resources/deleteButton.svg';

const ReplyUploadedFile = ({ imgType, file, src, id, onDeleteFile }) => {
  return (
    <>
      {imgType ? (
        <FilesWrapper>
          <Img alt="img-preview" src={src} />
          {onDeleteFile && <DeleteBtnStyle onClick={() => onDeleteFile(id)} />}
        </FilesWrapper>
      ) : (
        <FilesWrapper>
          <DocumentWrapper>
            <FileIcon />
            <DocumentDesc>
              <DocumentName>{file.name}</DocumentName>
              <DocumentProgress>
                <DocumentSize>{FormatBytes(file.size)}</DocumentSize>
              </DocumentProgress>
            </DocumentDesc>
          </DocumentWrapper>
          {onDeleteFile && <DeleteBtnStyle onClick={() => onDeleteFile(id)} />}
        </FilesWrapper>
      )}
    </>
  );
};

const FilesWrapper = styled.div`
  margin: 0 10px 10px 0;
  position: relative;
  :hover > svg {
    visibility: visible;
  }
`;
const DeleteBtnStyle = styled(DeleteBtn)`
  overflow: visible;
  position: absolute;
  top: -10px;
  right: -8px;
  visibility: hidden;
`;

const Img = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10%;
  object-fit: cover;
`;

const DocumentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  background: white;
  border: ${theme.border};
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  @media ${theme.mobile} {
  }
`;
const DocumentDesc = styled.div`
  display: table-column;
  width: 100%;
`;
const DocumentName = styled.p`
  display: block;
  margin: 0;
  margin-left: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #132e58;
  @media ${theme.mobile} {
    font-size: 12px;
  }
`;
const DocumentProgress = styled.div`
  margin-left: 12px;
`;
const DocumentSize = styled.div`
  font-weight: normal;
  margin: 0;
  font-size: 10px;
  line-height: 14px;
  color: #afafaf;
`;
export default ReplyUploadedFile;
