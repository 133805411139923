import errorStore from './ErrorStore';
import authStore from './AuthStore';
import serviceStore from './ServiceStore';
import productTourStore from './ProductTourStore';
import myInfoStore from './MyInfoStore';
import documentsFormStore from './DocumentsFormStore';
import proposalFormStore from './ProposalFormStore';
import datePickerStore from './DatePickerStore';
import feedbackStore from './FeedbackStore';
import profileFormStore from './ProfileFormStore';
import dashboardStore from './DashboardStore';
import projectStore from './ProjectStore';
import roworkPMStore from './RoworkPMStore';
import todoStore from './TodoStore';
import contractStore from './ContractStore';
import contractSignStore from './ContractSignStore';
import issueStore from './IssueStore';
import managedServicesStore from './ManagedServicesStore';
import paymentStore from './PaymentStore';
import memberStore from './MemberStore';
import cloudStore from './CloudStore';

const stores = {
  errorStore,
  authStore,
  serviceStore,
  productTourStore,
  myInfoStore,
  documentsFormStore,
  proposalFormStore,
  datePickerStore,
  feedbackStore,
  profileFormStore,
  dashboardStore,
  projectStore,
  roworkPMStore,
  todoStore,
  contractStore,
  contractSignStore,
  issueStore,
  managedServicesStore,
  paymentStore,
  memberStore,
  cloudStore,
};

export default stores;
