import { observer } from 'mobx-react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import stores from 'stores';

export const SEO = observer(({ title, landing }) => {
  const { dashboardStore } = stores;

  const { t } = useTranslation('common');
  const pageTitles = t('pageTitles');

  return (
    <HelmetProvider>
      <Helmet
        title={
          (pageTitles[title]
            ? `${pageTitles[title]}`
            : `${dashboardStore.productName} ${t('menu')[title.props.index]}`) +
          (landing ? '' : ` - ${t('secondTitle')}`)
        }
      />
    </HelmetProvider>
  );
});
