import {
  deleteClientFeedbackApi,
  deleteProposalFeedbackCommentApi,
  getProjectProposalsApi,
  getProjectProposalsByProposalIdApi,
  getProjectProposalsHistoryApi,
  getProposalByHistoryIdApi,
  getProposalFeedbackAllApi,
  getProposalFeedbackByIdApi,
  getProposalFeedbackCommentApi,
  getProposalHistoryFeedbackApi,
  postClientsFeedbackApi,
  postProposalFeedbackCommentApi,
  postProposalFeedbackCompletedApi,
  putClientFeedbackApi,
  putProposalFeedbackCommentApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';
import authStore from 'stores/AuthStore';

class FeedbackStore {
  proposalId = '';
  proposalInfo = {};
  proposalContent = [];
  feedback = {};
  feedbacks = [];
  notLastPage = false;
  feedbackId = '';

  feedbackIdCommentsMap = {};
  feedbackWriterNamesMap = {};
  commentsNamesMap = {};

  needCommentFeedbacks = [];
  noCommentWarnings = [];
  commentLoading = 0;

  proposalHistories = [];

  projectId = '';

  constructor() {
    makeAutoObservable(this);
  }

  setFocusedFeedbackId = (param) => {
    this.feedbackId = param;
  };

  setFeedback = (param) => {
    this.feedback = param;
  };

  resetProposal = () => {
    this.proposalId = '';
    this.proposalInfo = {};
    this.proposalContent = [];
  };

  resetProposalFeedback = () => {
    this.notLastPage = false;
    this.feedbacks = [];
  };

  checkNeedFeedbacks = (content) => {
    content.map((item) =>
      item.highlight
        ? this.needCommentFeedbacks.push(item.feedBackId) &&
          item.children.length > 0 &&
          this.checkNeedFeedbacks(item.children)
        : item.children.length > 0 && this.checkNeedFeedbacks(item.children),
    );
  };

  callHighlightFeedbacksAndComments = (isMobile) => {
    this.needCommentFeedbacks.map((feedbackId) =>
      this.callAheadFeedbackAndComment(feedbackId, isMobile),
    );
  };

  callAheadFeedbackAndComment = async (feedbackId, isMobile) => {
    await getProposalFeedbackByIdApi(feedbackId)
      .then((res) => {
        runInAction(() => {
          // this.needCommentFeedbacks = [...this.needCommentFeedbacks, res.data];
          this.feedbackWriterNamesMap[feedbackId] = res.data.writerName;
        });
      })
      .catch((error) => {
        console.log(error);
      });
    if (isMobile) {
      await getProposalFeedbackCommentApi(feedbackId)
        .then((res) => {
          runInAction(() => {
            this.commentsNamesMap[feedbackId] = res.data.map((item) => item.name);
            this.commentLoading++;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  detectAllFeedbacksAndComments = () => {
    this.needCommentFeedbacks.map((feedbackId) => this.detectFeedbackAndComment(feedbackId));
  };

  detectFeedbackAndComment = (feedbackId) => {
    // 하이라이트 연동 안된 것 제외 && 화면 진입 직후 호출시 map이 생성되기 전 실행되는 것 방지
    if (this.needCommentFeedbacks.includes(feedbackId) && this.commentsNamesMap[feedbackId]) {
      const feedBackWriterName = this.feedbackWriterNamesMap[feedbackId];
      const lastCommentName = this.commentsNamesMap[feedbackId].at(-1);
      const { length } = this.commentsNamesMap[feedbackId];

      const addOrRemoveWarnings = (condition) => {
        condition
          ? !this.noCommentWarnings.includes(feedbackId) && this.noCommentWarnings.push(feedbackId)
          : this.noCommentWarnings.includes(feedbackId) &&
            this.noCommentWarnings.splice(this.noCommentWarnings.indexOf(feedbackId), 1);
      };

      if (length === 0) addOrRemoveWarnings(feedBackWriterName !== authStore.userInfo.name);
      if (length > 0) addOrRemoveWarnings(lastCommentName !== authStore.userInfo.name);
    }
  };

  getProjectProposals = async (projectId, isMobile) => {
    this.commentLoading = 0;
    await getProjectProposalsApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.proposalId = res.data.proposalId;
          this.proposalInfo = res.data;
          this.proposalContent = res.data.content;
          if (this.needCommentFeedbacks.length === 0) {
            this.checkNeedFeedbacks(res.data.content);
            this.callHighlightFeedbacksAndComments(isMobile);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProjectProposalsByProposalId = async (proposalId, isMobile) => {
    this.commentLoading = 0;
    await getProjectProposalsByProposalIdApi(proposalId)
      .then((res) => {
        runInAction(() => {
          this.projectId = res.data.projectId;
          this.proposalId = res.data.proposalId;
          this.proposalInfo = res.data;
          this.proposalContent = res.data.content;
          if (this.needCommentFeedbacks.length === 0) {
            this.checkNeedFeedbacks(res.data.content);
            this.callHighlightFeedbacksAndComments(isMobile);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProjectProposalsHistory = async (projectId) => {
    await getProjectProposalsHistoryApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.proposalHistories = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProposalByHistoryId = async (proposalHistoryId) => {
    await getProposalByHistoryIdApi(proposalHistoryId)
      .then((res) => {
        runInAction(() => {
          this.proposalId = res.data.proposalId;
          this.proposalInfo = res.data;
          this.proposalContent = res.data.content;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProposalHistoryFeedback = async (proposalHistoryId) => {
    await getProposalHistoryFeedbackApi(proposalHistoryId)
      .then((res) => {
        // console.log(res.data, 'get proposal history feedback!!!');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProposalFeedbackAll = async (proposalId, pageNumber, pageSize) => {
    await getProposalFeedbackAllApi(proposalId, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          if (res.data.length < pageSize) {
            this.notLastPage = true;
          }
          if (pageNumber === 0) this.feedbacks = [...res.data];
          else this.feedbacks = [...this.feedbacks, ...res.data];
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProposalFeedbackById = async (feedbackId) => {
    await getProposalFeedbackByIdApi(feedbackId)
      .then((res) => {
        runInAction(() => {
          this.feedback = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postClientFeedback = async (body) => {
    await postClientsFeedbackApi(body)
      .then((res) => {
        this.getProjectProposalsByProposalId(this.proposalId);
        this.getProposalFeedbackAll(this.proposalId, 0, this.feedbacks.length + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  putClientFeedback = async (feedbackId, body, isMobile) => {
    await putClientFeedbackApi(feedbackId, body)
      .then((res) => {
        if (isMobile) this.getProposalFeedbackById(feedbackId);
        else {
          this.getProposalFeedbackAll(this.proposalId, 0, this.feedbacks.length);
          this.getProjectProposalsByProposalId(this.proposalId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteClientFeedback = async (feedbackId) => {
    await deleteClientFeedbackApi(feedbackId)
      .then((res) => {
        this.getProjectProposalsByProposalId(this.proposalId);
        this.getProposalFeedbackAll(
          this.proposalId,
          0,
          this.feedbacks.length - 1 === 0 ? 1 : this.feedbacks.length - 1,
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProposalFeedbackComment = async (feedbackId) => {
    await getProposalFeedbackCommentApi(feedbackId)
      .then((res) => {
        runInAction(() => {
          this.feedbackIdCommentsMap[feedbackId] = res.data;
          this.commentsNamesMap[feedbackId] = res.data.map((item) => item.name);
          this.detectFeedbackAndComment(feedbackId);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postProposalFeedbackComment = async (feedbackId, body) => {
    await postProposalFeedbackCommentApi(feedbackId, body)
      .then((res) => {
        this.getProposalFeedbackComment(feedbackId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  putProposalFeedbackComment = async (commentId, body) => {
    await putProposalFeedbackCommentApi(commentId, body)
      .then((res) => {
        this.getProposalFeedbackComment(this.feedbackId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteProposalFeedbackComment = async (commentId) => {
    await deleteProposalFeedbackCommentApi(commentId)
      .then((res) => {
        this.getProposalFeedbackComment(this.feedbackId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postProposalFeedbackCompleted = async (proposalId) => {
    await postProposalFeedbackCompletedApi(proposalId)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const feedbackStore = new FeedbackStore();

export default feedbackStore;
