import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from 'stores';
import { useTranslation } from 'react-i18next';
import { SEO } from 'components/atoms/SEO';

import RoworkerTips from './RoworkerTips';
import ClientTips from './ClientTips';
import ServiceMenu from './ServiceMenu';
import ServiceContent from './ServiceContent';
import Button from 'components/atoms/Button';

const Service = observer(() => {
  const { t } = useTranslation('service');

  const { serviceStore } = stores;
  const {
    getServiceInfos,
    serviceInfos,
    setActiveTab,
    activeTab,
    pricingInfoMenus,
    getPricingInfos,
    pricingInfos,
    usecaseInfoMenus,
    getUsecaseInfos,
    usecaseInfos,
    menuLengths,
  } = serviceStore;
  const { first, second } = menuLengths;
  let match = useRouteMatch();
  let history = useHistory();
  const currentTab = history.location.state?.currentTab;
  const currentType = window.location.pathname.split('/')[2];
  const language = localStorage.getItem('i18nextLng');

  useEffect(() => {
    getServiceInfos();
  }, [getServiceInfos, language]);

  useEffect(() => {
    if (match.path.startsWith('/service')) setActiveTab(currentTab || 0);

    if (match.path.startsWith('/pricing') && pricingInfoMenus[0]?.type) {
      setActiveTab(
        currentType
          ? 1 + pricingInfoMenus.findIndex((x) => x.type === currentType.toUpperCase())
          : currentTab || 1,
      );
      getPricingInfos(currentType || pricingInfoMenus[0].type);
    }
    if (match.path.startsWith('/use-cases') && usecaseInfoMenus[0]?.type) {
      setActiveTab(
        currentType
          ? 1 + first + usecaseInfoMenus.findIndex((x) => x.type === currentType.toUpperCase())
          : currentTab || 1 + first,
      );
      getUsecaseInfos(currentType || usecaseInfoMenus[0].type);
    }
    if (match.path.startsWith('/tips')) {
      setActiveTab(1 + first + second);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path, pricingInfoMenus, usecaseInfoMenus, currentTab, first]);

  const goRoworkDocs = () => {
    window.open('https://docs.rowork.com/', '_blank');
  };

  return (
    <Wrapper>
      <SEO title="service" />
      <Container>
        <ServiceNav>
          <ServiceMenu />
        </ServiceNav>
        <Switch>
          <Route path="/service">
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <ServiceContent keyName="serviceInfo" data={serviceInfos} t={t('introduction')}>
                <Button color="background" border onClick={goRoworkDocs}>
                  {t('introduction').roworkViewb}
                </Button>
              </ServiceContent>
            </div>
            <div
              style={{
                display: activeTab === 2 + first + second ? 'block' : 'none',
                width: '100%',
              }}
            >
              <ClientTips t={t('clientTips')} />
            </div>
          </Route>
          <Route path="/tips" render={() => <RoworkerTips t={t('roworkerTips')} />} />
          <Route
            path="/pricing"
            render={() => <ServiceContent keyName="pricingInfo" data={pricingInfos} />}
          />
          <Route
            path="/use-cases"
            render={() => <ServiceContent keyName="usecaseInfo" data={usecaseInfos} />}
          />
        </Switch>
        {/* <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
          <ServiceContent keyName="serviceInfo" data={serviceInfos} t={t('introduction')}>
            <Button color="background" border onClick={goRoworkDocs}>
              {t('introduction').roworkViewb}
            </Button>
          </ServiceContent>
        </div> */}
        {/* {0 < activeTab && activeTab < 1 + first && (
          <ServiceContent keyName="pricingInfo" data={pricingInfos} />
        )}
        {first < activeTab && activeTab < 1 + first + second && (
          <ServiceContent keyName="usecaseInfo" data={usecaseInfos} />
        )} */}
        {/* <div
          style={{ display: activeTab === 1 + first + second ? 'block' : 'none', width: '100%' }}
        >
          <RoworkerTips t={t('roworkerTips')} />
        </div>
        <div
          style={{ display: activeTab === 2 + first + second ? 'block' : 'none', width: '100%' }}
        >
          <ClientTips t={t('clientTips')} />
        </div> */}
      </Container>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100vw;
  padding: 152px 57px 270px 57px;
  @media ${theme.mobile} {
    padding: 76px 28px;
  }
`;
const Container = styled.main`
  width: 100%;
  max-width: 1222px;
  margin: 0px auto;
  display: flex;
`;

const ServiceNav = styled.nav`
  padding: 115px 5% 0 0;
  margin: 0;
  @media ${theme.tablet} {
    display: none;
  }
`;

export default Service;
