import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import theme, { fonts } from 'styles/theme';
import scrollButton from 'resources/scrollButton.png';
import scrollButtonSel from 'resources/scrollButtonSel.png';
import { throttle } from 'lodash-es';
import { Others } from 'styles/MediaQuery';

const Journey = ({ sectionTitle, title, data }) => {
  // 현재 스크롤 위치 계산 함수
  const slidePosition = useCallback(() => {
    if (!target.current) {
      return;
    }
    const element = target.current;
    const windowScroll = element.scrollLeft; // Distance of the scrollbar from the leftmost point
    const clientWidth = element.clientWidth;
    const totalWidth = element.scrollWidth - element.clientWidth; // Total width the scrollbar can traverse

    return { windowScroll, totalWidth, clientWidth };
  }, []);

  // 뷰포트에 맞는 스크롤 포인트 계산
  const [scrollPoint, setScrollPoint] = useState(0);

  const getScrollPoint = useCallback(() => {
    const { clientWidth } = slidePosition();

    let scrollPoint = 0;
    if (clientWidth < 393) {
      scrollPoint = clientWidth;
    }
    if (393 <= clientWidth) {
      scrollPoint = 393;
    }
    return scrollPoint;
  }, [slidePosition]);

  useEffect(() => {
    const scrollPoint = getScrollPoint();
    setScrollPoint(scrollPoint);
  }, [getScrollPoint]);

  // 버튼클릭 시 슬라이드 처리 함수
  const [active, setActive] = useState({ prev: false, next: true });

  const hadlePrevClick = () => {
    if (!active.prev) return;
    const { windowScroll } = slidePosition();
    const scrollPoint = getScrollPoint();

    if (0 < windowScroll && windowScroll <= scrollPoint)
      document.getElementById('slide').scrollLeft = 0;
    if (scrollPoint < windowScroll && windowScroll <= scrollPoint * 2)
      document.getElementById('slide').scrollLeft = scrollPoint;
    if (scrollPoint * 2 < windowScroll && windowScroll <= scrollPoint * 3)
      document.getElementById('slide').scrollLeft = scrollPoint * 2;
    if (scrollPoint * 3 < windowScroll && windowScroll <= scrollPoint * 4)
      document.getElementById('slide').scrollLeft = scrollPoint * 3;
    if (scrollPoint * 4 < windowScroll && windowScroll <= scrollPoint * 5)
      document.getElementById('slide').scrollLeft = scrollPoint * 4;
  };

  const handleNextClick = () => {
    if (!active.next) return;
    const { windowScroll } = slidePosition();
    const scrollPoint = getScrollPoint();

    if (0 <= windowScroll && windowScroll < scrollPoint)
      document.getElementById('slide').scrollLeft = scrollPoint;
    if (scrollPoint <= windowScroll && windowScroll < scrollPoint * 2)
      document.getElementById('slide').scrollLeft = scrollPoint * 2;
    if (scrollPoint * 2 <= windowScroll && windowScroll < scrollPoint * 3)
      document.getElementById('slide').scrollLeft = scrollPoint * 3;
    if (scrollPoint * 3 <= windowScroll && windowScroll < scrollPoint * 4)
      document.getElementById('slide').scrollLeft = scrollPoint * 4;
    if (scrollPoint * 4 <= windowScroll && windowScroll < scrollPoint * 5)
      document.getElementById('slide').scrollLeft = scrollPoint * 5;
  };

  // 스크롤시 슬라이드 처리 함수
  const target = useRef(null);

  const handleSlideScroll = useMemo(
    () =>
      throttle(() => {
        const { windowScroll, totalWidth } = slidePosition();
        // console.log('scroll:' + windowScroll);
        // console.log('total:' + totalWidth);

        if (windowScroll === 0) {
          return setActive({ prev: false, next: true });
        }
        if (0 < windowScroll && windowScroll < totalWidth) {
          return setActive({ prev: true, next: true });
        }
        if (windowScroll === totalWidth) {
          return setActive({ prev: true, next: false });
        }
      }, 300),
    [slidePosition],
  );

  return (
    <Wrapper>
      <Container>
        <Others>
          <SectionTitle>{sectionTitle}</SectionTitle>
        </Others>
        <TitleWrapper>
          <Title>{title}</Title>
          <ScrollBtnWrapper>
            <BtnContainer onClick={hadlePrevClick} active={active.prev}>
              <Button src={scrollButton} alt="scrollBtn" />
              <Button src={scrollButtonSel} alt="scrollBtn" />
            </BtnContainer>
            <BtnContainer right onClick={handleNextClick} active={active.next}>
              <Button src={scrollButton} alt="scrollBtn" />
              <Button src={scrollButtonSel} alt="scrollBtn" />
            </BtnContainer>
          </ScrollBtnWrapper>
        </TitleWrapper>
      </Container>
      <JourneyContainer>
        <JourneyScroll id="slide" onScroll={handleSlideScroll} ref={target}>
          {data.map((item, index) => (
            <ProcessCard key={item.id} id={item.id} width={scrollPoint}>
              <Step>{item.step}</Step>
              <LineWrapper>
                <Dot />
                <Line last={index} width={scrollPoint} />
              </LineWrapper>
              <ProcessTitle>{item.title}</ProcessTitle>
              <ProcessDesc>{item.desc}</ProcessDesc>
            </ProcessCard>
          ))}
        </JourneyScroll>
      </JourneyContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  padding: 184px 0 130px 0;
  overflow: hidden;
  @media ${theme.mobile} {
    padding: 113px 0 32px 0;
  }
`;
const Container = styled.div`
  /* width: 100%; */
  max-width: 1640px;
  margin: 0px 0 0 auto;
  padding: 0px 57px 0px 110px;
  @media ${theme.tablet} {
    padding: 0 57px;
  }
  @media ${theme.mobile} {
    padding: 0 28px;
  }
  @media ${theme.mobileS} {
    padding: 0 20px;
  }
`;
const SectionTitle = styled.div`
  ${fonts.l_sectionTitle}
  text-align: left;
`;
const TitleWrapper = styled.div`
  max-width: 1438px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${theme.mobile} {
    flex-direction: column;
    div:first-child {
      align-self: flex-start;
    }
    div:last-child {
      align-self: flex-end;
    }
  }
`;
const Title = styled.h2`
  ${fonts.l_Title}
  @media ${theme.mobile} {
    ${fonts.bigTitle}
  }
`;
const ScrollBtnWrapper = styled.div`
  width: 174px;
  display: flex;
  justify-content: space-between;
  @media ${theme.mobile} {
    margin-top: 24px;
    width: 100px;
  }
`;

const BtnContainer = styled.div`
  img:first-child {
    ${(props) => props.right && 'transform: rotate(180deg)'};
  }
  img:last-child {
    display: none;
    ${(props) => !props.right && 'transform: rotate(180deg)'};
  }
  ${(props) =>
    props.active &&
    css`
      cursor: pointer;
      img:first-child {
        display: none;
      }
      img:last-child {
        display: inline;
      }
    `}
  &:hover {
  }
`;

const Button = styled.img`
  width: 66px;
  height: 66px;
  @media ${theme.mobile} {
    width: 42.48px;
    height: 42.48px;
  }
`;

const JourneyContainer = styled.div`
  padding: 109px 0 0px 110px;
  max-width: 1630px;
  margin: 0px 0 0 auto;
  overflow: hidden;
  @media ${theme.tablet} {
    padding: 109px 0 0px 57px;
  }
  @media ${theme.mobile} {
    padding: 0px 0 0px 28px;
  }
  @media ${theme.mobileS} {
    padding: 0px 0 0px 20px;
  }
`;
const JourneyScroll = styled.div`
  width: 100%;
  padding-bottom: 54px;
  display: flex;
  /* a버튼으로 스크롤 이동 애니메이션 */
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ProcessCard = styled.div`
  width: ${(props) => props.width}px;
  white-space: pre-line;
  flex-shrink: 0;
`;
const Step = styled.div`
  height: 108px;
  font-size: 64px;
  font-weight: 700;
  line-height: 108px;
  color: var(--primary);
  @media ${theme.mobile} {
    height: 81px;
    font-size: 48px;
    line-height: 81px;
  }
`;
const LineWrapper = styled.div`
  margin-top: 26px;
  ${theme.flex.center}
  @media ${theme.mobile} {
    margin-top: 6px;
  }
`;
const Dot = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: var(--primary);
`;
const Line = styled.div`
  width: calc(${(props) => props.width}px - 19px);
  ${(props) => props.last === 5 && 'opacity: 0;'};
  height: 6px;
  background: #2c62ba14;
`;
const ProcessTitle = styled.h3`
  margin-top: 54px;
  font-size: 24px;
  font-weight: 700;
  line-height: 41px;
  color: var(--grayText);
  @media ${theme.mobile} {
    margin-top: 33px;
    width: 100%;
  }
`;
const ProcessDesc = styled.div`
  margin-top: 25px;
  width: 296px;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: var(--grayText2);
  @media ${theme.mobile} {
    margin-top: 16px;
    font-size: 17px;
  }
  @media ${theme.mobileS} {
    width: 90%;
  }
`;
export default Journey;
