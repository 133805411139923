import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const Input = forwardRef(({ value, ...rest }, ref) => {
  const language = localStorage.getItem('i18nextLng');

  return <InputBox value={value || ''} ref={ref} {...rest} lang={language} />;
});

const inputs = {
  small: {
    width: '169px',
  },
  medium: {
    width: '348px',
  },
  large: {
    width: '552px',
  },
  fullWidth: {
    width: '100%',
    height: '100%',
  },
};

const sizeStyles = css`
  ${({ size }) => css`
    width: ${inputs[size].width};
  `}
`;

const InputBox = styled.input`
  /* height: 47px; */
  height: inherit;
  border-radius: 5px;
  box-sizing: border-box;
  border: var(--border);
  box-shadow: var(--borderDropShadow);
  padding: 14px 0 14px 19px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: var(--bodyText2);

  ${sizeStyles}

  &::placeholder {
    color: var(--secondary);
    ${(props) => props.lang === 'en' && 'font-size: 14.5px;'}
  }
  &:focus {
    outline: none;
    border: 1px solid var(--primary);
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: var(--primary) !important;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
  &:-webkit-autofill::first-line {
  }
  &::-webkit-contacts-auto-fill-button {
    opacity: 0;
  }
  &.fail {
    color: var(--bodyText2);
    border: 1px solid var(--error);
    &:-webkit-autofill {
      -webkit-text-fill-color: var(--bodyText2) !important;
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
    }
  }
  &:disabled {
    background: #efefef;
    color: var(--secondary);
    ${(props) => props.color === 'primary' && 'color: var(--primary);'}
  }
`;

Input.defaultProps = {
  placeholder: '-',
  size: 'medium',
};

export default Input;
