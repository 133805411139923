import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Titles from './Titles';

const Solution = ({ sectionTitle, title, data }) => {
  return (
    <Wrapper>
      <Container>
        <Titles sectionTitle={sectionTitle} title={title} />
        <CardsArea>
          {data.map((item) => (
            <ImageCard key={item.id}>
              <Circle>
                <source srcSet={item.avif} type="image/avif" />
                <source srcSet={item.webp} type="image/webp" />
                <img
                  loading="lazy"
                  decoding="async"
                  src={item.img}
                  width={item.width}
                  height={item.height}
                  alt="solution-img"
                />
              </Circle>
              <SubTitle>{item.title}</SubTitle>
              <Content>{item.desc}</Content>
            </ImageCard>
          ))}
        </CardsArea>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  padding: 0 57px;
  @media ${theme.mobile} {
    padding: 0 28px;
  }
  @media ${theme.mobileS} {
    padding: 0 20px;
  }
`;
const Container = styled.div`
  width: 100%;
  max-width: 1222px;
  margin: 0px auto;
`;

const CardsArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 28px 0 184px 0;
  @media ${theme.mobile} {
    flex-direction: column;
    align-items: center;
    padding: 0px 0 86px 0;
  }
`;
const ImageCard = styled.div`
  width: 300px;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 61px 0 0 0;
  :last-child {
    margin-right: 0px;
  }
  @media ${theme.mobile} {
    margin-right: 0;
    /* padding: 61px 0 0 0; */
    width: 100%;
  }
`;
const Circle = styled.picture`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background: #2c62ba14;
  ${theme.flex.center}
`;

const SubTitle = styled.h3`
  margin-top: 53px;
  font-size: 24px;
  font-weight: 600;
  line-height: 41px;
  color: var(--primary);
  @media ${theme.mobile} {
    margin-top: 34px;
  }
`;
const Content = styled.div`
  margin-top: 18px;
  font-size: 17px;
  font-weight: 400;
  line-height: 27px;
  color: var(--grayText);
  text-align: center;
`;

export default Solution;
