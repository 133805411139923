import React, { useEffect, useState } from 'react';
import {
  NotificationWrapper,
  LoginedWrapper,
  NotificationIcon,
  HamburgerIcon,
  DarkBackground,
  DrawWrap,
  Drawer,
  User,
  MyInfoWrap,
  MyInfoTitle,
  MyInfoMenuWrap,
  MyInfoLink,
  InfoText,
  InfoIcon,
  LogoutButton,
  LogoutIcon,
  LogoutText,
  NotificationBadge,
  DevInfoBox,
  DevInfoText,
  DropdownWrap,
} from './MobileNotificationContainer.style';
import stores from 'stores';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import Button from 'components/atoms/Button';
import MobileAlarm from '../MobileAlarm';
import usePreventScroll from 'utils/usePreventScroll';
import { isEmpty } from 'lodash-es';
import { menus } from 'components/pages/myInfo/myInfoData';
import NavigationMenu from 'components/templates/navigation/NavigationMenu';
import { useTranslation } from 'react-i18next';
import LangSwitcher from 'components/molecules/LangSwitcher';
import UserName from 'components/atoms/UserName';
import { useParams } from 'react-router-dom';
import { DropdownWithImage } from 'components/atoms/DropdownMilestone';

const MobileNotificationContainer = observer(() => {
  const { myInfoStore, authStore, dashboardStore } = stores;
  const { getUser, userInfo } = authStore;
  const {
    getUnreadCountNotifications,
    unReadCount,
    getNavFetchNotifications,
    navAllNotifications,
    inProgressProducts,
    getInProgressProducts,
    currentId,
  } = dashboardStore;
  const { name } = toJS(userInfo);

  const { t } = useTranslation('common');

  useEffect(() => {
    if (isEmpty(toJS(userInfo))) {
      getUser();
      getUnreadCountNotifications();
      getNavFetchNotifications(0, 5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const match = useRouteMatch();
  let history = useHistory();

  const [openNav, setOpenNav] = usePreventScroll(false);
  const [openAlarm, setOpenAlarm] = usePreventScroll(false);
  const [openInfoTab, setOpenInfoTab] = useState(match.path.startsWith('/myinfo') ? true : false);
  const [openProfile, setOpenProfile] = useState(false);
  const [myInfoTab, setMyInfoTab] = useState('null');

  const toggleMenu = () => {
    setOpenNav((prev) => !prev);
  };

  const handleInfoTabClick = (tab) => {
    history.push('/myinfo');
    myInfoStore.setActiveTab(tab);
    toggleMenu();
  };

  const handleOpenProfile = (index, active) => {
    if (index === 0) setOpenProfile(!openProfile);
    else {
      history.push('/myinfo');
      handleInfoTabClick(active);
    }
  };

  useEffect(() => {
    setMyInfoTab(myInfoStore.activeTab);
  }, [myInfoStore.activeTab]);

  const handleOpenAlarm = () => {
    if (navAllNotifications.length > 0) setOpenAlarm(!openAlarm);
  };

  const { projectId, productId } = useParams();
  /* maintain projectId */
  const currentProjectId =
    history.location.state?.projectId ||
    history.location.state?.productId ||
    projectId ||
    productId ||
    currentId;

  useEffect(() => {
    getInProgressProducts(currentProjectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectId]);

  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    const productIndex = inProgressProducts.findIndex(
      (item) => item.productId === currentProjectId,
    );
    setSelectedProduct(inProgressProducts[productIndex]);
  }, [currentProjectId, inProgressProducts]);

  const handleSelectProduct = (val) => {
    setSelectedProduct(val);
    history.push({ pathname: `/project/${val.productId}` });
    window.location.reload();
  };

  return (
    <>
      {match.path === '/' ||
      match.path.startsWith('/service') ||
      match.path.startsWith('/pricing') ||
      match.path.startsWith('/use-cases') ? (
        <LoginedWrapper>
          <UserName name={t('user', { user: name })} />
          <Link to="/home">
            <Button size="small">{t('managementb')}</Button>
          </Link>
        </LoginedWrapper>
      ) : match.path.startsWith('/home') ? (
        <LoginedWrapper>
          <HamburgerIcon onClick={() => setOpenNav(!openNav)} />
          <UserName name={t('user', { user: name })} />
          <Link to="/proposalForm">
            <Button size="small">{t('pjR')}</Button>
          </Link>
        </LoginedWrapper>
      ) : (
        <NotificationWrapper>
          {unReadCount > 0 && (
            <NotificationBadge len={unReadCount}>{unReadCount}</NotificationBadge>
          )}
          <LangSwitcher />
          <NotificationIcon onClick={handleOpenAlarm} $active={navAllNotifications.length > 0} />
          <HamburgerIcon onClick={() => setOpenNav(!openNav)} />
        </NotificationWrapper>
      )}
      {openAlarm ? (
        <MobileAlarm data={toJS(navAllNotifications)} onSetAlarm={handleOpenAlarm} />
      ) : (
        openNav && (
          <>
            <DarkBackground onClick={() => setOpenNav(!openNav)} />
            <Drawer>
              <DrawWrap>
                <User>
                  <UserName name={t('user', { user: name })} />
                </User>
                <MyInfoWrap>
                  <MyInfoTitle
                    onClick={() => setOpenInfoTab(!openInfoTab)}
                    focus={match.path.startsWith('/myinfo') ? false : openInfoTab}
                  >
                    <InfoText focus={match.path.startsWith('/myinfo') ? false : openInfoTab}>
                      {t('myInfo')}
                    </InfoText>
                    <InfoIcon $focus={openInfoTab} stroke="var(--grayText)" />
                  </MyInfoTitle>
                  {openInfoTab && (
                    <MyInfoMenuWrap>
                      {menus
                        .filter((_, i) => i < 3)
                        .map((info, i) => (
                          <MyInfoLink
                            key={`mobile-info__${i}`}
                            onClick={() => handleInfoTabClick(info.active[0])}
                            focus={
                              match.path.startsWith('/myinfo')
                                ? myInfoTab === info.active[0]
                                : false
                            }
                          >
                            {info.value}
                          </MyInfoLink>
                        ))}
                    </MyInfoMenuWrap>
                  )}
                </MyInfoWrap>
                <DropdownWrap>
                  <DropdownWithImage
                    value={selectedProduct}
                    onSelect={handleSelectProduct}
                    options={inProgressProducts}
                  />
                </DropdownWrap>
                <NavigationMenu openInfoTab={openInfoTab} />
              </DrawWrap>
              <LogoutButton>
                <LogoutIcon />
                <LogoutText to="/" onClick={() => authStore.signOut()}>
                  {t('logOut')}
                </LogoutText>
              </LogoutButton>
            </Drawer>
          </>
        )
      )}
    </>
  );
});

export default MobileNotificationContainer;
