import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import theme, { fonts } from 'styles/theme';
import closeIconW from 'resources/closeIconW.png';
import { createPortal } from 'react-dom';

export const TitleTooltip = ({ children, text, titleLength, style, index }) => {
  const [show, setShow] = useState(false);
  const element = useRef(text);

  const renderTooltip = () => {
    if (!show) return null;

    const tooltipElement = (
      <TitleTip isShow={show} index={index}>
        {text}
      </TitleTip>
    );

    return createPortal(tooltipElement, document.getElementById(text));
  };

  return (
    <TitleTooltipWrap ref={element} style={style}>
      <Title onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        {children}
      </Title>
      {/* <TitleTip isShow={show}>{text}</TitleTip> */}
      {text.length > titleLength && renderTooltip()}
    </TitleTooltipWrap>
  );
};

const TitleTooltipWrap = styled.div`
  position: relative;
`;

const Title = styled.div`
  ${theme.ellipsis}
`;

const TitleTip = styled.span`
  display: flex;
  align-items: center;
  height: 35px;
  width: max-content;
  visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
  background-color: var(--primary);
  position: absolute;
  padding: 0 12px;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  border-radius: 5px;
  bottom: 0;
  z-index: 99;

  ${(props) =>
    props.index < 9 &&
    css`
      top: 57px;
      left: 90px;

      &:before {
        content: '';
        position: absolute;
        left: 30px;
        top: -18px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 11px solid var(--primary);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }
    `}

  ${(props) =>
    props.index == 9 &&
    css`
      bottom: 57px;
      left: 90px;

      &:before {
        content: '';
        position: absolute;
        left: 30px;
        bottom: -10px;
        width: 0;
        height: 0;
        border-top: 11px solid var(--primary);
        border-bottom: 8px transparent;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }
    `}
`;

const Tooltip = ({ title, desc, data, confirm, emphasis, style, visible, onClose, ...rest }) => {
  return (
    <>
      <Tip style={style ? style : null} visible={visible} {...rest}>
        {onClose && <Img src={closeIconW} alt="close" onClick={onClose} {...rest} />}
        {title && <TipHeader {...rest}>{title}</TipHeader>}
        {desc && <TipText {...rest}>{desc}</TipText>}
        {data &&
          data.map((item, index) => (
            <TipText key={`tooltip-item__${index}`} emphasis={emphasis === index}>
              {item}
            </TipText>
          ))}
        {confirm && <ConfirmBtn onClick={onClose}>{confirm}</ConfirmBtn>}
      </Tip>
    </>
  );
};

const Tip = styled.div`
  width: 272px;
  /* height: auto; */
  margin-top: 24px;
  padding: 20px;
  background-color: var(--primary);
  position: relative;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  z-index: 10;
  color: #fff;
  &:before {
    content: '';
    position: absolute;
    left: 123px;
    top: -27px;
    width: 0;
    height: 0;
    border-top: 13px solid transparent;
    border-bottom: 15px solid var(--primary);
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;

    ${(props) =>
      props.left &&
      css`
        left: -30px;
        top: 32px;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        border-left: 13px solid transparent;
        border-right: 19px solid var(--primary);
      `}

    ${(props) =>
      props.todoTooltip &&
      css`
        left: 58px;
        top: -27px;
        border-top: 13px solid transparent;
        border-bottom: 15px solid var(--primary);
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
      `}
  }
  ${(props) => !props.visible && { display: 'none' }}
`;

const Img = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const TipHeader = styled.h4`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  margin: 0;
  margin-bottom: 13px;
  ${(props) =>
    props.todoTooltip &&
    css`
      font-size: 14px;
      font-weight: 700;
      line-height: 15px;
    `}
`;

const TipText = styled.p`
  font-weight: ${(props) => (props.emphasis ? 'bold' : 'normal')};
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  margin: 0;
  ${(props) =>
    props.todoTooltip &&
    css`
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
    `}
`;

const ConfirmBtn = styled.button`
  margin-top: 9px;
  padding: 7px 9px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: var(--primary);
  background: white;
  :hover {
    font-weight: 700;
  }
`;

export default Tooltip;
