/* eslint-disable no-useless-escape */
const delimiter = /\<rowork-tag-data.*\<\/rowork-tag-data>/gi;
const preTag = /\<rowork-tag-data[^>]*\>/gi;

// data-type, data-id 찾기
// const dataTypeRegEx = /(?<=data-type=").*(?=" data-id)/gi;
// const dataIdRegEx = /(?<=data-id=").*(?=">)/gi;
const dataTypeRegEx = /data-type="(\S+).*(?=" data-id)/;
const dataIdRegEx = /data-id="(\S+).*(?=">)/;

// rowork-tag-data 태그 사이 내용 추출
export const getBetweenTag = (value) => {
  if (value.match(delimiter)) {
    let name = value.match(delimiter);
    name += '';
    name = name.split(preTag).join('');
    name = name.split('</rowork-tag-data>').join('');
    return '@' + name;
  }
};

// QuestionPopUp inputHashValue에 저장해 놓기
export const setHashContent = (value) => {
  if (!value.match(delimiter)) return [];
  return [getBetweenTag(value), value.match(delimiter)[0]];
};

// QuestionPopUp inputTextValues 표시
// 줄바꿈을 <br>로 변경
const renderNewLine = (str) => {
  str = str.replace(/\r\n|\n/g, '<br>');
  if (str.endsWith('<br>')) {
    // 문자열 끝의 <br>을 <div><br></div>로 대체
    str = str.slice(0, -4) + '<div><br></div>';
  }
  return str;
};

export const renderInputTextContent = (str) => {
  const value = renderNewLine(str);

  if (!value.match(delimiter)) return [value];
  return [
    value.split(delimiter)[0],
    `<span id='deleteTag'>${getBetweenTag(value)}</span>&nbsp;`,
    value.split(delimiter)[1],
  ];
};

// 줄바꿈을 <br>로 대체
const replaceNewLine = (str) => {
  return str.replace(/\r\n|\n/g, '<br>');
};

// <br> 태그를 \n으로 대체
const replaceBrTags = (str) => {
  return str.replace(/<br>/g, '\n');
};

// <div> 태그 사이 내용 추출하며 줄바꿈
const replaceDivTags = (str) => {
  return str.replace(/<div>(.*?)<\/div>/g, (_, p1) => {
    return p1 === '<br>' ? '\n' : `\n${p1}`;
  });
};

export const replaceTagsWithNewLine = (str) => {
  return replaceBrTags(replaceDivTags(str));
};

// QuestionPopUp에서 해시태그 최초 추가시 inputTextValues 변경
export const changeInputHashContent = (str, hashValue) => {
  const value = [];
  for (let i = 0; i < str.length; i++) {
    value.push(replaceNewLine(str[i]));
  }
  return [value.join(''), `<span id='deleteTag'>${hashValue}</span>&nbsp;`];
};

const spanDelimiter = /\<span.*\<\/span>/gi;
const preSpan = /\<span[^>]*\>/gi;

// 해시태그 삭제
export const deleteHashTag = (value) => {
  if (value.match(spanDelimiter))
    return [value.split(spanDelimiter)[0], value.split(spanDelimiter)[1].replace(/&nbsp;/g, '')];
  else return [value.replace(/&nbsp;/g, '')];
};

// <span> 태그 내용 추출
export const getHashTagText = (value) => {
  if (value.match(spanDelimiter)) {
    let hashText = value.match(spanDelimiter);
    hashText += '';
    hashText = hashText.split(preSpan).join('');
    hashText = hashText.split('</span>').join('');
    return hashText;
  }
};

// QuestionPopUp에서 content 서버로 보내기 전 업데이트
export const updateInnerHTML = (value, hash) => {
  if (!value.match(spanDelimiter)) return [value];
  return [
    value.split(spanDelimiter)[0],
    hash,
    value.split(spanDelimiter)[1].replace(/&nbsp;/g, ''),
  ].join('');
};

// QuestionCard 렌더링
export const RenderCardContent = ({ value, onClick }) => {
  const dataType = value.match(dataTypeRegEx);
  const dataId = value.match(dataIdRegEx);

  return (
    <>
      {value.split(delimiter)[0]}
      <span data-type={dataType} data-id={dataId} onClick={() => onClick(dataType[1], dataId[1])}>
        {getBetweenTag(value)}
      </span>{' '}
      {value.split(delimiter)[1]}
    </>
  );
};
