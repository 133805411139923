import { useEffect, useState } from 'react';

const usePreventScroll = (initialState) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    if (isActive) {
      setPreventScroll();
      return () => {
        clearPreventScroll();
      };
    }
  }, [isActive]);

  return [isActive, setIsActive];
};

export const setPreventScroll = () => {
  document.body.style.cssText = `
      touch-action: none;
      top: -${window.scrollY}px;
      overflow: hidden;
      width: 100%;`;
};

export const clearPreventScroll = () => {
  const scrollY = document.body.style.top;
  document.body.style.cssText = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
};
export default usePreventScroll;
