import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import { Mobile, Others } from 'styles/MediaQuery';
import Titles from './Titles';

const Benefit = ({ sectionTitle, title, data }) => {
  return (
    <Wrapper>
      <Container>
        <Titles sectionTitle={sectionTitle} title={title} mb={78} />
        <Others>
          {data.map((item, index) =>
            index % 2 === 0 ? (
              <BenefitList key={item.id}>
                <Img
                  loading="lazy"
                  decoding="async"
                  src={item.img}
                  alt="benefit-img"
                  size={item.width}
                />
                <Content>
                  <BenefitSub>{item.sub}</BenefitSub>
                  <BenefitTitle>{item.title}</BenefitTitle>
                  <BenefitDesp>{item.desc}</BenefitDesp>
                </Content>
              </BenefitList>
            ) : (
              <BenefitList key={item.id}>
                <Content>
                  <BenefitSub>{item.sub}</BenefitSub>
                  <BenefitTitle>{item.title}</BenefitTitle>
                  <BenefitDesp>{item.desc}</BenefitDesp>
                </Content>
                <Img
                  loading="lazy"
                  decoding="async"
                  src={item.img}
                  alt="benefit-img"
                  size={item.width}
                />
              </BenefitList>
            ),
          )}
        </Others>
        <Mobile>
          {data.map((item) => (
            <BenefitList key={item.id}>
              <Img
                loading="lazy"
                decoding="async"
                src={item.img}
                alt="benefit-img"
                size={item.width}
              />
              <Content>
                <BenefitSub>{item.sub}</BenefitSub>
                <BenefitTitle>{item.title}</BenefitTitle>
                <BenefitDesp>{item.desc}</BenefitDesp>
              </Content>
            </BenefitList>
          ))}
        </Mobile>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: var(--background);
  width: 100vw;
  padding: 0 57px 10px 57px;

  @media ${theme.mobile} {
    padding: 0 28px;
  }
  @media ${theme.mobileS} {
    padding: 0 20px;
  }
`;
const Container = styled.div`
  width: 100%;
  max-width: 1222px;
  margin: 0px auto;
`;

const BenefitList = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 0 184px 0;
  @media ${theme.mobile} {
    flex-direction: column;
    padding: 65px 0 59px 0;
  }
`;
const Img = styled.img`
  width: 30%;
  height: 30%;
  max-width: ${(props) => props.size};
  @media ${theme.mobile} {
    width: 70%;
    height: 70%;
  }
`;
const Content = styled.div`
  width: 408px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${theme.mobile} {
    width: 100%;
    padding-top: 51px;
  }
`;
const BenefitSub = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: var(--secondary);
`;
const BenefitTitle = styled.h3`
  width: 446px;
  margin-top: 8px;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  color: var(--bodyText);
  @media ${theme.mobile} {
    width: 100%;
    font-size: 24px;
    line-height: 33px;
  }
`;
const BenefitDesp = styled.div`
  margin-top: 18px;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  color: var(--bodyText2);
  @media ${theme.mobile} {
    font-size: 17px;
    line-height: 27px;
  }
`;

export default Benefit;
