import { useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import { Mobile, Others } from 'styles/MediaQuery';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { postTempFileApi, postSecuredTempFileApi } from 'api/API';
import replaceErrorState from 'api/replaceErrorState';
import FormatBytes from 'utils/FormatBytes';

import Progress from 'components/atoms/Progress';
import fileIcon from 'resources/file.png';
import fileSmall from 'resources/fileSmall.png';
import deleteButton from 'resources/deleteButton.png';
import { css } from 'styled-components';

import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { ImageDataTypes } from 'utils/ImageUtils';
import { VideoDataTypes } from 'utils/VideoUtils';

const UploadedFile = ({
  children,
  file,
  previewFile,
  onSetTempSavedFile,
  style,
  toDelete,
  index,
  fromServer,
  disabled,
  preview,
}) => {
  const { t } = useTranslation('error');

  const isImageType = useMemo(() => {
    return ImageDataTypes.includes(file?.file?.type);
  }, [file]);

  const isVideoType = useMemo(() => {
    return VideoDataTypes.includes(file?.file?.type);
  }, [file]);

  return children ? (
    <UploadedContainer file={file} onSetTempSavedFile={onSetTempSavedFile}>
      {children}
    </UploadedContainer>
  ) : isImageType && preview ? (
    <PhotoProvider>
      <PhotoView src={previewFile?.preview}>
        <UploadedWrapper style={style ? style : null} disabled={disabled}>
          <UploadedContainer
            file={file}
            onSetTempSavedFile={onSetTempSavedFile}
            style={style}
            toDelete={toDelete}
            index={index}
            fromServer={fromServer}
            disabled={disabled}
          />
        </UploadedWrapper>
      </PhotoView>
    </PhotoProvider>
  ) : isVideoType && preview ? (
    <PhotoProvider>
      <PhotoView
        width={640}
        height={360}
        render={({ scale, attrs }) => {
          const width = attrs.style.width;
          const offset = (width - 640) / 640;
          const childScale = scale === 1 ? scale + offset : 1 + offset;

          return (
            <div {...attrs}>
              <div
                style={{
                  transform: `scale(${childScale})`,
                  width: 640,
                  transformOrigin: '0 0',
                }}
              >
                <video width="640" controls autoPlay>
                  <source src={previewFile?.preview} type={file.file.type} />
                </video>
              </div>
            </div>
          );
        }}
      >
        <UploadedWrapper style={style ? style : null} disabled={disabled}>
          <UploadedContainer
            file={file}
            onSetTempSavedFile={onSetTempSavedFile}
            style={style}
            toDelete={toDelete}
            index={index}
            fromServer={fromServer}
            disabled={disabled}
          />
        </UploadedWrapper>
      </PhotoView>
    </PhotoProvider>
  ) : (
    <UploadedWrapper style={style ? style : null} disabled={disabled}>
      <UploadedContainer
        file={file}
        onSetTempSavedFile={onSetTempSavedFile}
        style={style}
        toDelete={toDelete}
        index={index}
        fromServer={fromServer}
        disabled={disabled}
      />
    </UploadedWrapper>
  );
};

const UploadedContainer = ({
  children,
  file,
  previewFile,
  onSetTempSavedFile,
  style,
  toDelete,
  index,
  fromServer,
  disabled,
  secured,
}) => {
  const { t } = useTranslation('error');

  const [progress, setProgress] = useState(99);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const uploadFile = useCallback(
    async (file, secured) => {
      let data = new FormData();
      if (file.file) data.append('file', file.file);
      else data.append('file', file);

      const config = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setProgress(percent);
        },
        cancelToken: source.token,
      };

      if (!secured) {
        await postTempFileApi(data, config)
          .then((res) => {
            onSetTempSavedFile((prev) => [...prev, res.data[0]]);
          })
          .catch((error) => {
            if (error.message !== 'canceled') replaceErrorState(error, 'file', t('fileFailure'));
          });
      } else {
        await postSecuredTempFileApi(data, config)
          .then((res) => {
            onSetTempSavedFile((prev) => [...prev, res.data[0]]);
          })
          .catch((error) => {
            if (error.message !== 'canceled') replaceErrorState(error, 'file', t('fileFailure'));
          });
      }
    },
    [onSetTempSavedFile, source.token, t],
  );

  console.log(file?.file?.type, 'file type');

  useEffect(() => {
    if (onSetTempSavedFile) {
      uploadFile(file, secured);
      setProgress(0);
    } else {
      setProgress(100);
    }
    return () => {
      setProgress(0);
      source.cancel('canceled');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children ? (
    <>{children}</>
  ) : (
    <>
      <UploadedCont href={file?.fileUrl}>
        <Others>
          <img id="file-icon" src={fileIcon} alt="fileIcon" />
        </Others>
        <Mobile>
          <img id="file-icon-small" src={fileSmall} alt="fileSmall" />
        </Mobile>
        <FileDesc>
          <FileName percent={progress < 100}>
            {file?.materialId || file.personalDocId ? file.fileName : file?.file?.name}
          </FileName>
          <FileProgress>
            {progress < 100 && <Progress percent={progress} />}
            {progress === 100 && (
              <FileSize>
                {file.materialId || file.personalDocId
                  ? FormatBytes(file.fileSize, fromServer)
                  : FormatBytes(file?.file?.size, fromServer)}
              </FileSize>
            )}
          </FileProgress>
        </FileDesc>
      </UploadedCont>
      {progress === 100 && (
        <CloseButton
          onClick={() =>
            toDelete(
              file.materialId ? file.materialId : file.personalDocId ? file.personalDocId : index,
            )
          }
          type="button"
          disabled={disabled}
        >
          <img id="colse-icon" src={deleteButton} alt="deleteButton" />
        </CloseButton>
      )}
    </>
  );
};

const UploadedWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 74px;
  background: white;
  border: ${theme.border};
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 45px 0 41px;
  justify-content: space-between;
  ${(props) => (props.style ? props.style : null)}
  &:hover {
    background: var(--primaryLight);
    text-shadow: 0 0 0.5px var(--bodyText2);
  }
  @media ${theme.mobile} {
    height: 61px;
    padding: 0 22px 0 22px;
  }
  ${(props) =>
    props.disabled &&
    css`
      &:hover {
        background: white;
        text-shadow: inherit;
      }
    `}
`;
const UploadedCont = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
`;
const FileDesc = styled.div`
  display: table-column;
  width: 100%;
`;
const FileName = styled.p`
  display: block;
  margin: 0;
  margin-left: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #132e58;
  @media ${theme.mobile} {
    font-size: 12px;
  }
`;
const FileProgress = styled.div`
  margin-left: 12px;
`;
const FileSize = styled.div`
  font-weight: normal;
  margin: 0;
  font-size: 10px;
  line-height: 14px;
  color: #afafaf;
`;
const CloseButton = styled.button`
  display: flex;
  :disabled {
    cursor: not-allowed;
    img {
      opacity: 30%;
    }
  }
`;
export default UploadedFile;
