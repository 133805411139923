import { makeAutoObservable, runInAction } from 'mobx';
import {
  getContractApi,
  getDevContractApi,
  getClientContractCommentsApi,
  postClientContractCommentsApi,
  deleteClientContractCommentsApi,
  getDevContractCommentsApi,
  postDevContractCommentsApi,
  deleteDevContractCommentsApi,
  signDevProjectApi,
  signProjectApi,
  getContractHistoryApi,
  getContractContentItemCommentsApi,
} from 'api/API';

class ContractSignStore {
  contract = {};
  comments = [];
  commentsLastPage = false;
  contractSignCompleted = false;
  contractHistory = [];

  constructor() {
    makeAutoObservable(this);
  }

  resetContractComments = () => {
    this.comments = [];
    this.commentsLastPage = false;
  };

  /* client contract comments */
  getFetchClientContractComments = async (id, pageNumber, pageSize) => {
    await getClientContractCommentsApi(id, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          if (res.data.length < pageSize) {
            this.commentsLastPage = true;
          }
          this.comments = [...this.comments, ...res.data];
        });
      })
      .catch((e) => console.log(e));
  };

  getReloadClientContractComments = async (id, pageNumber, pageSize) => {
    await getClientContractCommentsApi(id, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          this.comments = res.data;
        });
      })
      .catch((e) => console.log(e));
  };

  addClientContractComments = async (contractId, body) => {
    await postClientContractCommentsApi(contractId, body)
      .then((res) => {
        runInAction(() => {
          this.getReloadClientContractComments(contractId, 0, this.comments.length + 1);
        });
      })
      .catch((e) => console.log(e));
  };

  deleteClientContractComments = async (commentId, contractId) => {
    await deleteClientContractCommentsApi(commentId)
      .then((res) => {
        runInAction(() => {
          this.getReloadClientContractComments(
            contractId,
            0,
            this.comments.length - 1 === 0 ? 1 : this.comments.length - 1,
          );
        });
      })
      .catch((e) => console.log(e));
  };

  /* developer contract comments */
  getFetchDevContractComments = async (id, pageNumber, pageSize) => {
    await getDevContractCommentsApi(id, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          if (res.data.length < pageSize) {
            this.commentsLastPage = true;
          }
          this.comments = [...this.comments, ...res.data];
        });
      })
      .catch((e) => console.log(e));
  };

  getReloadDevContractComments = async (id, pageNumber, pageSize) => {
    await getDevContractCommentsApi(id, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          this.comments = res.data;
        });
      })
      .catch((e) => console.log(e));
  };

  addDevContractComments = async (contractId, body) => {
    await postDevContractCommentsApi(contractId, body)
      .then((res) => {
        runInAction(() => {
          this.getReloadDevContractComments(contractId, 0, this.comments.length + 1);
        });
      })
      .catch((e) => console.log(e));
  };

  deleteDevContractComments = async (commentId, contractId) => {
    await deleteDevContractCommentsApi(commentId)
      .then((res) => {
        runInAction(() => {
          this.getReloadDevContractComments(
            contractId,
            0,
            this.comments.length - 1 === 0 ? 1 : this.comments.length - 1,
          );
        });
      })
      .catch((e) => console.log(e));
  };

  contractSign = async (item, dev) => {
    if (dev) {
      await signDevProjectApi(item)
        .then((res) => {
          runInAction(() => {
            if (res.data.operationResult === 'SUCCESS') this.contractSignCompleted = true;
          });
        })
        .catch((e) => alert("Couldn't sign contract. error", e, `id: ${item}`));
    } else {
      await signProjectApi(item)
        .then((res) => {
          runInAction(() => {
            if (res.data.operationResult === 'SUCCESS') this.contractSignCompleted = true;
          });
        })
        .catch((e) => alert("Couldn't sign contract. error", e, `id: ${item}`));
    }
  };

  getContractHistory = async (contractId) => {
    await getContractHistoryApi(contractId)
      .then((res) => {
        runInAction(() => {
          console.log(res.data.data, 'history');
          this.contractHistory = res.data.data;
        });
      })
      .catch((e) => console.log(e));
  };

  getContractContentItemComments = async (contractContentItemId) => {
    await getContractContentItemCommentsApi(contractContentItemId)
      .then((res) => {
        runInAction(() => {
          console.log(res);
        });
      })
      .catch((e) => console.log(e));
  };
}

const contractSignStore = new ContractSignStore();

export default contractSignStore;
