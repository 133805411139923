import {
  Wrapper,
  TopContainer,
  MainContent,
  TitleContainer,
  ProjectTitle,
  Title,
  ChildrenWrapper,
  Desc,
} from './Container.style';
import NotificationContainer from '../NotificationContainer';
import Tabs from 'components/atoms/Tabs';
import { Mobile, Others } from 'styles/MediaQuery';
import { useHistory } from 'react-router-dom';
import Navbar from '../Navbar';
import SideMenuBar from 'components/templates/navigation/SideMenuBar';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import stores from 'stores';
import BreadcrombTrail from 'components/atoms/BreadcrombTrail';
import { Menu } from 'data/Menu';
import { SEO } from 'components/atoms/SEO';
import paths from 'data/paths';
import { PAGE, checkPath } from 'utils/PageUrlUtil';

const CommonLayout = observer(
  ({
    title,
    desc,
    children,
    tabs,
    activeTab,
    onTabClick,
    backButton,
    trailList,
    current,
    expandLayout,
  }) => {
    const { dashboardStore, projectStore } = stores;
    const path = window.location.pathname;

    let history = useHistory();
    const projectId =
      history.location.state?.projectId ||
      history.location.state?.productId ||
      window.location.pathname.split('/')[2];

    useEffect(() => {
      if (projectId) {
        projectStore.getProject(projectId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGobackProject = () => {
      if (
        path.startsWith('/milestone/') ||
        new RegExp('/product/[a-zA-Z0-9-_]+/milestone/form').test(path) ||
        new RegExp('/product/[a-zA-Z0-9-_]+/milestone/[a-zA-Z0-9-_]+/form').test(path) ||
        path.startsWith('/project-report/') ||
        path.startsWith('/project-code-quality') ||
        path.startsWith('/product-component-detail') ||
        path.startsWith('/create-repo') ||
        path.startsWith('/manage-repo') ||
        path.startsWith('/repo-detail')
      )
        history.push({ pathname: paths.project(projectId) });
      if (path.startsWith('/contract-final-product'))
        history.push({ pathname: paths.contract(projectId) });
      if (new RegExp('/product/[a-zA-Z0-9-_]+/issues-detail/[a-zA-Z0-9-_]+').test(path))
        history.push({ pathname: paths.issues(projectId) });
      if (
        new RegExp('/product/[a-zA-Z0-9-_]+/release-notes/[a-zA-Z0-9-_]+/form').test(path) ||
        new RegExp('/product/[a-zA-Z0-9-_]+/release-note-form').test(path) ||
        new RegExp('/product/[a-zA-Z0-9-_]+/release-notes/[a-zA-Z0-9-_]+').test(path) ||
        new RegExp('/product/[a-zA-Z0-9-_]+/deployment-run-form').test(path) ||
        new RegExp('/product/[a-zA-Z0-9-_]+/managed-services-detail').test(path)
      )
        history.push({ pathname: paths.managedServices(projectId) });
      if (
        checkPath(PAGE.CONTAINER_DETAIL) ||
        checkPath(PAGE.CONTAINER_FORM) ||
        checkPath(PAGE.DOMAIN_DETAIL) ||
        checkPath(PAGE.DOMAIN_FORM)
      ) {
        history.push({ pathname: paths.cloud(projectId) });
      }
    };

    return (
      <>
        <SEO title={title} />
        <Mobile>
          <Navbar />
        </Mobile>
        <Wrapper>
          <Others>
            <SideMenuBar expandLayout={expandLayout} />
            <TopContainer>
              <NotificationContainer inner />
            </TopContainer>
          </Others>
          <MainContent
            roworkPM={title.props.index === (<Menu index={'communication'} />).props.index}
            backButton={backButton}
            expandLayout={expandLayout}
          >
            {backButton ? (
              <>
                <BreadcrombTrail
                  trailList={trailList}
                  current={current}
                  onClick={handleGobackProject}
                />
              </>
            ) : (
              <>
                <TitleContainer
                  roworkPM={title.props.index === (<Menu index={'communication'} />).props.index}
                >
                  <ProjectTitle>{dashboardStore.projectName}</ProjectTitle>
                  <Title>
                    {title}
                    {desc && <Desc>{desc}</Desc>}
                  </Title>

                  {tabs ? <Tabs onTabClick={onTabClick} tabs={tabs} active={activeTab} /> : null}
                </TitleContainer>
              </>
            )}
            <ChildrenWrapper
              fullWidth={title.props.index === (<Menu index={'managed-services'} />).props.index}
              expandLayout={expandLayout}
            >
              {children}
            </ChildrenWrapper>
          </MainContent>
        </Wrapper>
      </>
    );
  },
);

export default CommonLayout;
