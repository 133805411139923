import React from 'react';
import styled, { keyframes } from 'styled-components';

const Progress = ({ percent }) => {
  return (
    <ProgressBar>
      <ProgressOuter>
        <ProgressLine width={percent} />
      </ProgressOuter>
      <ProgressText title={`${percent}%`}>{percent}%</ProgressText>
    </ProgressBar>
  );
};

const shine = keyframes`
  to {
    transform:translateX(0);
    opacity:0.1;
  }
`;
const ProgressBar = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressOuter = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 3px;
  border-radius: 50px;
  background-color: var(--grayLine);
`;

const ProgressLine = styled.div`
  width: ${(props) => props.width}%;
  overflow: hidden;
  text-align: center;
  border-radius: inherit;
  background-color: var(--primary);
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.7));
    transform: translateX(-100%);
    animation: ${shine} 2s infinite;
  }
`;

const ProgressText = styled.span`
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: var(--grayText);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
`;

export default Progress;
