import axios from 'axios';
import replaceErrorState from 'api/replaceErrorState';
import authStore from 'stores/AuthStore';
import { checkAccessTokenValidity, checkRefreshTokenValidity } from 'utils/checkTokenValidity';

export const API_URL = process.env.REACT_APP_API_URL;

export const api = axios.create({
  baseURL: `${API_URL}`,
});

export const accessApi = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    Authorization: '',
  },
});

let languageCode = 'ko';

export const setLanguageCodeInterceptor = (paramValue) => {
  languageCode = paramValue;
};

api.interceptors.request.use((config) => {
  if (config.method === 'get') {
    config.params = {
      ...config.params,
      languageCode,
    };
  }
  return config;
});

accessApi.interceptors.request.use(async (config) => {
  const refreshTokenCookie = authStore.getCookie('refreshToken');
  let isAccessTokenValid = checkAccessTokenValidity();
  if (isAccessTokenValid) {
    if (refreshTokenCookie === undefined || !refreshTokenCookie || checkRefreshTokenValidity()) {
      authStore.signOut('auto');
    } else {
      await api.get(`/api/v1/users/refresh-token`, {
        headers: {
          Authorization: `Bearer ${refreshTokenCookie}`,
        },
      });
    }
  }
  const isLoggedIn = authStore.isAuthenticated();
  if (isLoggedIn) {
    config.headers.Authorization = axios.defaults.headers.common['Authorization'];
  }
  if (config.method === 'get') {
    config.params = {
      ...config.params,
      languageCode,
    };
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error) {
      replaceErrorState(error);
    }
    return Promise.reject(error);
  },
);

accessApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error) {
      replaceErrorState(error);
    }
    return Promise.reject(error);
  },
);

/* common user authentication API */
export const signUpApi = (body) => api.post(`/api/v1/users/sign-up`, body);

export const signInApi = (body) => api.post(`/api/v1/users/sign-in`, body);

export const getUserApi = () => accessApi.get(`/api/v1/users/`);

export const refreshTokenApi = (token) =>
  api.get(`/api/v1/users/refresh-token`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const postEmailVerificationApi = (body) =>
  api.post(`/api/v1/users/email-verification`, body);

export const getEmailVerificationApi = (code, email) =>
  api.get(`/api/v1/users/email-verification?code=${code}&email=${email}`);

export const postCellphoneVerificationApi = (body) =>
  api.post(`/api/v1/users/cellphone-verification`, body);

export const getCellphoneVerificationApi = (code, cellphone) =>
  api.get(`/api/v1/users/cellphone-verification?cellphone=${cellphone}&code=${code}`);

export const sendPasswordResetCodeApi = (email) =>
  api.post(`/api/v1/users/email-for-password-reset?email=${email}`);

export const resetPasswordApi = (code, email, password) =>
  api.post(`/api/v1/users/password-reset?code=${code}&email=${email}&password=${password}`);

export const resignMembershipApi = (password) =>
  accessApi.delete(`/api/v1/users/?password=${password}`);

/* common user setting API */
export const getUserProfileInfoApi = () => accessApi.get(`/api/v1/users/profile-info`);

export const updateUserProfileInfoApi = (body) =>
  accessApi.patch(`/api/v1/users/profile-info`, body);

// Client settings API
export const getClientProfileInfoApi = () => accessApi.get(`/api/v1/clients/profile-info`);

export const updateClientProfileInfoApi = (body) =>
  accessApi.patch(`/api/v1/clients/profile-info`, body);
// Developer settings API
export const getDevProfileInfoApi = () => accessApi.get(`/api/v1/developers/profile-info`);

export const updateDevProfileInfoApi = (body) =>
  accessApi.patch(`/api/v1/developers/profile-info`, body);
// documents
export const addPersonalDocumentsApi = (formData) =>
  accessApi.post(`/api/v1/users/documents`, formData, { 'Content-Type': 'multipart/form-data' });

export const getPersonalDocumentsApi = () => accessApi.get(`/api/v1/users/documents`);

export const updatePersonalDocumentsInfoApi = (query) =>
  accessApi.put(`/api/v1/users/documents?${query}`);

export const deletePersonalDocumentsApi = (id) => accessApi.delete(`/api/v1/users/documents/${id}`);
// password
export const changePasswordApi = (newPassword, oldPassword) =>
  accessApi.post(`/api/v1/users/passwords?newPassword=${newPassword}&oldPassword=${oldPassword}`);

export const getPublicSshKeysApi = () => accessApi.get(`/api/v1/ssh-public-keys`);

export const postPublicSshKeyApi = (body) => accessApi.post(`/api/v1/ssh-public-keys`, body);

export const deletePublicSshKeyApi = (id) => accessApi.delete(`/api/v1/ssh-public-keys/${id}`);

/* Common Data API */
export const getServiceInfosApi = () => api.get(`/api/v1/data/service-infos`);

export const getPricingInfoMenusApi = () => api.get(`/api/v1/data/pricing-info-menus`);
export const getPricingInfosApi = (type) => api.get(`/api/v1/data/pricing-infos/${type}`);

export const getUsecaseInfoMenusApi = () => api.get(`/api/v1/data/usecase-info-menus`);
export const getUsecaseInfosApi = (type) => api.get(`/api/v1/data/usecase-infos/${type}`);

export const searchTechStacksApi = (keyword) =>
  api.get(`/api/v1/data/tech-stacks?keyword=${keyword}`);

export const checkProductDuplicate = (value) =>
  api.get(`/api/v1/clients/projects/check?alphanumericName=${value}`);

/* Common Temp file API */
export const postTempFileApi = (formData, config) =>
  accessApi.post(`/api/v1/temp-files`, formData, config);

export const postSecuredTempFileApi = (formData, config) =>
  accessApi.post(`/api/v1/temp-secured-files`, formData, config);

/* Common user notification API */
export const getUnreadCountNotificationsApi = () =>
  accessApi.get(`/api/v1/users/notifications/unread/count`);

export const getAllNotificationsApi = (pageNumber, pageSize) =>
  accessApi.get(`/api/v1/users/notifications?pageNumber=${pageNumber}&pageSize=${pageSize}`);

export const getNotificationDetailByIdApi = (id) =>
  accessApi.get(`/api/v1/users/notifications/${id}`);

export const updateNotificationCheckedApi = (id) =>
  accessApi.post(`/api/v1/users/notifications/${id}/checked`);

/* Client/Developer dashboard API */
export const getDashboardMetricApi = (user) => accessApi.get(`/api/v1/${user}/dashboard/metric`);

/* Common project dashboards for clients / developers respectively */
export const getDashboardProjectsApi = () => accessApi.get(`/api/v1/projects/`);

export const getProjectsStatusApi = (projectId) =>
  accessApi.get(`/api/v1/projects/${projectId}/status`);

/* Client projects API : Request New Project */
export const registerProjectApi = (body, accessToken) =>
  accessApi.post(`/api/v1/clients/projects/create`, body, {
    'Content-Type': 'multipart/form-data',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

/* Client project proposals API */
export const getProjectProposalsApi = (projectId) =>
  accessApi.get(`/api/v1/clients/proposals/${projectId}`);

export const getProjectProposalsByProposalIdApi = (proposalId) =>
  accessApi.get(`/api/v1/clients/proposals/${proposalId}/by-proposal-id`);

export const getProjectProposalsHistoryApi = (projectId) =>
  accessApi.get(`/api/v1/clients/proposals/${projectId}/history`);

export const getProposalByHistoryIdApi = (proposalHistoryId) =>
  accessApi.get(`/api/v1/clients/proposals/by-history/${proposalHistoryId}`);

export const getProposalHistoryFeedbackApi = (proposalHistoryId) =>
  accessApi.get(`/api/v1/clients/proposals/feedbacks/${proposalHistoryId}/history`);

/* Client project proposal feedbacks API */
export const getProposalFeedbackAllApi = (proposalId, pageNumber, pageSize) =>
  accessApi.get(
    `/api/v1/clients/proposals/feedbacks/${proposalId}/all?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

export const getProposalFeedbackByIdApi = (feedbackId) =>
  accessApi.get(`/api/v1/clients/proposals/feedbacks/${feedbackId}`);

export const postClientsFeedbackApi = (body) =>
  accessApi.post(`/api/v1/clients/proposals/feedbacks`, body);

export const putClientFeedbackApi = (feedbackId, body) =>
  accessApi.put(`/api/v1/clients/proposals/feedbacks/${feedbackId}`, body);

export const deleteClientFeedbackApi = (feedbackId) =>
  accessApi.delete(`/api/v1/clients/proposals/feedbacks/${feedbackId}`);

export const postProposalFeedbackCompletedApi = (proposalId) =>
  accessApi.post(`/api/v1/clients/proposals/feedbacks/${proposalId}/completed`, proposalId);

/* Client project proposals feedback commenst API */
export const getProposalFeedbackCommentApi = (feedbackId) =>
  accessApi.get(`/api/v1/proposals/feedbacks/${feedbackId}/comments`);

export const postProposalFeedbackCommentApi = (feedbackId, body) =>
  accessApi.post(`/api/v1/proposals/feedbacks/${feedbackId}/comments`, body);

export const putProposalFeedbackCommentApi = (commentId, body) =>
  accessApi.put(`/api/v1/proposals/feedbacks/comments/${commentId}`, body);

export const deleteProposalFeedbackCommentApi = (commentId) =>
  accessApi.delete(`/api/v1/proposals/feedbacks/comments/${commentId}`);

/* Common projects API */
export const getHomeDashboardMetricApi = () =>
  accessApi.get(`/api/v1/${'clients'}/dashboard/metric`);

export const getProjectByIdApi = (projectId) => accessApi.get(`/api/v1/projects/${projectId}`);

export const getRequestedProjectsApi = () => accessApi.get(`/api/v1/clients/projects/requested`);

export const getInProgressProjectsApi = () => accessApi.get(`/api/v1/clients/projects/in-progress`);

export const getCompletedProjectsApi = () => accessApi.get(`/api/v1/clients/projects/completed`);

export const getInvitedProjectsApi = () => accessApi.get(`/api/v1/projects/invited`);

export const getProductComponentsApi = (productId) =>
  accessApi.get(`/api/v1/products/${productId}/components`);

export const getProductComponentApi = (productComponentId) =>
  accessApi.get(`/api/v1/products/components/${productComponentId}`);

export const postProductComponentsApi = (body) =>
  accessApi.post(`/api/v1/products/components`, body);

export const deleteProductComponentApi = (productComponentId) =>
  accessApi.delete(`/api/v1/products/components/${productComponentId}`);

export const getGitReposApi = (productId) =>
  accessApi.get(`/api/v1/products/${productId}/git-repos`);

export const getGitRepoDetailApi = (id) => accessApi.get(`/api/v1/products/git-repos/${id}`);

export const getGitRepoCheckApi = (productId, name) =>
  accessApi.get(`/api/v1/products/${productId}/git-repos/check?name=${name ? name : ''}`);

export const postGitReposApi = (body) => accessApi.post(`/api/v1/products/git-repos`, body);

export const deleteGitReposApi = (gitRepoId) =>
  accessApi.delete(`/api/v1/products/git-repos/${gitRepoId}`);

export const getGitBranchesApi = (repoId) =>
  accessApi.get(`/api/v1/products/git-repos/${repoId}/branches`);

export const getGitBrancheCommitsApi = (repoId, branchName, pageNumber, pageSize) =>
  accessApi.get(
    `/api/v1/products/git-repos/${repoId}/branches/commits?branchName=${branchName}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

export const getGitTreesApi = (repoId, sha) =>
  accessApi.get(`/api/v1/products/git-repos/${repoId}/tree?sha=${encodeURIComponent(sha)}`);

export const getGitBlobApi = (repoId, sha) =>
  accessApi.get(`/api/v1/products/git-repos/${repoId}/blobs?sha=${encodeURIComponent(sha)}`);

export const getGitDiffApi = (repoId, sha) =>
  accessApi.get(`/api/v1/products/git-repos/${repoId}/git/diff?sha=${encodeURIComponent(sha)}`);

export const postTempToken = (body) => accessApi.post(`/api/v1/temp-token`, body);
/* Client projects API */
export const getProjectsApi = () => accessApi.get('/api/v1/clients/projects');

export const getClientProjectByIdApi = (projectId) =>
  accessApi.get(`/api/v1/clients/projects/${projectId}`);

export const getClientRequestedProjectsApi = () =>
  accessApi.get(`/api/v1/clients/projects/requested`);

export const getClientInProgressProjectsApi = () =>
  accessApi.get(`/api/v1/clients/projects/in-progress`);

export const getClientCompletedProjectsApi = () =>
  accessApi.get(`/api/v1/clients/projects/completed`);

/* Client matching developers API */
export const getProposedDevelopersApi = (projectId) =>
  accessApi.get(`/api/v1/projects/${projectId}/proposed-developers`);

export const getMatchingDevelopersApi = (projectId) =>
  accessApi.get(`/api/v1/projects/${projectId}/developers`);

export const getProposedDevelopersByContractIdApi = (contractId) =>
  accessApi.get(`/api/v1/projects/contracts/${contractId}/proposed-developers`);

export const getMatchingDevelopersByContractIdApi = (contractId) =>
  accessApi.get(`/api/v1/projects/contracts/${contractId}/developers`);

export const getMatchingDeveloperDetailsApi = (matchingId) =>
  accessApi.get(`/api/v1/projects/developers/${matchingId}`);

export const confirmMatchingDeveloperApi = (matchingId, body) =>
  accessApi.post(`/api/v1/projects/developers/${matchingId}`, body);

/* Common contract API */
export const getContractListByProjectIdApi = (projectId, pageNumber, pageSize) =>
  accessApi.get(
    `/api/v1/projects/${projectId}/contracts?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

export const getContractByContractIdApi = (contractId) =>
  accessApi.get(`/api/v1/projects/contracts/${contractId}`);

export const getContractContentByContractContentIdApi = (contractContentId) =>
  accessApi.get(`/api/v1/projects/contracts/contents/${contractContentId}`);

export const getContractHistoryApi = (contractId) =>
  accessApi.get(`/api/v1/projects/contracts/${contractId}/contents/history`);

export const getContractContentCommentsApi = (contractContentId) =>
  accessApi.get(`/api/v1/projects/contracts/contents/${contractContentId}/comments`);

export const getContractContentItemCommentsApi = (contractContentItemId) =>
  accessApi.get(`/api/v1/projects/contracts/contents/items/${contractContentItemId}/comments`);

export const postContractsContentsCommentsApi = (formData) =>
  accessApi.post(`/api/v1/projects/contracts/contents/comments`, formData);

export const deleteContractsContentsCommentsApi = (commentId) =>
  accessApi.delete(`/api/v1/projects/contracts/contents/comments/${commentId}`);

export const postContractsContentsRespondedApi = (contractContentId) =>
  accessApi.post(`/api/v1/projects/contracts/contents/${contractContentId}/responded`);

/* Client contract API */
// export const getContractApi = (projectId) =>
//   accessApi.get(`/api/v1/projects/client/contract/${projectId}/`); // unused

export const signProjectApi = (contractId) =>
  accessApi.post(`/api/v1/projects/client/contract/${contractId}/`, { agreed: true }); //add user agent?

export const getContractMaterialsApi = (contractId) =>
  accessApi.get(`/api/v1/projects/contracts/${contractId}/materials`);

// Client contract comments API
export const getClientContractCommentsApi = (contractId, pageNumber, pageSize) =>
  accessApi.get(
    `/api/v1/projects/client/contract/${contractId}/comments?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

export const postClientContractCommentsApi = (contractId, body) =>
  accessApi.post(`/api/v1/projects/client/contract/${contractId}/comments`, body);

export const deleteClientContractCommentsApi = (commentId) =>
  accessApi.delete(`/api/v1/projects/client/contract/comments/${commentId}`);

/* Client project materials API */
export const getClientMaterialsApi = (contractId) =>
  accessApi.get(`/api/v1/projects/contract/${contractId}/materials/all`);

export const uploadMaterialApi = (contractId, body) =>
  accessApi.post(`/api/v1/projects/contract/${contractId}/materials`, body, {
    'Content-Type': 'multipart/form-data',
  });

export const deleteMaterialApi = (projectId) => accessApi.delete(`/api/v1/projects/${projectId}/`);

/* Client project payment API */
export const getPaymentInfoApi = (projectId) =>
  accessApi.get(`/api/v1/projects/${projectId}/payments/pending`);

export const getPendingPaymentApi = (projectId) =>
  accessApi.get(`/api/v1/projects/${projectId}/payments/deposit/pending`);

export const getRefundPolicyApi = () => accessApi.get(`/api/v1/data/refund-policy`);

/* Client project receipt API */
export const getReceiptApi = (item) => accessApi.get(`/api/v1/projects/${item}/receipts`);

/* Developer external projects API */
export const addDeveloperExternalProjectsApi = (body) =>
  accessApi.post(`/api/v1/developers/ex-projects/`, body);

export const deleteDeveloperExternalProjectsApi = (id) =>
  accessApi.delete(`/api/v1/developers/ex-projects/${id}/`);

export const getDeveloperExternalGitConnectionApi = () =>
  accessApi.get(`/api/v1/developers/external-git-connections/`);

export const updateDeveloperExternalGitConnectionApi = (externalGitConnectId) =>
  accessApi.post(`/api/v1/developers/external-git-connections/${externalGitConnectId}/update`);

export const deleteDeveloperExternalGitConnectionApi = (externalGitConnectId) =>
  accessApi.delete(`/api/v1/developers/external-git-connections/${externalGitConnectId}`);

/* Developer work experience API */
export const addDeveloperWorkExperiencesApi = (body) =>
  accessApi.post(`/api/v1/developers/experiences/`, body);

export const getAllDeveloperWorkExperiencesApi = () =>
  accessApi.get(`/api/v1/developers/experiences/`);

export const getDeveloperWorkExperiencesApi = (id) =>
  accessApi.get(`/api/v1/developers/experiences/${id}/`);

export const updateDeveloperWorkExperiencesApi = (id, body) =>
  accessApi.patch(`/api/v1/developers/experiences/${id}/`, body);

export const deleteDeveloperWorkExperiencesApi = (id) =>
  accessApi.delete(`/api/v1/developers/experiences/${id}/`);

/* Developer confirmation to the project API */
export const confirmDeveloperToProjectApi = (body) =>
  accessApi.post(`/api/v1/developers/matching/`, body);

export const getDevRequestedProjectDetailsApi = (projectId) =>
  accessApi.get(`/api/v1/developers/matching/${projectId}`);

export const getDevRequestedProjectApi = () => accessApi.get(`/api/v1/developers/matching/request`);

/* Developer contract API */
export const getDevContractApi = (projectId) =>
  accessApi.get(`/api/v1/projects/developer/contract/${projectId}/`);

export const signDevProjectApi = (contractId) =>
  accessApi.post(`/api/v1/projects/developer/contract/${contractId}/`, { agreed: true }); //add user agent?

//Developer contract comments API
export const getDevContractCommentsApi = (contractId, pageNumber, pageSize) =>
  accessApi.get(
    `/api/v1/projects/developer/contract/${contractId}/comments?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

export const postDevContractCommentsApi = (contractId, body) =>
  accessApi.post(`/api/v1/projects/developer/contract/${contractId}/comments`, body);

export const deleteDevContractCommentsApi = (commentId) =>
  accessApi.delete(`/api/v1/projects/developer/contract/comments/${commentId}`);

/* Developer project files API */
export const getCommonMaterialsApi = (projectId) =>
  accessApi.get(`/api/v1/projects/${projectId}/materials`);

/* Developer project payment statement API */
export const getDevPaymentsApi = (projectId) =>
  accessApi.get(`/api/v1/developers/projects/${projectId}/payment-statements`);

/* Developer Project API */
export const getDevProjectByIdApi = (projectId) =>
  accessApi.get(`/api/v1/developers/projects/${projectId}`);

export const getDevInProgressProjectsApi = () =>
  accessApi.get(`/api/v1/developers/projects/in-progress`);

export const getDevCompletedProjectsApi = () =>
  accessApi.get(`/api/v1/developers/projects/completed`);

/* Developer project proposals API */
export const getDevProposalPreviewApi = (projectId) =>
  accessApi.get(`/api/v1/developers/proposals/${projectId}`);

/* Client/Developer project management APIs */
export const getProjectMetricsApi = (user, projectId) =>
  accessApi.get(`/api/v1/${user}/projects/${projectId}/metrics`);

export const getMilestonesApi = (productId, statuses, pageNumber, pageSize) => {
  let query = '';
  if (statuses && statuses.length > 0) {
    for (let i = 0; i < statuses.length; i++) {
      query += `status=${statuses[i]}&`;
    }
  }
  query += `pageNumber=${pageNumber}&`;
  query += `pageSize=${pageSize}&`;
  return accessApi.get(`/api/v1/products/${productId}/milestones?${query}`);
};

export const getInProgressMilestonesApi = (productId) =>
  accessApi.get(`/api/v1/products/${productId}/milestones/in-progress`);

export const getDraftMilestonesApi = (projectId) =>
  accessApi.get(`/api/v1/products/${projectId}/milestones/draft`);

export const getPastMilestonesApi = (projectId) =>
  accessApi.get(`/api/v1/products/${projectId}/milestones/past`);

export const getAfterMilestonesApi = (projectId) =>
  accessApi.get(`/api/v1/products/${projectId}/milestones/after`);

/* Client/Developer project code quality API */
export const getProjectCodeQualityApi = (user, projectId) =>
  accessApi.get(`/api/v1/${user}/projects/${projectId}/code-qualities`);

export const getMilestoneDetailApi = (id) => accessApi.get(`/api/v1/milestones/${id}/detail`);

export const postClientMilestoneConfirmApi = (id, body) =>
  accessApi.post(`/api/v1/milestones/${id}/confirm`, body);

export const postDevMilestoneSubmitApi = (id, body) =>
  accessApi.post(`/api/v1/milestones/${id}/submit`, body);

export const postMilestoneApi = (body) => accessApi.post(`/api/v1/milestones`, body);

export const deleteMilestoneApi = (id) => accessApi.delete(`/api/v1/milestones/${id}`);

/* Developer search milestone commit API */
export const searchDevMilestoneCommitApi = (id, keyword) =>
  accessApi.get(
    `/api/v1/developers/projects/milestones/${id}/commit-candidates?keyword=${keyword}`,
  );

/* interim-report API */
export const getClientWorkReportsApi = (projectId) =>
  accessApi.get(`/api/v1/clients/projects/${projectId}/work-reports`);

export const getWorkReportByIdApi = (workReportId) =>
  accessApi.get(`/api/v1/clients/projects/work-reports/${workReportId}`);

/* activity history API */
export const getClientActivitiesApi = (projectId, pageNumber, pageSize) =>
  accessApi.get(
    `/api/v1/clients/projects/${projectId}/activities?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

export const getDeveloperActivitiesApi = (projectId, pageNumber, pageSize) =>
  accessApi.get(
    `/api/v1/developers/projects/${projectId}/activities?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

/* project task(todo) api */
export const getProjectTaskApi = (projectId) =>
  accessApi.get(`/api/v1/clients/projects/${projectId}/tasks`);

export const postProjectTaskSubmitApi = (taskId, body) =>
  accessApi.post(`/api/v1/clients/projects/tasks/${taskId}/submit`, body);

export const getDeveloperProjectTaskApi = (projectId) =>
  accessApi.get(`/api/v1/developers/products/${projectId}/tasks`);

export const putDeveloperTaskApi = (taskId, body) =>
  accessApi.put(`/api/v1/developers/products/tasks/${taskId}`, body);

export const postApiInternalApi = (actionValue) => accessApi.post(actionValue);

/* 로워크 PM API */
export const getAllRoworkPMApi = (query, pageNumber, pageSize) =>
  accessApi.get(`/api/v1/${query}&pageNumber=${pageNumber}&pageSize=${pageSize}`);

export const getRoworkPMByIdApi = (user, requestId) =>
  accessApi.get(`/api/v1/${user}/projects/communications/${requestId}`);

export const postRoworkPMApi = (user, projectId, formData) =>
  accessApi.post(`/api/v1/${user}/projects/${projectId}/communications`, formData, {
    'Content-Type': 'multipart/form-data',
  });

export const updateRoworkPMApi = (user, requestId, body) =>
  accessApi.put(`/api/v1/${user}/projects/communications/${requestId}`, body);

export const deleteRoworkPMApi = (user, requestId) =>
  accessApi.delete(`/api/v1/${user}/projects/communications/${requestId}`);

export const searchRoworkPMHashApi = (user, projectId, keyword, tag) =>
  accessApi.get(
    `/api/v1/${user}/projects/${projectId}/communications/tags?keyword=${keyword}&tag=${tag}`,
  );

/* Client 로워크 PM comments API */
export const getClientRoworkPMCommentsApi = (requestId) =>
  accessApi.get(`/api/v1/clients/projects/communications/${requestId}/comments`);

export const postClientRoworkPMCommentsApi = (requestId, formData) =>
  accessApi.post(`/api/v1/clients/projects/communications/${requestId}/comments`, formData);

export const patchClientRoworkPMCommentsApi = (commentId, body) =>
  accessApi.patch(`/api/v1/clients/projects/communications/comments/${commentId}`, body);

export const deleteClientRoworkPMCommentsApi = (commentId) =>
  accessApi.delete(`/api/v1/clients/projects/communications/comments/${commentId}`);

/* Developer 로워크 PM comments API */
export const postDevRoworkPMCommentsApi = (requestId, formData) =>
  accessApi.post(`/api/v1/developers/projects/rowork-pms/comments/${requestId}/`, formData);

export const patchDevRoworkPMCommentsApi = (commentId, body) =>
  accessApi.patch(`/api/v1/developers/projects/rowork-pms/comments/${commentId}/`, body);

export const deleteDevRoworkPMCommentsApi = (commentId) =>
  accessApi.delete(`/api/v1/developers/projects/rowork-pms/comments/${commentId}/`);

/* Client Managed Services API */
export const getManagedServicesApi = (productId) =>
  accessApi.get(`/api/v1/products/${productId}/managed-services`);

export const getManagedServiceDetailApi = (managedServiceId) =>
  accessApi.get(`/api/v1/products/managed-services/${managedServiceId}`);

export const postManagedServicesApi = (body) =>
  accessApi.post(`/api/v1/products/managed-services`, body);

export const deleteManagedServiceDetailApi = (managedServiceId) =>
  accessApi.delete(`/api/v1/products/managed-services/${managedServiceId}`);

/* Client Managed Services Deployment API */

export const getDeploymentsPageApi = (
  productId,
  managedServiceId,
  status,
  notMappedByReleaseNote,
  pageNumber,
  pageSize,
) =>
  accessApi.get(
    `/api/v1/products/${productId}/deployments?notMappedByReleaseNote=${notMappedByReleaseNote}&managedServiceId=${managedServiceId}&status=${status}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

export const getReleaseNotesPageApi = (productId, pageNumber, pageSize) =>
  accessApi.get(
    `/api/v1/products/${productId}/release-notes?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

export const getReleasenoteDetailApi = (releaseNoteId) =>
  accessApi.get(`/api/v1/products/release-notes/${releaseNoteId}/detail`);

export const postReleaseNoteApi = (body) => accessApi.post(`/api/v1/products/release-notes`, body);

export const deleteReleaseNoteApi = (releaseNoteId) =>
  accessApi.delete(`/api/v1/products/release-notes/${releaseNoteId}`);

export const postDeploymentRunApi = (body) =>
  accessApi.post(`/api/v1/products/deployment-runs`, body);

/** deployment setting */
export const getDeploymentSettingsApi = (managedServiceId) =>
  accessApi.get(`/api/v1/products/managed-services/${managedServiceId}/deployment-settings`);

export const getDeploymentSettingDetailApi = (managedServiceId, itemId) =>
  accessApi.get(
    `/api/v1/products/managed-services/${managedServiceId}/deployment-settings/${itemId}`,
  );

export const postDeploymentSettingsApi = (body) =>
  accessApi.post(`/api/v1/products/managed-services/deployment-settings`, body);

export const postDeploymentSettingEnableApi = (deploymentSettingId) =>
  accessApi.post(`/api/v1/products/deployment-settings/${deploymentSettingId}/enable`);

export const postDeploymentSettingDisableApi = (deploymentSettingId) =>
  accessApi.post(`/api/v1/products/deployment-settings/${deploymentSettingId}/disable`);

export const deleteDeploymentSettingApi = (deploymentSettingId) =>
  accessApi.delete(`/api/v1/products/managed-services/deployment-settings/${deploymentSettingId}`);

export const getDeploymentBuildEnvTemplatesApi = () =>
  accessApi.get(`/api/v1/products/deployment-build-env-templates`);

export const getDeploymentDeliveryTemplatesApi = () =>
  accessApi.get(`/api/v1/products/deployment-delivery-templates`);

export const getMacOsVersionsApi = () => accessApi.get(`/api/v1/data/mac-os-versions`);

export const getXcodeVersionsApi = () => accessApi.get(`/api/v1/data/xcode-versions`);

/* Infrastructures (Cloud) */
export const getContainerSettingsApi = (productId, pageNumber, pageSize) =>
  accessApi.get(
    `/api/v1/products/${productId}/container-settings?pageNumber=${
      pageNumber ? pageNumber : 0
    }&pageSize=${pageSize ? pageSize : 10}`,
  );

export const getContainerSettingsAllApi = (productId) =>
  accessApi.get(`/api/v1/products/${productId}/container-settings/all`);

export const getContainerDetailApi = (containerId) =>
  accessApi.get(`/api/v1/products/containers/${containerId}`);

export const postContainerSettingsApi = (productId, body) =>
  accessApi.post(`/api/v1/products/${productId}/container-settings`, body);

export const getContainerSettingDetailApi = (containerSettingId) =>
  accessApi.get(`/api/v1/products/container-settings/${containerSettingId}`);

export const getContainersSummaryByContainerSettingIdApi = (containerSettingId) =>
  accessApi.get(`/api/v1/products/container-settings/${containerSettingId}/containers/summary`);

export const deleteContainerSettingApi = (containerSettingId) =>
  accessApi.delete(`/api/v1/products/container-settings/${containerSettingId}`);

export const getContainerImageCandidatesApi = (managedServiceId) =>
  accessApi.get(`/api/v1/products/managed-services/${managedServiceId}/image-candidates`);

export const searchDockerImageApi = (keyword) =>
  accessApi.get(`/api/v1/products/container-images/search?keyword=${keyword}`);

export const getProductVolumesApi = (productId) =>
  accessApi.get(`/api/v1/products/${productId}/volumes`);

export const getDomainsApi = (productId, pageNumber, pageSize) =>
  accessApi.get(
    `/api/v1/products/${productId}/domains?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

export const getAllDomainOptionsApi = (productId) =>
  accessApi.get(`/api/v1/products/${productId}/domains/all-options`);

export const postDomainsApi = (productId, body) =>
  accessApi.get(`/api/v1/products/${productId}/domains`, body);

export const deleteDomainsApi = (productId, domainId) =>
  accessApi.delete(`/api/v1/products/${productId}/domains/${domainId}`);

export const getDomainRecordsApi = (productId, domainId) =>
  accessApi.get(`/api/v1/products/${productId}/domains/${domainId}/records`);

export const getDomainDetailApi = (productId, domainId) =>
  accessApi.get(`/api/v1/products/${productId}/domains/${domainId}`);

export const checkDomainAvailabilityApi = (domainCandidate) =>
  accessApi.get(`/api/v1/products/domains/check?domainCandidate=${domainCandidate}`);

export const deleteDomainRecordApi = (
  productId,
  domainId,
  recordName,
  rrType,
  ttl,
  alias,
  aliasHostedZoneId,
  aliasEvaluateTargetHealth,
  values,
) => {
  let query = `recordName=${encodeURIComponent(recordName)}&rrType=${encodeURIComponent(rrType)}`;
  if (ttl) {
    query += `&ttl=${ttl}`;
  }
  if (alias !== undefined) {
    query += `&alias=${alias}`;
  }
  if (aliasHostedZoneId) {
    query += `&aliasHostedZoneId=${aliasHostedZoneId}`;
  }
  if (aliasEvaluateTargetHealth) {
    query += `&aliasEvaluateTargetHealth=${aliasEvaluateTargetHealth}`;
  }
  if (values && values.length > 0) {
    for (let i = 0; i < values.length; i++) {
      query += `&value=${values[i]}`;
    }
  }
  return accessApi.delete(`/api/v1/products/${productId}/domains/${domainId}/records?${query}`);
};

export const updateDomainRecordApi = (
  productId,
  domainId,
  recordName,
  rrType,
  ttl,
  alias,
  aliasHostedZoneId,
  aliasEvaluateTargetHealth,
  values,
) => {
  let query = `recordName=${encodeURIComponent(recordName)}&rrType=${encodeURIComponent(rrType)}`;
  if (ttl) {
    query += `&ttl=${ttl}`;
  }
  if (alias !== undefined) {
    query += `&alias=${alias}`;
  }
  if (aliasHostedZoneId) {
    query += `&aliasHostedZoneId=${aliasHostedZoneId}`;
  }
  if (aliasEvaluateTargetHealth) {
    query += `&aliasEvaluateTargetHealth=${aliasEvaluateTargetHealth}`;
  }
  if (values && values.length > 0) {
    for (let i = 0; i < values.length; i++) {
      query += `&value=${values[i]}`;
    }
  }
  return accessApi.put(`/api/v1/products/${productId}/domains/${domainId}/records?${query}`);
};

export const startContainerSettingApi = (containerSettingId) =>
  accessApi.post(`/api/v1/products/container-settings/${containerSettingId}/start`);

export const stopContainerSettingApi = (containerSettingId) =>
  accessApi.post(`/api/v1/products/container-settings/${containerSettingId}/stop`);

/* Client Project Issues API */
export const getIssueListApi = (projectId) =>
  accessApi.get(`/api/v1/clients/projects/${projectId}/issues`);

export const getIssuePageApi = (projectId, query) =>
  accessApi.get(`/api/v1/clients/projects/${projectId}/issues/page?${query}`);

export const postIssueApi = (projectId, body) =>
  accessApi.post(`/api/v1/clients/projects/${projectId}/issues`, body);

export const getIssueDetailApi = (issueId) =>
  accessApi.get(`/api/v1/clients/projects/issues/${issueId}`);

export const putIssueApi = (issueId, body) =>
  accessApi.put(`/api/v1/clients/projects/issues/${issueId}`, body);

export const deleteIssueApi = (issueId) =>
  accessApi.delete(`/api/v1/clients/projects/issues/${issueId}`);

/* Project member API */
export const getProjectMemberApi = (projectId) =>
  accessApi.get(`/api/v1/projects/${projectId}/members`);

export const deleteMemberApi = (memberId) =>
  accessApi.delete(`/api/v1/projects/members/${memberId}`);

export const deleteInviteApi = (inviteId) =>
  accessApi.delete(`/api/v1/projects/members/invites/${inviteId}`);

export const resendInviteApi = (inviteId) =>
  accessApi.post(`/api/v1/projects/members/invites/${inviteId}/re-send`);

export const postInviteProjectMemberApi = (projectId, body) =>
  accessApi.post(`/api/v1/projects/${projectId}/members`, body);

export const postAcceptInviteProjectMemberApi = (inviteId) =>
  accessApi.post(`/api/v1/projects/members/invites/${inviteId}/accept`, {});

export const postRejectInviteProjectMemberApi = (inviteId) =>
  accessApi.post(`/api/v1/projects/members/invites/${inviteId}/deny`, {});

export const putChangeUserTypeApi = (memberId, body) =>
  accessApi.put(`/api/v1/projects/members/${memberId}`, body);

export const getMyProjectMemberApi = (projectId) =>
  accessApi.get(`/api/v1/projects/${projectId}/my-member`);

export const postSettingProductApi = (productId, body) =>
  accessApi.post(`/api/v1/products/${productId}`, body);

export const getInProgressProductsApi = () => accessApi.get(`/api/v1/products/in-progress/meta`);
