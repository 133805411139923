import {
  changePasswordApi,
  getUserProfileInfoApi,
  updateUserProfileInfoApi,
  getAllDeveloperWorkExperiencesApi,
  addDeveloperWorkExperiencesApi,
  addDeveloperExternalProjectsApi,
  deleteDeveloperExternalProjectsApi,
  getDeveloperWorkExperiencesApi,
  updateDeveloperWorkExperiencesApi,
  deleteDeveloperWorkExperiencesApi,
  getPublicSshKeysApi,
  postPublicSshKeyApi,
  deletePublicSshKeyApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';
import AuthStore from 'stores/AuthStore';

class MyInfoStore {
  dashboardStore;
  activeTab = 0;
  userInfo = {};
  operationResult = '';
  loading = false;
  myInfoConfirmAlert = false;
  techStacks = [];
  jobPositions = [];
  otherPosition = {};
  otherPositionValue = '';
  externalProjects = [];
  experienceId = '';
  workExperiences = [];
  publicSshKeys = [];

  constructor() {
    makeAutoObservable(this);
    this.authStore = AuthStore;
  }

  setActiveTab = (param) => {
    this.activeTab = param;
  };

  changeUserInfo = (e) => {
    const { name, value } = e.target;
    this.userInfo = { ...this.userInfo, [name]: value };
  };

  showLoading = () => {
    this.loading = true;
  };

  hideLoading = () => {
    this.loading = false;
  };

  toggleMyInfoConfirm = () => {
    this.myInfoConfirmAlert = !this.myInfoConfirmAlert;
  };

  // use runInAction for handling data
  getUserProfileInfo = async () => {
    await getUserProfileInfoApi()
      .then((res) => {
        runInAction(() => {
          this.userInfo = res.data;
          this.techStacks = res.data.techStacks;
          this.sortJobPosition(res.data.jobPositions);
          if (res.data.externalProjects.length === 0)
            this.externalProjects.splice(0, 1, { projectUrl: '' });
          // this.externalProjects.push({ projectUrl: '' });
          if (res.data.externalProjects.length !== 0)
            this.externalProjects = res.data.externalProjects;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateUserProfileInfo = async (body) => {
    this.showLoading();
    await updateUserProfileInfoApi(body)
      .then((res) => {
        if (res.status === 200) {
          this.hideLoading();
          this.toggleMyInfoConfirm();
          this.authStore.getUser();
        }
      })
      .catch((error) => {
        this.hideLoading();
        console.log(error);
      });
  };

  changePassword = async (newPassword, oldPassword) => {
    await changePasswordApi(newPassword, oldPassword)
      .then((res) => {
        this.operationResult = res.data.operationResult;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addLinkInput = () => {
    this.externalProjects.push({ projectUrl: '' });
    // console.log(toJS(this.externalProjects));
  };

  deleteLinkInput = (index) => {
    this.externalProjects = this.externalProjects.filter((item, i) => i !== index);
    // console.log(toJS(this.externalProjects));
  };

  changeExternalProjectUrl = (e, index) => {
    const { name, value } = e.target;
    for (let i = 0; i < this.externalProjects.length; i++) {
      this.externalProjects[index] = { ...this.externalProjects[index], [name]: value };
    }
    // console.log(toJS(this.externalProjects));
  };

  sortJobPosition = (data) => {
    if (!data) {
      data = [];
    }
    this.jobPositions = data.filter((job) => job.positionType !== 'OTHERS');
    this.otherPosition = data.find((job) => job.positionType === 'OTHERS');
    if (this.otherPosition) this.otherPositionValue = this.otherPosition.otherPosition;
  };

  changeJobPosition = (e) => {
    const { value, checked } = e.target;
    if (value !== 'OTHERS' && !this.jobPositions.find((job) => job.positionType === value))
      this.jobPositions.push({ positionType: value, otherPosition: '' });
    if (value === 'OTHERS') this.toggleOtherPosition(e);
    if (!checked) {
      const jobs = this.jobPositions.find((item) => item.positionType === value);
      this.jobPositions.remove(jobs);
    }
  };

  toggleOtherPosition = (e) => {
    const { value, checked } = e.target;
    this.otherPosition = { positionType: value, otherPosition: '' };
    if (!checked) {
      this.otherPosition = {};
      this.otherPositionValue = '';
    }
  };
  changeOtherPosition = (e) => {
    const { value } = e.target;
    this.otherPosition = { positionType: 'OTHERS', otherPosition: value };
    this.otherPositionValue = value;
  };

  addCombineJobPosition = () => {
    this.jobPositions.push(this.otherPosition);
  };

  getDevProfileInfo = async () => {
    await getUserProfileInfoApi()
      .then((res) => {
        runInAction(() => {
          this.userInfo = res.data;
          this.techStacks = res.data.techStacks;
          this.sortJobPosition(res.data.jobPositions);
          if (res.data.externalProjects.length === 0)
            this.externalProjects.splice(0, 1, { projectUrl: '' });
          // this.externalProjects.push({ projectUrl: '' });
          if (res.data.externalProjects.length !== 0)
            this.externalProjects = res.data.externalProjects;
        });
        // console.log(res.data.externalProjects);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateDevProfileInfo = async (body, edit) => {
    this.showLoading();
    await updateUserProfileInfoApi(body)
      .then((res) => {
        if (res.status === 201) {
          if (edit) MyInfoStore.toggleMyInfoConfirm();
          this.hideLoading();
          this.getDevProfileInfo();
          this.authStore.getUser();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addDeveloperExternalProjects = async (body, edit) => {
    this.showLoading();
    await addDeveloperExternalProjectsApi(body)
      .then((res) => {
        this.hideLoading();
        this.getDevProfileInfo();
        if (res.status === 201 && edit) {
          MyInfoStore.toggleMyInfoConfirm();
        }
      })
      .catch((error) => {
        console.log(error);
        this.hideLoading();
      });
  };

  deleteDeveloperExternalProjects = async (id) => {
    await deleteDeveloperExternalProjectsApi(id)
      .then((res) => {
        this.getDevProfileInfo();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAllDeveloperWorkExperiences = async () => {
    await getAllDeveloperWorkExperiencesApi()
      .then((res) => {
        runInAction(() => {
          this.workExperiences = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDeveloperWorkExperiences = async (id) => {
    await getDeveloperWorkExperiencesApi(id)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addDeveloperWorkExperiences = async (body, edit) => {
    await addDeveloperWorkExperiencesApi(body)
      .then((res) => {
        this.getAllDeveloperWorkExperiences();
        if (res.status === 201 && edit) {
          MyInfoStore.toggleMyInfoConfirm();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateDeveloperWorkExperiences = async (id, body, edit) => {
    await updateDeveloperWorkExperiencesApi(id, body)
      .then((res) => {
        this.getAllDeveloperWorkExperiences();
        if (res.status === 200 && edit) {
          MyInfoStore.toggleMyInfoConfirm();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteDeveloperWorkExperiences = async (id, edit) => {
    await deleteDeveloperWorkExperiencesApi(id)
      .then((res) => {
        this.getAllDeveloperWorkExperiences();
        if (res.status === 200 && edit) {
          MyInfoStore.toggleMyInfoConfirm();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getPublicSshKeys = async () => {
    await getPublicSshKeysApi()
      .then((res) => {
        this.publicSshKeys = res.data.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  postPublicSshKey = (body) => {
    postPublicSshKeyApi(body)
      .then((res) => {
        if ('SUCCESS' === res.data?.responseCode) {
          this.getPublicSshKeys();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  deletePublicSshKey = async (id) => {
    await deletePublicSshKeyApi(id)
      .then((res) => {
        if ('SUCCESS' === res.data?.responseCode) {
          this.getPublicSshKeys();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

const myInfoStore = new MyInfoStore();

export default myInfoStore;
