import React from 'react';
import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';

function Button({ children, ...rest }) {
  return <Btn {...rest}>{children}</Btn>;
}

const colorStyles = css`
  ${({ theme, color }) => {
    const selected = theme.colors[color];
    return css`
      background: ${selected};
      &:hover {
        background: ${selected === '#2C62BA' || selected === '#2C62BA14'
          ? lighten(0.1, selected)
          : selected === '#FFF' || selected === '#FCFBFC'
          ? theme.colors['primaryLight']
          : theme.colors['secondary']};
        border: ${selected === '#FFF' && '1px solid var(--primary)'};
      }
      &:active {
        background: ${selected === '#FFF' || selected === '#FCFBFC'
          ? darken(0.1, theme.colors['primaryLight'])
          : darken(0.1, selected)};
      }
      &:disabled {
        cursor: not-allowed;
        background: ${selected === '#2C62BA' || selected === '#D6D6D6'
          ? 'var(--grayLine)'
          : 'var(--background)'};
        color: ${selected !== '#2C62BA' && selected !== '#D6D6D6' && 'var(--grayLine)'};
        border: var(--border);
      }
    `;
  }}
`;

const sizeStyles = css`
  ${({ theme, size }) => css`
    min-width: ${theme.btns[size].width};
    height: ${theme.btns[size].height};
    padding: ${theme.btns[size].padding};
    font-size: ${theme.btns[size].fontSize};
    font-weight: ${theme.btns[size].fontWeight};
    line-height: ${theme.btns[size].lineHeight};
  `}
`;

const Btn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  white-space: nowrap;
  border: ${(props) =>
    props.border === 'gray'
      ? '1px solid var(--grayLine)'
      : props.border
      ? '1px solid var(--primary)'
      : 'none'};
  color: ${(props) =>
    props.fontColor
      ? props.fontColor
      : props.border || props.color === 'primaryLight'
      ? 'var(--primary)'
      : 'white'};
  :hover {
    ${(props) => props.border === 'gray' && 'color: white;'}
  }

  ${colorStyles}

  ${sizeStyles}

  & + & {
    margin-left: 10px;
  }

  ${(props) =>
    props.mh0 === true &&
    css`
      margin-left: 0 !important;
    `}

  ${(props) =>
    props.mt12 === true &&
    css`
      margin-top: 12px !important;
    `}

    ${(props) =>
    props.dotted &&
    css`
      border-style: dotted;
    `}

    ${(props) =>
    props.dashed &&
    css`
      border-style: dashed;
    `}
`;

Button.defaultProps = {
  color: 'primary',
  size: 'medium',
};

export default Button;
