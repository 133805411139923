const COMMON_ID_REGEX = '[a-zA-Z0-9-_]+';

const PAGE_REGEX_MAP = {
  CLOUD: new RegExp(`/product/${COMMON_ID_REGEX}/cloud`),
  CONTAINERS: new RegExp(`/product/${COMMON_ID_REGEX}/cloud/containers`),
  CONTAINER_FORM: new RegExp(`/product/${COMMON_ID_REGEX}/cloud/container-setting-detail`),
  CONTAINER_DETAIL: new RegExp(
    `/product/${COMMON_ID_REGEX}/cloud/container-setting-detail/${COMMON_ID_REGEX}`,
  ),
  DOMAINS: new RegExp(`/product/${COMMON_ID_REGEX}/cloud/domains`),
  DOMAIN_FORM: new RegExp(`/product/${COMMON_ID_REGEX}/cloud/domain-form`),
  DOMAIN_DETAIL: new RegExp(`/product/${COMMON_ID_REGEX}/cloud/domain-detail/${COMMON_ID_REGEX}`),
};

export const PAGE = {
  CLOUD: 'CLOUD',
  CONTAINERS: 'CONTAINERS',
  CONTAINER_FORM: 'CONTAINER_FORM',
  CONTAINER_DETAIL: 'CONTAINER_DETAIL',
  DOMAINS: 'DOMAINS',
  DOMAIN_FORM: 'DOMAIN_FORM',
  DOMAIN_DETAIL: 'DOMAIN_DETAIL',
};

export const checkPath = (pageKey) => {
  const path = window.location.pathname;
  if (!PAGE_REGEX_MAP[pageKey]) {
    return false;
  }
  return PAGE_REGEX_MAP[pageKey].test(path);
};
