import {
  getDeveloperExternalGitConnectionApi,
  refreshTokenApi,
  updateDeveloperExternalGitConnectionApi,
  deleteDeveloperExternalGitConnectionApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class ProfileFormStore {
  profileFormTab = 0;
  github = [];
  bitbucket = [];
  gitlab = [];
  updateStatus = { github: false, bitbucket: false, gitlab: false };

  constructor() {
    makeAutoObservable(this);
  }

  setProfileFormTab = (param) => {
    this.profileFormTab = param;
  };

  resetUpdateStatus = () => {
    this.updateStatus = { github: false, bitbucket: false, gitlab: false };
  };

  addGitConnect = async (token, path, referrerPath) => {
    await refreshTokenApi(token).then((res) => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const connectPath = path;
      const referrer = `${process.env.REACT_APP_BASE_URL}${referrerPath}`; // 현재 프론트 웹 url (github 연동 완료 후 되돌아올 주소)
      const { accessToken } = res.data; // 현재 로그인 유저의 인증토큰
      window.location.href = `${apiUrl}${connectPath}?referrer=${referrer}&accessToken=${accessToken}`;
    });
  };

  replaceDate = (data) => {
    return { ...data, updatedAt: data.updatedAt.replace(/\./g, '-').replace(/ /g, 'T') };
  };

  getDeveloperExternalGitConnection = async () => {
    await getDeveloperExternalGitConnectionApi()
      .then((res) => {
        runInAction(() => {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].versionControlType === 'GIT_HUB')
              this.github = this.replaceDate(res.data[i]);
            if (res.data[i].versionControlType === 'BIT_BUCKET')
              this.bitbucket = this.replaceDate(res.data[i]);
            if (res.data[i].versionControlType === 'GIT_LAB')
              this.gitlab = this.replaceDate(res.data[i]);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateDeveloperExternalGitConnection = async (externalGitConnectId, item) => {
    runInAction(() => {
      this.updateStatus[item] = true;
    });
    await updateDeveloperExternalGitConnectionApi(externalGitConnectId)
      .then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.resetUpdateStatus();
          }, 1000);
          this.getDeveloperExternalGitConnection();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteDeveloperExternalGitConnection = async (externalGitConnectId) => {
    await deleteDeveloperExternalGitConnectionApi(externalGitConnectId)
      .then((res) => {
        runInAction(() => {
          console.log(res.data);
          this.getDeveloperExternalGitConnection();
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const profileFormStore = new ProfileFormStore();

export default profileFormStore;
