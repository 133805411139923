import {
  getDeploymentBuildEnvTemplatesApi,
  getDeploymentDeliveryTemplatesApi,
  getDeploymentsPageApi,
  getReleasenoteDetailApi,
  getReleaseNotesPageApi,
  getDeploymentSettingsApi,
  getManagedServiceDetailApi,
  getManagedServicesApi,
  getMacOsVersionsApi,
  getXcodeVersionsApi,
  getDeploymentSettingDetailApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class ManagedServicesStore {
  serviceProperties = [];
  lastCheckedAt = '';
  serviceList = [];
  service = {};

  deploymentPage = {};
  releaseNotePage = {};
  releaseNoteDetailInfo = {};

  deploymentSettings = [];
  deploymentSettingDetail = {};
  deploymentBuildEnvTemplates = [];
  deploymentDeliveryTemplates = [];

  macOsVersions = [];
  xcodeVersions = [];

  constructor() {
    makeAutoObservable(this);
  }

  getManagedServices = async (projectId) => {
    await getManagedServicesApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.serviceProperties = res.data.properties;
          this.lastCheckedAt = res.data.lastCheckedAt;
          this.serviceList = res.data.serviceList;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getManagedService = async (managedServiceId) => {
    await getManagedServiceDetailApi(managedServiceId)
      .then((res) => {
        runInAction(() => {
          this.service = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDeploymentPage = async (productId, managedServiceId, status, pageNumber, pageSize) => {
    await getDeploymentsPageApi(productId, managedServiceId, status, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          this.deploymentPage = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getReleaseNotesPage = async (projectId, pageNumber, pageSize) => {
    await getReleaseNotesPageApi(projectId, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          console.log(res.data);
          this.releaseNotePage = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getReleasenoteDetail = async (releaseNoteId) => {
    await getReleasenoteDetailApi(releaseNoteId)
      .then((res) => {
        runInAction(() => {
          this.releaseNoteDetailInfo = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDeploymentSettings = async (managedServiceId) => {
    await getDeploymentSettingsApi(managedServiceId)
      .then((res) => {
        runInAction(() => {
          this.deploymentSettings = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDeploymentSettingDetail = async (managedServiceId, deploymentSettingId) => {
    await getDeploymentSettingDetailApi(managedServiceId, deploymentSettingId)
      .then((res) => {
        runInAction(() => {
          this.deploymentSettingDetail = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDeploymentBuildEnvTemplates = async () => {
    await getDeploymentBuildEnvTemplatesApi()
      .then((res) => {
        runInAction(() => {
          this.deploymentBuildEnvTemplates = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDeploymentDeliveryTemplates = async () => {
    await getDeploymentDeliveryTemplatesApi()
      .then((res) => {
        runInAction(() => {
          this.deploymentDeliveryTemplates = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getMacOsVersions = async () => {
    await getMacOsVersionsApi()
      .then((res) => {
        runInAction(() => {
          this.macOsVersions = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getXcodeVersions = async () => {
    await getXcodeVersionsApi()
      .then((res) => {
        runInAction(() => {
          this.xcodeVersions = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  resetManagedService = () => {
    this.service = {};
  };

  resetManagedServices = () => {
    this.serviceList = [];
  };

  resetDeploymentSettings = () => {
    this.deploymentSettings = [];
  };

  resetReleaseNote = () => {
    this.releaseNoteDetailInfo = {};
  };
}

const managedServicesStore = new ManagedServicesStore();

export default managedServicesStore;
