import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import stores from 'stores';
import styled from 'styled-components';
import theme, { fonts } from 'styles/theme';
import { LogoNavbar } from './Navbar';
import { useTranslation } from 'react-i18next';

const Error = () => {
  const { errorStore, authStore } = stores;
  let history = useHistory();
  const { t } = useTranslation('error');

  useEffect(() => {
    let timer = setTimeout(() => {
      // window.location.href = '/';
      // window.location.replace('/');
      history.push('/');
      errorStore.setErrorCode('');
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LogoNavbar />
      <TitleContainer>
        <Title>{t('errorPage')[0]}</Title>
        <Sub>{t('errorPage')[1]}</Sub>
        <Info>{t('errorPage')[2]}</Info>
      </TitleContainer>
    </>
  );
};

const TitleContainer = styled.div`
  height: calc(100vh - 99px);
  margin-top: -99px;
  padding: 0 28px;
  ${theme.flex.centerColumn}
  @media ${theme.mobile} {
    height: calc(100vh - 42px);
    margin-top: -42px;
  }
`;
const Title = styled.div`
  font-size: 14.4rem;
  font-weight: 700;
  text-align: center;
  line-height: 19.7rem;
  color: var(--primary);
  opacity: 20%;
`;
const Sub = styled.div`
  margin-top: -9px;
  font-size: 4.8rem;
  font-weight: 700;
  text-align: center;
  line-height: 6.6rem;
  color: var(--primary);
`;
const Info = styled.div`
  margin-top: 23px;
  ${fonts.body}
  text-align: center;
  color: var(--secondary);
`;
export default Error;
