import {
  deleteClientRoworkPMCommentsApi,
  deleteDevRoworkPMCommentsApi,
  deleteRoworkPMApi,
  getAllRoworkPMApi,
  getClientRoworkPMCommentsApi,
  getRoworkPMByIdApi,
  patchClientRoworkPMCommentsApi,
  patchDevRoworkPMCommentsApi,
  postClientRoworkPMCommentsApi,
  postDevRoworkPMCommentsApi,
  postRoworkPMApi,
  searchRoworkPMHashApi,
  updateRoworkPMApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class RoworkPMStore {
  loading = false;
  roworkPMConfirmAlert = false;

  pmInquiryList = [];
  pmLastPage = false;
  answerEditId = '';

  query = '';

  requestId = null;
  comments = [];

  constructor() {
    makeAutoObservable(this);
  }

  showLoading = () => {
    this.loading = true;
  };

  hideLoading = () => {
    this.loading = false;
  };

  toggleRoworkPMConfirm = () => {
    this.roworkPMConfirmAlert = !this.roworkPMConfirmAlert;
  };

  resetPmLastPage = () => {
    this.pmLastPage = false;
  };

  setAnswerEditId = (id) => {
    this.answerEditId = id;
  };

  resetPmComments = () => {
    this.requestId = null;
    this.comments = [];
  };

  getAllRoworkPM = async (query, pageNumber, pageSize) => {
    await getAllRoworkPMApi(query, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          this.query = query;
          if (pageNumber === 0) {
            this.pmInquiryList = [...res.data.data];
          } else {
            this.pmInquiryList = [...this.pmInquiryList, ...res.data.data];
          }
          if (
            res.data.currentPage + 1 === res.data.totalPages ||
            res.data.currentPage + 1 > res.data.totalPages
          ) {
            return (this.pmLastPage = true);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getRoworkPMById = async (user, requestId) => {
    let inquiryTags = '';
    await getRoworkPMByIdApi(user, requestId)
      .then((res) => {
        runInAction(() => {
          this.pmInquiryList = [res.data];
          inquiryTags = res.data.inquiryTags;
        });
      })
      .catch((error) => {
        console.log(error);
      });
    return inquiryTags;
  };

  postRoworkPM = async (user, projectId, formData) => {
    this.showLoading();
    let success = false;
    await postRoworkPMApi(user, projectId, formData)
      .then((res) => {
        runInAction(() => {
          if (res.status === 201) {
            success = true;
            this.getAllRoworkPM(this.query, 0, this.pmInquiryList.length + 1);
          }
        });
      })
      .catch((error) => {
        console.log(error);
        this.hideLoading();
      });
    return success;
  };

  updateRoworkPM = async (user, requestId, body) => {
    this.showLoading();
    let success = false;
    await updateRoworkPMApi(user, requestId, body)
      .then((res) => {
        runInAction(() => {
          if (res.status === 200) {
            success = true;
            this.getAllRoworkPM(this.query, 0, this.pmInquiryList.length);
          }
        });
      })
      .catch((error) => {
        console.log(error);
        this.hideLoading();
      });
    return success;
  };

  deleteRoworkPM = async (user, requestId) => {
    await deleteRoworkPMApi(user, requestId)
      .then((res) => {
        runInAction(() => {
          if (res.data.operationResult === 'SUCCESS') {
            this.getAllRoworkPM(
              this.query,
              0,
              this.pmInquiryList.length - 1 === 0 ? 1 : this.pmInquiryList.length - 1,
            );
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  searchRoworkPMHash = async (user, projectId, keyword, tag) => {
    let result = [];
    await searchRoworkPMHashApi(user, projectId, keyword, tag)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          result = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };

  getClientRoworkPMComments = async (requestId) => {
    await getClientRoworkPMCommentsApi(requestId)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.requestId = requestId;
          this.comments = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postRoworkPMComments = async (pageType, requestId, formData) => {
    await postClientRoworkPMCommentsApi(requestId, formData)
      .then((res) => {
        runInAction(() => {
          if (pageType === 'roworkPM')
            this.getAllRoworkPM(this.query, 0, this.pmInquiryList.length);
          else this.getClientRoworkPMComments(this.requestId);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  patchRoworkPMComments = async (pageType, commentId, body) => {
    await patchClientRoworkPMCommentsApi(commentId, body)
      .then((res) => {
        runInAction(() => {
          if (pageType === 'roworkPM')
            this.getAllRoworkPM(this.query, 0, this.pmInquiryList.length);
          else this.getClientRoworkPMComments(this.requestId);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteRoworkPMComments = async (pageType, commentId) => {
    await deleteClientRoworkPMCommentsApi(commentId)
      .then((res) => {
        runInAction(() => {
          if (pageType === 'roworkPM')
            this.getAllRoworkPM(this.query, 0, this.pmInquiryList.length);
          else this.getClientRoworkPMComments(this.requestId);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const roworkPMStore = new RoworkPMStore();

export default roworkPMStore;
