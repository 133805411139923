import React from 'react';
import downloadImg from 'resources/downloadIcon.png';
import downloadH from 'resources/downloadIconHover.png';
import styled, { css } from 'styled-components';
import theme, { fonts } from 'styles/theme';
import { Mobile, Others } from 'styles/MediaQuery';
import { useTranslation } from 'react-i18next';

function DownloadBtn({
  all,
  allDownloadLink,
  download,
  onDownloadLink,
  imageOnly,
  fullWord,
  ...rest
}) {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      {all && (
        <DownloadWrapper href={allDownloadLink}>
          <AllDownload>{t('downloadBtn')[0]}</AllDownload>
          <img id="icon-hover" src={downloadH} alt="download" />
          <img id="icon" src={downloadImg} alt="download" />
        </DownloadWrapper>
      )}
      {download && (
        <DownloadWrapper href={onDownloadLink}>
          <Download>
            <Others>{t('downloadBtn')[1]}</Others>
            <Mobile>{fullWord ? t('downloadBtn')[1] : t('downloadBtn')[2]}</Mobile>
          </Download>
          <img id="icon-hover" src={downloadH} alt="download" />
          <img id="icon" src={downloadImg} alt="download" />
        </DownloadWrapper>
      )}
      {!download && onDownloadLink && (
        <DownloadWrapper href={onDownloadLink}>
          <img id="icon-hover" src={downloadH} alt="download" />
          <img id="icon" src={downloadImg} alt="download" />
        </DownloadWrapper>
      )}
      {imageOnly && (
        <ImageOnly>
          <img id="icon-hover" src={downloadH} alt="download" />
          <img id="icon" src={downloadImg} alt="download" />
        </ImageOnly>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;

const DownloadStyle = css`
  ${theme.flex.center}
  img {
    width: 19.71px;
    height: 13.8px;
    margin-left: 11px;
    cursor: pointer;
    @media ${theme.mobile} {
      margin-left: 4px;
    }
  }
  & #icon-hover {
    display: none;
  }
  :hover {
    & > p {
      font-weight: 600;
    }
  }
  :hover #icon {
    display: none;
  }
  :hover #icon-hover {
    display: inline;
  }
`;

const DownloadWrapper = styled.a`
  ${DownloadStyle}
`;

const ImageOnly = styled.div`
  ${DownloadStyle}
`;

const AllDownload = styled.p`
  margin: 0;
  ${fonts.formA}
  color: var(--bodyText2);
`;
const Download = styled.p`
  margin: 0;
  ${fonts.listTd}
`;

export default DownloadBtn;
