import {
  confirmDeveloperToProjectApi,
  getAllNotificationsApi,
  getDevCompletedProjectsApi,
  getHomeDashboardMetricApi,
  getRequestedProjectsApi,
  getInProgressProjectsApi,
  getCompletedProjectsApi,
  getDevInProgressProjectsApi,
  getDevRequestedProjectApi,
  getDevRequestedProjectDetailsApi,
  getClientRequestedProjectsApi,
  getClientInProgressProjectsApi,
  getClientCompletedProjectsApi,
  getUnreadCountNotificationsApi,
  updateNotificationCheckedApi,
  getNotificationDetailByIdApi,
  getDevProposalPreviewApi,
  getProjectProposalsApi,
  getProjectProposalsByProposalIdApi,
  getProjectsStatusApi,
  getProjectByIdApi,
  getInvitedProjectsApi,
  postAcceptInviteProjectMemberApi,
  postRejectInviteProjectMemberApi,
  getInProgressProductsApi,
} from 'api/API';
import { projectStatusArray } from 'data/constanst';
import { makeAutoObservable, runInAction } from 'mobx';
import issueStore from 'stores/IssueStore';

class DashboardStore {
  currentId = '';
  menuAvailable = false;
  briefBoardMetric = {};
  requestProjectDetails = {};
  requestProjects = [];
  proposedProjects = [];
  invitedProjects = [];
  inProgressProjectsCards = [];
  completedProjectsCards = [];

  unReadCount = 0;
  unReadNotifications = [];
  allNotifications = [];
  notiLastPage = false;
  navAllNotifications = [];
  navNotiLastPage = false;

  productName = '';
  projectStatus = '';

  isInProposal = false;
  proposalContent = [];
  proposalTitle = '';
  proposalDescription = '';

  inProgressProducts = [];

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentId = (projectId) => {
    this.currentId = projectId;
    this.menuAvailable = true;
  };

  getUnreadCountNotifications = async () => {
    await getUnreadCountNotificationsApi()
      .then((res) => {
        runInAction(() => {
          this.unReadCount = res.data.unreadCount;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // include function to chage checked
  getToUpdateNotificationById = async (id) => {
    await getNotificationDetailByIdApi(id)
      .then((res) => {
        runInAction(() => {
          this.getUnreadCountNotifications();
          this.getFetchNotifications(0, this.allNotifications.length);
          this.getNavFetchNotifications(0, this.navAllNotifications.length);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateNotificationChecked = async (id) => {
    await updateNotificationCheckedApi(id)
      .then((res) => {
        runInAction(() => {
          this.getUnreadCountNotifications();
          // console.log(this.allNotifications.length, this.navAllNotifications.length);
          if (this.allNotifications.length !== 0)
            this.getFetchNotifications(0, this.allNotifications.length);
          if (this.navAllNotifications.length !== 0)
            this.getNavFetchNotifications(0, this.navAllNotifications.length);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getFetchNotifications = async (pageNumber, pageSize) => {
    await getAllNotificationsApi(pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          if (res.data.length < pageSize) {
            this.notiLastPage = true;
          }
          if (pageNumber === 0) {
            this.allNotifications = [...res.data];
          } else {
            this.allNotifications = [...this.allNotifications, ...res.data];
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getNavFetchNotifications = async (pageNumber, pageSize) => {
    await getAllNotificationsApi(pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          if (res.data.length < pageSize) {
            this.navNotiLastPage = true;
          }
          if (pageNumber === 0) {
            this.navAllNotifications = [...res.data];
          } else {
            this.navAllNotifications = [...this.navAllNotifications, ...res.data];
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDashboardMetric = async () => {
    await getHomeDashboardMetricApi()
      .then((res) => {
        runInAction(() => {
          this.briefBoardMetric = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setDashboardProjects = (data, dev) => {
    let filteredProjects = [];
    let afterRequestProject = [];
    runInAction(() => {
      for (let proj of data) {
        if (proj.projectStatus !== 'COMPLETED' && proj.projectStatus !== 'IN_PROGRESS')
          filteredProjects.push(proj);
        if (!dev) {
          if (proj.projectStatus !== 'REQUESTED') afterRequestProject.push(proj);
        }
        if (dev) {
          // 프로젝트 상태가 NEED_DOCUMENT 이후인 프로젝트
          if (projectStatusArray.includes(proj.projectStatus, 6)) {
            afterRequestProject.push(proj);
          }
        }
      }
      if (dev) {
        this.proposedProjects = filteredProjects;
      } else {
        this.requestProjects = filteredProjects;
      }
      if (!this.currentId) this.currentId = afterRequestProject[0]?.projectId;
      this.menuAvailable = afterRequestProject.length > 0;
    });
  };

  /* client projects */
  getDashboardProjects = async () => {
    await getHomeDashboardMetricApi()
      .then((res) => {
        this.setDashboardProjects(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getRequestedProjects = async () => {
    await getRequestedProjectsApi()
      .then((res) => {
        // console.log(res.data);
        this.setDashboardProjects(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getInProgressProjects = async () => {
    await getInProgressProjectsApi()
      .then((res) => {
        runInAction(() => {
          this.inProgressProjectsCards = res.data;
          this.currentId = res.data[0]?.projectId;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCompletedProjects = async () => {
    await getCompletedProjectsApi()
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.completedProjectsCards = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getInvitedProjects = async () => {
    await getInvitedProjectsApi()
      .then((res) => {
        this.invitedProjects = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getClientRequestedProjects = async () => {
    await getClientRequestedProjectsApi()
      .then((res) => {
        // console.log(res.data);
        this.setDashboardProjects(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getClientInProgressProjects = async () => {
    await getClientInProgressProjectsApi()
      .then((res) => {
        runInAction(() => {
          this.inProgressProjectsCards = res.data;
          this.currentId = res.data[0]?.projectId;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getClientCompletedProjects = async () => {
    await getClientCompletedProjectsApi()
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.completedProjectsCards = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProjectNameById = async (projectId) => {
    await getProjectByIdApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.projectName = res.data.projectDetail.projectName;
          issueStore.gitUrl = res.data.projectDetail.gitUrl;
        });
      })
      .catch((e) => console.log(e));
  };

  getProjectsStatus = async (projectId) => {
    await getProjectsStatusApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.projectStatus = res.data.status;
          this.isInProposal = res.data.status === 'IN_PROPOSAL';
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getClientProposalPreview = async (projectId) => {
    await getProjectProposalsApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.proposalContent = res.data.content;
          this.proposalTitle = res.data.title;
          this.proposalDescription = res.data.description;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProjectProposalsByProposalId = async (proposalId) => {
    await getProjectProposalsByProposalIdApi(proposalId)
      .then((res) => {
        runInAction(() => {
          this.proposalContent = res.data.content;
          this.proposalTitle = res.data.title;
          this.proposalDescription = res.data.description;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* developer projects */
  getDevRequestedProject = async () => {
    await getDevRequestedProjectApi()
      .then((res) => {
        // console.log(res.data);
        this.setDashboardProjects(res.data, 'dev');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDevRequestedProjectDetails = async (projectId) => {
    await getDevRequestedProjectDetailsApi(projectId)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.requestProjectDetails = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDevProposalPreview = async (projectId) => {
    await getDevProposalPreviewApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.proposalContent = res.data.content;
          this.proposalTitle = res.data.title;
          this.proposalDescription = res.data.description;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  confirmDeveloperToProject = async (body) => {
    await confirmDeveloperToProjectApi(body)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDevInProgressProjects = async () => {
    await getDevInProgressProjectsApi()
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.inProgressProjectsCards = res.data;
          this.currentId = res.data[0]?.projectId;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDevCompletedProjects = async () => {
    await getDevCompletedProjectsApi()
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.completedProjectsCards = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  acceptInvitation = async (inviteId) => {
    await postAcceptInviteProjectMemberApi(inviteId)
      .then((res) => {
        runInAction(() => {
          this.getDashboardMetric();
          this.getInProgressProjects();
          this.getInvitedProjects();
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  rejectInvitation = async (inviteId) => {
    await postRejectInviteProjectMemberApi(inviteId)
      .then((res) => {
        runInAction(() => {
          this.getDashboardMetric();
          this.getInProgressProjects();
          this.getInvitedProjects();
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getInProgressProducts = async (productId) => {
    await getInProgressProductsApi()
      .then((res) => {
        runInAction(() => {
          this.inProgressProducts = res.data.data;
          for (let product of res.data.data) {
            if (product.productId === productId) {
              this.productName = product.productName;
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const dashboardStore = new DashboardStore();

export default dashboardStore;
