import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { fonts } from 'styles/theme';
import { MilestoneStatusBtn, PriorityTypeBtn, WorkStatusBtn } from './StatusBtn';
import Profile from 'components/atoms/Profile';
import dockerImg from 'images/docker.svg';
import macImg from 'images/mac-os-logo.png';
import ContainerImage from 'components/molecules/ContainerImage';
import useDetectOutsideClick from 'utils/useDetectOutsideClick';

const DropdownSelect = ({
  options,
  onClick,
  active,
  onSelect,
  type,
  height,
  scrollable,
  visibleDropdown,
  func,
}) => {
  const wrapperRef = useRef(null);
  const [visible, setVisible] = useDetectOutsideClick(wrapperRef, false);

  useEffect(() => {
    setVisible(visibleDropdown);
  }, [visibleDropdown]);

  useEffect(() => {
    if (func) {
      if (!visible) {
        func();
      }
    }
  }, [visible]);

  // 화살표 버튼으로 자동 완성된 목록 선택
  const btnRef = useRef(-1);
  const itemRef = useRef([]);
  itemRef.current = [];

  const addToRefs = (el) => {
    itemRef.current.push(el);
  };

  const selectArrow = useCallback(
    (e) => {
      if (e.isComposing) return;
      if (active) {
        const lilen = document.querySelectorAll('.dropwown-item').length;
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          if (btnRef.current === lilen - 1) btnRef.current = -1;
          btnRef.current += 1;
          document.getElementById(`dropwown-item${btnRef.current}`).focus();
        } else if (e.key === 'ArrowUp') {
          e.preventDefault();
          if (btnRef.current === 0 || btnRef.current === -1) btnRef.current = lilen;
          btnRef.current -= 1;
          document.getElementById(`dropwown-item${btnRef.current}`).focus();
        }
        if (e.key === 'Enter') {
          e.preventDefault();
          if (btnRef.current > -1) {
            onSelect(itemRef.current[btnRef.current], btnRef.current);
            btnRef.current = -1;
          }
        }
      }
    },
    [active, onSelect],
  );

  useEffect(() => {
    document.body.addEventListener('keydown', selectArrow);
    return () => {
      document.body.removeEventListener('keydown', selectArrow);
    };
  });

  const [optionsScroll, setOptionScroll] = useState(false);

  useEffect(() => {
    //optionScroll
    if (scrollable) {
      if (options?.length > 5) {
        setOptionScroll(true);
      }
    }
  }, [options?.length]);

  return (
    <>
      {visible && (
        <Wrap scroll={optionsScroll} ref={wrapperRef}>
          {options?.map((item, index) => (
            <DropdownItem
              key={`dropdown-select__${index}`}
              index={index}
              id={`dropwown-item${index}`}
              className="dropwown-item"
              onClick={(e) => {
                onClick(e, item, index);
              }}
              ref={addToRefs(item)}
              type={type}
              height={height}
            >
              {type === 'general' ? (
                item.name
              ) : type === 'containerImage' ? (
                <ContainerImage image={item} />
              ) : type === 'buildEnvType' ? (
                <>
                  {item.name}{' '}
                  {item.code === 'DOCKER_CONTAINER' && <BuildEnvTypeImg src={dockerImg} />}
                  {item.code === 'MAC' && <BuildEnvTypeImg src={macImg} />}
                </>
              ) : type === 'gitTree' ? (
                <>{item.path}</>
              ) : type === 'priorityType' ? (
                <PriorityTypeBtn priorityType={item.code} />
              ) : type === 'status' ? (
                <WorkStatusBtn status={item.code} />
              ) : type === 'hashtag' ? (
                item.name
              ) : type === 'milestone' ? (
                <div>
                  {item.name}
                  <MilestoneStatusBtn ml={8} status={item.milestoneStatus} />
                </div>
              ) : type === 'user' ? (
                <ProfileWrapper>
                  <Profile profile={item?.profileImageUrl} />
                  <Body>{item?.username}</Body>
                </ProfileWrapper>
              ) : (
                `${item.sha.substring(0, 7)} - ${item.message}`
              )}
            </DropdownItem>
          ))}
        </Wrap>
      )}
    </>
  );
};

const Wrap = styled.div`
  background-color: white;
  border: var(--border);
  box-shadow: 0px 0px 10px 0px rgba(87, 87, 87, 0.15);
  border-radius: 5px;

  ${(props) =>
    props.scroll === true &&
    css`
      height: 230px;
      overflow-y: auto;
    `}
`;

const DropdownItem = styled.button`
  border-top: ${(props) => (props.index === 0 ? 'none' : 'var(--border)')};
  padding-left: 17px;
  padding-right: 17px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : '47px')};
  background: transparent;
  ${(props) =>
    props.type === 'hashtag'
      ? css`
          letter-spacing: 0.1px;
          color: var(--grayText);
        `
      : css`
          letter-spacing: 0.4px;
          ${fonts.formA}
        `};
  :hover,
  :focus {
    outline: none;
    background: rgba(44, 98, 186, 0.05);
    color: var(--primary);
    font-weight: 600;
    letter-spacing: 0px;
  }
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
`;
const Body = styled.div`
  ${fonts.body}
`;

const BuildEnvTypeImg = styled.img`
  width: 30px;
  height: 30px;
  margin-left: 8px;
`;

export default DropdownSelect;
