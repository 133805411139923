import React from 'react';
import styled from 'styled-components';
import theme, { fonts } from 'styles/theme';

const Titles = ({ sectionTitle, title, ...rest }) => {
  return (
    <>
      <SectionTitle {...rest}>{sectionTitle}</SectionTitle>
      <Title {...rest}>{title}</Title>
    </>
  );
};

const SectionTitle = styled.div`
  padding-top: ${(props) => (props.pt ? props.pt : 184)}px;
  ${fonts.l_sectionTitle}
  @media ${theme.mobile} {
    padding-top: 86px;
    ${fonts.m_secondary}
    text-align: center;
    color: var(--primary);
  }
`;

const Title = styled.h2`
  ${fonts.l_Title}
  white-space: pre-line;
  ${(props) => props.mb && `margin-bottom:  ${props.mb}px`};
  @media ${theme.mobile} {
    margin-top: 7px;
    ${fonts.bigTitle}
  }
`;

export default Titles;
