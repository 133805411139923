import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Button from 'components/atoms/Button';
import OptionButton from 'components/atoms/OptionButton';
import close from 'resources/closeIcon.png';
import theme, { fonts } from 'styles/theme';
import success from 'resources/successIcon.png';
import { Mobile, Others } from 'styles/MediaQuery';
import { setPreventScroll, clearPreventScroll } from 'utils/usePreventScroll';
import TextArea from 'components/atoms/TextArea';
import { useTranslation } from 'react-i18next';

const Tconfirm = () => {
  const { t } = useTranslation('common');

  return t('confirmb');
};

export const PopUpCommon = ({ type, visible, closable, onClose, title, children }) => {
  useEffect(() => {
    if (visible) {
      setPreventScroll();
      return () => clearPreventScroll();
    }
  }, [visible]);

  if (!visible) return null;
  return (
    <Container>
      <DarkBackground />
      <PopupContainer type={type}>
        {closable && (
          <PopupClose onClick={onClose}>
            <img src={close} alt="close" />
          </PopupClose>
        )}
        {title && <PopupTitle>{title}</PopupTitle>}
        {children}
      </PopupContainer>
    </Container>
  );
};

const PopUp = ({ title, children, desc, appendix, visible, btnCenter }) => {
  useEffect(() => {
    if (visible) {
      setPreventScroll();
      return () => clearPreventScroll();
    }
  }, [visible]);

  if (!visible) return null;
  return (
    <Container>
      <DarkBackground />
      <BasicPopUp>
        <div>
          {title && <PopupTitle>{title}</PopupTitle>}
          {desc && <PopupContent>{desc}</PopupContent>}
          {appendix && <Appendix>{appendix}</Appendix>}
        </div>
        <PopupButtonsWrapper btnCenter={btnCenter}>{children}</PopupButtonsWrapper>
      </BasicPopUp>
    </Container>
  );
};
export default PopUp;

export const PopupPolicy = ({ visible, title, onClose, children }) => {
  useEffect(() => {
    if (visible) {
      setPreventScroll();
      return () => clearPreventScroll();
    }
  }, [visible]);

  if (!visible) return null;
  return (
    <Container>
      <DarkBackground />
      <SignPopup>
        <PopupTitle>{title}</PopupTitle>
        <SignPopupContent>{children}</SignPopupContent>
        <PopupButtonsWrapper>
          <Others>
            <Button size="large" onClick={onClose}>
              <Tconfirm />
            </Button>
          </Others>
          <Mobile>
            <Button size="fullWidth" onClick={onClose}>
              <Tconfirm />
            </Button>
          </Mobile>
        </PopupButtonsWrapper>
      </SignPopup>
    </Container>
  );
};

export const PopupOptions = ({
  visible,
  title,
  onChange,
  options,
  pgEnabled,
  buttonTitle,
  next,
  onClose,
}) => {
  const [active, setActive] = React.useState(null);
  useEffect(() => {
    if (visible) {
      setPreventScroll();
      return () => {
        clearPreventScroll();
      };
    }
  }, [visible]);

  const handleNext = () => {
    setActive(null);
    next();
  };

  if (!visible) return null;
  return (
    <Container>
      <DarkBackground />
      <PopupContainer>
        <PopupClose
          onClick={() => {
            setActive(null);
            onClose();
          }}
        >
          <img src={close} alt="close" />
        </PopupClose>
        {title && <PopupTitle>{title}</PopupTitle>}
        <PopupOptionsWrapper>
          {pgEnabled
            ? options.map((item, index) => (
                <OptionButton
                  key={`option-button__${index}`}
                  title={item.label}
                  active={active === index ? true : false}
                  onClick={() => {
                    onChange(item);
                    setActive(index);
                  }}
                />
              ))
            : options
                .filter((_, index) => index === 0)
                .map((item, index) => (
                  <OptionButton
                    key={`option-button__${index}`}
                    title={item.label}
                    active={active === index ? true : false}
                    onClick={() => {
                      onChange(item);
                      setActive(index);
                    }}
                  />
                ))}
        </PopupOptionsWrapper>
        <Others>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={active === null ? true : false}
              color={active === null ? 'grayLine' : 'primary'}
              size="large"
              onClick={() => (next ? handleNext() : null)}
            >
              {buttonTitle ? buttonTitle : <Tconfirm />}
            </Button>
          </div>
        </Others>
        <Mobile>
          <Button
            disabled={active === null ? true : false}
            color={active === null ? 'grayLine' : 'primary'}
            onClick={() => (next ? handleNext() : null)}
            size="m_fullWidth"
          >
            {buttonTitle ? buttonTitle : <Tconfirm />}
          </Button>
        </Mobile>
      </PopupContainer>
    </Container>
  );
};

export const PopupInfo = ({ title, visible, desc, onClose, type, center }) => {
  useEffect(() => {
    if (visible) {
      setPreventScroll();
      return () => clearPreventScroll();
    }
  }, [visible]);
  if (!visible) return null;
  return (
    <Container>
      <DarkBackground />
      <PopupContainer type={type}>
        {/* <PopupClose onClick={() => onClose()}>
          <img src={close} alt="close" />
        </PopupClose> */}
        <div>
          {type === 'success' && (
            <PopupSuccess>
              <img src={success} alt="Done" />
            </PopupSuccess>
          )}
          {title && <PopupTitle center={type === 'success' || center}>{title}</PopupTitle>}
          {desc && <PopupContent center={type === 'success' || center}>{desc}</PopupContent>}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Others>
            <Button color="primary" size="large" onClick={() => onClose()}>
              <Tconfirm />
            </Button>
          </Others>
          <Mobile>
            <Button color="primary" size="m_fullWidth" onClick={() => onClose()}>
              <Tconfirm />
            </Button>
          </Mobile>
        </div>
      </PopupContainer>
    </Container>
  );
};
export const PopupReason = ({ visible, desc, placeholder, onClick, onClose }) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    if (visible) {
      setPreventScroll();
      return () => clearPreventScroll();
    }
  }, [visible]);

  const [textAreaValue, setTextAreaValue] = useState('');
  const onChange = (e) => {
    const { value } = e.target;
    setTextAreaValue(value);
  };

  if (!visible) return null;
  return (
    <Container>
      <DarkBackground />
      <ExPopUp>
        <PopupTitle>{t('popupReason')[0]}</PopupTitle>
        {desc && <PopupContent>{desc}</PopupContent>}
        <PopUpTextArea
          height="145px"
          placeholder={placeholder}
          value={textAreaValue}
          onChange={onChange}
        />
        <PopupExButtonsWrapper>
          <Others>
            <Button onClick={() => onClick(textAreaValue)}>{t('popupReason')[1]}</Button>
            <Button color="transparent" border="gray" onClick={onClose}>
              {t('popupReason')[2]}
            </Button>
          </Others>
          <Mobile>
            <Button size="m_fullWidth" onClick={() => onClick(textAreaValue)}>
              {t('popupReason')[1]}
            </Button>
            <Button size="m_fullWidth" color="transparent" border="gray" onClick={onClose}>
              {t('popupReason')[2]}
            </Button>
          </Mobile>
        </PopupExButtonsWrapper>
      </ExPopUp>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
`;

const PopupContainer = styled.div`
  position: relative;
  width: 550px;
  height: 355px;
  padding: 56px 44px 60px 54px;
  background-color: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) =>
    props.type === 'success' &&
    css`
      text-align: center;
    `}
  @media ${theme.mobile} {
    max-width: calc(100% - 56px);
    height: 310px;
    padding: 40px 32px 40px 32px;
  }
  ${(props) =>
    props.type === 'issue' &&
    css`
      height: 808px;
      padding: 57px 58px 60px;
      @media ${theme.mobile} {
        height: 750px;
      }
    `}
  ${(props) =>
    props.type === 'payInfo' &&
    css`
      min-height: 715px;
      padding: 57px 58px 60px;
      justify-content: initial;
      @media ${theme.mobile} {
        min-height: 630px;
        max-height: calc(100vh - 56px);
        padding: 40px 32px;
      }
    `}
    ${(props) =>
    props.type === 'refund' &&
    css`
      height: 623px;
      padding: 57px 58px 60px;
      @media ${theme.mobile} {
        height: 750px;
      }
    `}
    ${(props) =>
    props.type === 'memberInvite' &&
    css`
      min-height: 540px;
      padding: 57px 58px 60px;
      justify-content: initial;
      @media ${theme.mobile} {
        min-height: 630px;
        max-height: calc(100vh - 56px);
        padding: 40px 32px;
      }
    `}
`;
const BasicPopUp = styled(PopupContainer)`
  @media ${theme.mobile} {
    padding-bottom: 36px;
  }
`;
const SignPopup = styled(PopupContainer)`
  height: 520px;
  padding: 68px 58px 55px 58px;
  @media ${theme.mobile} {
    height: 407px;
    padding: 49px 32px 40px 32px;
  }
`;
const ExPopUp = styled(PopupContainer)`
  height: 491px;
  padding: 68px 58px 55px 58px;
  @media ${theme.mobile} {
    padding: 40px 32px 40px 32px;
  }
`;

const PopupTitle = styled.h1`
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  ${fonts.bigTitle}
  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}
  @media ${theme.mobile} {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    ${(props) =>
      props.center &&
      css`
        margin-bottom: 14px;
      `}
  }
`;

const PopupContent = styled.div`
  font-size: 16px;
  line-height: 171.69%;
  white-space: pre-line;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: 12px;

  color: ${theme.colors.bodyText2};
  ${(props) => props.center && { textAlign: 'center' }}
`;
const SignPopupContent = styled(PopupContent)`
  height: 270px;
  overflow-y: scroll;
  @media ${theme.mobile} {
    font-size: 14px;
    font-weight: 400;
    height: 200px;
  }
  ul {
    padding-inline-start: 20px;
  }
  li {
    margin-bottom: 20px;
  }
`;

const Appendix = styled.p`
  ${fonts.secondary}
  margin-top: 30px;
`;
const PopUpTextArea = styled(TextArea)`
  margin-top: 17px;
`;
const PopupButtonsWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.btnCenter ? 'center' : 'flex-end')};
  padding-right: 14px;
  @media ${theme.mobile} {
    padding-right: 0px;
  }
`;
const PopupOptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${theme.mobile} {
    flex-direction: column;
  }
`;
const PopupExButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 41px;
  @media ${theme.mobile} {
    flex-direction: column;
    margin-top: 20px;
    button:last-child {
      margin: 10px 0 0;
    }
  }
`;

const PopupClose = styled.div`
  position: absolute;
  top: 41px;
  right: 50px;
  cursor: pointer;
  @media ${theme.mobile} {
    top: 32px;
    right: 32px;
  }
`;

const PopupSuccess = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 34px;
  margin-bottom: 12px;
  @media ${theme.mobile} {
    margin-top: 0;
  }
`;
