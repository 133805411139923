import { makeAutoObservable } from 'mobx';

class ErrorStore {
  errorCode = '';

  setErrorCode = (code) => {
    this.errorCode = code;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const errorStore = new ErrorStore();

export default errorStore;
