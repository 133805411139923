import {
  getIssueDetailApi,
  getIssueListApi,
  getIssuePageApi,
  postIssueApi,
  putIssueApi,
  deleteIssueApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';
import roworkPMStore from 'stores/RoworkPMStore';

class IssueStore {
  gitUrl = '';
  issueList = [];
  totalPages = 0;
  totalElements = 0;
  pageable = {};
  issueQuery = null;
  issueDetailInfo = {};

  loading = false;
  issueSubmitAlert = false;

  constructor() {
    makeAutoObservable(this);
  }

  showLoading = () => {
    this.loading = true;
  };

  hideLoading = () => {
    this.loading = false;
  };

  toggleIssueConfirmAlert = () => {
    this.issueSubmitAlert = !this.issueSubmitAlert;
  };

  getIssueList = async (projectId) => {
    await getIssueListApi(projectId)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.issueList = res.data.list;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getIssuePage = async (projectId, query) => {
    await getIssuePageApi(projectId, query)
      .then((res) => {
        runInAction(() => {
          this.issueList = res.data.content;
          this.totalElements = res.data.totalElements;
          this.totalPages = res.data.totalPages;
          this.pageable = res.data.pageable;
          this.issueQuery = query;
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  postIssue = async (projectId, body, type) => {
    this.showLoading();
    let success = false;
    await postIssueApi(projectId, body)
      .then((res) => {
        runInAction(() => {
          this.hideLoading();
          if (res.status === 200) {
            success = true;
            this.getIssuePage(projectId, this.issueQuery);
            if (type === 'roworkPM') {
              roworkPMStore.getAllRoworkPM(
                roworkPMStore.query,
                0,
                roworkPMStore.pmInquiryList.length,
              );
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
        this.hideLoading();
      });
    return success;
  };

  getIssueDetail = async (issueId) => {
    await getIssueDetailApi(issueId)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.issueDetailInfo = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeIssue = async (issueId, body, type) => {
    await putIssueApi(issueId, body)
      .then((res) => {
        runInAction(() => {
          console.log(res);
          this.issueDetailInfo = res.data;
          if (type?.type === 'issueList') {
            this.getIssuePage(type.projectId, type.query);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteIssue = async (issueId) => {
    await deleteIssueApi(issueId)
      .then((res) => {
        runInAction(() => {
          console.log(res);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const issueStore = new IssueStore();

export default issueStore;
