import {
  getProjectMetricsApi,
  getInProgressMilestonesApi,
  getPastMilestonesApi,
  getAfterMilestonesApi,
  getMilestoneDetailApi,
  postClientMilestoneConfirmApi,
  getClientWorkReportsApi,
  getWorkReportByIdApi,
  getClientActivitiesApi,
  getDeveloperActivitiesApi,
  postDevMilestoneSubmitApi,
  searchDevMilestoneCommitApi,
  getProjectCodeQualityApi,
  getProjectByIdApi,
  getMyProjectMemberApi,
  getProductComponentsApi,
  getProductComponentApi,
  getGitReposApi,
  getGitRepoDetailApi,
  getGitBranchesApi,
  postMilestoneApi,
  getDraftMilestonesApi,
  getMilestonesApi,
  getGitBrancheCommitsApi,
  getGitTreesApi,
  getGitBlobApi,
  getGitDiffApi,
  postSettingProductApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

import DashboardStore from './DashboardStore';

class ProjectStore {
  loading = false;
  milestoneConfirmAlert = false;
  milestoneSubmitAlert = false;

  project = {};
  paymentMethods = []; // todo
  metricList = [];
  codeQualityList = [];

  productComponentList = [];
  productComponent = {};
  gitRepoList = [];
  gitRepo = {};
  gitBranchList = [];
  gitCommitPage = [];
  gitTree = {};
  gitBlob = {};
  gitDiff = null;

  inProgressMilestones = [];
  draftMilestones = [];
  pastMilestones = [];
  afterMilestones = [];
  milestoneList = [];
  milestoneDetail = {};

  workReportList = [];
  workReport = {};

  actiLastPage = false;
  activities = [];
  myProjectMember = {};

  updateProductInfoAlert = false;

  constructor() {
    makeAutoObservable(this);
  }

  showLoading = () => {
    this.loading = true;
  };

  hideLoading = () => {
    this.loading = false;
  };

  toggleMilestoneConfirmAlert = () => {
    this.milestoneConfirmAlert = !this.milestoneConfirmAlert;
  };

  toggleMilestoneSubmitAlert = () => {
    this.milestoneSubmitAlert = !this.milestoneSubmitAlert;
  };

  setWorkReportList = (param) => {
    this.workReportList = param;
  };

  setWorkReport = (param) => {
    this.workReport = param;
  };

  resetActivities = () => {
    this.actiLastPage = false;
    this.activities = [];
  };

  resetMilestoneDetail = () => {
    this.milestoneDetail = {};
  };

  toggleUpdateProductInfoAlert = () => {
    this.updateProductInfoAlert = !this.updateProductInfoAlert;
  };

  getProject = async (projectId) => {
    await getProjectByIdApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.project = res.data;
          this.myProjectMember = res.data.projectMember;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProjectMetrics = async (projectId) => {
    await getProjectMetricsApi('clients', projectId)
      .then((res) => {
        runInAction(() => {
          this.metricList = res.data.metricList;
          // console.log(toJS(this.metricList));
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProjectCodeQuality = async (projectId) => {
    await getProjectCodeQualityApi('clients', projectId)
      .then((res) => {
        runInAction(() => {
          this.codeQualityList = res.data.itemList;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProductComponents = async (productId) => {
    await getProductComponentsApi(productId)
      .then((res) => {
        runInAction(() => {
          this.productComponentList = res.data.data.content;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProductComponent = async (productComponentId) => {
    await getProductComponentApi(productComponentId)
      .then((res) => {
        runInAction(() => {
          this.productComponent = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getGitRepos = async (productId) => {
    await getGitReposApi(productId)
      .then((res) => {
        runInAction(() => {
          this.gitRepoList = res.data.data.content;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getGitRepoDetail = async (repoId) => {
    await getGitRepoDetailApi(repoId)
      .then((res) => {
        runInAction(() => {
          this.gitRepo = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getGitBranches = async (gitRepoId) => {
    await getGitBranchesApi(gitRepoId)
      .then((res) => {
        runInAction(() => {
          this.gitBranchList = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getGitCommits = async (gitRepoId, branchName, pageNumber, pageSize) => {
    await getGitBrancheCommitsApi(gitRepoId, branchName, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          this.gitCommitPage = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getGitTrees = async (gitRepoId, sha) => {
    await getGitTreesApi(gitRepoId, sha)
      .then((res) => {
        runInAction(() => {
          this.gitTree = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getGitBlob = async (gitRepoId, sha) => {
    await getGitBlobApi(gitRepoId, sha)
      .then((res) => {
        runInAction(() => {
          this.gitBlob = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getGitDiff = async (gitRepoId, sha) => {
    await getGitDiffApi(gitRepoId, sha)
      .then((res) => {
        runInAction(() => {
          this.gitDiff = res.data.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  resetGitDetailInfo = () => {
    this.gitRepo = {};
    this.gitBranchList = [];
    this.gitCommitPage = [];
    this.gitTree = {};
    this.gitBlob = {};
    this.gitDiff = null;
  };

  resetGitTree = () => {
    this.gitTree = {};
  };

  resetProductComponent = () => {
    this.productComponent = {};
  };

  getMilestones = async (productId, statuses, pageNumber, pageSize) => {
    await getMilestonesApi(productId, statuses, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          if (pageNumber === 0) {
            this.milestoneList = res.data.data.content;
          } else {
            this.milestoneList = [...this.milestoneList, ...res.data.data.content];
          }
          // console.log(toJS(this.inProgressMilestones));
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getInProgressMilestones = async (projectId) => {
    await getInProgressMilestonesApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.inProgressMilestones = res.data.list;
          // console.log(toJS(this.inProgressMilestones));
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDraftMilestones = async (projectId) => {
    await getDraftMilestonesApi(projectId)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.draftMilestones = res.data.list;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getPastMilestones = async (projectId) => {
    await getPastMilestonesApi(projectId)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.pastMilestones = res.data.list;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAfterMilestones = async (projectId) => {
    await getAfterMilestonesApi(projectId)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          this.afterMilestones = res.data.list;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getMilestoneDetail = async (id) => {
    await getMilestoneDetailApi(id)
      .then((res) => {
        runInAction(() => {
          this.milestoneDetail = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeMilestone = async (body) => {
    await postMilestoneApi(body)
      .then((res) => {
        runInAction(() => {
          this.milestoneDetail = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postClientMilestoneConfirm = async (id, body) => {
    this.showLoading();
    let success = false;
    await postClientMilestoneConfirmApi(id, body)
      .then((res) => {
        runInAction(() => {
          if (res.status === 200) success = true;
        });
      })
      .catch((error) => {
        console.log(error);
        this.hideLoading();
      });
    return success;
  };

  searchDevMilestoneCommit = async (id, keyword) => {
    let result = [];
    await searchDevMilestoneCommitApi(id, keyword)
      .then((res) => {
        runInAction(() => {
          result = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };

  postDevMilestoneSubmit = async (id, body) => {
    this.showLoading();
    let success = false;
    await postDevMilestoneSubmitApi(id, body)
      .then((res) => {
        runInAction(() => {
          if (res.status === 200) success = true;
        });
      })
      .catch((error) => {
        console.log(error);
        this.hideLoading();
      });
    return success;
  };

  getClientWorkReports = async (id) => {
    await getClientWorkReportsApi(id)
      .then((res) => {
        this.setWorkReportList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getWorkReportById = async (id) => {
    await getWorkReportByIdApi(id)
      .then((res) => {
        this.setWorkReport(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getActivities = async (id, pageNumber, pageSize) => {
    // todo change to common
    await getClientActivitiesApi(id, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          if (res.data.data.length < pageSize) {
            this.actiLastPage = true;
          }
          if (pageNumber === 0) {
            this.activities = [...res.data.data];
            // console.log(res.data, pageNumber, 'if pageNumber === 0');
          } else {
            this.activities = [...this.activities, ...res.data.data];
            // console.log(res.data, pageNumber, 'else');
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getClientActivities = async (id, pageNumber, pageSize) => {
    await getClientActivitiesApi(id, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          if (res.data.data.length < pageSize) {
            this.actiLastPage = true;
          }
          if (pageNumber === 0) {
            this.activities = [...res.data.data];
            // console.log(res.data, pageNumber, 'if pageNumber === 0');
          } else {
            this.activities = [...this.activities, ...res.data.data];
            // console.log(res.data, pageNumber, 'else');
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDeveloperActivities = async (id, pageNumber, pageSize) => {
    await getDeveloperActivitiesApi(id, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          if (res.data.data.length < pageSize) {
            this.actiLastPage = true;
          }
          if (pageNumber === 0) {
            this.activities = [...res.data.data];
          } else {
            this.activities = [...this.activities, ...res.data.data];
          }
          // console.log(res.data);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getMyProjectMember = async (projectId) => {
    getMyProjectMemberApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.myProjectMember = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postSettingProduct = async (productId, body) => {
    this.showLoading();
    await postSettingProductApi(productId, body)
      .then((res) => {
        this.hideLoading();
        this.toggleUpdateProductInfoAlert();
        DashboardStore.getInProgressProducts();
      })
      .catch((error) => {
        this.hideLoading();
        console.log(error);
      });
  };
}

const projectStore = new ProjectStore();

export default projectStore;
