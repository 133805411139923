import React, { useEffect, useState } from 'react';
import stores from 'stores';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import ServiceMenuItem from './ServiceMenuItem';
import OpenTab from 'components/molecules/OpenTab';

const ServiceMenu = observer(({ onMobile, transform }) => {
  const { t } = useTranslation('service');

  const [openPricingTab, setOpenPricingTab] = useState(false);
  const [openUsecaseTab, setOpenUsecaseTab] = useState(false);
  const [openTipTab, setOpenTipTab] = useState(false);

  const { serviceStore } = stores;
  const {
    activeTab,
    getPricingInfoMenus,
    pricingInfoMenus,
    getUsecaseInfoMenus,
    usecaseInfoMenus,
    menuLengths,
  } = serviceStore;
  const { first, second, third } = menuLengths;
  const history = useHistory();

  const handleTabClick = (tab, type) => {
    console.log(tab, type);

    if (!type) history.push({ pathname: '/service', state: { currentTab: tab } });
    if (isTabInRange(1, 1 + first, tab)) {
      history.push({ pathname: `/pricing/${type.toLowerCase()}`, state: { currentTab: tab } });
    }
    if (isTabInRange(1 + first, 1 + first + second, tab)) {
      history.push({ pathname: `/use-cases/${type.toLowerCase()}`, state: { currentTab: tab } });
    }
    if (tab === 9) {
      history.push({ pathname: `/tips`, state: { currentTab: tab } });
    }
    if (onMobile) {
      setOpenPricingTab(false);
      setOpenUsecaseTab(false);
      setOpenTipTab(false);
      onMobile();
    }
  };

  const isTabInRange = (start, end, activeTab) => {
    return start <= activeTab && activeTab < end;
  };

  useEffect(() => {
    if (!transform) {
      setOpenPricingTab(isTabInRange(1, 1 + first, activeTab));
      setOpenUsecaseTab(isTabInRange(1 + first, 1 + first + second, activeTab));
      setOpenTipTab(isTabInRange(1 + first + second, 1 + first + second + third, activeTab));
    } else {
      setOpenPricingTab(false);
      setOpenUsecaseTab(false);
      setOpenTipTab(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, second, activeTab, transform]);

  useEffect(() => {
    getPricingInfoMenus();
    getUsecaseInfoMenus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ServiceMenuItem onClick={() => handleTabClick(0)} focus={activeTab === 0} h2>
        {t('serviceMenu')[0]}
      </ServiceMenuItem>
      <OpenTab
        initialNum={1}
        topMenu={t('serviceMenu')[1]}
        // topFocus={[1, 2, 3].includes(activeTab)}
        topFocus={isTabInRange(1, 1 + first, activeTab)}
        isOpenSub={openPricingTab}
        onOpenSubMenu={() => setOpenPricingTab(!openPricingTab)}
        subFocus={activeTab}
        onActiveTab={handleTabClick}
        subMenus={pricingInfoMenus}
      />
      <OpenTab
        initialNum={1 + first}
        topMenu={t('serviceMenu')[2]}
        // topFocus={[4, 5, 6].includes(activeTab)}
        topFocus={isTabInRange(1 + first, 1 + first + second, activeTab)}
        isOpenSub={openUsecaseTab}
        onOpenSubMenu={() => setOpenUsecaseTab(!openUsecaseTab)}
        subFocus={activeTab}
        onActiveTab={handleTabClick}
        subMenus={usecaseInfoMenus}
      />
      <OpenTab
        initialNum={1 + first + second}
        topMenu={t('serviceMenu')[3]}
        // topFocus={[7, 8].includes(activeTab)}
        topFocus={isTabInRange(1 + first + second, 1 + first + second + third, activeTab)}
        isOpenSub={openTipTab}
        onOpenSubMenu={() => setOpenTipTab(!openTipTab)}
        subFocus={activeTab}
        onActiveTab={handleTabClick}
        subMenus={t('serviceMenu')[4]}
      />
    </>
  );
});

export default ServiceMenu;
