import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Logo from 'components/atoms/Logo';
import SideButton from 'components/atoms/SideButton';
import NavigationMenu from 'components/templates/navigation/NavigationMenu';

const SideMenuBar = ({ expandLayout }) => {
  const [xPosition, setX] = useState({
    opened: true,
    width: 280,
    transform: 0,
  });
  const sideRef = useRef(null);

  const toggleMenu = () => {
    if (xPosition.opened) {
      setX({ opened: false, width: 0, transform: 280 });
    } else {
      setX({
        opened: true,
        width: 280,
        transform: 0,
      });
    }
  };

  useEffect(() => {
    toggleMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (expandLayout) {
      setX({ ...xPosition, width: 0, transform: 280 });
    } else {
      setX({ ...xPosition, width: 280, transform: 0 });
    }
  }, [expandLayout]);

  const closeAll = (e) => {
    if (expandLayout === null) {
      if (sideRef.current !== null && !sideRef.current.contains(e.target)) {
        setX({
          opened: false,
          width: 0,
          transform: 280,
        });
      }
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', closeAll);
    return () => {
      document.body.removeEventListener('click', closeAll);
    };
  });

  return (
    <SidebarContainer width={xPosition.width} transform={xPosition.transform} ref={sideRef}>
      <SideButton open={xPosition.opened} onClick={() => toggleMenu()} />
      <Logo side="true" />
      <NavigationMenu side />
    </SidebarContainer>
  );
};

const SidebarContainer = styled.nav`
  position: fixed;
  padding-top: 26.62px;
  display: block;
  border-right: 1px solid #dfdfdf;
  background-color: #fff;
  width: 280px;
  min-height: 100vh;
  transition: 0.5s ease;
  z-index: 11;
  width: ${(props) => props.width}px;
  transform: translateX(-${(props) => props.transform}px);
  @media ${theme.tablet} {
    position: fixed;
    width: ${(props) => props.width}px;
    transform: translateX(-${(props) => props.transform}px);
  }
`;
export default SideMenuBar;
