const getLangCodeFromi18n = (i18nLanguage) => {
  let langCode =
    i18nLanguage && i18nLanguage.indexOf('-') > -1 ? i18nLanguage.split('-')[0] : i18nLanguage;
  if (!['ko', 'en'].includes(langCode)) {
    langCode = 'en';
  }
  return langCode;
};

export const getLanguageCode = (i18nLanguage) => {
  const savedLanguageCode = localStorage.getItem('languageCode');
  if (!savedLanguageCode) {
    return getLangCodeFromi18n(i18nLanguage);
  }
  return savedLanguageCode;
};
