import React from 'react';
import styled from 'styled-components';

function Height({ height }) {
  return <Margin height={height}></Margin>;
}

const Margin = styled.div`
  height: ${(props) => props.height};
`;

export default Height;
