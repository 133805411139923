import React from 'react';
import styled, { css } from 'styled-components';

function Tags({ children, ...rest }) {
  return <Tag {...rest}>{children}</Tag>;
}

const TagList = ({ data, onTagClick }) => {
  return data.map((data) => (
    <Tags selected={data.selected} key={data.id} onClick={() => onTagClick(data.id)}>
      {data.name}
    </Tags>
  ));
};

const Tag = styled.div`
  cursor: pointer;
  display: inline-block;
  border-radius: 26px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  border: var(--border);
  color: var(--bodyText2);
  background: var(--background);
  :hover {
    background: var(--primaryLight);
  }
  ${(props) =>
    props.selected &&
    css`
      font-weight: 600;
      border: 1px solid var(--primary);
      color: var(--primaryLight);
      background-color: var(--primary);
      :hover {
        background: var(--primary);
      }
    `}

  &:not(:last-child) {
    margin-bottom: 14px;
    margin-right: 7px;
  }
`;

export default TagList;
