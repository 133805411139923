import React from 'react';
import styled, { css } from 'styled-components';
import theme, { fonts } from 'styles/theme';
import { useTranslation } from 'react-i18next';

const EditButtons = ({ setId, onEdit, onDelete, ...rest }) => {
  const { t } = useTranslation('common');

  const handleBubbling = (e) => {
    e.stopPropagation();
  };

  return (
    <EditArea onClick={handleBubbling} {...rest}>
      <EditBtn onClick={() => onEdit(setId)}>{t('editButtons')[0]}</EditBtn>
      <EditBtn onClick={() => onDelete(setId)}>{t('editButtons')[1]}</EditBtn>
    </EditArea>
  );
};

const EditArea = styled.div`
  display: flex;
  min-width: 70px;
  margin: 8px 0;
  ${fonts.secondary};
  color: var(--grayText2);
  ${(props) =>
    props.left
      ? css`
          justify-content: flex-start;
          margin-left: -5px;
        `
      : css`
          justify-content: flex-end;
          @media ${theme.mobile} {
            margin: 0;
            padding-left: 43px;
          }
        `};
`;
const EditBtn = styled.button`
  padding: 5px;
  & + & {
    margin-left: 8px;
  }
`;

export default EditButtons;
