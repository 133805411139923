import { makeAutoObservable, runInAction, toJS } from 'mobx';
import {
  getProjectByIdApi,
  getDevProjectByIdApi,
  getReceiptApi,
  getMatchingDeveloperDetailsApi,
  confirmMatchingDeveloperApi,
  getPaymentInfoApi,
  getClientMaterialsApi,
  uploadMaterialApi,
  deleteMaterialApi,
  getProposedDevelopersApi,
  getMatchingDevelopersApi,
  getCommonMaterialsApi,
  getContractMaterialsApi,
  getDevPaymentsApi,
  getContractListByProjectIdApi,
  getContractByContractIdApi,
  getContractContentByContractContentIdApi,
  getContractContentCommentsApi,
  postContractsContentsCommentsApi,
  deleteContractsContentsCommentsApi,
  postContractsContentsRespondedApi,
  getMatchingDevelopersByContractIdApi,
  getProposedDevelopersByContractIdApi,
} from 'api/API';
import { projectStatusArray } from 'data/constanst';
import contractSignStore from './ContractSignStore';

class ContractStore {
  projectDetail = {};

  contractsList = [];
  // contractsLastPage = false;
  contractId = '';
  contractsDetailList = [];
  contractDetail = {};
  contractContent = {};
  contractItemList = [];
  noCommentWarnings = [];
  contractCommentList = [];
  contractContentId = '';
  commentsNamesMap = {};
  feedbackResponded = false;
  mobileFocusedCommentId = '';
  mobileFocusedComment = [];
  mobileHightlightFocusedComment = [];
  mobileHightlightFocusedId = '';

  tip = { first: false, second: false };
  contractSignDocumentReady = false;
  definitionReady = false;

  // client
  dataSubmissionReady = false;
  fileUploaded = false;
  finalProductReady = false;
  receipt = [];
  paymentInfo = {};

  acceptedByClient = false;
  projectId = null;
  partNameList = [];
  developers = [];
  selectedDevelopers = [];

  developerDetails = {};

  // developer
  paymentsFiles = [];
  projectFiles = [];
  contractFiles = [];

  constructor() {
    makeAutoObservable(this);
  }

  toggleFileUpload = () => {
    this.fileUploaded = !this.fileUploaded;
  };

  resetAcceptedByClient = () => {
    this.acceptedByClient = false;
  };

  resetClientContract = () => {
    this.tip = { first: false, second: false };
    this.contractSignDocumentReady = false;
    this.definitionReady = false;
    this.dataSubmissionReady = false;
    this.selectedDevelopers = [];
    this.finalProductReady = false;
  };

  resetDevContract = () => {
    this.tip = { first: false, second: false };
    this.contractSignDocumentReady = false;
    this.definitionReady = false;
  };

  getMobileFeedbackCardComments = (id) => {
    this.mobileFocusedComment = this.contractCommentList.filter((item) => item.commentId === id);
    this.mobileFocusedCommentId = id;
  };

  getMobileHightlightComments = (id) => {
    this.mobileHightlightFocusedComment = this.contractCommentList.filter(
      (item) => item.contractItemId === id,
    );
    this.mobileHightlightFocusedId = id;
  };

  getContractListByProjectId = async (projectId, pageNumber, pageSize, contractId) => {
    let success = false;
    await getContractListByProjectIdApi(projectId, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          this.contractsList = res.data.content;
          this.contractId =
            contractId ||
            res.data.content.filter((contract) => contract.inProgress)[0]?.contractId ||
            res.data.content[0]?.contractId;
          success = this.getContractByContractId(this.contractId);
          // if (res.data.content.length < pageSize) {
          //   this.contractsLastPage = true;
          // }
          // if (pageNumber === 0) this.contractsList = [...res.data.content];
          // else this.contractsList = [...this.contractsList, ...res.data.content];
        });
      })
      .catch((error) => {
        console.log(error);
      });
    return success;
  };

  getContractByContractId = async (contractId) => {
    let success = false;
    await getContractByContractIdApi(contractId)
      .then((res) => {
        runInAction(() => {
          console.log(res);
          this.contractId = contractId;
          this.contractsDetailList[contractId] = { ...res.data };
          this.contractDetail = res.data;
          if (this.contractDetail.contractType === 'CLIENT') {
            this.dataSubmissionReady =
              res.data.uploadAttachmentFileAvailable || res.data.statusCode === 'IN_REVIEW';
            this.setClientStatusView(res.data.projectRequestStatus);
          }
          if (this.contractDetail.contractType === 'DEVELOPER') {
            this.setDevStatusView(res.data.projectRequestStatus);
          }
          this.finalProductReady = res.data.finalOutputAvailable;
          contractSignStore.contractSignCompleted = res.data.signedIn;
          this.contractContentId = res.data.content.contractContentId;
          this.getContractContentByContractContentId(res.data.content.contractContentId);
          this.getContractContentComments(res.data.content.contractContentId);
          success = true;
        });
      })
      .catch((error) => {
        console.log(error);
      });
    return success;
  };

  getContractContentByContractContentId = async (contractContentId) => {
    let success = false;
    await getContractContentByContractContentIdApi(contractContentId)
      .then((res) => {
        runInAction(() => {
          console.log(res.data.data, 'getContractContentByContractContentId');
          this.contractContent = res.data.data;
          this.contractItemList = res.data.data.itemList;
          this.feedbackResponded = res.data.data.feedbackResponded;
          // this.proposalId = res.data.proposalId;
          // this.proposalInfo = res.data;
          // this.proposalContent = res.data.content;
          // if (this.needCommentFeedbacks.length === 0) {
          //   this.checkNeedFeedbacks(res.data.content);
          //   //this.callHighlightFeedbacksAndComments(isMobile);
          // }
        });
      })
      .catch((error) => {
        console.log(error);
      });
    return success;
  };

  getContractContentComments = async (contractContentId) => {
    await getContractContentCommentsApi(contractContentId)
      .then((res) => {
        runInAction(() => {
          console.log(res, 'contract comment');
          this.contractCommentList = res.data.data.content;
          if (this.mobileFocusedCommentId) {
            this.getMobileFeedbackCardComments(this.mobileFocusedCommentId);
          }
          if (this.mobileHightlightFocusedId) {
            this.getMobileHightlightComments(this.mobileHightlightFocusedId);
          }
        });
      })
      .catch((e) => console.log(e));
  };

  addContractsContentsComments = async (formData) => {
    await postContractsContentsCommentsApi(formData)
      .then((res) => {
        runInAction(() => {
          console.log(res);
          this.getContractContentComments(this.contractContentId);
          this.getContractContentByContractContentId(this.contractContentId);
        });
      })
      .catch((e) => console.log(e));
  };

  deleteContractsContentsComments = async (commentId) => {
    await deleteContractsContentsCommentsApi(commentId)
      .then((res) => {
        runInAction(() => {
          console.log(res);
          this.getContractContentComments(this.contractContentId);
          this.getContractContentByContractContentId(this.contractContentId);
        });
      })
      .catch((e) => console.log(e));
  };

  postContractsContentsResponded = async (contractContentId) => {
    await postContractsContentsRespondedApi(contractContentId)
      .then((res) => {
        runInAction(() => {
          console.log(res);
          this.feedbackResponded = true;
        });
      })
      .catch((e) => console.log(e));
  };

  getProjectById = async (projectId) => {
    let projectStatus = '';
    await getProjectByIdApi(projectId)
      .then((res) => {
        runInAction(() => {
          // this.contractProject[projectId] = { ...res.data };
          this.projectDetail = res.data.projectDetail;
          projectStatus = res.data.projectDetail.projectStatus;
          // this.setClientStatusView(res.data.projectDetail.projectStatus);
        });
      })
      .catch((e) => console.log(e));
    return projectStatus;
  };

  setClientStatusView = (status) => {
    // 프로젝트 상태가 FINDING_DEVELOPER 이후인 프로젝트
    if (projectStatusArray.includes(status, 3)) this.definitionReady = true;

    if (status === 'IN_CONTRACT') this.tip = { first: true, second: true };

    // 프로젝트 상태가 PENDING_TAX_INVOICE 이후인 프로젝트
    if (projectStatusArray.includes(status, 9)) this.contractSignDocumentReady = true;

    // if (status === 'PENDING_TAX_INVOICE' || status === 'PENDING_PAYMENT') {
    //   this.dataSubmissionReady = true;
    // }
  };

  getDevProjectById = async (projectId) => {
    await getDevProjectByIdApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.projectDetail = res.data.projectDetail;
          this.setDevStatusView(res.data.projectDetail.projectStatus);
        });
      })
      .catch((e) => console.log(e));
  };

  setDevStatusView = (status) => {
    if (status === 'IN_CONTRACT') {
      this.tip = { first: true, second: true };
    }

    // 프로젝트 상태가 NEED_DOCUMENT 이후인 프로젝트
    if (projectStatusArray.includes(status, 6)) this.definitionReady = true;

    // 프로젝트 상태가 PENDING_TAX_INVOICE 이후인 프로젝트
    if (projectStatusArray.includes(status, 9)) this.contractSignDocumentReady = true;
  };

  getProposedDevelopers = async (item) => {
    this.partNameList = [];
    await getProposedDevelopersApi(item)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data);
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < res.data[i].matchingDeveloperList.length; j++) {
              this.developers[res.data[i].partName] = { ...res.data[i] };
              // if (res.data[i].matchingDeveloperList[j].acceptedByClient) {
              //   this.selectedDevelopers[res.data[i].partName] = { ...res.data[i] };
              // } else {
              //   this.developers[res.data[i].partName] = { ...res.data[i] };
              // }
            }
            if (!this.partNameList.includes(res.data[i].partName)) {
              this.partNameList.push(res.data[i].partName);
            }
          }
        });
      })
      .catch((e) => console.log(e));
  };
  getProposedDevelopersByContractId = async (contractId) => {
    this.partNameList = [];
    await getProposedDevelopersByContractIdApi(contractId)
      .then((res) => {
        runInAction(() => {
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < res.data[i].matchingDeveloperList.length; j++) {
              this.developers[res.data[i].partName] = { ...res.data[i] };
            }
            if (!this.partNameList.includes(res.data[i].partName)) {
              this.partNameList.push(res.data[i].partName);
            }
          }
        });
      })
      .catch((e) => console.log(e));
  };

  getMatchingDevelopers = async (projectId) => {
    this.partNameList = [];
    await getMatchingDevelopersApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.selectedDevelopers = res.data;
          // console.log(res.data);
          for (let i = 0; i < res.data.length; i++) {
            if (!this.partNameList.includes(res.data[i].partName)) {
              this.partNameList.push(res.data[i].partName);
            }
          }
        });
      })
      .catch((e) => console.log(e));
  };
  getMatchingDevelopersByContractId = async (contractId) => {
    this.partNameList = [];
    await getMatchingDevelopersByContractIdApi(contractId)
      .then((res) => {
        runInAction(() => {
          this.selectedDevelopers = res.data;
          for (let i = 0; i < res.data.length; i++) {
            if (!this.partNameList.includes(res.data[i].partName)) {
              this.partNameList.push(res.data[i].partName);
            }
          }
        });
      })
      .catch((e) => console.log(e));
  };

  getMatchingDeveloperDetails = async (matchingId) => {
    await getMatchingDeveloperDetailsApi(matchingId)
      .then((res) => {
        runInAction(() => {
          this.contractId = res.data.contractId;
          this.developerDetails = res.data;
          this.acceptedByClient = res.data.acceptedByClient;
          this.projectId = res.data.projectId;
        });
      })
      .catch((e) => console.log(e));
  };
  confirmMatchingDeveloper = async (id, body) => {
    let result = {};
    await confirmMatchingDeveloperApi(id, body)
      .then((res) => {
        result = {
          lastAgreedDeveloper: res.data.lastAgreedDeveloper,
          operationName: res.data.operationName,
        };
      })
      .catch((e) => console.log('confirmDeveloper error: ', e));
    return result;
  };

  getPaymentInfo = async (projectId) => {
    await getPaymentInfoApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.paymentInfo = res.data;
        });
      })
      .catch((e) => console.log(e));
  };

  getReceipt = async (projectId) => {
    await getReceiptApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.receipt = res.data;
        });
      })
      .catch((e) => console.log(e));
  };

  getClientMaterials = async (contractId) => {
    let temp = [];
    await getClientMaterialsApi(contractId)
      .then((res) => {
        temp = res.data;
      })
      .catch((e) => console.log('Error getCommonMaterials e', e));
    return temp;
  };
  uploadMaterial = async (contractId, item) => {
    let success = false;
    await uploadMaterialApi(contractId, item)
      .then(() => {
        success = true;
        this.toggleFileUpload();
      })
      .catch((e) => console.log('Upload material error, id: ', contractId, ' error: ', e));
    return success;
  };
  deleteMaterial = async (id) => {
    let success = false;
    await deleteMaterialApi(id)
      .then((res) => {
        if (res.status === 200) success = true;
      })
      .catch((e) => alert('Error with deleting material, e: ', e));
    return success;
  };

  getCommonMaterials = async (projectId) => {
    await getCommonMaterialsApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.projectFiles = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getContractMaterials = async (contractId) => {
    await getContractMaterialsApi(contractId)
      .then((res) => {
        runInAction(() => {
          this.contractFiles = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDevPayments = async (projectId) => {
    await getDevPaymentsApi(projectId)
      .then((res) => {
        runInAction(() => {
          this.paymentsFiles = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  turnOffTip = (val) => {
    if (val === 'FIRST') this.tip = { ...this.tip, first: false };
    else this.tip = { ...this.tip, second: false };
  };
}

const contractStore = new ContractStore();

export default contractStore;
