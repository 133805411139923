import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme, { fonts } from 'styles/theme';
import { Large, LargeTablet, Others, Tablet } from 'styles/MediaQuery';
import { ReactComponent as MobileHamburger } from 'resources/mobileHamburger.svg';
import { analytics } from 'fBase';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import LangSwitcher from 'components/molecules/LangSwitcher';
import { useRouteMatch } from 'react-router-dom';
import { MobileDropdownMenu } from 'components/molecules/Dropdown';
import { getLanguageCode } from 'utils/LanguageUtil';

const HeaderMenu = () => {
  const { t, i18n } = useTranslation('landing');

  let history = useHistory();
  let match = useRouteMatch();

  const [landing, setLanding] = useState(false);
  const [service, setService] = useState(false);

  const menuList = [
    {
      id: 'ld02',
      title: t('pjR'),
      path: '/proposalForm',
      onClick: () => {
        analytics.logEvent('register_project', { position: 'top' });
      },
    },
    {
      id: 'ld03',
      title: t('pfR'),
      path: '/devProfileForm',
      onClick: () => {
        analytics.logEvent('register_profile', { position: 'top' });
      },
    },
  ];

  const [langLocale, setLocale] = useState('');
  useEffect(() => {
    const langCode = getLanguageCode(i18n.language);
    setLocale(langCode);
  }, [i18n.language, t]);

  useEffect(() => {
    // if (['/', '/service', '/pricing', '/use-cases'].includes(match.path)) setLanding(true);
    if (
      match.path === '/' ||
      match.path.startsWith('/service') ||
      match.path.startsWith('/pricing') ||
      match.path.startsWith('/use-cases')
    )
      setLanding(true);
  }, [match]);

  useEffect(() => {
    // if (['/service', '/pricing', '/use-cases'].includes(match.path)) setService(true);
    if (
      match.path.startsWith('/service') ||
      match.path.startsWith('/pricing') ||
      match.path.startsWith('/use-cases')
    )
      setService(true);
  }, [match]);

  const menuRef = useRef(null);
  const menuHeight = 279.44;
  // const [menuVisible, setMenuVisible] = useDetectOutsideClick(menuRef, false);
  const [menuTransform, setMenuTransform] = useState(menuHeight);
  const handleClickMobileMenu = () => {
    // setMenuVisible(!menuVisible);
    if (!menuTransform) setMenuTransform(menuHeight);
    if (menuTransform) setMenuTransform(0);
  };

  return (
    <>
      {landing ? (
        <MenuWrapper ref={menuRef}>
          <Tablet>
            <HamburgerIcon onClick={handleClickMobileMenu} />
          </Tablet>
          <Large>
            <MenuService
              onClick={() => history.push({ pathname: '/service', state: { currentTab: 0 } })}
              active={service}
            >
              {t('serviceMenu')[0]}
            </MenuService>
          </Large>
          <LargeTablet>
            {menuList.map((item) => (
              <Link to={item.path} key={item.id} onClick={item.onClick}>
                <Menu active={service}>{item.title}</Menu>
              </Link>
            ))}
          </LargeTablet>
          <Large>
            <LangSwitcher />
          </Large>
        </MenuWrapper>
      ) : (
        <Others>
          <LangWrapper>
            <LangSwitcher />
          </LangWrapper>
        </Others>
      )}
      <Tablet>
        <MobileDropdownMenu
          transform={menuTransform}
          selected={langLocale}
          onClose={handleClickMobileMenu}
        />
      </Tablet>
    </>
  );
};
const MenuWrapper = styled.nav`
  position: absolute;
  top: calc((100% - 23px) / 2);
  left: 251px;
  display: flex;
  align-items: center;
  z-index: 12;
  @media ${theme.tablet} {
    top: 26px;
    left: 220px;
  }
  @media ${theme.mobile} {
    top: 21px;
    left: 145px;
  }
  @media ${theme.mobileS} {
    left: 137px;
  }
`;
const LangWrapper = styled.nav`
  position: absolute;
  top: calc((100% - 23px) / 2);
  /* right: 270px; */
  left: 251px;
  z-index: 13;
`;
const HamburgerIcon = styled(MobileHamburger)`
  cursor: pointer;
  width: 20px;
  height: 20px;
  @media ${theme.mobile} {
    width: 18px;
    height: 18px;
  }
`;
const MenuService = styled.div`
  margin-right: 3vw;
  ${fonts.body}
  cursor: pointer;
  ${(props) => props.active && 'font-weight: 700;'}
  :hover {
    color: var(--primary);
  }
`;
const Menu = styled.div`
  margin-right: 3vw;
  ${fonts.body}
  cursor: pointer;
  ${(props) => props.active && 'color: var(--secondary);'}
  :hover {
    color: var(--primary);
  }
`;

export default HeaderMenu;
