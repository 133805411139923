import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const RequestCounter = ({ countdown, onDisabled }) => {
  const { t } = useTranslation('common');

  const [second, setSecond] = useState(180);

  useEffect(() => {
    if (!second) {
      if (onDisabled) onDisabled();
      return;
    }
    let intervalId;
    if (countdown) {
      intervalId = setInterval(() => {
        setSecond((second) => second - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [second, countdown]);

  return (
    <Request>
      <span>
        {parseInt((second % 3600) / 60)}
        {t('requestCounter')[0]} {second % 60}
        {t('requestCounter')[1]}
      </span>{' '}
      {t('requestCounter')[2]}
    </Request>
  );
};

const Request = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: var(--grayText);
  margin-top: 5px;
  span {
    color: var(--error);
  }
`;

export default RequestCounter;
