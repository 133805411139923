const paths = {
  home: '/home',
  project: (projectId) => `/project/${projectId}`,
  todo: (projectId) => `/project/${projectId}/todo`,
  communication: (projectId) => `/project/${projectId}/communication`,
  contract: (projectId) => `/project/${projectId}/contract`,
  payment: (projectId) => `/project/${projectId}/payment`,
  member: (projectId) => `/project/${projectId}/member`,
  managedServices: (projectId) => `/project/${projectId}/managed-services`,
  cloud: (productId) => `/product/${productId}/cloud`,
  issues: (projectId) => `/project/${projectId}/issues`,
  setting: (projectId) => `/project/${projectId}/setting`,
};

export default paths;
