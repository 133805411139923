import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

import stores from 'stores';

import { addFormdataFiles } from 'utils/addTempSavedFiles';
import Height from 'components/atoms/Height';
import Answer from './Answer';
import ReplyFileUpload from 'components/molecules/ReplyFileUpload';

const RoworkPMComments = ({ data, requestId, pageType }) => {
  const { roworkPMStore, authStore } = stores;
  const { postRoworkPMComments } = roworkPMStore;
  const { userInfo } = authStore;
  const { id, profileImageUrl } = userInfo;

  // 댓글
  const [replyValue, setReplyValue] = useState('');

  const handleChangeReply = (value) => {
    setReplyValue(value);
  };

  const handleCancelReply = () => {
    setReplyValue('');
  };

  const [tempSavedFiles, setTempSavedFiles] = useState([]);
  const getTempSavedFiles = useCallback((tempSavedFiles) => {
    setTempSavedFiles(tempSavedFiles);
    console.log(tempSavedFiles, 'temp');
  }, []);

  // 댓글 보내기
  const handleSendReply = () => {
    let formData = addFormdataFiles(tempSavedFiles);
    formData.append('content', replyValue);

    postRoworkPMComments(pageType, requestId, formData);
    setReplyValue('');
    setTempSavedFiles([]);
  };

  // 대댓글
  // 대댓글 아이디
  const [comment, setComment] = useState();

  // 대댓글 정보
  const [commentCreate, setCommentCreate] = useState({
    create: false,
    value: '',
  });

  // 답변하기 클릭
  const handleCommentClick = (id) => {
    setComment(id);
    setCommentCreate({ ...commentCreate, create: true });
  };

  // 대댓글 작성
  const handleChangeComment = useCallback(
    (value) => {
      setCommentCreate({ ...commentCreate, value: value });
    },
    [commentCreate],
  );

  // 대댓글 취소
  const handleCancelComment = () => {
    setCommentCreate({ create: false, value: '' });
  };

  // 대댓글 보내기
  const handleSendComment = (requestId, commentId) => {
    let formData = addFormdataFiles(tempSavedFiles);
    formData.append('content', commentCreate.value);
    formData.append('parentCommentId', commentId);

    postRoworkPMComments(pageType, requestId, formData);
    setCommentCreate({ create: false, value: '' });
    setTempSavedFiles([]);
  };

  return (
    <AnswerContainer>
      <ReplyFileUpload
        id={requestId}
        profile={profileImageUrl}
        value={replyValue}
        onChange={handleChangeReply}
        onTextAreaValue={handleSendReply}
        onClick={handleSendReply}
        onCancel={handleCancelReply}
        tempSavedFiles={tempSavedFiles}
        setTempSavedFiles={getTempSavedFiles}
      />
      {data.map((a, i) => (
        <div key={`question-answer__${i}`}>
          <Height height="24px" />
          <Answer
            data={a}
            userId={id}
            answerable
            onReplyClick={handleCommentClick}
            pageType={pageType}
          />
          {a.children &&
            [...a.children].reverse().map((r, i) => (
              <ReplyContainer key={`question-answer-reply__${i}`}>
                <Answer data={r} userId={id} pageType={pageType} />
              </ReplyContainer>
            ))}
          {a.commentId.includes(comment) && commentCreate.create && (
            <ReplyContainer>
              <ReplyFileUpload
                id={a.commentId}
                profile={profileImageUrl}
                value={commentCreate.value}
                onChange={handleChangeComment}
                onTextAreaValue={() => handleSendComment(requestId, a.commentId)}
                onClick={() => handleSendComment(requestId, a.commentId)}
                onCancel={handleCancelComment}
                tempSavedFiles={tempSavedFiles}
                setTempSavedFiles={getTempSavedFiles}
              />
            </ReplyContainer>
          )}
        </div>
      ))}
    </AnswerContainer>
  );
};
const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px 20px 0px 9px;
  @media ${theme.mobile} {
    padding: 29px 0px 0px 0px;
  }
`;
const ReplyContainer = styled.div`
  padding: 18px 0 0 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export default RoworkPMComments;
