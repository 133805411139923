import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as ToggleMenu } from 'resources/toggleMenuIcon.svg';
import ServiceMenuItem from 'components/pages/service/ServiceMenuItem';

const OpenTab = ({
  initialNum,
  topMenu,
  topFocus,
  isOpenSub,
  subMenus,
  subFocus,
  onActiveTab,
  onOpenSubMenu,
}) => {
  const handleClickServiceItem = (tab, type) => {
    onActiveTab(tab, type);
  };

  return (
    <>
      <TipWrapper onClick={onOpenSubMenu}>
        <ServiceMenuItem focus={topFocus} h2>
          {topMenu}
        </ServiceMenuItem>
        <InfoIcon stroke={isOpenSub ? '#132E58' : '#AFAFAF'} $active={isOpenSub} />
      </TipWrapper>
      {isOpenSub &&
        subMenus.map((menu, index) => (
          <ServiceMenuItem
            key={`${menu}__${index}`}
            onClick={() => handleClickServiceItem(index + initialNum, menu.type)}
            focus={subFocus === index + initialNum}
          >
            {menu.text}
          </ServiceMenuItem>
        ))}
    </>
  );
};

const TipWrapper = styled.div`
  width: 220px;
  margin-top: 12px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  :hover > div {
    font-weight: 700;
    color: var(--primary);
  }
`;

const InfoIcon = styled(ToggleMenu)`
  margin-left: -120px;
  object-fit: contain;
  ${(props) =>
    props.$active === true &&
    css`
      transform: rotate(90deg);
    `}
`;

export default OpenTab;
