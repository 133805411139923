import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import closeIcon from 'resources/closeIcon.png';
import stores from 'stores';
import FetchMore from 'utils/FetchMore';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const MobileAlarm = ({ data, onSetAlarm }) => {
  const { dashboardStore } = stores;
  const { getNavFetchNotifications, navNotiLastPage, getToUpdateNotificationById } = dashboardStore;
  const { t } = useTranslation('common');

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!navNotiLastPage) {
      async function fetchData() {
        setLoading(true);
        await getNavFetchNotifications(page, 5);
        setLoading(false);
      }
      fetchData();
    }
  }, [getNavFetchNotifications, navNotiLastPage, page]);

  let history = useHistory();
  const handleNotificationClick = (id) => {
    getToUpdateNotificationById(id);
    history.push('/home');
    onSetAlarm();
  };

  return (
    <AlarmWrap>
      <AlarmHeader>
        <AlarmHeaderText>{t('mobileAlarm')}</AlarmHeaderText>
        <AlarmCloseIcon src={closeIcon} alt="mobile-icon" onClick={() => onSetAlarm()} />
      </AlarmHeader>
      <AlarmScrollArea lastPage={navNotiLastPage}>
        {data.map((item) => (
          <Alarmbox
            key={`noti__${item.id}`}
            unCheck={!item.checked}
            onClick={() => handleNotificationClick(item.id)}
          >
            <AlarmText>{item.title}</AlarmText>
          </Alarmbox>
        ))}
        <FetchMore loading={page !== 0 && loading} setPage={setPage} />
      </AlarmScrollArea>
    </AlarmWrap>
  );
};

const AlarmWrap = styled.div`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
`;

const AlarmHeader = styled.div`
  height: 64px;
  border-bottom: 1px solid ${theme.colors.grayLine};
  padding: 21px 0;
`;

const AlarmHeaderText = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.primary};
  text-align: center;
  line-height: 21.6px;
  margin: 0;
`;

const AlarmCloseIcon = styled.img`
  position: absolute;
  right: 28px;
  top: 22px;
  cursor: pointer;
`;

const AlarmScrollArea = styled.div`
  position: absolute;
  overflow-y: scroll;
  width: 100%;
  top: 64px;
  bottom: calc(-100vh);
  background-color: white;
  padding-bottom: ${(props) => (props.lastPage ? '62px' : '90px')};
`;

const Alarmbox = styled.div`
  padding: 28px;
  border-bottom: 1px solid ${theme.colors.grayLine};
  background: ${(props) => (props.unCheck ? 'var(--primaryLight)' : 'white')};
  & > p {
    ${(props) => props.unCheck && 'font-weight: 600;'}
  }
`;

const AlarmText = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${theme.colors.grayText2};
  font-weight: 500;
`;

export default MobileAlarm;
