// Break Point
const media = {
  mobileS: '320px',
  mobile: '576px',
  tablet: '820px',
  laptop: '1047px',
  second: '1366px',
  desktop: '1440px',
};

const theme = {
  mobileS: `(max-width: ${media.mobileS})`,
  mobile: `(max-width: ${media.mobile})`,
  tablet: `(max-width: ${media.tablet})`,
  laptop: `(max-width: ${media.laptop})`,
  second: `(max-width: ${media.second})`,
  desktop: `(max-width: ${media.desktop})`,
  colors: {
    primary: '#2C62BA',
    // primaryLight: '#2C62BA14',
    primaryLight: '#EEF2FA',
    background: '#FCFBFC',
    white: '#FFF',
    transparent: '#FEFEFE',
    listBg: '#F6F6F6',
    grayLine: '#D6D6D6',
    error: '#FF4646',
    redLight: '#FBE2E2',
    red: '#D56E6E',
    yellowLight: '#FBF4DE',
    yellow: '#F3AA55',
    greenLight: '#D2FFD7',
    green: '#72A677',
    skyBlueLight: '#E2F4FB',
    skyBlue: '#6EB6D5',
    title: '#103063',
    bodyText: '#1A2942',
    bodyText2: '#132E58',
    grayText: '#464646',
    grayText2: '#858585',
    secondary: '#B7B7B7',
    empty: '#CACACA',
  },
  btns: {
    small: {
      height: '31px',
      padding: '7px 9px',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '16.8px',
    },
    medium: {
      width: '82px',
      height: '42px',
      padding: '12px 20px',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '19.2px',
    },
    large: {
      width: '126px',
      height: '47px',
      padding: '14px 42px',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '19.2px',
    },
    flexible: {
      height: '47px',
      padding: '14px 24px',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '19.2px',
    },
    mediumLarge: {
      height: '65px',
      padding: '24px 84px',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '16.8px',
    },
    fullWidth: {
      width: '100%',
      height: '55px',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '19.2px',
    },
    m_fullWidth: {
      width: '100%',
      height: '43px',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '19.2px',
    },
  },
  flex: {
    center: `
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    centerColumn: `
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
  },
  ellipsis: `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `,
  border: '1px solid rgba(214, 214, 214, 1)',
  boxShadow: '0px 4px 10px 0px rgba(184, 184, 184, 0.05)',
};

export const fonts = {
  bigTitle: {
    fontSize: '30px',
    fontWeight: '700',
    lineHeight: '41.1px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'var(--title)',
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'var(--title)',
  },
  m_sectionTitle: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '22.4px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'var(--title)',
  },
  formQ: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '16.8px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'var(--bodyText2)',
  },
  formA: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '19.2px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'var(--grayText)',
  },
  body: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '25.44px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'var(--bodyText)',
  },
  m_body: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28.32px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'var(--bodyText)',
  },
  secondary: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '21.6px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'var(--secondary)',
  },
  m_secondary: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '19.6px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'var(--secondary)',
  },
  empty: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '27.72px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: 'var(--empty)',
  },
  listTh: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '14.4px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'var(--primary)',
  },
  listTd: {
    fontSize: '17px',
    fontWeight: '400',
    lineHeight: '20.4px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'var(--bodyText)',
  },
  l_Title: {
    fontSize: '40px',
    fontWeight: '700',
    lineHeight: '68px',
    textAlign: 'center',
    color: 'var(--title)',
    marginTop: '-8px',
  },
  l_sectionTitle: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '30px',
    textAlign: 'center',
    color: 'var(--primary)',
  },
};

export default theme;
