import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { PopUpCommon } from 'components/molecules/PopUp';
import LabeledInput from 'components/molecules/LabeledInput';
import { DropdownInput } from 'components/atoms/DropdownMilestone';
import Label from 'components/atoms/Label';
import FileUpload from 'components/molecules/FileUpload';
import TextArea from 'components/atoms/TextArea';
import Button from 'components/atoms/Button';
import { observer } from 'mobx-react';
import stores from 'stores';
import Loading from 'components/atoms/Loading';
import { useTranslation } from 'react-i18next';

const IssuePopup = observer(({ type, projectId, editIssue, visible, onClose }) => {
  const { issueStore, roworkPMStore } = stores;
  const { postIssue, loading } = issueStore;

  const { t } = useTranslation('maintenance');
  const ipt = t('issuePopup');

  const [inputs, setInputs] = useState({
    issueId: null,
    title: '',
    priorityType: ipt.priorityType[1],
    content: '',
  });
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const [materialIdList, setMaterialIdList] = useState([]);
  const [tempSavedFiles, setTempSavedFiles] = useState([]);
  const getMaterialIdList = useCallback((materialIdList) => {
    setMaterialIdList(materialIdList);
    // console.log(materialIdList);
  }, []);
  const getTempSavedFiles = useCallback((tempSavedFiles) => {
    setTempSavedFiles(tempSavedFiles);
    console.log(tempSavedFiles);
  }, []);

  const convertTypeKr = (type) => {
    return type === 'HIGH'
      ? ipt.priorityType[0]
      : type === 'MEDIUM'
      ? ipt.priorityType[1]
      : ipt.priorityType[2];
  };

  const convertTypeEn = (type) => {
    return type === ipt.priorityType[0] ? 'HIGH' : type === ipt.priorityType[1] ? 'MEDIUM' : 'LOW';
  };

  useEffect(() => {
    if (editIssue) {
      setInputs({
        issueId: editIssue.issueId,
        title: editIssue.title,
        priorityType: convertTypeKr(editIssue.priorityType),
        content: editIssue.content,
      });
    }
    return () => {
      setInputs({
        issueId: null,
        title: '',
        priorityType: ipt.priorityType[1],
        content: '',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editIssue]);

  const handleSubmitIssue = useCallback(
    async (e) => {
      e.preventDefault();
      const body = {
        ...inputs,
        priorityType: convertTypeEn(inputs.priorityType),
        materialIdList,
        tempSavedFiles,
      };
      let success = await postIssue(projectId, body, type);
      if (success) {
        onClose();

        setInputs({
          issueId: null,
          title: '',
          priorityType: ipt.priorityType[1],
          content: '',
        });
        setMaterialIdList([]);
        setTempSavedFiles([]);

        if (type === 'roworkPM') roworkPMStore.toggleRoworkPMConfirm();
        if (type === 'issues') issueStore.toggleIssueConfirmAlert();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputs, materialIdList, onClose, postIssue, projectId, roworkPMStore, tempSavedFiles],
  );
  return (
    <PopUpCommon
      type="issue"
      visible={visible}
      closable
      onClose={onClose}
      title="제품 개발 업무 생성하기"
    >
      <form onSubmit={handleSubmitIssue}>
        <GradiantArea len={editIssue?.materialList.length + tempSavedFiles.length}>
          <ScrollArea>
            <LabeledInput
              top="30px"
              name="title"
              value={inputs.title}
              label={ipt.title}
              placeholder={ipt.titlePh}
              size="fullWidth"
              onChange={handleOnChange}
            />
            <DropdownInput
              top="32px"
              name="priority"
              label={ipt.priority}
              value={inputs.priorityType}
              onSelect={(val) => setInputs({ ...inputs, priorityType: val })}
              options={ipt.priorityType}
            />
            <Label top="32px" label={ipt.fileLabel} />
            <FileUpload
              height="75px"
              materialList={editIssue?.materialList}
              materialIdList={materialIdList}
              setMaterialIdList={getMaterialIdList}
              tempSavedFiles={tempSavedFiles}
              setTempSavedFiles={getTempSavedFiles}
              preview={true}
            />
            <Label top="32px" label={ipt.desc} />
            <TextArea
              name="content"
              height="144px"
              placeholder={ipt.descPh}
              value={inputs.content}
              onChange={handleOnChange}
            />
          </ScrollArea>
        </GradiantArea>
        <ButtonWrapper>
          <Button type="submit" size="large">
            {loading ? <Loading dark /> : ipt.createIssueb}
          </Button>
        </ButtonWrapper>
      </form>
    </PopUpCommon>
  );
});

const GradiantArea = styled.div`
  height: 580px;
  position: relative;
  ${(props) =>
    props.len > 0 &&
    css`
      :after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 30%;
        bottom: 0;
        background: linear-gradient(to top, white, 20%, rgba(255, 255, 255, 0));
        background-repeat: no-repeat;
        transition: all 500ms ease;
      }
      :hover {
        &:after {
          background-position: 0 200px;
          display: none;
        }
      }
    `}
`;

const ScrollArea = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 10px;
  overflow-y: scroll;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export default IssuePopup;
