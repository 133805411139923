import React from 'react';
import styled from 'styled-components';
import theme, { fonts } from 'styles/theme';

const ServiceContent = ({ keyName, data, t, children }) => {
  return (
    <ServiceDesc>
      {t && <Title>{t.title}</Title>}
      {data.map((info, i) => (
        <div key={`${keyName}__${i}`}>
          <SentionTitle>{info.title}</SentionTitle>
          <Desc dangerouslySetInnerHTML={{ __html: info.contents }} />
          {info.type === 'SERVICE_INFO' && children}
        </div>
      ))}
    </ServiceDesc>
  );
};

const ServiceDesc = styled.div`
  min-height: calc(100vh - 771px);
  div:last-child {
    margin-bottom: 0;
  }
  @media ${theme.mobile} {
    min-height: calc(100vh - 491px);
  }
`;
const Title = styled.div`
  margin-bottom: 74px;
  ${fonts.bigTitle}
`;
const SentionTitle = styled.p`
  margin-top: 61px;
  margin-bottom: 14px;
  ${fonts.sectionTitle}
  font-weight: 600;
`;
const Desc = styled.div`
  margin-bottom: 16px;
  ${fonts.body}
  color: var(--grayText);
  span {
    text-decoration: underline;
    text-underline-position: under;
    color: var(--primary);
  }
  ul {
    padding-top: 10px;
  }
  li {
    padding-bottom: 5px;
  }
`;

export default ServiceContent;
