import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'styles/theme';
import logo from '../../resources/MainLogo.png';
import logo2x from '../../resources/MainLogo@2x.png';
import logo3x from '../../resources/MainLogo@3x.png';
import { ReactComponent as MainLogo } from 'resources/MainLogo.svg';
import { Others, Mobile } from 'styles/MediaQuery';

const Logo = ({ side }) => {
  return (
    <Link to="/" aria-label="landing page">
      <Others>
        <LogoMain side={side} />
      </Others>
      <Mobile>
        <LogoImg src={logo3x} srcSet={`${logo}, ${logo2x} 2x, ${logo3x} 3x`} alt="ROWORK" />
      </Mobile>
    </Link>
  );
};

export default Logo;

const LogoMain = styled(MainLogo)`
  object-fit: contain;
  ${(props) => props.side && 'margin-left: 56px;'}
`;

const LogoImg = styled.img`
  object-fit: contain;
  @media ${theme.mobile} {
    width: 99px;
    height: 14px;
  }
`;
