import EmptyDoc from 'components/atoms/EmptyDoc';
import React from 'react';
import styled from 'styled-components';
import theme, { fonts } from 'styles/theme';

const ClientTips = ({ t }) => {
  return (
    <>
      <Title>{t.title}</Title>
      {/* <SentionTitle></SentionTitle> */}
      <EmptyWrapper>
        <EmptyDoc inner>
          <p>{t.emptyTip}</p>
        </EmptyDoc>
      </EmptyWrapper>
    </>
  );
};

const Title = styled.div`
  margin-bottom: 74px;
  ${fonts.bigTitle}
`;
// const SentionTitle = styled.p`
//   margin-bottom: 14px;
//   ${fonts.sectionTitle}
//   font-weight: 600;
// `;
const EmptyWrapper = styled.div`
  div:first-child {
    margin: 100px auto 0;
    @media ${theme.mobile} {
      margin: 100px auto;
    }
  }
`;
export default ClientTips;
