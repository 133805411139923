import { useMediaQuery } from 'react-responsive';

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({
    query: '(max-width:576px)',
  });
  return <>{isMobile && children}</>;
};

export const Others = ({ children }) => {
  const isOthers = useMediaQuery({
    query: '(min-width:577px)',
  });
  return <>{isOthers && children}</>;
};

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({
    query: '(max-width:820px)',
  });
  return <>{isTablet && children}</>;
};

export const Large = ({ children }) => {
  const isLarge = useMediaQuery({
    query: '(min-width:821px)',
  });
  return <>{isLarge && children}</>;
};

export const LargeTablet = ({ children }) => {
  const isLargeTablet = useMediaQuery({
    query: '(min-width:960px)',
  });
  return <>{isLargeTablet && children}</>;
};

export const Laptop = ({ children }) => {
  const isLaptop = useMediaQuery({
    query: '(max-width:1047px)',
  });
  return <>{isLaptop && children}</>;
};

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({
    query: '(min-width:1048px)',
  });
  return <>{isDesktop && children}</>;
};
