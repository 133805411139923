import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import CommonLayout from 'components/templates/CommonLayout.js';
import SearchInput from './SearchInput';
import TagList from 'components/atoms/TagList';
import Height from 'components/atoms/Height';
import Profile from 'components/atoms/Profile';
import QuestionBtn from './QuestionBtn';
import QuestionCard from './QuestionCard';
import Button from 'components/atoms/Button';
import PopUp from 'components/molecules/PopUp';
import QuestionPopUp from './QuestionPopUp';
import EmptyDoc from 'components/atoms/EmptyDoc';
import theme from 'styles/theme';
import usePreventScroll from 'utils/usePreventScroll';
import { useHistory } from 'react-router';
import stores from 'stores';
import { toJS } from 'mobx';
import FetchMore from 'utils/FetchMore';
import { useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash-es';
import { Menu } from 'data/Menu';
import Alert from 'components/atoms/Alert';
import IssuePopup from '../issues/IssuePopup';
import { useTranslation } from 'react-i18next';
import paths from 'data/paths';
import RoworkPMComments from './RoworkPMComments';

const RoworkPM = observer(() => {
  const { roworkPMStore, authStore } = stores;
  const {
    getAllRoworkPM,
    pmLastPage,
    resetPmLastPage,
    pmInquiryList,
    deleteRoworkPM,
    getRoworkPMById,
    roworkPMConfirmAlert,
    toggleRoworkPMConfirm,
  } = roworkPMStore;
  const { userInfo } = authStore;
  const { id, profileImageUrl } = toJS(userInfo);

  let history = useHistory();
  const projectId = history.location.state?.projectId || window.location.pathname.split('/')[2];
  let routeMatch = useRouteMatch();
  const { requestId } = useParams();

  const { t } = useTranslation('main');

  const [keyword, setKeyword] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearch = useMemo(() => debounce((value) => setKeyword(value), 500), []);

  const handleOnChangeSearch = useCallback(
    (e) => {
      setSearchValue(e.target.value);
      debouncedSearch(e.target.value);
    },
    [debouncedSearch],
  );

  // inquiryTags tag
  const [sort, setSort] = useState('ALL');

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  // 조회 쿼리 업데이트
  const updateQuery = useCallback(
    (inquiryTags, keyword) => {
      let query = `clients/projects/${projectId}/communications?inquiryTags=${inquiryTags}&keyword=${keyword}`;
      return query;
    },
    [projectId],
  );

  useEffect(() => {
    if (routeMatch.path === '/project/:projectId/communication' && projectId) {
      if (!pmLastPage) {
        async function fetchData() {
          setLoading(true);
          await getAllRoworkPM(updateQuery(sort, keyword), page, 20);
          setLoading(false);
        }
        fetchData();
      }
    } else if (routeMatch.path === '/project/:projectId/communication/:requestId' && requestId) {
      const loadData = async () => {
        let tagId = 'ALL';
        tagId = await getRoworkPMById('clients', requestId);
        if (tagId) setTagSort(tagId);
      };
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pmLastPage, sort, keyword, routeMatch.path]);

  useEffect(() => {
    setPage(0);
    return () => {
      resetPmLastPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, keyword]);

  // tag list
  const [tagData, setTagData] = useState([
    { id: 'ALL', name: t('ALL'), selected: true },
    { id: 'PROJECT_MANAGEMENT', name: t('PROJECT_MANAGEMENT'), selected: false },
    { id: 'TO_DO', name: t('TO_DO'), selected: false },
    { id: 'CONTRACT', name: t('CONTRACT'), selected: false },
    { id: 'OTHER', name: t('OTHER'), selected: false },
    { id: 'REPORT_BUG', name: t('REPORT_BUG'), selected: false },
  ]);

  const inquiryTagsValue = {
    ALL: t('ALL'),
    PROJECT_MANAGEMENT: t('PROJECT_MANAGEMENT'),
    TO_DO: t('TO_DO'),
    CONTRACT: t('CONTRACT'),
    OTHER: t('OTHER'),
    REPORT_BUG: t('REPORT_BUG'),
  };

  const setTagSort = useCallback(
    (tagId) => {
      setTagData(
        tagData.map((data) =>
          data.id === tagId ? { ...data, selected: true } : { ...data, selected: false },
        ),
      );
      setSort(tagId);
    },
    [tagData],
  );

  // tag click function
  const onTagClick = useCallback(
    (tagId) => {
      setTagSort(tagId);
      setPage(0);
      if (routeMatch.path === '/project/:projectId/communication/:requestId') {
        history.push({ pathname: paths.communication(projectId) });
      }
    },
    [history, projectId, routeMatch.path, setTagSort],
  );

  // QuestionPopup
  const [popupState, setPopupState] = useState({
    editId: '',
    deletePopup: false,
  });

  const [createPopup, setCreatePopup] = usePreventScroll(false);

  const onCreate = () => {
    setCreatePopup(true);
  };

  const [editQuetion, setEditQuetion] = useState();
  const [editIssue, setEditIssue] = useState();

  // QuestionCard 수정
  const onEditQuetion = (id) => {
    if (pmInquiryList.filter((item) => item.requestId === id)[0].inquiryTags === 'REPORT_BUG') {
      setEditIssue(pmInquiryList.filter((item) => item.requestId === id)[0].issue);
      handleOpenIssuePopup();
    } else {
      setEditQuetion(pmInquiryList.filter((item) => item.requestId === id));
      setCreatePopup(true);
    }
  };

  // QuestionCard requestId 끌어올리기
  const onDelete = (id) => {
    setPopupState({ ...popupState, editId: id, deletePopup: true });
  };

  // 문의 삭제
  const handleDelete = () => {
    // console.log(popupState.editId);
    deleteRoworkPM('clients', popupState.editId);
    setPopupState({ ...popupState, deletePopup: false });
  };

  const handlePopupClose = (id) => {
    setCreatePopup(false);
    setPopupState({ ...popupState, deletePopup: false });
    setEditQuetion();
    if (id === 'REPORT_BUG') {
      handleOpenIssuePopup();
    }
  };

  const handleAlertSubmitClose = () => {
    toggleRoworkPMConfirm();
  };

  // issuePopup
  const [issuePopup, setIssuePopup] = useState(false);
  const handleOpenIssuePopup = () => {
    setIssuePopup(true);
  };
  const handleCloseIssuePopup = () => {
    setIssuePopup(false);
    setEditIssue();
  };

  return (
    <Container>
      <CommonLayout title={<Menu index={'communication'} />}>
        <RoworkWrapper>
          <InputContainer>
            <SearchInput value={searchValue} onChange={handleOnChangeSearch} />
            <Height height="24px" />
            <TagList data={tagData} onTagClick={onTagClick} />
          </InputContainer>
          <Inquiry>
            <Profile profile={profileImageUrl} profileSize="large" />
            <QuestionBtn onClick={onCreate} />
          </Inquiry>
          {/* {pmInquiryList.filter((q) => (sort === 'ALL' ? q : q.inquiryTags.includes(sort))).length ===
        0  */}
          {pmInquiryList.length === 0 ? (
            <EmptyDoc>
              <p>
                {t('emptyPM')[0]}
                <br />
                {t('emptyPM')[1]}
              </p>
            </EmptyDoc>
          ) : (
            <QuestionCardContainer>
              {pmInquiryList
                // .filter((q) => (sort === 'ALL' ? q : q.inquiryTags.includes(sort)))
                .map((q, i) => (
                  <QuestionCard
                    key={`question-card__${i}`}
                    projectId={projectId}
                    data={q}
                    userId={id}
                    sortName={inquiryTagsValue[q.inquiryTags]}
                    onEdit={onEditQuetion}
                    onDelete={onDelete}
                  >
                    <RoworkPMComments
                      data={q.commentList}
                      requestId={q.requestId}
                      pageType="roworkPM"
                    />
                  </QuestionCard>
                ))}
            </QuestionCardContainer>
          )}
          {!requestId && <FetchMore loading={page !== 0 && loading} setPage={setPage} />}
        </RoworkWrapper>
        <PopUp
          title={t('deleteCommunication').title}
          desc={t('deleteCommunication').desc}
          visible={popupState.deletePopup}
        >
          <Button size="flexible" onClick={handleDelete}>
            {t('deleteCommunication').deleteb}
          </Button>
          <Button size="flexible" color="transparent" border="gray" onClick={handlePopupClose}>
            {t('deleteCommunication').cancelb}
          </Button>
        </PopUp>
        <QuestionPopUp
          projectId={projectId}
          editQuetion={editQuetion}
          profile={profileImageUrl}
          visible={createPopup}
          onClose={handlePopupClose}
        />
        <IssuePopup
          type="roworkPM"
          projectId={projectId}
          editIssue={editIssue}
          visible={issuePopup}
          onClose={handleCloseIssuePopup}
        />
        {roworkPMConfirmAlert && (
          <Alert message={t('confirmAlert')} closable onClose={handleAlertSubmitClose} />
        )}
      </CommonLayout>
    </Container>
  );
});
const Container = styled.div`
  background: var(--background);
`;

const RoworkWrapper = styled.div`
  max-width: 752px;
  margin: 44px auto 0;
  @media ${theme.mobile} {
    margin: 0;
  }
`;

const InputContainer = styled.div`
  max-width: 752px;
  @media ${theme.mobile} {
    padding: 0 28px;
  }
  @media ${theme.mobileS} {
    padding: 0 20px;
  }
`;
const Inquiry = styled.div`
  margin-top: 55px;
  display: flex;
  @media ${theme.mobile} {
    margin-top: 31px;
    padding: 0 28px;
  }
  @media ${theme.mobileS} {
    padding: 0 20px;
  }
`;

const QuestionCardContainer = styled.div`
  margin-top: 112px;
  /* padding-bottom: 50px; */
  @media ${theme.mobile} {
    margin-top: 34px;
  }
`;

export default RoworkPM;
