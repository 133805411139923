import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import profileDefault from 'resources/profileDefault.png';

const Profile = ({ profile, profileSize }) => {
  return <Img src={profile || profileDefault} alt="profile" profileSize={profileSize || 'small'} />;
};

const Img = styled.img`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  object-fit: cover;

  ${(props) =>
    props.profileSize === 'small' &&
    css`
      height: 32px;
      width: 32px;
    `}
  ${(props) =>
    props.profileSize === 'medium' &&
    css`
      width: 47px;
      height: 47px;
    `}
  ${(props) =>
    props.profileSize === 'large' &&
    css`
      height: 62px;
      width: 62px;
      @media ${theme.mobile} {
        height: 31px;
        width: 31px;
      }
    `}
`;

export default Profile;
