import { getPendingPaymentApi, getReceiptApi, getRefundPolicyApi } from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class PaymentStore {
  pendingPaymentList = [];
  refundPolicy = '';
  receipt = [];

  constructor() {
    makeAutoObservable(this);
  }

  getPendingPayment = async (projectId) => {
    await getPendingPaymentApi(projectId)
      .then((res) => {
        runInAction(() => {
          console.log(res.data);
          this.pendingPaymentList = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getRefundPolicy = async () => {
    await getRefundPolicyApi()
      .then((res) => {
        runInAction(() => {
          this.refundPolicy = res.data;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getReceipt = async (projectId) => {
    await getReceiptApi(projectId)
      .then((res) => {
        runInAction(() => {
          console.log(res.data);
          this.receipt = res.data;
        });
      })
      .catch((e) => console.log(e));
  };
}

const paymentStore = new PaymentStore();

export default paymentStore;
