import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import c_proposal_a from 'images/c_proposal.avif';
import c_proposal_w from 'images/c_proposal.webp';
import c_proposal from 'images/c_proposal.png';
import c_contract_a from 'images/c_contract.avif';
import c_contract_w from 'images/c_contract.webp';
import c_contract from 'images/c_contract.png';
import c_milestone_a from 'images/c_milestone.avif';
import c_milestone_w from 'images/c_milestone.webp';
import c_milestone from 'images/c_milestone.png';
import c_todo_a from 'images/c_todo.avif';
import c_todo_w from 'images/c_todo.webp';
import c_todo from 'images/c_todo.png';
import c_roworkPM_a from 'images/c_roworkPM.avif';
import c_roworkPM_w from 'images/c_roworkPM.webp';
import c_roworkPM from 'images/c_roworkPM.png';
import d_proposal_a from 'images/d_proposal.avif';
import d_proposal_w from 'images/d_proposal.webp';
import d_proposal from 'images/d_proposal.png';
import d_contract_a from 'images/d_contract.avif';
import d_contract_w from 'images/d_contract.webp';
import d_contract from 'images/d_contract.png';
import d_milestone_a from 'images/d_milestone.avif';
import d_milestone_w from 'images/d_milestone.webp';
import d_milestone from 'images/d_milestone.png';
import d_todo_a from 'images/d_todo.avif';
import d_todo_w from 'images/d_todo.webp';
import d_todo from 'images/d_todo.png';
import d_roworkPM_a from 'images/d_roworkPM.avif';
import d_roworkPM_w from 'images/d_roworkPM.webp';
import d_roworkPM from 'images/d_roworkPM.png';
import { useTranslation } from 'react-i18next';
import Titles from './Titles';

const Feature = ({ client }) => {
  const { t } = useTranslation('landing');
  // feature tab
  const [clientActiveTab, setClientActiveTab] = useState(0);
  const [roworkerActiveTab, setRoworkerActiveTab] = useState(0);

  const clientFeatureList = [
    { id: t('feature.fc1'), avif: c_proposal_a, webp: c_proposal_w, img: c_proposal },
    { id: t('feature.f2'), avif: c_contract_a, webp: c_contract_w, img: c_contract },
    { id: t('feature.f3'), avif: c_milestone_a, webp: c_milestone_w, img: c_milestone },
    { id: t('feature.f4'), avif: c_todo_a, webp: c_todo_w, img: c_todo },
    { id: t('feature.f5'), avif: c_roworkPM_a, webp: c_roworkPM_w, img: c_roworkPM },
  ];
  const roworkerFeatureList = [
    { id: t('feature.fr1'), avif: d_proposal_a, webp: d_proposal_w, img: d_proposal },
    { id: t('feature.f2'), avif: d_contract_a, webp: d_contract_w, img: d_contract },
    { id: t('feature.f3'), avif: d_milestone_a, webp: d_milestone_w, img: d_milestone },
    { id: t('feature.f4'), avif: d_todo_a, webp: d_todo_w, img: d_todo },
    { id: t('feature.f5'), avif: d_roworkPM_a, webp: d_roworkPM_w, img: d_roworkPM },
  ];

  const FeaturePicture = ({ item }) => {
    return (
      <picture>
        <source srcSet={item.avif} type="image/avif" />
        <source srcSet={item.webp} type="image/webp" />
        <FeatureImage loading="lazy" decoding="async" src={item.img} alt="feature-img" />
      </picture>
    );
  };

  return (
    <Wrapper>
      <Container>
        <Titles
          sectionTitle={t('feature.sectionTitle')}
          title={client ? t('feature.clientTitle') : t('feature.roworkerTitle')}
          pt={114}
        />
        <FeatureMenu>
          {client
            ? clientFeatureList.map((item, index) => (
                <FeatureMenuBox key={item.id}>
                  <FeatureItem
                    focus={index === clientActiveTab}
                    onClick={() => setClientActiveTab(index)}
                  >
                    {item.id}
                  </FeatureItem>
                </FeatureMenuBox>
              ))
            : roworkerFeatureList.map((item, index) => (
                <FeatureMenuBox key={item.id}>
                  <FeatureItem
                    focus={index === roworkerActiveTab}
                    onClick={() => setRoworkerActiveTab(index)}
                  >
                    {item.id}
                  </FeatureItem>
                </FeatureMenuBox>
              ))}
        </FeatureMenu>
        <ImageTabs>
          <ImageWrapper>
            {client
              ? clientFeatureList
                  .filter((_, index) => index === clientActiveTab)
                  .map((item) => <FeaturePicture item={item} key={item} />)
              : roworkerFeatureList
                  .filter((_, index) => index === roworkerActiveTab)
                  .map((item) => <FeaturePicture item={item} key={item} />)}
          </ImageWrapper>
        </ImageTabs>
      </Container>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  background: var(--background);
  width: 100vw;
  padding: 0 57px;
  @media ${theme.mobile} {
    padding: 0 28px;
  }
  @media ${theme.mobileS} {
    padding: 0 20px;
  }
`;
const Container = styled.div`
  width: 100%;
  /* max-width: 1222px; */
  margin: 0 auto;
`;

const ImageTabs = styled.div`
  display: flex;
  justify-content: center;
  padding: 28px 0 184px 0;
  @media ${theme.tablet} {
    padding: 28px 0 94px 0;
  }
`;
const ImageWrapper = styled.div`
  max-width: 864px;
`;
const FeatureImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 14px;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 4px 40px 0px rgba(119, 119, 119, 0.05);
`;

const FeatureMenu = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 26px;
`;
const FeatureMenuBox = styled.div`
  margin: 0 1px;
`;
const FeatureItem = styled.h3`
  display: flex;
  border-radius: 5px;
  padding: 7px 16px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: ${theme.colors.grayText2};

  ${(props) =>
    props.focus === true &&
    css`
      background-color: ${theme.colors.primaryLight};
      color: ${theme.colors.primary};
      -webkit-text-stroke: 0.6px ${theme.colors.primary};
    `}
  :hover {
    background-color: ${theme.colors.listBg};
  }
  @media ${theme.mobile} {
    padding: 11px 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: var(--secondary);
    ${(props) =>
      props.focus === true &&
      css`
        background-color: ${theme.colors.primaryLight};
        color: ${theme.colors.primary};
        -webkit-text-stroke: 1px ${theme.colors.primary};
      `}
  }
  @media ${theme.mobileS} {
    padding: 7px 17px;
    font-size: 16px;
    font-weight: 300;
  }
`;

export default Feature;
