import React, { useEffect, useRef } from 'react';
import dropdownIcon from 'resources/dropdownIcon.png';
import {
  NotificationWrapper,
  NotificationUser,
  NotificationDropdown,
  NotificationBadge,
  NotificationIcon,
  NotificationProfile,
} from './Notification.style';
import { Dropdown, DropdownProfile } from 'components/molecules/Dropdown';
import Button from 'components/atoms/Button';
import stores from 'stores';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import useDetectOutsideClick from 'utils/useDetectOutsideClick';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import LangSwitcher from 'components/molecules/LangSwitcher';
import UserName from 'components/atoms/UserName';
import { Large, Tablet } from 'styles/MediaQuery';
import { Link } from 'react-router-dom';

const NotificationContainer = observer(({ inner, button, onClick }) => {
  const { authStore, dashboardStore } = stores;
  const { getUser, userInfo } = authStore;
  const {
    getUnreadCountNotifications,
    unReadCount,
    getNavFetchNotifications,
    navAllNotifications,
    updateNotificationChecked,
  } = dashboardStore;
  const { name } = toJS(userInfo);

  const { t } = useTranslation('common');

  let history = useHistory();

  useEffect(() => {
    if (isEmpty(toJS(userInfo))) {
      getUser();
      getUnreadCountNotifications();
      getNavFetchNotifications(0, 5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notiRef = useRef(null);
  const [dropdownNotification, setDropdownNotification] = useDetectOutsideClick(notiRef, false);

  const infoSettingRef = useRef(null);
  const [dropdownProfile, setDropdownProfile] = useDetectOutsideClick(infoSettingRef, false);

  const handleNotificationClick = (id) => {
    updateNotificationChecked(id);
    history.push('/home');
  };

  return (
    <NotificationWrapper>
      <div>
        {button && (
          <>
            <Large>
              <Button onClick={() => onClick()}>{t('managementb')}</Button>
            </Large>
            <Tablet>
              <Button size="small" onClick={() => onClick()}>
                {t('managementb')}
              </Button>
            </Tablet>
          </>
        )}
      </div>
      {!inner && !button && (
        <Link to="/proposalForm">
          <Button size="small">{t('pjR')}</Button>
        </Link>
      )}
      {inner && <LangSwitcher />}
      <NotificationDropdown ref={notiRef}>
        {unReadCount > 0 && <NotificationBadge len={unReadCount}>{unReadCount}</NotificationBadge>}
        <NotificationIcon
          onClick={() => setDropdownNotification(!dropdownNotification)}
          $active={navAllNotifications.length > 0}
        />
        <Dropdown
          visible={navAllNotifications.length > 0 && dropdownNotification}
          data={toJS(navAllNotifications)}
          onClick={handleNotificationClick}
        />
      </NotificationDropdown>
      <NotificationProfile ref={infoSettingRef}>
        <NotificationUser onClick={() => setDropdownProfile(!dropdownProfile)}>
          <UserName name={t('user', { user: name })} />
          <img
            src={dropdownIcon}
            alt="arrowdown"
            style={{ cursor: 'pointer', marginLeft: '6px' }}
          />
        </NotificationUser>
        <DropdownProfile
          visible={dropdownProfile}
          onClose={() => setDropdownProfile(!dropdownProfile)}
        />
      </NotificationProfile>
    </NotificationWrapper>
  );
});

export default NotificationContainer;
