import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import theme, { fonts } from 'styles/theme';
import { observer } from 'mobx-react';
import stores from 'stores';
import FetchMore from 'utils/FetchMore';
import React, { useState, useEffect } from 'react';
import { setPreventScroll, clearPreventScroll } from 'utils/usePreventScroll';
import dropdownIcon from 'resources/dropdownIcon.png';
import { useTranslation } from 'react-i18next';
import ServiceMenu from 'components/pages/service/ServiceMenu';
import { setLanguageCodeInterceptor } from 'api/API';

//DropdownProfile at the bottom
export const Dropdown = ({ visible, title, data, onClick }) => {
  const { dashboardStore } = stores;

  const { getNavFetchNotifications, navNotiLastPage } = dashboardStore;
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      setPreventScroll();
      return () => clearPreventScroll();
    }
  }, [visible]);

  useEffect(() => {
    if (visible && !navNotiLastPage) {
      async function fetchData() {
        setLoading(true);
        await getNavFetchNotifications(page, 5);
        setLoading(false);
      }
      fetchData();
    }
  }, [getNavFetchNotifications, navNotiLastPage, page, visible]);
  if (!visible) return null;

  return (
    <DropdownContainer>
      {title && <DropdownTitle>{title}</DropdownTitle>}
      {data && (
        <ScrollArea>
          <DropdownContent>
            {data.map((item, index) => (
              <DropdownItem
                key={`noti__${index}`}
                index={index}
                unCheck={!item.checked}
                onClick={() => onClick(item.id)}
              >
                {item.title}
              </DropdownItem>
            ))}
            <FetchMore loading={page !== 0 && loading} setPage={setPage} />
          </DropdownContent>
        </ScrollArea>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  z-index: 10;
  width: 285px;
  left: calc(50% - 142.5px);
  position: absolute;
  background: #ffffff;
  border: 1px solid var(--grayLine);
  top: 38px;
  border-bottom: 0;
  box-shadow: 0px 4px 10px rgba(184, 184, 184, 0.05);
  border-radius: 2px;
  :before,
  :after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
  }
  :before {
    top: -10px;
    left: calc(50% - 10px);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--grayLine);
  }
  :after {
    top: -9px;
    left: calc(50% - 10px);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }
`;
const DropdownTitle = styled.h4`
  margin: 0;
  width: 100%;
  height: 63px;
  background: var(--primaryLight);
  padding-left: 22px;
  border-radius: 2px;
  align-self: center;
  padding-top: 20px;
  padding-bottom: 19px;
  cursor: default;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: var(--title);
  border-bottom: 1px solid var(--grayLine);
`;
const ScrollArea = styled.div`
  height: 294px;
  overflow-y: scroll;
  border-bottom: 1px solid var(--grayLine);
`;
const DropdownContent = styled.ul`
  padding-inline-start: 0;
  margin: 0;
  display: block;
  width: 100%;
  list-style: none;
`;
const DropdownItem = styled.li`
  ${(props) => props.index !== 0 && `border-top: 1px solid var(--grayLine)`};
  padding: 16px 22px;
  ${(props) =>
    props.unCheck
      ? css`
          background: var(--primaryLight);
          color: var(--grayText2);
          font-weight: 600;
        `
      : css`
          color: var(--grayText2);
        `}
  /* ${theme.ellipsis} */
  span {
    ${fonts.formQ}
  }
  :hover {
    color: var(--primary);
    background: white;
  }
`;

export const DropdownCommon = ({ children, ...rest }) => {
  return (
    <DropdownCommonContainer {...rest}>
      <DropdownCommonContent>{children}</DropdownCommonContent>
    </DropdownCommonContainer>
  );
};

const DropdownCommonContainer = styled.div`
  z-index: 1;
  width: ${(props) => (props.width ? props.width : 142)}px;
  right: -9px;
  position: absolute;
  background: #ffffff;
  border: 1px solid var(--grayLine);
  top: 35px;
  box-shadow: 0px 4px 10px rgba(184, 184, 184, 0.05);
  border-radius: 2px;
  :before,
  :after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
  }
  :before {
    top: -9px;
    left: calc(85%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid var(--grayLine);
  }
  :after {
    top: -7px;
    left: calc(85%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #fff;
  }
`;
const DropdownCommonContent = styled.ul`
  padding-inline-start: 0;
  margin: 0;
  display: block;
  width: 100%;
  list-style: none;
`;

export const DropdownProfile = observer(({ visible, onClose }) => {
  const { t } = useTranslation('common');

  const { authStore, myInfoStore } = stores;

  const handleClickMyInfo = () => {
    myInfoStore.setActiveTab(0);
    onClose();
  };

  if (!visible) return null;
  return (
    <DropdownCommon>
      <DropdownProfileItem>
        <DropdownLink to="/myinfo" onClick={handleClickMyInfo}>
          {t('pageTitles').myInfo}
        </DropdownLink>
      </DropdownProfileItem>
      <DropdownProfileItem>
        <DropdownLink to="/" onClick={() => authStore.signOut()}>
          {t('logOut')}
        </DropdownLink>
      </DropdownProfileItem>
    </DropdownCommon>
  );
});

const DropdownProfileItem = styled.li`
  border-bottom: 1px solid var(--grayLine);
  padding: 16px;
  padding-inline-end: 18px;
  text-align: right;
  :hover > a {
    color: var(--primary);
    font-weight: 600;
    letter-spacing: 0.07px;
  }
  :last-child {
    border: 0;
  }
`;
const DropdownLink = styled(Link)`
  color: var(--grayText);
  outline: none;
  border: none;
  text-decoration: none;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  cursor: pointer;
`;

export const DropdownMenu = observer(({ visible, onClose }) => {
  const { t, i18n } = useTranslation('landing');

  const handleClickLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguageCodeInterceptor(lang);
    onClose();

    localStorage.setItem('languageCode', lang);
  };

  if (!visible) return null;
  return (
    <DropdownMenuContainer>
      <DropdownMenuContent>
        {Object.keys(t('languages')).map((lang, i) => (
          <DropdownMenuItem key={`language-select__${i}`} onClick={() => handleClickLanguage(lang)}>
            {t(`languages.${lang}`)}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenuContainer>
  );
});

const DropdownMenuContainer = styled.div`
  z-index: 10;
  width: 142px;
  position: absolute;
  top: 35px;
  left: 10px;
  background: #ffffff;
  border: 1px solid var(--grayLine);
  border-bottom: 0;
  box-shadow: 0px 4px 10px rgba(184, 184, 184, 0.05);
  border-radius: 2px;
  :before,
  :after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
  }
  :before {
    top: -9px;
    left: calc(15%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid var(--grayLine);
  }
  :after {
    top: -7px;
    left: calc(15%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #fff;
  }
`;
const DropdownMenuContent = styled.ul`
  padding-inline-start: 0;
  margin: 0;
  display: block;
  width: 100%;
  list-style: none;
`;
const DropdownMenuItem = styled.li`
  border-bottom: 1px solid var(--grayLine);
  padding-top: 16px;
  padding-bottom: 17px;
  text-align: left;
  padding-inline-start: 18px;
  ${fonts.body}
  color: var(--grayText);
  :hover {
    color: var(--primary);
    font-weight: 600;
    letter-spacing: 0.07px;
  }
`;

export const MobileDropdownMenu = observer(({ transform, selected, onClose }) => {
  const { t, i18n } = useTranslation('landing');

  const handleChangeLang = (e) => {
    const { value } = e.target;
    i18n.changeLanguage(t(`languagesKeys.${value}`));
    setLanguageCodeInterceptor(t(`languagesKeys.${value}`));
    onClose();

    localStorage.setItem('languageCode', value);
  };

  return (
    <MobileMenuContainer transform={transform}>
      <MobileMenuItem link>
        <ServiceMenu onMobile={onClose} transform={transform} />
      </MobileMenuItem>
      {/* add after finishing locales */}
      <MobileMenuItem>
        <MobileMenuSelectWrapper>
          <MobileMenuSelect onChange={(e) => handleChangeLang(e)}>
            {Object.keys(t('languages')).map((lang, i) => (
              <option key={`language-select__${i}`} value={selected === lang}>
                {t(`languages.${lang}`)}
              </option>
            ))}
          </MobileMenuSelect>
        </MobileMenuSelectWrapper>
      </MobileMenuItem>
    </MobileMenuContainer>
  );
});

const MobileMenuContainer = styled.nav`
  padding-bottom: 20px;
  position: absolute;
  top: ${(props) => (props.transform === 0 ? '72px' : 0)};
  left: 0px;
  z-index: 10;
  width: 100vw;
  background-color: var(--background);
  box-shadow: rgb(255 255 255 / 2%) 0px 0px 2px 0px inset, rgb(0 0 0 / 0%) 0px 0px 2px 1px,
    rgb(0 0 0 / 7%) 0px 10px 15px;
  transform: translateY(-${(props) => props.transform}px);
  transition: 0.5s ease;
  @media ${theme.mobile} {
    top: ${(props) => (props.transform === 0 ? '62px' : 0)};
  }
`;
const MobileMenuItem = styled.div`
  padding: ${(props) => (props.link ? '12px 56px' : '16px 56px')};
  ${fonts.body}
  cursor: pointer;
  /* border-bottom: 1px solid var(--grayLine); */
  :hover {
    color: var(--primary);
    font-weight: 600;
  }
  @media ${theme.mobile} {
    padding: ${(props) => (props.link ? '12px 28px' : '16px 28px')};
  }
  @media ${theme.mobileS} {
    padding: ${(props) => (props.link ? '10px 20px' : '16px 20px')};
  }
`;

const MobileMenuSelectWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: url(${dropdownIcon}) no-repeat 97% 50%/15px auto;
`;

const MobileMenuSelect = styled.select`
  width: 100%;
  height: 100%;
  ${fonts.body};
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  border: 1px solid var(--grayLine);
  background: transparent;
  :focus {
    outline: none;
    border-radius: 5px;
    border: 1px solid var(--primary);
  }
`;
