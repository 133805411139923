import React from 'react';
import Feature from './Feature';
import Solution from './Solution';
import Benefit from './Benefit';
import Journey from './Journey';
import Registration from './Registration';
import comunicationA from 'images/comunication.avif';
import comunicationW from 'images/comunication.webp';
import comunication from 'images/comunication.png';
import clipboardA from 'images/clipboard.avif';
import clipboardW from 'images/clipboard.webp';
import clipboard from 'images/clipboard.png';
import writingHandA from 'images/writingHand.avif';
import writingHandW from 'images/writingHand.webp';
import writingHand from 'images/writingHand.png';
import qualityCheck from 'images/qualityCheck.png';
import qualityCheckA from 'images/qualityCheck.avif';
import qualityCheckW from 'images/qualityCheck.webp';
import jobDefinition from 'images/jobDefinition.png';
import jobProgress from 'images/jobProgress.png';
import jobQuality from 'images/jobQuality.png';
import assignment from 'images/workAssignment.png';
import gitEnvironments from 'images/workEnvironments.png';
import payment from 'images/workPayment.png';
import { useTranslation } from 'react-i18next';

const LandingData = ({ user, commonData }) => {
  const { t } = useTranslation('landing');

  const client = {
    solutionSectionTitle: t('solution').sectionTitle,
    solutionTitle: t('solution').Title,
    solutionList: [
      {
        id: 'img01',
        avif: comunicationA,
        webp: comunicationW,
        img: comunication,
        width: '55px',
        height: '48px',
        title: t('solution').st1,
        desc: t('solution').sd1,
      },
      {
        id: 'img02',
        avif: clipboardA,
        webp: clipboardW,
        img: clipboard,
        width: '51px',
        height: '49px',
        title: t('solution').st2,
        desc: t('solution').sd2,
      },
      {
        id: 'img03',
        avif: qualityCheckA,
        webp: qualityCheckW,
        img: qualityCheck,
        width: '48px',
        height: '49px',
        title: t('solution').sct3,
        desc: t('solution').scd3,
      },
    ],
    benefitSectionTitle: t('benefit').cSectionTitle,
    benefitTitle: t('benefit').cTitle,
    benefitList: [
      {
        id: 'bn01',
        img: jobDefinition,
        width: '407px',
        sub: t('benefit').cbs1,
        title: t('benefit').cbt1,
        desc: t('benefit').cbd1,
      },
      {
        id: 'bn02',
        img: jobProgress,
        width: '510px',
        sub: t('benefit').cbs2,
        title: t('benefit').cbt2,
        desc: t('benefit').cbd2,
      },
      {
        id: 'bn03',
        img: jobQuality,
        width: '371px',
        sub: t('benefit').cbs3,
        title: t('benefit').cbt3,
        desc: t('benefit').cbd3,
      },
    ],
    processSectionTitle: t('process').sectionTitle,
    processTitle: t('process').Title,
    processList: [
      {
        id: 'pl01',
        step: 1,
        title: t('process').cpt1,
        desc: t('process').cpd1,
      },
      {
        id: 'pl02',
        step: 2,
        title: t('process').cpt2,
        desc: t('process').cpd2,
      },
      {
        id: 'pl03',
        step: 3,
        title: t('process').pt3,
        desc: t('process').cpd3,
      },
      {
        id: 'pl04',
        step: 4,
        title: t('process').pt4,
        desc: t('process').cpd4,
      },
      {
        id: 'pl05',
        step: 5,
        title: t('process').pt5,
        desc: t('process').cpd5,
      },
      {
        id: 'pl06',
        step: 6,
        title: t('process').pt6,
        desc: '',
      },
    ],
  };
  const developer = {
    solutionList: [
      {
        id: 'img01',
        avif: comunicationA,
        webp: comunicationW,
        img: comunication,
        width: '55px',
        height: '48px',
        title: t('solution').st1,
        desc: t('solution').sd1,
      },
      {
        id: 'img02',
        avif: clipboardA,
        webp: clipboardW,
        img: clipboard,
        width: '51px',
        height: '49px',
        title: t('solution').st2,
        desc: t('solution').sd2,
      },
      {
        id: 'img03',
        avif: writingHandA,
        webp: writingHandW,
        img: writingHand,
        width: '52px',
        height: '52px',
        title: t('solution').srt3,
        desc: t('solution').srd3,
      },
    ],
    benefitSectionTitle: t('benefit').rSectionTitle,
    benefitTitle: t('benefit').rTitle,
    benefitList: [
      {
        id: 'bn01',
        img: assignment,
        width: '362px',
        sub: t('benefit').rbs1,
        title: t('benefit').rbt1,
        desc: t('benefit').rbd1,
      },
      {
        id: 'bn02',
        img: gitEnvironments,
        width: '394px',
        sub: t('benefit').rbs2,
        title: t('benefit').rbt2,
        desc: t('benefit').rbd2,
      },
      {
        id: 'bn03',
        img: payment,
        width: '431px',
        sub: t('benefit').rbs3,
        title: t('benefit').rbt3,
        desc: t('benefit').rbd3,
      },
    ],
    processList: [
      {
        id: 'pl01',
        step: 1,
        title: t('process').rpt1,
        desc: t('process').rpd1,
      },
      {
        id: 'pl02',
        step: 2,
        title: t('process').rpt2,
        desc: t('process').rpd2,
      },
      {
        id: 'pl03',
        step: 3,
        title: t('process').pt3,
        desc: t('process').rpd3,
      },
      {
        id: 'pl04',
        step: 4,
        title: t('process').pt4,
        desc: t('process').rpd4,
      },
      {
        id: 'pl05',
        step: 5,
        title: t('process').pt5,
        desc: t('process').rpd5,
      },
      {
        id: 'pl06',
        step: 6,
        title: t('process').pt6,
        desc: '',
      },
    ],
  };

  return (
    <>
      {user === 0 ? (
        <>
          <Feature client />
          <Solution
            sectionTitle={client.solutionSectionTitle}
            title={client.solutionTitle}
            data={client.solutionList}
            client
          />
          <Benefit
            sectionTitle={client.benefitSectionTitle}
            title={client.benefitTitle}
            data={client.benefitList}
          />
          <Journey
            sectionTitle={client.processSectionTitle}
            title={client.processTitle}
            data={client.processList}
          />
          <Registration data={commonData} />
        </>
      ) : (
        <>
          <Feature />
          <Solution
            sectionTitle={client.solutionSectionTitle}
            title={client.solutionTitle}
            data={developer.solutionList}
          />
          <Benefit
            sectionTitle={developer.benefitSectionTitle}
            title={developer.benefitTitle}
            data={developer.benefitList}
          />
          <Journey
            sectionTitle={client.processSectionTitle}
            title={client.processTitle}
            data={developer.processList}
          />
          <Registration data={commonData} />
        </>
      )}
    </>
  );
};

export default LandingData;
