import styled, { css } from 'styled-components';
import { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploadIcon } from 'resources/uploadIcon.svg';
import { Mobile, Others } from 'styles/MediaQuery';
import FormatBytes from 'utils/FormatBytes';
import { useTranslation } from 'react-i18next';

const UploadContainer = ({
  onDragDrop,
  onSetPreview,
  style,
  size,
  warning,
  limitFileSize,
  extensions,
  top,
  disabled,
  preview,
}) => {
  const { t } = useTranslation('common');

  const limitFileSizeInBytes = useMemo(() => {
    if (!limitFileSize) {
      return 1024 * 1024; // default 1MB;
    }
    if (typeof limitFileSize !== 'number' || isNaN(limitFileSize)) {
      if (
        limitFileSize &&
        ['KB', 'MB', 'GB'].some((s) => {
          return limitFileSize.endsWith(s);
        })
      ) {
        const onlyDigit = limitFileSize.replace(/\D/g, '');
        if (limitFileSize.endsWith('KB')) {
          return onlyDigit * 1024;
        } else if (limitFileSize.endsWith('MB')) {
          return onlyDigit * 1024 * 1024;
        } else if (limitFileSize.endsWith('GB')) {
          return onlyDigit * 1024 * 1024 * 1024;
        }
      }
    }
    return limitFileSize * 1024 * 1024 * 1024;
  }, [limitFileSize]);

  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileExtensionError, setFileExtensionError] = useState(false);
  const onDrop = useCallback(
    (accFiles, rejFiles) => {
      if (disabled) return;

      if (extensions && extensions.length > 0) {
        if (!extensions.includes(accFiles[0].name.split('.').pop())) {
          setFileExtensionError(true);
          return;
        }
      }
      setFileExtensionError(false);

      if (accFiles[0].size > limitFileSizeInBytes) {
        setFileSizeError(true);
        return;
      } else {
        setFileSizeError(false);
      }
      const mapAcc = accFiles.map((file) => ({ file, errors: [] }));

      // 이미지 미리보기
      if (preview) {
        const mappedFiles = accFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        );
        onSetPreview((preview) => [...preview, ...mappedFiles]);
      }

      onDragDrop((curr) => [...curr, ...mapAcc, ...rejFiles]);
    },
    [disabled, extensions, limitFileSizeInBytes, onDragDrop, onSetPreview],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <>
      {top && fileSizeError && (
        <Warning top={top}>
          {FormatBytes(limitFileSizeInBytes)} {t('uploadCountainer')[4]}
        </Warning>
      )}
      {top && fileExtensionError && (
        <Warning top={top}>
          {t('uploadCountainer', { availableExtension: extensions.join(', ') })[5]}
        </Warning>
      )}
      <UploadCont
        {...getRootProps({ isDragActive })}
        type="button"
        style={style}
        size={size}
        warning={warning || fileSizeError || fileExtensionError}
        disabled={disabled}
      >
        <>
          <input {...getInputProps()} />
          <div style={{ margin: 0 }}>
            {!disabled && isDragActive ? (
              <Text>{t('uploadCountainer')[0]}</Text>
            ) : (
              <TextWrap>
                <Icon>
                  <UploadIcon
                    fill={
                      warning || fileSizeError || fileExtensionError
                        ? 'var(--error)'
                        : disabled
                        ? 'var(--secondary)'
                        : 'var(--primary)'
                    }
                  />
                </Icon>
                <Text warning={warning || fileSizeError || fileExtensionError} disabled={disabled}>
                  <Others>
                    {t('uploadCountainer')[1]} <span>{t('uploadCountainer')[2]}</span>
                  </Others>
                  <Mobile>
                    <span>{t('uploadCountainer')[3]}</span>
                  </Mobile>
                </Text>
              </TextWrap>
            )}
          </div>
        </>
      </UploadCont>
      {!top && fileSizeError && (
        <Warning>
          {FormatBytes(limitFileSizeInBytes)} {t('uploadCountainer')[4]}
        </Warning>
      )}
      {!top && fileExtensionError && (
        <Warning>{t('uploadCountainer', { availableExtension: extensions.join(', ') })[5]}</Warning>
      )}
    </>
  );
};

const getColor = (props) => {
  if (props.isDragActive && !props.disabled) {
    return 'rgba(44, 98, 186, 0.1)';
  }
  return '#f4f4f4';
};

const container = {
  small: {
    width: '598px',
    height: '74px',
  },
  large: {
    width: '688px',
    height: '108px',
  },
  flexibleW: {
    width: '100%',
    height: '108px',
  },
  flexible: {
    width: '100%',
    height: '100%',
  },
};

const sizeStyles = css`
  ${({ size }) => css`
    width: ${container[size].width};
    height: ${container[size].height};
  `}
`;

const UploadCont = styled.button`
  ${sizeStyles}
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--primary);
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
  background: ${(props) => getColor(props)};
  ${(props) => (props.style ? props.style : null)};
  ${(props) =>
    props.isDragActive &&
    css`
      border: 2px dashed var(--primary);
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
    `};
  ${(props) =>
    props.warning &&
    css`
      border: ${(props) =>
        props.isDragActive ? '2px dashed var(--error)' : '1px dashed var(--error)'};
      color: var(--error);
    `};
  :disabled {
    cursor: not-allowed;
    border: 1px dashed var(--secondary);
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--secondary);
  }
`;

const TextWrap = styled.div`
  display: flex;
`;

const Icon = styled.div`
  margin: 0 10px 0 0;
`;

const Text = styled.p`
  margin: 0;
  & > span {
    border-bottom: ${(props) =>
      props.warning
        ? '1px solid var(--error)'
        : props.disabled
        ? '1px solid var(--secondary)'
        : '1px solid var(--primary)'};
  }
`;

const Warning = styled.p`
  font-size: 11px;
  font-weight: 400;
  color: var(--error);
  margin: 6px 0 0 0;
  ${(props) =>
    props.top &&
    css`
      position: absolute;
      top: -14px;
    `}
  span {
    color: var(--grayText2);
  }
`;

export default UploadContainer;
