import styled from 'styled-components';
import React from 'react';
import theme from 'styles/theme';
const OptionButton = ({ title, active, style, onClick }) => {
  return (
    <OptionButtonWrapper style={style} active={active} onClick={() => onClick()}>
      <OptionButtonText active={active}>{title}</OptionButtonText>
    </OptionButtonWrapper>
  );
};

export default OptionButton;
const OptionButtonWrapper = styled.button`
  position: relative;
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48%;
  height: 50px;
  background-color: ${(props) => (props.active ? '#EEF2F9' : 'white')};
  border: ${(props) => (props.active ? '1px solid #2C62BA' : '1px solid #D6D6D6')};
  border-radius: 3px;
  @media ${theme.mobile} {
    width: 100%;
    height: 43px;
  }
`;
const OptionButtonText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 171.69%;
  color: ${(props) => (props.active ? '#2C62BA' : '#686868')};
`;
