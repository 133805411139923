import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import RegistrationBtns from './RegistrationBtns';
import { Mobile, Others } from 'styles/MediaQuery';

const Registration = ({ data }) => {
  return (
    <OfferWrapper>
      <Container>
        <Offer>{data.offer}</Offer>
        <BtnWrapper>
          <Others>
            <RegistrationBtns
              data={data}
              size="medium"
              color="background"
              border="gray"
              position="bottom"
            />
          </Others>
          <Mobile>
            <RegistrationBtns data={data} size="fullWidth" color="white" border position="bottom" />
          </Mobile>
        </BtnWrapper>
      </Container>
    </OfferWrapper>
  );
};
const OfferWrapper = styled.div`
  background: var(--background);
  width: 100vw;
  padding: 96px 57px;
  @media ${theme.tablet} {
    padding: 82px 57px;
  }
  @media ${theme.mobile} {
    padding: 0;
  }
`;
const Container = styled.div`
  width: 100%;
  max-width: 1222px;
  margin: 0px auto;
  height: 367px;
  border-radius: 20px;
  background: var(--primaryLight);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${theme.tablet} {
    height: 337px;
  }
  @media ${theme.mobile} {
    height: 307px;
    border-radius: 0;
    padding: 0 28px;
  }
`;
const Offer = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 51px;
  text-align: center;
  color: var(--title);
  @media ${theme.tablet} {
    width: 80%;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
  }
  @media ${theme.mobile} {
    width: 269px;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
  }
`;
const BtnWrapper = styled.div`
  display: flex;
  margin-top: 31px;
  @media ${theme.mobile} {
    flex-direction: column;
    width: 100%;
    a:last-child {
      margin-top: 10px;
    }
  }
`;

export default Registration;
