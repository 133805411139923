import React from 'react';
import styled from 'styled-components';
import theme, { fonts } from 'styles/theme';

const UserName = ({ name }) => {
  return <Name title={name}>{name}</Name>;
};

export const Name = styled.p`
  margin: 0;
  ${fonts.body}
  line-height: 19px;
  ${theme.ellipsis};
  max-width: 14vw;
  @media ${theme.mobile} {
    max-width: 20vw;
  }
`;

export default UserName;
