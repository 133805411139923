import React from 'react';
import styled from 'styled-components';
import { fonts } from 'styles/theme';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  parseISO,
  format,
} from 'date-fns';
import { useTranslation } from 'react-i18next';

const TimeSince = ({ createdAt, updatedAt, modified }) => {
  const { t } = useTranslation('common');

  const formattedDate = format(parseISO(updatedAt), 'yyyy.MM.dd HH:mm:ss');

  const getTimeSince = (updatedAt) => {
    const today = new Date();
    const updatedDate = new Date(updatedAt);

    if (differenceInDays(today, updatedDate) > 0) {
      return `${formattedDate}`;
    } else if (differenceInHours(today, updatedDate) > 0) {
      return `${differenceInHours(today, updatedDate)}${t('hours')}`;
    } else if (differenceInMinutes(today, updatedDate) > 0) {
      return `${differenceInMinutes(today, updatedDate)}${t('minutes')}`;
    } else if (differenceInSeconds(today, updatedDate) >= 0) {
      return `${differenceInSeconds(today, updatedDate)}${t('seconds')}`;
    }
  };

  return (
    <Time title={formattedDate}>
      {getTimeSince(updatedAt)} {modified && createdAt !== updatedAt && t('modified')}
    </Time>
  );
};

const Time = styled.span`
  ${fonts.secondary}
`;

export default TimeSince;
