import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Tag from 'components/atoms/Tag';
import Profile from 'components/atoms/Profile';
import EditButtons from 'components/atoms/EditButtons';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { RenderCardContent } from './renderContent';
import Height from 'components/atoms/Height';
import DownloadableFile from 'components/atoms/DownloadableFile';
import { PriorityTypeBtn, WorkStatusBtn } from 'components/atoms/StatusBtn';
import TimeSince from 'components/atoms/TimeSince';

const QuestionCard = observer(
  ({ projectId, data, userId, sortName, children, onEdit, onDelete }) => {
    const {
      requestId,
      profilePicUrl,
      writerUsername,
      writerUserId,
      createdAt,
      updatedAt,
      formatType,
      content,
      materialList,
      issue,
    } = data;

    let history = useHistory();

    const handleClickTagLink = (type, id) => {
      if (type === 'MILESTONE') {
        history.push({ pathname: `/milestone/${id}`, state: { projectId } });
      } else if (type === 'CLIENT_TASK') {
        history.push({ pathname: `/todo`, state: { projectId } });
      } else if (type === 'TASK_DEFINITION') {
        history.push({ pathname: `/proposal/feedback`, state: { projectId, proposalId: id } });
      } else if (type === 'CONTRACT') {
        history.push({ pathname: `/contract/sign`, state: { projectId, contractId: id } });
      }
    };

    return (
      <Container>
        <QuestionContainer>
          <ProfileArea>
            <Profile profile={profilePicUrl} />
            <UserInfo>
              <UserName>{writerUsername}</UserName>
              <TimeSince createdAt={createdAt} updatedAt={updatedAt} modified />
            </UserInfo>
          </ProfileArea>
          <ContentArea>
            <TagsWrapper>
              <Tag>{sortName}</Tag>
              {issue?.priorityType && <PriorityTypeBtn priorityType={issue.priorityType} />}
              {issue?.status && issue?.status !== 'NONE' && <WorkStatusBtn status={issue.status} />}
            </TagsWrapper>
            {issue?.title && <Content>{issue.title}</Content>}
            <Content>
              {formatType === 'HTML' ? (
                <div dangerouslySetInnerHTML={{ __html: content }} />
              ) : (
                content.split('\n').map((line, i) => (
                  <div key={`content-line-${i}`}>
                    <RenderCardContent value={line} onClick={handleClickTagLink} />
                    <br />
                  </div>
                ))
              )}
            </Content>
            {materialList?.length > 0 && (
              <>
                <Height height="32px" />
                {materialList.map((list, i) => (
                  <DownloadableFile key={`pm-material-list__${i}`} data={list} />
                ))}
              </>
            )}
            {issue?.materialList?.length > 0 && (
              <>
                <Height height="32px" />
                {issue.materialList.map((list, i) => (
                  <DownloadableFile key={`pm-material-list__${i}`} data={list} />
                ))}
              </>
            )}
          </ContentArea>
          {userId === writerUserId ? (
            <EditButtons setId={requestId} onEdit={onEdit} onDelete={onDelete} />
          ) : (
            <Height height="31.59px" />
          )}
        </QuestionContainer>
        {children}
      </Container>
    );
  },
);

const Container = styled.div`
  width: 100%;
  min-height: 370px;
  border-radius: 8px;
  border: var(--border);
  background: white;
  padding: 40px 35px;
  & + & {
    margin-top: 20px;
  }
  @media ${theme.mobile} {
    border-radius: 0px;
    border-left: 0;
    border-right: 0;
    padding: 27px 28px;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: var(--border);
`;
const ProfileArea = styled.div`
  display: flex;
  padding: 0 9px;
  @media ${theme.mobile} {
    padding: 0;
  }
`;
const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;
const UserName = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  color: var(--title);
`;

const ContentArea = styled.div`
  min-height: 88px;
  padding: 9px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media ${theme.mobile} {
    padding: 9px 0;
  }
`;
const TagsWrapper = styled.div`
  display: flex;
  & > div:not(:first-child) {
    margin-left: 7px;
  }
`;

const Content = styled.span`
  margin-top: 9px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  color: var(--bodyText);
  span {
    color: var(--primary);
    cursor: pointer;
  }
`;

export default QuestionCard;
