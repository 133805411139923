import { makeAutoObservable, runInAction } from 'mobx';
import {
  addPersonalDocumentsApi,
  getPersonalDocumentsApi,
  updatePersonalDocumentsInfoApi,
  deletePersonalDocumentsApi,
} from 'api/API';
import MyInfoStore from 'stores/MyInfoStore';

class DocumentsFormStore {
  documentsInfo = {};
  businessType = [];
  cashReceiptMethod = [];
  idDocuments = [];
  bankbookDocuments = [];
  loading = false;
  deletePopup = false;
  deleteConfirm = false;

  constructor() {
    makeAutoObservable(this);
  }

  changeDocumentsInfo = (name, value) => {
    this.documentsInfo = { ...this.documentsInfo, [name]: value };
  };

  toggleDeletePopup = () => {
    this.deletePopup = !this.deletePopup;
  };

  setDeleteConfirm = (param) => {
    this.deleteConfirm = param;
  };

  showLoading = () => {
    this.loading = true;
  };

  hideLoading = () => {
    this.loading = false;
  };

  setFileSizeWarning = (param) => {
    this.fileSizeWarning = param;
  };

  addPersonalDocuments = async (formData) => {
    await addPersonalDocumentsApi(formData)
      .then((res) => {
        runInAction(() => {
          for (let i = 0; i < res.data.personalDocuments.length; i++) {
            if (res.data.personalDocuments[i].documentType === 'ID_CARD')
              this.idDocuments.splice(0, 1, res.data.personalDocuments[i]);
            if (res.data.personalDocuments[i].documentType === 'BANK_BOOK')
              this.bankbookDocuments.splice(0, 1, res.data.personalDocuments[i]);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getPersonalDocuments = async () => {
    await getPersonalDocumentsApi()
      .then((res) => {
        runInAction(() => {
          this.documentsInfo = res.data;
          this.businessType.splice(0, 1, res.data.businessType);
          this.cashReceiptMethod.splice(0, 1, res.data.cashReceiptMethod);
          for (let i = 0; i < res.data.personalDocuments.length; i++) {
            if (res.data.personalDocuments[i].documentType === 'ID_CARD')
              this.idDocuments.splice(0, 1, res.data.personalDocuments[i]);
            if (res.data.personalDocuments[i].documentType === 'BANK_BOOK')
              this.bankbookDocuments.splice(0, 1, res.data.personalDocuments[i]);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updatePersonalDocumentInfo = async (query, edit) => {
    this.showLoading();
    await updatePersonalDocumentsInfoApi(query)
      .then((res) => {
        this.hideLoading();
        if (res.status === 200 && edit) MyInfoStore.toggleMyInfoConfirm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deletePersonalDocuments = async (id) => {
    await deletePersonalDocumentsApi(id)
      .then((res) => {
        this.setDeleteConfirm(false);
        this.getPersonalDocuments();
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const documentsFormStore = new DocumentsFormStore();

export default documentsFormStore;
