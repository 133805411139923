import { makeAutoObservable } from 'mobx';
import { registerProjectApi } from 'api/API';

class ProposalFormStore {
  proposalFormTab = 0;
  projectName = '';
  projectTypes = [];
  expectedStartDate = '';
  expectedDueDate = '';
  startNow = false;
  undefined = false;
  minBudget = 0;
  maxBudget = 0;
  budgetDecisionSupportRequired = false;
  paymentPlanType = '';
  projectIntroduction = '';
  preferredTechStack = [];
  projectTechStackRequired = false;
  developerInvited = null;
  developersEmails = [
    {
      developersEmails: '',
    },
  ];
  projectLinkUrls = [
    {
      projectLink: '',
    },
  ];
  projectFileUrl = [];
  files = [];
  localFiles = [];
  projectFileSuccess = false;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setProposalFormTab = (param) => {
    this.proposalFormTab = param;
  };

  setProjectName = (param) => {
    this.projectName = param;
  };

  setProjectTypes = (param) => {
    this.projectTypes = param;
  };

  setExpectedStartDate = (param) => {
    this.expectedStartDate = param;
  };

  setExpectedDueDate = (param) => {
    this.expectedDueDate = param;
  };

  setStartNow = (param) => {
    this.startNow = param;
  };

  setUndefined = (param) => {
    this.undefined = param;
  };

  setMinBudget = (param) => {
    this.minBudget = param;
  };

  setMaxBudget = (param) => {
    this.maxBudget = param;
  };

  setBudgetDecisionSupportRequired = (param) => {
    this.budgetDecisionSupportRequired = param;
  };

  setPaymentPlanType = (param) => {
    this.paymentPlanType = param;
  };

  setProjectIntroduction = (param) => {
    this.projectIntroduction = param;
  };

  setPreferredTechStack = (param) => {
    this.preferredTechStack = param;
  };

  setProjectTechStackRequired = (param) => {
    this.projectTechStackRequired = param;
  };

  setDeveloperInvited = (param) => {
    this.developerInvited = param;
  };

  setDevelopersEmails = (param) => {
    this.developersEmails = param;
  };

  setProjectLinkUrls = (param) => {
    this.projectLinkUrls = param;
  };

  setFiles = (param) => {
    this.files = param;
  };

  setLocalFiles = (param) => {
    this.localFiles = param;
  };

  setProjectFileSuccess = (param) => {
    this.projectFileSuccess = param;
  };

  showLoading = () => {
    this.loading = true;
  };

  hideLoading = () => {
    this.loading = false;
  };

  resetLocalStorage = () => {
    localStorage.removeItem('techStack');
    localStorage.removeItem('minBudget');
    localStorage.removeItem('projectTypes');
    localStorage.removeItem('paymentPlanType');
    localStorage.removeItem('files');
    localStorage.removeItem('maxBudget');
    localStorage.removeItem('projectLinkUrls');
    localStorage.removeItem('expectedDueDate');
    localStorage.removeItem('checkBudgetRequired');
    localStorage.removeItem('developersEmails');
    localStorage.removeItem('projectIntroduction');
    localStorage.removeItem('projectKindInput');
    localStorage.removeItem('expectedStartDate');
    localStorage.removeItem('projectName');
    localStorage.removeItem('alphanumericName');
    localStorage.removeItem('undefined');
    localStorage.removeItem('startNow');
    localStorage.removeItem('localFiles');
    localStorage.removeItem('projectTechStackRequired');
    localStorage.removeItem('developerInvited');
  };

  registerProposalForm = async (accessToken) => {
    this.showLoading();
    const changeDateFormat = (date) => {
      if (date) {
        let year = date?.getFullYear();
        let month = ('0' + (date?.getMonth() + 1)).slice(-2);
        let day = ('0' + date?.getDate()).slice(-2);
        const dateString = year + '-' + month + '-' + day;
        return dateString;
      }
    };
    const formData = new FormData();

    formData.append('projectName', localStorage.getItem('projectName'));
    formData.append('alphanumericName', localStorage.getItem('alphanumericName'));
    for (let i = 0; i < JSON.parse(localStorage.getItem('projectTypes')).length; i++) {
      formData.append(
        `projectTypes[${i}].projectType`,
        JSON.parse(localStorage.getItem('projectTypes'))[i],
      );
    }
    if (JSON.parse(localStorage.getItem('projectTypes')).includes('OTHERS')) {
      const findDirectInput = JSON.parse(localStorage.getItem('projectTypes')).findIndex(
        (item) => item === 'OTHERS',
      );
      formData.append(
        `projectTypes[${findDirectInput}].projectTypeDirectInput`,
        localStorage.getItem('projectKindInput'),
      );
    }

    if (localStorage.getItem('expectedStartDate')) {
      formData.append(
        'expectedStartDate',
        changeDateFormat(new Date(localStorage.getItem('expectedStartDate'))),
      );
    }
    if (localStorage.getItem('expectedDueDate')) {
      formData.append(
        'expectedDueDate',
        changeDateFormat(new Date(localStorage.getItem('expectedDueDate'))),
      );
    }
    formData.append('startNow', JSON.parse(localStorage.getItem('startNow')) || false);
    formData.append('undefined', JSON.parse(localStorage.getItem('undefined')) || false);
    formData.append(
      'minBudget',
      parseFloat(localStorage.getItem('minBudget').replace(/,/g, '')) || 0,
    );
    formData.append(
      'maxBudget',
      parseFloat(localStorage.getItem('maxBudget').replace(/,/g, '')) || 0,
    );
    formData.append(
      'budgetDecisionSupportRequired',
      JSON.parse(localStorage.getItem('checkBudgetRequired')),
    );
    if (localStorage.getItem('paymentPlanType')) {
      formData.append('paymentPlanType', localStorage.getItem('paymentPlanType'));
    }

    formData.append('projectIntroduction', localStorage.getItem('projectIntroduction'));
    for (let i = 0; i < JSON.parse(localStorage.getItem('techStack')).length; i++) {
      formData.append(
        `preferredTechStack[${i}].stackName`,
        JSON.parse(localStorage.getItem('techStack'))[i].stackName,
      );
    }
    formData.append(
      'projectTechStackRequired',
      JSON.parse(localStorage.getItem('projectTechStackRequired')),
    );
    formData.append('developerInvited', JSON.parse(localStorage.getItem('developerInvited')));
    if (JSON.parse(localStorage.getItem('developersEmails')) !== ['']) {
      formData.append(
        'numberOfInvitedDevelopers',
        JSON.parse(localStorage.getItem('developersEmails')).length,
      );
    }
    if (JSON.parse(localStorage.getItem('developersEmails')) !== ['']) {
      for (let i = 0; i < JSON.parse(localStorage.getItem('developersEmails')).length; i++) {
        formData.append(
          `developersEmails[${i}].developersEmails`,
          JSON.parse(localStorage.getItem('developersEmails'))[i],
        );
      }
    }
    if (JSON.parse(localStorage.getItem('projectLinkUrls')) !== ['']) {
      for (let i = 0; i < JSON.parse(localStorage.getItem('projectLinkUrls')).length; i++) {
        formData.append(
          `projectLinkUrls[${i}].projectLink`,
          JSON.parse(localStorage.getItem('projectLinkUrls'))[i],
        );
      }
    }
    const tempSavedFiles = JSON.parse(localStorage.getItem('localFiles'));
    for (let i = 0; i < tempSavedFiles?.length; i++) {
      formData.append(`tempSavedFiles[${i}].fileType`, tempSavedFiles[i].fileType);
      formData.append(`tempSavedFiles[${i}].fileSize`, parseFloat(tempSavedFiles[i].fileSize));
      formData.append(`tempSavedFiles[${i}].width`, parseFloat(tempSavedFiles[i].width));
      formData.append(`tempSavedFiles[${i}].fileUrl`, tempSavedFiles[i].fileUrl);
      formData.append(`tempSavedFiles[${i}].fileName`, tempSavedFiles[i].fileName);
      formData.append(`tempSavedFiles[${i}].height`, parseFloat(tempSavedFiles[i].height));
    }
    await registerProjectApi(formData, accessToken)
      .then((res) => {
        this.resetLocalStorage();
        window.location.href = '/confirm';
      })
      .catch((error) => {
        console.log(error);
        this.hideLoading();
      });
  };
}

const proposalFormStore = new ProposalFormStore();

export default proposalFormStore;
