import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

import stores from 'stores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import reply from 'resources/replyIcon.png';
import Profile from 'components/atoms/Profile';
import EditButtons from 'components/atoms/EditButtons';
import ReplyFileUpload from 'components/molecules/ReplyFileUpload';
import DownloadableFile from 'components/atoms/DownloadableFile';
import TimeSince from 'components/atoms/TimeSince';

const Answer = observer(({ data, userId, answerable, onReplyClick, pageType }) => {
  const { roworkPMStore } = stores;

  const { t } = useTranslation('main');

  const {
    commentId,
    profilePicUrl,
    writerUsername,
    writerUserId,
    createdAt,
    updatedAt,
    content,
    parentCommentId,
    materialList,
  } = data;

  const [answerEdit, setAnswerEdit] = useState({
    edit: false,
    value: '',
  });

  const [materialIdList, setMaterialIdList] = useState([]);
  const [tempSavedFiles, setTempSavedFiles] = useState([]);
  const getMaterialIdList = useCallback((materialIdList) => {
    setMaterialIdList(materialIdList);
    // console.log(materialIdList);
  }, []);
  const getTempSavedFiles = useCallback((tempSavedFiles) => {
    setTempSavedFiles(tempSavedFiles);
    // console.log(tempSavedFiles);
  }, []);

  const handleAnswerEditChange = (value) => {
    console.log(value);
    setAnswerEdit({ ...answerEdit, value: value });
  };

  const handleAnswerEditSend = () => {
    roworkPMStore.patchRoworkPMComments(pageType, commentId, {
      content: answerEdit.value,
      parentCommentId,
      materialIdList,
      tempSavedFiles,
    });
    setAnswerEdit({ edit: false, value: '' });
    setTempSavedFiles([]);
    setMaterialIdList([]);
  };

  const handleCancelAnswer = () => {
    setAnswerEdit({ edit: false, value: '' });
  };

  const handleEditAnswer = (id) => {
    roworkPMStore.setAnswerEditId(id);
    setAnswerEdit({ edit: true, value: content });
  };

  const handleDeleteAnswer = () => {
    roworkPMStore.deleteRoworkPMComments(pageType, commentId);
  };

  return (
    <Container>
      {roworkPMStore.answerEditId === commentId && answerEdit.edit ? (
        <ReplyFileUpload
          id={commentId}
          profile={profilePicUrl}
          value={answerEdit.value}
          editValue={content}
          onChange={handleAnswerEditChange}
          onTextAreaValue={handleAnswerEditSend}
          onClick={handleAnswerEditSend}
          onCancel={handleCancelAnswer}
          materialList={materialList}
          materialIdList={materialIdList}
          setMaterialIdList={getMaterialIdList}
          tempSavedFiles={tempSavedFiles}
          setTempSavedFiles={getTempSavedFiles}
        />
      ) : (
        <>
          <ContentArea>
            <ProfileWrap>
              <Profile profile={profilePicUrl} />
            </ProfileWrap>
            <Content>
              <PMInfo>
                <Name>{writerUsername}</Name>
                <TimeSince createdAt={createdAt} updatedAt={updatedAt} modified />
              </PMInfo>
              <AnswerContent>{content}</AnswerContent>
              {materialList?.length > 0 && (
                <FileWrapper>
                  {materialList.map((list, i) => (
                    <DownloadableFile key={`pm-material-list__${i}`} data={list} />
                  ))}
                </FileWrapper>
              )}
            </Content>
          </ContentArea>
          {answerable ? (
            <ReplyEditWrapper>
              {userId === writerUserId && (
                <EditButtonswithReply
                  setId={commentId}
                  onEdit={handleEditAnswer}
                  onDelete={handleDeleteAnswer}
                />
              )}
              <ReplyContainer onClick={() => onReplyClick(commentId)}>
                {t('reply')}
                <img src={reply} alt="reply" />
              </ReplyContainer>
            </ReplyEditWrapper>
          ) : (
            <EditButtons
              setId={commentId}
              onEdit={handleEditAnswer}
              onDelete={handleDeleteAnswer}
            />
          )}
        </>
      )}
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  @media ${theme.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const ContentArea = styled.div`
  width: 100%;
  display: flex;
`;
const ProfileWrap = styled.div`
  margin: 3px 0 0 0;
`;
const Content = styled.div`
  width: 90%;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;
const AnswerContent = styled.div`
  margin-top: 7px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--bodyText);
  word-break: keep-all;
`;
const FileWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const PMInfo = styled.div`
  display: flex;
`;
const Name = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  color: var(--title);
  margin-right: 10px;
`;

const ReplyEditWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const EditButtonswithReply = styled(EditButtons)`
  margin-top: -5px;
  margin-left: -5px;
`;
const ReplyContainer = styled.div`
  width: 65.4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: var(--primary);
  @media ${theme.mobile} {
    margin-top: 7px;
    margin-left: 48px;
  }
`;

export default Answer;
