import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import theme, { fonts } from 'styles/theme';

export const SectionTitle = ({ children, h3 }) => {
  return h3 ? (
    <SectionTitleH3>{children}</SectionTitleH3>
  ) : (
    <SectionTitleH2>{children}</SectionTitleH2>
  );
};

const SectionStyle = css`
  ${fonts.sectionTitle}
  margin-bottom: 14px;
  @media ${theme.mobile} {
    ${fonts.m_sectionTitle}
  }
`;

const SectionTitleH2 = styled.h2`
  ${SectionStyle}
`;

const SectionTitleH3 = styled.h3`
  ${SectionStyle}
`;

const Label = ({ top, label, required, subLabel }) => {
  return (
    <>
      <LabelH3 top={top}>
        {label} {required && <Required>*</Required>}
      </LabelH3>
      {subLabel && <SubLabel>{subLabel}</SubLabel>}
    </>
  );
};

const LabelH3 = styled.h3`
  ${fonts.formQ}
  ${(props) => props.top && `margin-top: ${props.top}`};
  margin-bottom: 7px;
`;

const Required = styled.span`
  font-size: 14px;
  font-style: bold;
  line-height: 17px;
  color: var(--error);
`;

const SubLabel = styled.p`
  margin: 0 0 19px 0;
  font-size: 12px;
  font-weight: 400;
  color: var(--secondary);
  line-height: 16.86px;
  ${(props) => props.warning && `color: var(--error);`}
`;

export default Label;
