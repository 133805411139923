import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { fonts } from 'styles/theme';

const ServiceMenuItem = ({ onClick, focus, children, h2 }) => {
  return h2 ? (
    <ServiceItemH2 onClick={onClick} focus={focus}>
      {children}
    </ServiceItemH2>
  ) : (
    <ServiceItemH3 onClick={onClick} focus={focus}>
      {children}
    </ServiceItemH3>
  );
};

const ServiceItemStyle = css`
  cursor: pointer;
  margin-bottom: 0;
  width: 205px;
  height: 30px;
  ${fonts.body}
  line-height: 31px;
  color: ${(props) => (props.focus ? 'var(--bodyText2)' : 'var(--grayText)')};
  font-weight: ${(props) => (props.focus ? '700' : '400')};
  :hover {
    font-weight: 700;
    color: var(--primary);
  }
`;

const ServiceItemH2 = styled.h2`
  ${ServiceItemStyle}
`;

const ServiceItemH3 = styled.h3`
  ${ServiceItemStyle}
  margin: 6px 0 0 14px;
`;

export default ServiceMenuItem;
