import { forwardRef } from 'react';
import styled from 'styled-components';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import ko from 'react-phone-input-2/lang/ko.json';
import { useTranslation } from 'react-i18next';

import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import RequestCounter from 'components/atoms/RequestCounter';
import check from 'resources/check.png';

const LabeledInput = forwardRef(
  (
    {
      top,
      id,
      name,
      value,
      onChange,
      type,
      label,
      required,
      onClick,
      onBlur,
      warning,
      active,
      request,
      countdown,
      onDisabled,
      disabled,
      completed,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation('common');

    return (
      <InputItem>
        <Label top={top} label={label} required={required} />
        {type === 'auth' || type === 'email' || type === 'cellphone' ? (
          <InputWrap>
            {type === 'cellphone' ? (
              <Phone
                country="kr"
                localization={window.navigator.language === 'ko' ? ko : false}
                enableSearch={true}
                value={value}
                onChange={(value, data) => onChange(value, data)}
                disabled={disabled || completed}
                inputProps={{
                  id: 'phone-input',
                }}
                inputClass={warning && 'fail'}
                dropdownStyle={dropdownStyle}
                {...rest}
              />
            ) : (
              <Input
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type={type}
                className={warning && 'fail'}
                disabled={disabled || completed}
                {...rest}
              />
            )}
            <BtnWrap>
              {completed ? (
                <img src={check} alt="인증완료" />
              ) : (
                <Button type="button" size="small" onClick={onClick} disabled={!active}>
                  {type === 'auth'
                    ? t('labeledInput')[0]
                    : request
                    ? t('labeledInput')[1]
                    : t('labeledInput')[2]}
                </Button>
              )}
            </BtnWrap>
          </InputWrap>
        ) : (
          <Input
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            type={type}
            className={warning && 'fail'}
            disabled={disabled}
            {...rest}
            ref={ref}
          />
        )}
        {warning && <Warning>{warning}</Warning>}
        {type === 'auth' && countdown && !completed && (
          <RequestCounter countdown={countdown} onDisabled={onDisabled} />
        )}
      </InputItem>
    );
  },
);

const InputItem = styled.div`
  width: 100%;
  & + & {
    margin-top: 34px;
  }
`;

const InputWrap = styled.div`
  position: relative;
`;

const dropdownStyle = {
  width: '350px',
  marginTop: 0,
};

const Phone = styled(PhoneInput)`
  #phone-input {
    width: 100%;
    height: 47px;
    border: var(--border);
    box-shadow: var(--borderDropShadow);
    color: var(--bodyText2);
    &::placeholder {
      color: var(--secondary);
    }
    &:focus {
      outline: none;
      border: 1px solid var(--primary);
    }
    &:-webkit-autofill {
      -webkit-text-fill-color: var(--primary) !important;
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
    }
    &::-webkit-contacts-auto-fill-button {
      opacity: 0;
    }
    &.fail {
      color: var(--bodyText2);
      border: 1px solid var(--error);
      &:-webkit-autofill {
        -webkit-text-fill-color: var(--bodyText2) !important;
        -webkit-box-shadow: 0 0 0 1000px #fff inset;
      }
    }
    &:disabled {
      background: #efefef;
      color: var(--secondary);
    }
  }
`;

const BtnWrap = styled.div`
  position: absolute;
  top: 8px;
  right: 9px;
  img {
    position: absolute;
    top: 9px;
    right: 10px;
  }
`;

const Warning = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: var(--error);
  margin-top: 5px;
`;

export default LabeledInput;
