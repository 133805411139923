export const moveCursorToEnd = (element) => {
  setTimeout(() => {
    element.focus();
    const range = document.createRange();
    const selection = window.getSelection();
    const lastChild = element.lastChild;

    if (lastChild) {
      if (lastChild.nodeType === Node.TEXT_NODE) {
        range.setStart(lastChild, lastChild.length);
      } else {
        range.selectNodeContents(element);
        range.collapse(false);
      }
    } else {
      range.setStart(element, 0);
    }

    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }, 0);
};

export const isCursorAfterNbsp = () => {
  const selection = window.getSelection();
  if (selection.rangeCount === 0) return false;
  const range = selection.getRangeAt(0);
  const precedingRange = range.cloneRange();

  precedingRange.setStart(range.startContainer, Math.max(0, range.startOffset - 1));
  precedingRange.setEnd(range.startContainer, range.startOffset);

  const precedingText = precedingRange.toString();
  return precedingText === '\u00a0';
};
