import React, { useEffect } from 'react';
import styled from 'styled-components';
import { fonts } from 'styles/theme';
import closeEx from 'images/closeEx.png';
import { SectionTitle } from 'components/atoms/Label';

const RoworkerTips = ({ t }) => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      document.getElementById(hash.substr(1)).scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div>
      <Title>{t.title}</Title>
      <HashTag>#1</HashTag>
      <SentionTitle>{t.closeKeyword}</SentionTitle>
      <Desc>{t.closeKeywordDesc[0]}</Desc>
      <Desc>
        {t.closeKeywordDesc[1]}:
        <br /> #{'>'} git commit -m "{t.closeKeywordDesc[2]}"
      </Desc>
      <Desc>
        {t.closeKeywordDesc[3]}
        <br /> - close
        <br /> - closes
        <br /> - closed
        <br /> - fix
        <br /> - fixes
        <br /> - fixed
        <br /> - resolve
        <br /> - resolves
        <br />
      </Desc>
      <Desc>{t.closeKeywordDesc[4]}</Desc>
      <ExImage src={closeEx} />
      <a href="#addKey" id="addKey"></a>
      <Padding />
      <SectionTitle>{t.addSshKey}</SectionTitle>
      <Desc>{t.addSshKeyDesc}</Desc>
      <SectionSubTitle>{t.addSshKeyStep1}</SectionSubTitle>
      <Desc>{t.addSshKeyStep1Desc}</Desc>
      <br />
      <SectionSubTitle>{t.addSshKeyStep2}</SectionSubTitle>
      <Desc>{t.addSshKeyStep2Desc}</Desc>
      <br />
      <SectionSubTitle>{t.addSshKeyStep3}</SectionSubTitle>
      <Desc>{t.addSshKeyStep3Desc}</Desc>
      <br />
      <Desc>{t.addSshKeyAddDesc}</Desc>
      <a href={t.addSshKeyDescLink} target="_blank">
        확인하기
      </a>
    </div>
  );
};

const Title = styled.div`
  margin-bottom: 74px;
  ${fonts.bigTitle}
`;
const HashTag = styled.div`
  display: inline-flex;
  margin-bottom: 26px;
  padding: 7px 17px;
  border-radius: 5px;
  background: var(--primaryLight);
  font-size: 24px;
  font-weight: 700;
  line-height: 41px;
  color: var(--primary);
`;
const SentionTitle = styled.p`
  margin-bottom: 14px;
  ${fonts.sectionTitle}
  font-weight: 600;
`;

const SectionSubTitle = styled.p`
  margin-bottom: 14px;
  ${fonts.sectionTitle}
  font-size: 16px;
  font-weight: 500;
`;

const Desc = styled.div`
  margin-bottom: 30px;
  ${fonts.body}
  color: var(--grayText);
`;
const ExImage = styled.img`
  margin-left: -13px;
  width: 100%;
  max-width: 516px;
`;
const Padding = styled.div`
  height: 44px;
`;
export default RoworkerTips;
