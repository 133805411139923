import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import theme from 'styles/theme';

const ResizeTextArea = ({
  id,
  setHeight,
  noBtn,
  value,
  placeholder,
  onChange,
  onResize,
  onTextAreaValue,
  enterable,
  ...rest
}) => {
  const ref = useRef(null);
  const height = `${setHeight}px`;

  useEffect(() => {
    if (ref === null || ref.current === null) {
      return;
    }
    if (value === '') {
      ref.current.style.height = height;
      ref.current.style.height = ref.current.scrollHeight + 'px';
      if (onResize) onResize(setHeight);
    }
    ref.current.style.height = ref.current.scrollHeight + 'px';
    if (onResize) onResize(ref.current.scrollHeight);
  }, [height, onResize, setHeight, value]);

  // 줄바꿈 시 textarea 높이 조절 함수
  const handleResizeHeight = useCallback(() => {
    if (ref === null || ref.current === null) {
      return;
    }
    ref.current.style.height = height;
    ref.current.style.height = ref.current.scrollHeight + 'px';
  }, [height]);

  // enter 클릭 시 처리 함수
  const handleEnterPress = useCallback(
    (e) => {
      if (!enterable && e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault();
        if (onTextAreaValue) onTextAreaValue(e.target.value);
        document.getElementById(id)?.blur();
      }
    },
    [enterable, id, onTextAreaValue],
  );

  return (
    <TextArea
      id={id}
      rows={1}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onInput={handleResizeHeight}
      onKeyDown={handleEnterPress}
      ref={ref}
      {...rest}
    />
  );
};

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  min-height: 44px;
  resize: none;
  overflow: hidden;
  border-radius: 5px;
  box-sizing: border-box;
  border: var(--border);
  box-shadow: var(--borderDropShadow);
  padding: ${(props) =>
    props.paddingBig
      ? '18px 78px 18px 25px'
      : props.fileUpload
      ? '10px 10px 10px 18px'
      : '10px 78px 10px 18px'};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--bodyText);

  &::placeholder {
    font-size: 16px;
    color: var(--secondary);
    @media ${theme.mobile} {
      font-size: 14px;
    }
    line-height: ${(props) => (props.paddingBig ? '28px' : 'auto')};
  }
  &:focus {
    outline: none;
    border: 1px solid var(--primary);
  }
  &.fail {
    color: var(--bodyText2);
    border: 1px solid var(--error);
    &:-webkit-autofill {
      -webkit-text-fill-color: var(--bodyText2) !important;
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
    }
  }
  ${(props) =>
    props.fileUpload &&
    css`
      border: 0;
      &:focus {
        outline: none;
        border: none;
      }
    `};
`;

ResizeTextArea.defaultProps = {
  placeholder: '',
};

export default ResizeTextArea;
