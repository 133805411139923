import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { fonts } from 'styles/theme';
import Profile from 'components/atoms/Profile';
import profileDefault from 'resources/profileDefault.png';
import ResizeTextArea from 'components/atoms/ResizeTextArea';
import { ReactComponent as SendBtn } from 'resources/sendBtn.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Plus } from 'resources/plus.svg';
import ReplyUploadedFile from './ReplyUploadedFile';
import UploadedFile from 'components/atoms/UploadedFile';

const ReplyFileUpload = ({
  id,
  data,
  noProfile,
  profile,
  value,
  editValue,
  onTextAreaValue,
  onChange,
  onClick,
  onCancel,
  materialList,
  materialIdList,
  setMaterialIdList,
  tempSavedFiles,
  setTempSavedFiles,
  ...rest
}) => {
  const [active, setActive] = useState('#D6D6D6');
  const { t } = useTranslation('common');

  const handleOnChange = useCallback(
    (e) => {
      e.preventDefault();
      onChange(e.target.value);
    },
    [onChange],
  );

  useEffect(() => {
    if (editValue) {
      onChange(editValue);
      getFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileRef = useRef(null);
  const height = 52;
  const [resizeHeight, setResizeHeight] = useState(height);

  const onFocus = useCallback(() => {
    setActive('#2C62BA');
  }, []);

  const onBlur = useCallback(() => {
    setActive('#D6D6D6');
  }, []);

  const getFocus = useCallback(() => {
    document.getElementById(id)?.focus();
  }, [id]);

  // file upload, delete
  const [previewUrls, setPreviewUrls] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFileChange = (e) => {
    e.preventDefault();
    const nowSelectImgList = e.target.files;

    let fileURLs = [...previewUrls];
    let tempfiles = [...files];

    for (let i = 0; i < nowSelectImgList.length; i++) {
      const nowImgUrl = URL.createObjectURL(nowSelectImgList[i]);
      fileURLs.push(nowImgUrl);
      tempfiles.push(nowSelectImgList[i]);
      // console.log(nowSelectImgList[i]);
      // console.log(nowImgUrl);
    }

    setPreviewUrls(fileURLs);
    setFiles(tempfiles);

    e.target.value = '';
    getFocus();
  };

  useEffect(() => {
    if (materialList) {
      setUploadedFiles(materialList);
      setMaterialIdList(materialList?.map((data) => data.materialId));
    }
  }, [materialList, setMaterialIdList]);

  const handleDeleteWithId = (id) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.materialId !== id));
    setMaterialIdList(materialIdList.filter((item) => item !== id));
    getFocus();
  };

  const handleDeleteWithIndex = (index) => {
    setPreviewUrls(previewUrls.filter((_, i) => i !== index));
    setFiles(files.filter((_, i) => i !== index));
    setTempSavedFiles(tempSavedFiles.filter((_, i) => i !== index));
    getFocus();
  };

  const handleDeleteFile = (deleteId) => {
    if (typeof deleteId !== 'number') handleDeleteWithId(deleteId);
    if (typeof deleteId === 'number') handleDeleteWithIndex(deleteId);
  };

  const handleResizeHeight = (resize) => {
    setResizeHeight(height + resize);
    if (fileRef !== null && fileRef.current !== null) {
      setResizeHeight(height + fileRef.current.clientHeight + 10 + resize);
    }
  };

  useEffect(() => {
    if (fileRef !== null && fileRef.current !== null) {
      setResizeHeight(height + fileRef.current.clientHeight + 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRef.current?.clientHeight]);

  // 첨부파일 있는 경우 높이 조절. useEffect 순서 중요
  useEffect(() => {
    if (materialList?.length > 0) setResizeHeight(168);
  }, [materialList?.length]);

  const handleClickSend = () => {
    setPreviewUrls([]);
    setFiles([]);
    onClick();
  };

  return (
    <Container>
      <ReplyWrapper>
        {!noProfile && (
          <ProfileWrap>
            <Profile profile={profile} />
          </ProfileWrap>
        )}
        <ReplyArea height={resizeHeight} active={active === '#2C62BA'}>
          <ResizeTextArea
            id={id}
            setHeight={44}
            placeholder={t('reply')}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={handleOnChange}
            onTextAreaValue={onTextAreaValue}
            onResize={handleResizeHeight}
            fileUpload
            {...rest}
          />
          {(uploadedFiles?.length > 0 || files.length > 0) && (
            <FilePreviewArea ref={fileRef}>
              {uploadedFiles?.map((file, i) => (
                <div key={`uploaded-file__${i}`}>
                  <ReplyUploadedFile
                    imgType={file.fileType.startsWith('image')}
                    file={{ name: file.fileName, size: file.fileSize }}
                    src={file.fileUrl}
                    id={file.materialId}
                    onDeleteFile={handleDeleteFile}
                  />
                </div>
              ))}
              {files.map((file, i) => (
                <UploadedFile
                  key={`new-file__${i}`}
                  file={file}
                  onSetTempSavedFile={(prev) => setTempSavedFiles(prev)}
                >
                  <ReplyUploadedFile
                    imgType={file.type.startsWith('image')}
                    file={file}
                    src={previewUrls[i]}
                    id={i}
                    onDeleteFile={handleDeleteFile}
                  />
                </UploadedFile>
              ))}
            </FilePreviewArea>
          )}
          <ReplyBtnWrapper>
            <PlusWrapper active={active === '#2C62BA'}>
              <input type="file" multiple={true} onChange={handleFileChange} />
              <Plus stroke={active} />
            </PlusWrapper>
            <CancelSend>
              <Cancel fill={active} onClick={onCancel}>
                {t('cancelb')}
              </Cancel>
              <Send onMouseDown={handleClickSend} fill={active} />
            </CancelSend>
          </ReplyBtnWrapper>
        </ReplyArea>
      </ReplyWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const ReplyWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  position: relative;
`;

const ProfileWrap = styled.div`
  margin: 3px 12px 0 0;
`;
const ReplyArea = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: white;
  position: relative;
  border-radius: 5px;
  ${(props) =>
    props.active
      ? css`
          outline: none;
          border: 1px solid var(--primary);
        `
      : css`
          outline: none;
          border: var(--border);
        `}
`;

const FilePreviewArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: absolute;
  bottom: 47px;
  left: 15px;
`;

const ReplyBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  padding: 10px;
  bottom: 0;
  justify-content: space-between;
`;
const PlusWrapper = styled.label`
  display: flex;
  align-items: center;

  width: 32px;
  height: 32px;
  ${(props) =>
    props.active
      ? css`
          :hover {
            border-radius: 50%;
            background-color: var(--primaryLight);
          }
        `
      : css`
          cursor: default;
        `}

  input {
    display: none;
  }
  svg {
    width: 100%;
    /* height: 16px; */
    align-items: center;
    justify-content: space-between;
  }
`;
const CancelSend = styled.div`
  display: flex;
`;
const Cancel = styled.button`
  background: transparent;
  ${fonts.secondary}
  color: ${(props) => (props.fill === '#2C62BA' ? 'var(--primary)' : 'var(--grayLine)')};
`;
const Send = styled(SendBtn)`
  cursor: pointer;
`;

ReplyFileUpload.defaultPops = {
  profile: profileDefault,
};

export default ReplyFileUpload;
