import React from 'react';
import styled from 'styled-components';
import sideButton from 'resources/sideButton.png';
import sideButtonSel from 'resources/sideButtonSel.png';
import theme from 'styles/theme';

function SideButton({ open, ...rest }) {
  return (
    <ButtonContainer {...rest}>
      <Button src={sideButton} open={open} />
      <Button src={sideButtonSel} open={open} />
    </ButtonContainer>
  );
}
const ButtonContainer = styled.div`
  img:last-child {
    display: none;
  }
  &:hover {
    img:first-child {
      display: none;
    }
    img:last-child {
      display: inline;
    }
  }
`;
const Button = styled.img`
  opacity: 0;
  position: absolute;
  @media ${theme.tablet} {
    opacity: 1;
    transition: opacity 0.2s ease;
    width: 38px;
    height: 38px;
    transform: ${(props) => props.open && 'rotate(180deg)'};
    top: 20px;
    left: ${(props) => (props.open ? '215px' : '295px')};
  }
`;

export default SideButton;
