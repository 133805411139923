import { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

import { ReactComponent as AlertConfirm } from 'resources/alertConfirm.svg';
import alertError from 'resources/alertError.png';
import closeIcon from 'resources/closeIcon.png';
import { useTranslation } from 'react-i18next';

const Alert = ({ width, message, closable, onClose }) => {
  const { t } = useTranslation('common');
  useEffect(() => {
    if (onClose) {
      let timer = setTimeout(() => {
        onClose();
      }, 4000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [onClose]);

  return (
    <Wrapper>
      <AlertContainer width={width}>
        <AlertWrapper>
          <AlertConfirmStyle />
          <AlertMessage>{message ? message : t('requestSuccess')}</AlertMessage>
          {closable && (
            <AlertCloseBtn onClick={onClose ? () => onClose() : null}>
              <img src={closeIcon} alt="alert-close" />
            </AlertCloseBtn>
          )}
        </AlertWrapper>
      </AlertContainer>
    </Wrapper>
  );
};

export default Alert;

export const ErrorAlert = ({ width, message, closable, onClose }) => {
  const { t } = useTranslation('common');
  useEffect(() => {
    if (onClose) {
      let timer = setTimeout(() => {
        onClose();
      }, 4000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [onClose]);

  return (
    <Wrapper>
      <AlertContainer width={width}>
        <ErrorAlertWrapper>
          <ErrorMessageWrap>
            <img src={alertError} alt="alert-error" />
            <ErrorAlertMessage>{message ? message : t('requestFailed')}</ErrorAlertMessage>
          </ErrorMessageWrap>
          {closable && (
            <AlertCloseBtn onClick={onClose ? () => onClose() : null}>
              <img src={closeIcon} alt="alert-close" />
            </AlertCloseBtn>
          )}
        </ErrorAlertWrapper>
      </AlertContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const AlertContainer = styled.div`
  position: fixed;
  margin: 0 auto;
  bottom: 50px;
  z-index: 10;
  @media ${theme.mobile} {
    width: calc(100% - 56px);
  }
  @media ${theme.mobileS} {
    width: 90%;
  }
`;

const AlertWrapper = styled.div`
  height: 66px;
  margin: 25px auto;
  background-color: #ebeff7;
  border-radius: 5px;
  padding: 0 57px 0 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--boxShadow);
  @media ${theme.mobile} {
    height: 54px;
    padding: 0 15px 0 27px;
  }
`;

const ErrorAlertWrapper = styled(AlertWrapper)`
  background-color: rgba(255, 70, 70, 0.2);
`;

const ErrorMessageWrap = styled.div`
  display: flex;
  align-items: center;
`;

const AlertConfirmStyle = styled(AlertConfirm)`
  width: 24px;
  height: 24px;
  @media ${theme.mobile} {
    width: 20px;
    height: 20px;
  }
`;

const AlertMessage = styled.p`
  margin: 0 0 0 9px;
  font-size: 21px;
  line-height: 25px;
  font-weight: 700;
  color: ${theme.colors.primary};
  @media ${theme.mobile} {
    font-size: 14px;
  }
`;

const ErrorAlertMessage = styled(AlertMessage)`
  color: ${theme.colors.error};
  margin: 0 0 0 7px;
`;

const AlertCloseBtn = styled.button`
  padding: 0;
  margin-left: 47px;
  img {
    width: 18px;
    height: 18px;
  }
  @media ${theme.mobile} {
    margin-left: 23px;
    img {
      width: 14px;
      height: 14px;
    }
  }
`;
