import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import theme from 'styles/theme';
import { ReactComponent as ToggleMenu } from 'resources/toggleMenuIcon.svg';
import { ReactComponent as Notification } from 'resources/notification.svg';
import { ReactComponent as MobileHamburger } from 'resources/mobileHamburger.svg';
import { ReactComponent as MobileLogoutIcon } from 'resources/mobileLogoutIcon.svg';

export const NotificationWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  top: 18px;
  right: 28px;
`;

export const LoginedWrapper = styled(NotificationWrapper)`
  top: initial;
  p {
    padding: 7px 11px;
  }
`;

export const NotificationIcon = styled(Notification)`
  cursor: ${(props) => (props.$active ? 'pointer' : 'default')};
`;

export const NotificationBadge = styled.p`
  margin: 0;
  position: absolute;
  top: -7px;
  left: 10px;
  height: 16px;
  background: #ff6161;
  border-radius: 32px;
  padding-inline-start: 4px;
  padding-inline-end: 5px;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  ${(props) => props.len < 10 && 'width: 16px;'}
`;

export const HamburgerIcon = styled(MobileHamburger)`
  cursor: pointer;
  margin-left: 22px;
`;

export const DarkBackground = styled.div`
  background-color: rgba(214, 214, 214, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  transition: all 1s;
  z-index: 99;
`;

export const Drawer = styled.div`
  background-color: white;
  height: 100%;
  width: 268px;
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: scroll;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DrawWrap = styled.div`
  padding: 34px 20px 0 25px;
`;

export const User = styled.div`
  padding: 34px 22px;
  border-bottom: 1px solid ${theme.colors.grayLine};
  display: flex;
  align-items: center;
  p {
    margin: 0;
    font-size: 18px;
    color: ${theme.colors.bodyText2};
    font-weight: 700;
  }
  p:first-child {
    max-width: 110px;
  }
`;

export const MyInfoWrap = styled.div`
  border-bottom: 1px solid ${theme.colors.grayLine};
  padding: 10px 0;
`;

export const MyInfoTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 20px 0 16px;
  border-radius: 5px;
  ${(props) =>
    props.focus === true &&
    css`
      background-color: ${theme.colors.primaryLight};
    `}
`;

export const InfoText = styled.p`
  color: ${theme.colors.grayText};
  font-size: 18px;
  font-weight: 400;
  margin: 0 10px 0 0;
  ${(props) =>
    props.focus === true &&
    css`
      color: ${theme.colors.primary};
      font-weight: 600;
    `}
`;

export const InfoIcon = styled(ToggleMenu)`
  object-fit: contain;
  ${(props) =>
    props.$focus &&
    css`
      transform: rotate(90deg);
      stroke: var(--primary);
    `}
`;

export const MyInfoMenuWrap = styled.div``;

export const MyInfoLink = styled.div`
  height: 48px;
  padding: 14px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: ${theme.colors.grayText};
  border-radius: 5px;
  padding: 0 0 0 36px;
  line-height: 18px;
  ${(props) =>
    props.focus === true &&
    css`
      color: ${theme.colors.primary};
      font-weight: 600;
      background-color: ${theme.colors.primaryLight};
    `}
  &:hover:not([disabled]) {
    background-color: ${theme.colors.listBg};
    color: ${(props) => (props.focus === 'true' ? 'var(--primary)' : 'var(--grayText)')};
  }
`;
export const DevInfoBox = styled.div`
  height: 85px;
  margin: 7px 0 15px 51px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DevInfoText = styled(Link)`
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: ${theme.colors.secondary};
  :hover {
    color: ${theme.colors.primary};
  }
`;

export const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 65px 20px 64px 40px;
`;

export const LogoutIcon = styled(MobileLogoutIcon)`
  object-fit: contain;
  margin: 0 10px 0 0;
`;

export const LogoutText = styled(Link)`
  font-size: 18px;
  font-weight: 400;
  color: ${theme.colors.error};
  margin: 0;
  :hover {
    color: ${theme.colors.error};
  }
`;

export const DropdownWrap = styled.div`
  margin: 25px 0 0 0;
`;
