import React from 'react';
import styled from 'styled-components';

function Tag({ children, ...rest }) {
  return <TagElement {...rest}>{children}</TagElement>;
}

const TagElement = styled.div`
  display: inline-block;
  border-radius: 26px;
  height: 26px;
  padding: 6.5px 10px;
  font-size: 11px;
  font-weight: 600;
  line-height: 13.2px;
  background: var(--background);
  color: var(--primaryLight);
  background: var(--primary);
  & + & {
    margin-left: 7px;
  }
`;

export default Tag;
