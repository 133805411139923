import axios from 'axios';

export const checkAccessTokenValidity = () => {
  const accessTokenExpireTime = JSON.parse(localStorage.getItem('accessTokenExpireTime'));
  const currentTime = new Date().getTime();
  return (
    axios.defaults.headers.common['Authorization'] === undefined ||
    accessTokenExpireTime < currentTime
  );
};

export const checkNeedRefresh = () => {
  const accessTokenExpireTime = JSON.parse(localStorage.getItem('accessTokenExpireTime'));
  const refreshTokenExpireTime = JSON.parse(localStorage.getItem('refreshTokenExpireTime'));
  const currentTime = new Date().getTime();
  return accessTokenExpireTime < currentTime && currentTime < refreshTokenExpireTime;
};

export const checkRefreshTokenValidity = () => {
  const refreshTokenExpireTime = JSON.parse(localStorage.getItem('refreshTokenExpireTime'));
  const currentTime = new Date().getTime();
  return refreshTokenExpireTime < currentTime;
};

export const getAutoRefreshTime = () => {
  const accessTokenExpireTime = JSON.parse(localStorage.getItem('accessTokenExpireTime'));
  const currentTime = new Date().getTime();
  return accessTokenExpireTime - currentTime;
};
