import React from 'react';
import { get } from 'lodash-es';
import Error from 'components/templates/Error';
import PopUp from 'components/molecules/PopUp';
import Button from 'components/atoms/Button';
import { useTranslation } from 'react-i18next';
import authStore from 'stores/AuthStore';
import { checkNeedRefresh } from 'utils/checkTokenValidity';

const ErrorHandler = ({ children, visible, onClose }) => {
  let isNeedRefresh = checkNeedRefresh();
  const refreshTokenCookie = authStore.getCookie('refreshToken');

  const { t } = useTranslation('error');
  const ErrorPopUp = ({ desc }) => {
    return (
      <>
        <PopUp title={t('errorTitle')} desc={desc} visible={visible} btnCenter>
          <Button size="large" onClick={onClose}>
            {t('confirmb')}
          </Button>
        </PopUp>
        {children}
      </>
    );
  };
  // ${t(get(window.history.state, 'errorMessage'))}
  const ErrorStatus = () => {
    if (
      get(window.history.state, 'errorCode') &&
      get(window.history.state, 'errorCode') !== 'network'
    )
      return <ErrorPopUp desc={`${t('errorDesc')}\n\n${get(window.history.state, 'errorCode')}`} />;
    else if (get(window.history.state, 'errorMessage'))
      return (
        <ErrorPopUp desc={`${t('errorDesc')}\n\n${get(window.history.state, 'errorMessage')}`} />
      );
    else return <ErrorPopUp desc={t('errorDesc')} />;
  };

  switch (get(window.history.state, 'errorStatus')) {
    case 400:
      if (get(window.history.state, 'url')?.startsWith('/api/v1/users/refresh-token')) {
        return authStore.signOut('auto');
      }
      return <ErrorStatus />;
    case 401:
      return children;
    case 415:
    case 'file':
      return <ErrorStatus />;
    case 403:
      if (get(window.history.state, 'errorMessage') === 'Access Denied' && isNeedRefresh) {
        authStore.refreshToken(refreshTokenCookie);
        return children;
      }
      if (get(window.history.state, 'errorCode') === 'TOKEN_HAS_BEEN_EXPIRED') {
        return authStore.signOut('auto');
      }
      return <ErrorStatus />;
    case 404:
      return <Error />;

    default:
      return <ErrorStatus />;
  }
};

export default ErrorHandler;
