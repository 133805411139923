import styled from 'styled-components';
import theme, { fonts } from 'styles/theme';

export const Required = styled.span`
  font-size: 14px;
  font-style: bold;
  line-height: 17px;
  color: ${theme.colors.error};
`;

export const InputDesc = styled.div`
  ${fonts.secondary}
  font-size: 11px;
  font-weight: 500;
  margin-top: 5px;
  line-height: inherit;
`;

export const Warning = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: var(--error);
  margin-top: 5px;
  text-align: left;
`;
