import { lazy } from 'react';

export const lazyRetry = (componentImport, name) =>
  lazy(async () => {
    const pageAlreadyRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-lazy-refreshed`) || 'false',
    );

    try {
      const component = await componentImport();
      // 페이지가 올바르게 로드된 경우 리프레시 여부 초기화
      window.sessionStorage.setItem(`retry-lazy-refreshed`, 'false');
      return component;
    } catch (error) {
      // 에러가 발생한 경우 로그에 기록
      console.error('Error loading component:', error);

      // 페이지 리프레시 여부 확인
      if (!pageAlreadyRefreshed) {
        // 리프레시 여부 기록 및 페이지 리프레시
        window.sessionStorage.setItem(`retry-lazy-refreshed`, 'true');
        window.location.reload();
      } else {
        // 리프레시가 이미 시도되었을 경우 에러 던지기
        throw error;
      }
    }
  });
