import Button from 'components/atoms/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'styles/theme';

const RegistrationBtns = ({ data, size, color, border, position }) => {
  return (
    <>
      <Link to={data.projectPath} onClick={() => data.projectOnClick(position)}>
        <ProjButton size={size}>{data.projectBtn}</ProjButton>
      </Link>
      <Link to={data.profilePath} onClick={() => data.profileOnClick(position)}>
        <ProfileButton size={size} color={color} border={border} position={position}>
          {data.profileBtn}
        </ProfileButton>
      </Link>
    </>
  );
};

const ProjButton = styled(Button)`
  width: 240px;
  height: 75px;
  font-size: 22px;
  font-weight: 700;
  line-height: 37px;
  text-align: center;
  box-shadow: var(--boxShadow);
  padding: 0;
  /* padding: 19px 42px; */
  @media ${theme.laptop} {
    width: 200px;
    height: 55px;
    font-size: 20px;
    font-weight: 600;
  }
  @media ${theme.tablet} {
    font-weight: 400;
  }
  @media ${theme.mobile} {
    height: 47px;
    font-size: 16px;
    line-height: 27px;
  }
`;
const ProfileButton = styled(ProjButton)`
  margin-left: 15.11px;
  :hover {
    color: var(--primary);
  }
  @media ${theme.tablet} {
    ${(props) => props.position === 'top' && 'margin-left: 0'};
  }
  @media ${theme.mobile} {
    margin-left: 0;
  }
`;

export default RegistrationBtns;
