import React from 'react';
import styled from 'styled-components';
import theme, { fonts } from 'styles/theme';
import { useTranslation } from 'react-i18next';

const UserSelect = ({ user, onUserSelect }) => {
  const { t } = useTranslation('landing');

  // user select
  const userTabList = t('userSelect.user');

  return (
    <Wrapper>
      <Container>
        <UsersWrapper>
          <Users>{t('userSelect.desc')}</Users>
          <UserTabWrapper>
            {userTabList.map((item, index) => (
              <UserTab key={item} focus={user === index} onClick={() => onUserSelect(index)}>
                {item}
              </UserTab>
            ))}
          </UserTabWrapper>
        </UsersWrapper>
      </Container>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  background: var(--background);
  width: 100vw;
  padding: 0 57px;
  @media ${theme.mobile} {
    padding: 0 28px;
  }
`;
const Container = styled.div`
  width: 100%;
  max-width: 1222px;
  margin: 0px auto;
`;
const UsersWrapper = styled.div`
  padding: 69px 0 0 0;
  @media ${theme.mobile} {
    padding: 71px 0 0 0;
  }
`;
const Users = styled.h1`
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  color: var(--secondary);
  @media ${theme.mobile} {
    ${fonts.m_secondary}
  }
`;
const UserTabWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  div:first-child {
    margin-right: 31px;
  }
  @media ${theme.mobile} {
    margin-top: 17px;
    div:first-child {
      margin-right: 15px;
    }
  }
`;
const UserTab = styled.div`
  cursor: pointer;
  font-size: 30px;
  font-weight: 600;
  line-height: 51px;
  color: ${(props) => (props.focus ? 'var(--primary)' : 'var(--secondary)')};
  @media ${theme.mobile} {
    font-size: 20px;
    font-weight: ${(props) => (props.focus ? '700' : '400')};
    line-height: 28px;
  }
`;

export default UserSelect;
