import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { workStatusArray } from 'data/constanst';

function StatusBtn({ children, ...rest }) {
  return <Status {...rest}>{children}</Status>;
}

export const PriorityTypeBtn = ({ priorityType, onClick, editable }) => {
  const { t } = useTranslation('maintenance');

  return (
    <StatusBtn
      onClick={editable ? onClick : () => {}}
      editable={editable}
      state={
        priorityType.includes('HIGH')
          ? 'emphasis'
          : priorityType.includes('MEDIUM')
          ? 'check'
          : 'review'
      }
    >
      {t('issueDetail').priorityTypes.filter((i) => i.code === priorityType)[0].value}
    </StatusBtn>
  );
};

export const WorkStatusBtn = ({ status, onClick, editable }) => {
  const { t } = useTranslation('maintenance');

  return (
    <StatusBtn
      onClick={editable ? onClick : () => {}}
      editable={editable}
      state={
        workStatusArray.includes(status, 9)
          ? 'complete'
          : workStatusArray.includes(status, 5)
          ? 'review'
          : 'check'
      }
    >
      {t('issueDetail').workStatuses.filter((i) => i.code === status)[0]?.value}
    </StatusBtn>
  );
};

export const WorkTypeBtn = ({ type, onClick, editable }) => {
  const { t } = useTranslation('maintenance');

  return <IssueType type={type}>{type}</IssueType>;
};

export const MilestoneStatusBtn = ({ status, onClick, editable, ...props }) => {
  const { t } = useTranslation('project');

  return (
    <StatusBtn
      {...props}
      onClick={editable ? onClick : () => {}}
      editable={editable}
      state={
        t('milestone')
          .statusOptions.map((i) => i.code)
          .includes(status, 7)
          ? 'complete'
          : t('milestone')
              .statusOptions.map((i) => i.code)
              .includes(status, 2)
          ? 'review'
          : 'check'
      }
    >
      {t('milestone').statusOptions.filter((i) => i.code === status)[0]?.name}
    </StatusBtn>
  );
};

const status = {
  emphasis: {
    color: 'var(--red)',
    background: 'var(--redLight)',
  },
  check: {
    color: 'var(--yellow)',
    background: 'var(--yellowLight)',
  },
  review: {
    color: 'var(--green)',
    background: 'var(--greenLight)',
  },
  complete: {
    color: 'var(--skyBlue)',
    background: 'var(--skyBlueLight)',
  },
};

const statusStyles = css`
  ${({ state }) => css`
    color: ${status[state].color};
    background: ${status[state].background};
  `}
`;

const Status = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  padding: 6px 7px 6px 7px;
  height: 26px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;

  ${statusStyles}

  & + & {
    margin-left: 7px;
  }

  ${(props) =>
    props.editable &&
    css`
      cursor: pointer;
    `}

  ${(props) =>
    props.ml &&
    css`
      margin-left: ${props.ml}px;
    `}
`;

StatusBtn.defaultProps = {
  state: 'check',
};

const IssueType = styled.div`
  display: inline-block;
  padding: 3px 10px;
  border-radius: 8px;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 19px;
  text-transform: uppercase;
  ${(props) => props.type === 'NEW' && 'color: var(--yellow);background: var(--yellowLight);'}
  ${(props) => props.type === 'FEATURE' && 'background-color: #c6f6d5;color: #276749;'}
  ${(props) =>
    props.type === 'CHANGED' && 'background-color: rgb(222, 235, 255);color: rgb(0, 82, 204);'}
  ${(props) => props.type === 'FIXED' && 'color: var(--skyBlue);background: var(--skyBlueLight);'}
  ${(props) =>
    props.type === 'DEPRECATED' && 'background-color: rgb(255, 235, 230);color: rgb(222, 55, 10);'}
  ${(props) => props.type === 'ETC' && 'background-color: #ddd;color: #666;'}
`;

export default StatusBtn;
