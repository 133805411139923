export const stackData = ['HTML', 'CSS', 'CSS3', 'C#', 'C++', 'C/C++', 'crawling'];

export const projectStatusArray = [
  'REQUESTED',
  'IN_PROPOSAL',
  'PROPOSAL_CONFIRMED',
  'FINDING_DEVELOPER',
  'MATCHING',
  'NO_MATCHING_RESULT',
  'NEED_DOCUMENT',
  'PREPARING_CONTRACT',
  'IN_CONTRACT',
  'PENDING_TAX_INVOICE',
  'PENDING_PAYMENT',
  'PENDING',
  'IN_PROGRESS',
  'COMPLETED',
  'CANCELLED',
];

export const workStatusArray = [
  'NONE',
  'DRAFT',
  'REGISTER',
  'ANALYZING',
  'DEFERRED',
  'PENDING_WORK',
  'IN_PROGRESS',
  'DEV_WORK_DONE',
  'TESTING',
  'PENDING_DEPLOYMENT',
  'COMPLETED',
];

export const workTypeArray = ['NEW', 'FEATURE', 'CHANGED', 'FIXED', 'DEPRECATED', 'ETC'];

export const memoryOptions = [
  { name: '100MB', value: 100 },
  { name: '250MB', value: 250 },
  { name: '500MB', value: 500 },
  { name: '1GB', value: 1024 },
  { name: '1.5GB', value: 1536 },
  { name: '2GB', value: 2048 },
  { name: '3GB', value: 2048 },
  { name: '4GB', value: 2048 },
];

export const cpuOptions = [
  { name: '0.5', value: 0.5 },
  { name: '1', value: 1 },
  { name: '1.5', value: 1.5 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
];

export const volumeOptions = [
  { name: '2GiB', value: 2 },
  { name: '8GiB', value: 8 },
  { name: '20GiB', value: 20 },
  { name: '50GiB', value: 50 },
  { name: '100GiB', value: 100 },
];

export const contryOptions = [
  {
    name: 'Afghanistan',
    value: 'AF',
  },
  {
    name: 'Albania',
    value: 'AL',
  },
  {
    name: 'Algeria',
    value: 'DZ',
  },
  {
    name: 'American Samoa',
    value: 'AS',
  },
  {
    name: 'Andorra',
    value: 'AD',
  },
  {
    name: 'Angola',
    value: 'AO',
  },
  {
    name: 'Anguilla',
    value: 'AI',
  },
  {
    name: 'Argentina',
    value: 'AR',
  },
  {
    name: 'Armenia',
    value: 'AM',
  },
  {
    name: 'Aruba',
    value: 'AW',
  },
  {
    name: 'Australia',
    value: 'AU',
  },
  {
    name: 'Austria',
    value: 'AT',
  },
  {
    name: 'Azerbaijan',
    value: 'AZ',
  },
  {
    name: 'Bahamas',
    value: 'BS',
  },
  {
    name: 'Bahrain',
    value: 'BH',
  },
  {
    name: 'Bangladesh',
    value: 'BD',
  },
  {
    name: 'Barbados',
    value: 'BB',
  },
  {
    name: 'Belarus',
    value: 'BY',
  },
  {
    name: 'Belgium',
    value: 'BE',
  },
  {
    name: 'Belize',
    value: 'BZ',
  },
  {
    name: 'Benin',
    value: 'BJ',
  },
  {
    name: 'Bermuda',
    value: 'BM',
  },
  {
    name: 'Bhutan',
    value: 'BT',
  },
  {
    name: 'Bolivia',
    value: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 'BA',
  },
  {
    name: 'Botswana',
    value: 'BW',
  },
  {
    name: 'Brazil',
    value: 'BR',
  },
  {
    name: 'British Virgin Islands',
    value: 'VG',
  },
  {
    name: 'Brunei',
    value: 'BN',
  },
  {
    name: 'Bulgaria',
    value: 'BG',
  },
  {
    name: 'Burkina Faso',
    value: 'BF',
  },
  {
    name: 'Burundi',
    value: 'BI',
  },
  {
    name: 'Cambodia',
    value: 'KH',
  },
  {
    name: 'Cameroon',
    value: 'CM',
  },
  {
    name: 'Canada',
    value: 'CA',
  },
  {
    name: 'Cape Verde',
    value: 'CV',
  },
  {
    name: 'Cayman Islands',
    value: 'KY',
  },
  {
    name: 'Central African Republic',
    value: 'CF',
  },
  {
    name: 'Chad',
    value: 'TD',
  },
  {
    name: 'Chile',
    value: 'CL',
  },
  {
    name: 'China',
    value: 'CN',
  },
  {
    name: 'Columbia',
    value: 'CO',
  },
  {
    name: 'Comoros',
    value: 'KM',
  },
  {
    name: 'Cook Islands',
    value: 'CK',
  },
  {
    name: 'Costa Rica',
    value: 'CR',
  },
  {
    name: 'Croatia',
    value: 'HR',
  },
  {
    name: 'Cuba',
    value: 'CU',
  },
  {
    name: 'Curacao',
    value: 'CW',
  },
  {
    name: 'Cyprus',
    value: 'CY',
  },
  {
    name: 'Czech Republic',
    value: 'CZ',
  },
  {
    name: 'Democratic Republic of the Congo',
    value: 'CD',
  },
  {
    name: 'Denmark',
    value: 'DK',
  },
  {
    name: 'Djibouti',
    value: 'DJ',
  },
  {
    name: 'Dominica',
    value: 'DM',
  },
  {
    name: 'Dominican Republic',
    value: 'DO',
  },
  {
    name: 'East Timor',
    value: 'TL',
  },
  {
    name: 'Ecuador',
    value: 'EC',
  },
  {
    name: 'Egypt',
    value: 'EG',
  },
  {
    name: 'El Salvador',
    value: 'SV',
  },
  {
    name: 'Eritrea',
    value: 'ER',
  },
  {
    name: 'Estonia',
    value: 'EE',
  },
  {
    name: 'Ethiopia',
    value: 'ET',
  },
  {
    name: 'Faroe Islands',
    value: 'FO',
  },
  {
    name: 'Fiji',
    value: 'FJ',
  },
  {
    name: 'Finland',
    value: 'FI',
  },
  {
    name: 'France',
    value: 'FR',
  },
  {
    name: 'French Polynesia',
    value: 'PF',
  },
  {
    name: 'Gabon',
    value: 'GA',
  },
  {
    name: 'Gambia',
    value: 'GM',
  },
  {
    name: 'Georgia',
    value: 'GE',
  },
  {
    name: 'Germany',
    value: 'DE',
  },
  {
    name: 'Ghana',
    value: 'GH',
  },
  {
    name: 'Greece',
    value: 'GR',
  },
  {
    name: 'Greenland',
    value: 'GL',
  },
  {
    name: 'Grenada',
    value: 'GD',
  },
  {
    name: 'Guam',
    value: 'GU',
  },
  {
    name: 'Guatemala',
    value: 'GT',
  },
  {
    name: 'Guernsey',
    value: 'GG',
  },
  {
    name: 'Guinea',
    value: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    value: 'GW',
  },
  {
    name: 'Guyana',
    value: 'GY',
  },
  {
    name: 'Haiti',
    value: 'HT',
  },
  {
    name: 'Honduras',
    value: 'HN',
  },
  {
    name: 'Hong Kong',
    value: 'HK',
  },
  {
    name: 'Hungary',
    value: 'HU',
  },
  {
    name: 'Iceland',
    value: 'IS',
  },
  {
    name: 'India',
    value: 'IN',
  },
  {
    name: 'Indonesia',
    value: 'ID',
  },
  {
    name: 'Iran',
    value: 'IR',
  },
  {
    name: 'Iraq',
    value: 'IQ',
  },
  {
    name: 'Ireland',
    value: 'IE',
  },
  {
    name: 'Isle of Man',
    value: 'IM',
  },
  {
    name: 'Israel',
    value: 'IL',
  },
  {
    name: 'Italy',
    value: 'IT',
  },
  {
    name: 'Ivory Coast',
    value: 'CI',
  },
  {
    name: 'Jamaica',
    value: 'JM',
  },
  {
    name: 'Japan',
    value: 'JP',
  },
  {
    name: 'Jersey',
    value: 'JE',
  },
  {
    name: 'Jordan',
    value: 'JO',
  },
  {
    name: 'Kazakhstan',
    value: 'KZ',
  },
  {
    name: 'Kenya',
    value: 'KE',
  },
  {
    name: 'Kiribati',
    value: 'KI',
  },
  {
    name: 'Kosovo',
    value: 'XK',
  },
  {
    name: 'Kuwait',
    value: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    value: 'KG',
  },
  {
    name: 'Laos',
    value: 'LA',
  },
  {
    name: 'Latvia',
    value: 'LV',
  },
  {
    name: 'Lebanon',
    value: 'LB',
  },
  {
    name: 'Lesotho',
    value: 'LS',
  },
  {
    name: 'Liberia',
    value: 'LR',
  },
  {
    name: 'Libya',
    value: 'LY',
  },
  {
    name: 'Liechtenstein',
    value: 'LI',
  },
  {
    name: 'Lithuania',
    value: 'LT',
  },
  {
    name: 'Luxembourg',
    value: 'LU',
  },
  {
    name: 'Macau',
    value: 'MO',
  },
  {
    name: 'Macedonia',
    value: 'MK',
  },
  {
    name: 'Madagascar',
    value: 'MG',
  },
  {
    name: 'Malawi',
    value: 'MW',
  },
  {
    name: 'Malaysia',
    value: 'MY',
  },
  {
    name: 'Maldives',
    value: 'MV',
  },
  {
    name: 'Mali',
    value: 'ML',
  },
  {
    name: 'Malta',
    value: 'MT',
  },
  {
    name: 'Marshall Islands',
    value: 'MH',
  },
  {
    name: 'Mauritania',
    value: 'MR',
  },
  {
    name: 'Mauritius',
    value: 'MU',
  },
  {
    name: 'Mayotte',
    value: 'YT',
  },
  {
    name: 'Mexico',
    value: 'MX',
  },
  {
    name: 'Micronesia',
    value: 'FM',
  },
  {
    name: 'Moldova',
    value: 'MD',
  },
  {
    name: 'Monaco',
    value: 'MC',
  },
  {
    name: 'Mongolia',
    value: 'MN',
  },
  {
    name: 'Montenegro',
    value: 'ME',
  },
  {
    name: 'Morocco',
    value: 'MA',
  },
  {
    name: 'Mozambique',
    value: 'MZ',
  },
  {
    name: 'Myanmar',
    value: 'MM',
  },
  {
    name: 'Namibia',
    value: 'NA',
  },
  {
    name: 'Nepal',
    value: 'NP',
  },
  {
    name: 'Netherlands',
    value: 'NL',
  },
  {
    name: 'Netherlands Antilles',
    value: 'AN',
  },
  {
    name: 'New Caledonia',
    value: 'NC',
  },
  {
    name: 'New Zealand',
    value: 'NZ',
  },
  {
    name: 'Nicaragua',
    value: 'NI',
  },
  {
    name: 'Niger',
    value: 'NE',
  },
  {
    name: 'Nigeria',
    value: 'NG',
  },
  {
    name: 'North Korea',
    value: 'KP',
  },
  {
    name: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    name: 'Norway',
    value: 'NO',
  },
  {
    name: 'Oman',
    value: 'OM',
  },
  {
    name: 'Pakistan',
    value: 'PK',
  },
  {
    name: 'Palestine',
    value: 'PS',
  },
  {
    name: 'Panama',
    value: 'PA',
  },
  {
    name: 'Papua New Guinea',
    value: 'PG',
  },
  {
    name: 'Paraguay',
    value: 'PY',
  },
  {
    name: 'Peru',
    value: 'PE',
  },
  {
    name: 'Philippines',
    value: 'PH',
  },
  {
    name: 'Poland',
    value: 'PL',
  },
  {
    name: 'Portugal',
    value: 'PT',
  },
  {
    name: 'Puerto Rico',
    value: 'PR',
  },
  {
    name: 'Qatar',
    value: 'QA',
  },
  {
    name: 'Republic of the Congo',
    value: 'CG',
  },
  {
    name: 'Reunion',
    value: 'RE',
  },
  {
    name: 'Romania',
    value: 'RO',
  },
  {
    name: 'Russia',
    value: 'RU',
  },
  {
    name: 'Rwanda',
    value: 'RW',
  },
  {
    name: 'Saint Kitts and Nevis',
    value: 'KN',
  },
  {
    name: 'Saint Lucia',
    value: 'LC',
  },
  {
    name: 'Saint Martin',
    value: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: 'VC',
  },
  {
    name: 'Samoa',
    value: 'WS',
  },
  {
    name: 'San Marino',
    value: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    value: 'ST',
  },
  {
    name: 'Saudi Arabia',
    value: 'SA',
  },
  {
    name: 'Senegal',
    value: 'SN',
  },
  {
    name: 'Serbia',
    value: 'RS',
  },
  {
    name: 'Seychelles',
    value: 'SC',
  },
  {
    name: 'Sierra Leone',
    value: 'SL',
  },
  {
    name: 'Singapore',
    value: 'SG',
  },
  {
    name: 'Sint Maarten',
    value: 'SX',
  },
  {
    name: 'Slovakia',
    value: 'SK',
  },
  {
    name: 'Slovenia',
    value: 'SI',
  },
  {
    name: 'Solomon Islands',
    value: 'SB',
  },
  {
    name: 'Somalia',
    value: 'SO',
  },
  {
    name: 'South Africa',
    value: 'ZA',
  },
  {
    name: 'South Korea',
    value: 'KR',
  },
  {
    name: 'South Sudan',
    value: 'SS',
  },
  {
    name: 'Spain',
    value: 'ES',
  },
  {
    name: 'Sri Lanka',
    value: 'LK',
  },
  {
    name: 'Sudan',
    value: 'SD',
  },
  {
    name: 'Suriname',
    value: 'SR',
  },
  {
    name: 'Swaziland',
    value: 'SZ',
  },
  {
    name: 'Sweden',
    value: 'SE',
  },
  {
    name: 'Switzerland',
    value: 'CH',
  },
  {
    name: 'Syria',
    value: 'SY',
  },
  {
    name: 'Taiwan',
    value: 'TW',
  },
  {
    name: 'Tajikistan',
    value: 'TJ',
  },
  {
    name: 'Tanzania',
    value: 'TZ',
  },
  {
    name: 'Thailand',
    value: 'TH',
  },
  {
    name: 'Togo',
    value: 'TG',
  },
  {
    name: 'Tonga',
    value: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    value: 'TT',
  },
  {
    name: 'Tunisia',
    value: 'TN',
  },
  {
    name: 'Turkey',
    value: 'TR',
  },
  {
    name: 'Turkmenistan',
    value: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    value: 'TC',
  },
  {
    name: 'Tuvalu',
    value: 'TV',
  },
  {
    name: 'U.S. Virgin Islands',
    value: 'VI',
  },
  {
    name: 'Uganda',
    value: 'UG',
  },
  {
    name: 'Ukraine',
    value: 'UA',
  },
  {
    name: 'United Arab Emirates',
    value: 'AE',
  },
  {
    name: 'United Kingdom',
    value: 'GB',
  },
  {
    name: 'United States',
    value: 'US',
  },
  {
    name: 'Uruguay',
    value: 'UY',
  },
  {
    name: 'Uzbekistan',
    value: 'UZ',
  },
  {
    name: 'Vanuatu',
    value: 'VU',
  },
  {
    name: 'Venezuela',
    value: 'VE',
  },
  {
    name: 'Vietnam',
    value: 'VN',
  },
  {
    name: 'Western Sahara',
    value: 'EH',
  },
  {
    name: 'Yemen',
    value: 'YE',
  },
  {
    name: 'Zambia',
    value: 'ZM',
  },
  {
    name: 'Zimbabwe',
    value: 'ZW',
  },
];

export const domainRecordTypeOptions = [
  { value: 'SOA', name: 'SOA' },
  { value: 'A', name: 'A' },
  { value: 'TXT', name: 'TXT' },
  { value: 'NS', name: 'NS' },
  { value: 'CNAME', name: 'CNAME' },
  { value: 'MX', name: 'MX' },
];
