import { makeAutoObservable } from 'mobx';

class DatePickerStore {
  startDate = localStorage.getItem('expectedStartDate')
    ? new Date(localStorage.getItem('expectedStartDate'))
    : '';
  endDate = localStorage.getItem('expectedDueDate')
    ? new Date(localStorage.getItem('expectedDueDate'))
    : '';
  startNow = JSON.parse(localStorage.getItem('startNow')) || false;
  endUndefined = JSON.parse(localStorage.getItem('undefined')) || false;

  constructor() {
    makeAutoObservable(this);
  }

  setStartDate = (param) => {
    this.startDate = param;
  };

  setEndDate = (param) => {
    this.endDate = param;
  };

  setStartNow = (param) => {
    this.startNow = param;
  };

  setUndefined = (param) => {
    this.endUndefined = param;
  };
}

const datePickerStore = new DatePickerStore();

export default datePickerStore;
