import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme, { fonts } from 'styles/theme';
import { ReactComponent as World } from 'resources/world.svg';
import useDetectOutsideClick from 'utils/useDetectOutsideClick';
import { DropdownMenu } from './Dropdown';
import { Mobile, Others } from 'styles/MediaQuery';
import { getLanguageCode } from 'utils/LanguageUtil';

const LangSwitcher = () => {
  const { t, i18n } = useTranslation('landing');
  const [lang, setLang] = useState(t(`languages.${i18n.language}`));

  useEffect(() => {
    const langCode = getLanguageCode(i18n.language);
    setLang(t(`languages.${langCode}`));
  }, [i18n.language, t]);

  const langRef = useRef(null);
  const [langVisible, setLangVisible] = useDetectOutsideClick(langRef, false);
  const handleClickLanguage = () => {
    setLangVisible(!langVisible);
  };

  return (
    <>
      <Others>
        <LanguageWrapper onClick={handleClickLanguage} ref={langRef}>
          <World stroke={'var(--bodyText)'} width="24" height="24" />
          <span>{lang}</span>
          <DropdownMenu visible={langVisible} onClose={handleClickLanguage} />
        </LanguageWrapper>
      </Others>
      <Mobile>
        <LanguageWrapper onClick={handleClickLanguage} ref={langRef}>
          <World stroke={'var(--bodyText)'} width="21" height="21" />
          <DropdownMenuPosition>
            <DropdownMenu visible={langVisible} onClose={handleClickLanguage} />
          </DropdownMenuPosition>
        </LanguageWrapper>
      </Mobile>
    </>
  );
};

const LanguageWrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  ${fonts.body}
  span {
    margin-left: 5px;
  }
  @media ${theme.mobile} {
    margin-right: 20px;
  }
`;

const DropdownMenuPosition = styled.div`
  position: absolute;
  left: -30px;
`;

export default LangSwitcher;
