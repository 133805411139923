import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Arrow } from 'resources/arrow.svg';
import { useHistory } from 'react-router-dom';
import theme from 'styles/theme';

const BreadcrombTrail = ({ trailList, current, onClick }) => {
  const history = useHistory();

  const handleGoback = () => {
    history.goBack();
  };

  return (
    <BreadcrombTrailContainer>
      <Goback onClick={onClick || handleGoback} />
      {trailList?.map(
        (item, i) => item !== false && <Previous key={`trail__${i}`}>{item} /</Previous>,
      )}
      <Current>{current}</Current>
    </BreadcrombTrailContainer>
  );
};
const BreadcrombTrailContainer = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 28px;
  z-index: 2;
  @media ${theme.mobile} {
    top: 62px;
    width: 100%;
    background: var(--background);
  }
`;
const Goback = styled(Arrow)`
  margin-right: 8px;
  cursor: pointer;
`;
const Previous = styled.span`
  margin-right: 3px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: var(--grayText2);
`;
const Current = styled.span`
  color: var(--bodyText2);
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${theme.laptop} {
    width: 100px;
  }

  @media ${theme.mobile} {
    width: 180px;
  }
`;
export default BreadcrombTrail;
