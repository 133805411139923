import React from 'react';
import styled from 'styled-components';
import headerImgA from 'images/headerImage.avif';
import headerImgW from 'images/headerImage.webp';
import headerImg from 'images/headerImage.png';
import mobileHeaderImgA from 'images/m_headerImage.avif';
import mobileHeaderImgW from 'images/m_headerImage.webp';
import mobileHeaderImg from 'images/m_headerImage.png';
import theme from 'styles/theme';
import { Tablet, Large } from 'styles/MediaQuery';
import RegistrationBtns from './RegistrationBtns';
import { useTranslation } from 'react-i18next';

const Header = ({ data }) => {
  const { t } = useTranslation('landing');

  return (
    <Container>
      <TitleWrapper>
        <Headline>
          <span>{t('header.headline')[0]}</span>
          <span>{t('header.headline')[1]}</span>
        </Headline>
        <SubHeadline>
          <span>{t('header.sub')[0]}</span> {t('header.sub')[1]}
        </SubHeadline>
        <Large>
          <BtnWrapper>
            <RegistrationBtns
              data={data}
              size="medium"
              color="background"
              border="gray"
              position="top"
            />
          </BtnWrapper>
        </Large>
      </TitleWrapper>
      <Large>
        <ImageWrapper>
          <source srcSet={headerImgA} type="image/avif" />
          <source srcSet={headerImgW} type="image/webp" />
          <Img src={headerImg} alt="header-img" />
        </ImageWrapper>
      </Large>
      <Tablet>
        <ImageWrapper>
          <source srcSet={mobileHeaderImgA} type="image/avif" />
          <source srcSet={mobileHeaderImgW} type="image/webp" />
          <MobileImage src={mobileHeaderImg} alt="m-header-img" />
        </ImageWrapper>
      </Tablet>
      <Tablet>
        <BtnWrapper>
          <RegistrationBtns data={data} size="fullWidth" color="white" border position="top" />
        </BtnWrapper>
      </Tablet>
    </Container>
  );
};

const Container = styled.div`
  min-height: 696px; /* second  */
  padding: 16px 0 13px 57px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--background);
  @media ${theme.tablet} {
    padding: 74px 57px 0 57px;
    flex-direction: column;
    justify-content: center;
  }
  @media ${theme.mobile} {
    padding: 51px 28px 0;
  }
  @media ${theme.mobileS} {
    padding: 0 20px;
  }
`;
const TitleWrapper = styled.div`
  margin-right: 10%;
  min-width: 553px;
  display: flex;
  flex-direction: column;
  @media ${theme.second} {
    margin-right: 5%;
  }
  @media ${theme.laptop} {
    margin-right: 10px;
    min-width: 472px;
  }
  @media ${theme.tablet} {
    min-width: 0;
    width: 100%;
    margin-right: 0;
  }
`;
const Headline = styled.h1`
  font-size: 55px;
  font-weight: 700;
  line-height: 85.25px;
  color: var(--primary);
  display: flex;
  flex-direction: column;
  @media ${theme.laptop} {
    font-size: 47px;
  }
  @media ${theme.tablet} {
    text-align: center;
  }
  @media ${theme.mobile} {
    font-size: 30px;
    line-height: 53px;
  }
  @media ${theme.mobileS} {
    font-size: 27px;
    line-height: 44px;
  }
`;
const SubHeadline = styled.div`
  margin-top: 19px;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.49px;
  text-align: left;
  color: var(--title);
  @media ${theme.tablet} {
    margin-top: 14px;
    text-align: center;
  }
  @media ${theme.mobile} {
    font-size: 16px;
    line-height: 27px;
    display: flex;
    flex-direction: column;
  }
  @media ${theme.mobileS} {
    font-size: 14px;
    line-height: 25px;
  }
`;
const BtnWrapper = styled.div`
  display: flex;
  margin-top: 102px;
  @media ${theme.laptop} {
    margin-top: 62px;
  }
  @media ${theme.tablet} {
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding-bottom: 44px;
    a:last-child {
      margin-top: 17px;
    }
  }
`;

const ImageWrapper = styled.picture`
  display: flex;
  @media ${theme.tablet} {
    padding: 17px 0 102px 0;
  }
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
`;
const MobileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: var(--boxShadow);
`;

export default Header;
