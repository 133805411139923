import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';

const QuestionBtn = ({ ...rest }) => {
  const { t } = useTranslation('common');

  return <Btn {...rest}>{t('questionBtn')}</Btn>;
};

const Btn = styled.button`
  width: 100%;
  height: 81px;
  margin-left: 18px;
  border-radius: 5px;
  box-sizing: border-box;
  border: var(--border);
  box-shadow: var(--borderDropShadow);
  padding: 25px 0px 24px 38px;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  color: var(--secondary);
  background: white;
  &:hover {
    background: var(--listBg);
  }
  @media ${theme.mobile} {
    height: 56px;
    font-size: 18px;
    line-height: 21.6px;
    padding: 16px 0px 16px 17px;
  }
`;

export default QuestionBtn;
