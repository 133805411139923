import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import setAuthToken from 'api/setAuthtoken';
import { Cookies } from 'react-cookie';
import { analytics } from 'fBase';

import {
  signInApi,
  signUpApi,
  refreshTokenApi,
  postEmailVerificationApi,
  getEmailVerificationApi,
  postCellphoneVerificationApi,
  getCellphoneVerificationApi,
  sendPasswordResetCodeApi,
  resetPasswordApi,
  getUserApi,
  resignMembershipApi,
} from 'api/API';
import proposalFormStore from './ProposalFormStore';
import replaceErrorState from 'api/replaceErrorState';

class AuthStore {
  cookies = new Cookies();
  loading = false;
  userInfo = {};

  signUpBeforeProposalForm = false;
  signInBeforeProposalForm = false;

  signInError = false;
  withdrawError = false;

  constructor() {
    makeAutoObservable(this);
  }

  getCookie = (name) => {
    return this.cookies.get(name);
  };

  setCookie = (name, value) => {
    if (process.env.REACT_APP_MODE === 'production') {
      this.cookies.set(name, value, {
        path: '/',
        secure: true,
        // httpOnly: true,
      });
    } else {
      this.cookies.set(name, value, {
        path: '/',
      });
    }
  };

  setSignInData = (data, stayLoggedIn) => {
    localStorage.removeItem('autoLogout');
    const { accessToken, refreshToken } = data;
    setAuthToken(accessToken);
    localStorage.setItem('accessTokenExpireTime', data.accessTokenExpireTime);
    this.setCookie('refreshToken', refreshToken);
    localStorage.setItem('refreshTokenExpireTime', data.refreshTokenExpireTime);
    localStorage.setItem('stayLoggedIn', stayLoggedIn);
    localStorage.setItem('loggedIn', true);
  };

  removeSignInData = () => {
    localStorage.removeItem('accessTokenExpireTime');
    this.cookies.remove('refreshToken');
    localStorage.removeItem('refreshTokenExpireTime');
    localStorage.removeItem('stayLoggedIn');
    localStorage.setItem('loggedIn', false);
    this.userInfo = {};
  };

  isAuthenticated = () => {
    return axios.defaults.headers.common['Authorization'] !== undefined;
  };

  authError = (error) => {
    if (error.response?.status === 401) {
      this.signInError = true;
    } else {
      replaceErrorState(error, 'network', 'network');
    }
    this.hideLoading();
  };

  changeUserInfo = (e, directName, directValue) => {
    if (e) {
      const { name, value } = e.target;
      this.userInfo = { ...this.userInfo, [name]: value };
    }
    if (!e) {
      this.userInfo = { ...this.userInfo, [directName]: directValue };
    }
  };

  showLoading = () => {
    this.loading = true;
  };

  hideLoading = () => {
    this.loading = false;
  };

  togglewithdrawError = () => {
    this.withdrawError = !this.withdrawError;
  };

  setSignUpBeforeProposalForm = (param) => {
    this.signUpBeforeProposalForm = param;
  };

  setSignInBeforeProposalForm = (param) => {
    this.signInBeforeProposalForm = param;
  };

  signUp = async (body, email, password) => {
    this.showLoading();
    await signUpApi(body)
      .then((response) => {
        this.signInAfterSignUp({ email, password });
        if (process.env.REACT_APP_MODE === 'production') {
          analytics.logEvent('sign_up');
        }
      })
      .catch((error) => {
        console.log(error);
        this.hideLoading();
      });
  };

  signIn = async (body, from, stayLoggedIn) => {
    this.showLoading();
    this.signInError = false;
    await signInApi(body)
      .then((res) => {
        this.setSignInData(res.data, stayLoggedIn);
        // console.log(axios.defaults.headers.common['Authorization']);

        if (from !== undefined) {
          window.location.href = from;
        } else window.location.href = '/';
      })
      .catch((error) => {
        this.authError(error);
      });
  };

  signInAfterSignUp = async (body) => {
    await signInApi(body)
      .then((res) => {
        localStorage.setItem('firstVisit', true);
        this.setSignInData(res.data);

        window.location.href = '/';
      })
      .catch((error) => {
        this.authError(error);
      });
  };

  signUpWithProposalForm = async (body, email, password) => {
    this.showLoading();
    await signUpApi(body)
      .then((response) => {
        localStorage.setItem('firstVisit', true);
        this.signInWithProposalForm({ email, password });
        if (process.env.REACT_APP_MODE === 'production') {
          analytics.logEvent('sign_up');
        }
      })
      .catch((error) => {
        console.log(error);
        this.hideLoading();
      });
  };

  signInWithProposalForm = async (body, stayLoggedIn) => {
    if (!this.loading) this.showLoading();
    this.signInError = false;
    await signInApi(body)
      .then((res) => {
        this.setSignInData(res.data, stayLoggedIn);

        proposalFormStore.registerProposalForm(res.data.accessToken);
      })
      .catch((error) => {
        this.authError(error);
      });
  };

  refreshToken = async (refreshTokenCookie) => {
    const stayLoggedIn = JSON.parse(localStorage.getItem('stayLoggedIn'));
    await refreshTokenApi(refreshTokenCookie)
      .then((res) => {
        this.setSignInData(res.data, stayLoggedIn);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getUser = async () => {
    await getUserApi()
      .then((res) => {
        runInAction(() => {
          this.userInfo = res.data;
          analytics.setUserId(res.data.id);
        });
      })
      .catch((error) => {
        // replaceErrorState(error);
      });
  };

  postEmailVerification = async (body) => {
    let result = '';
    await postEmailVerificationApi(body)
      .then((res) => {
        result = res.data.operationResult;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          result = error.response.data.code;
        }
      });
    return result;
  };

  getEmailVerification = async (code, email) => {
    let result = '';
    await getEmailVerificationApi(code, email)
      .then((res) => {
        result = res.data.operationResult;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          result = error.response.data.httpStatus;
        }
      });
    return result;
  };

  postCellphoneVerification = async (body) => {
    let result = '';
    await postCellphoneVerificationApi(body)
      .then((res) => {
        result = res.data.operationResult;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          result = error.response.data.code;
        }
      });
    return result;
  };

  getCellphoneVerification = async (code, cellphone) => {
    let result = '';
    await getCellphoneVerificationApi(code, cellphone)
      .then((res) => {
        result = res.data.operationResult;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          result = error.response.data.httpStatus;
        }
      });
    return result;
  };

  sendPasswordResetCode = async (email) => {
    let result = '';
    await sendPasswordResetCodeApi(email)
      .then((res) => {
        result = res.data.operationResult;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          result = error.response.data.code;
        }
      });
    return result;
  };

  resetPassword = async (code, email, password) => {
    this.showLoading();
    await resetPasswordApi(code, email, password)
      .then((res) => {
        window.location.href = '/login';
      })
      .catch((error) => {
        console.log(error);
        this.hideLoading();
      });
  };

  signOut = (param) => {
    runInAction(() => {
      this.removeSignInData();
      if (param === 'auto') {
        window.location.href = '/login';
        localStorage.setItem('autoLogout', true);
      }
    });
  };

  resignMembership = async (password) => {
    this.showLoading();
    await resignMembershipApi(password)
      .then(() => {
        this.signOut();
        localStorage.removeItem('firstVisit');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.togglewithdrawError();
        }
        this.hideLoading();
      });
  };
}

const authStore = new AuthStore();

export default authStore;
