import Logo from 'components/atoms/Logo';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from 'styles/theme';

const LoadingLogo = () => {
  return (
    <Wrapper>
      <LoadingAnimation>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </LoadingAnimation>
      <Logo />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: var(--background);
  width: 100%;
  height: 100vh;
  ${theme.flex.centerColumn};
  @media ${theme.mobile} {
    padding: 0 0 50px 0;
  }
`;

const wave = keyframes`
  50%,
  75% {
    transform: scale(2.5);
  }
  80%,
  100% {
    opacity: 0;
  }
`;

const LoadingAnimation = styled.div`
  display: flex;
  margin-bottom: 10px;

  .dot {
    position: relative;
    width: 1em;
    height: 1em;
    margin: 0.8em;
    border-radius: 50%;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: inherit;
      border-radius: inherit;
      animation: ${wave} 2s ease-out infinite;
    }
    &:nth-child(1) {
      background: #7ef9ff;
      &::before {
        animation-delay: calc(1 * 0.2s);
      }
    }
    &:nth-child(2) {
      background: #89cff0;
      &::before {
        animation-delay: calc(2 * 0.2s);
      }
    }
    &:nth-child(3) {
      background: var(--primary);
      &::before {
        animation-delay: calc(3 * 0.2s);
      }
    }
  }
`;

export default LoadingLogo;
