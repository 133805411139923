import { InfoMenu } from 'data/Menu';

export const menus = [
  {
    active: [0, 1, 2, 3],
    value: <InfoMenu index={0} />,
    icon: true, // subTabIcon
    subTabList: [
      <InfoMenu index={1} />,
      <InfoMenu index={2} />,
      <InfoMenu index={3} />,
      <InfoMenu index={4} />,
    ],
  },
  { active: [4], value: <InfoMenu index={5} /> },
  { active: [5], value: <InfoMenu index={6} /> },
  { active: [6], value: <InfoMenu index={7} /> },
  { active: [7], value: <InfoMenu index={8} /> },
];
