import React, { useEffect, useState } from 'react';
import UploadContainer from 'components/atoms/UploadContainer';
import UploadedFile from 'components/atoms/UploadedFile';

const FileUpload = ({
  height,
  materialList,
  materialIdList,
  setMaterialIdList,
  tempSavedFiles,
  setTempSavedFiles,
  preview,
}) => {
  /* 파일 */
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [previewFile, setPreviewFile] = useState([]);

  useEffect(() => {
    setUploadedFiles(materialList);
    setMaterialIdList(materialList?.map((data) => data.materialId));
  }, [materialList, setMaterialIdList]);

  const handleDeleteWithId = (id) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.materialId !== id));
    setMaterialIdList(materialIdList.filter((item) => item !== id));
  };

  const handleDeleteWithIndex = (index) => {
    setFiles(files.filter((_, i) => i !== index));
    setTempSavedFiles(tempSavedFiles.filter((_, i) => i !== index));
    setPreviewFile(previewFile.filter((_, i) => i !== index));
  };

  const handleDelete = (deleteId) => {
    if (typeof deleteId !== 'number') handleDeleteWithId(deleteId);
    if (typeof deleteId === 'number') handleDeleteWithIndex(deleteId);
  };

  console.log(files, 'file');
  console.log(previewFile, 'previewfile');

  return (
    <div>
      <UploadContainer
        onDragDrop={(file) => setFiles(file)}
        onSetPreview={(preview) => setPreviewFile(preview)}
        size="flexible"
        style={{ height: height }}
        limitFileSize={1.5}
        preview={preview}
      />
      {uploadedFiles?.map((file, index) => (
        <UploadedFile
          key={`matrial-file__${index}`}
          type="list"
          file={file}
          toDelete={handleDelete}
          style={{ height: 70, marginBottom: 10, marginTop: 10 }}
        />
      ))}
      {files.map((file, index) => (
        <UploadedFile
          key={`upload-file__${index}`}
          type="list"
          file={file}
          previewFile={previewFile[index]}
          onSetTempSavedFile={(prev) => setTempSavedFiles(prev)}
          index={index}
          toDelete={handleDelete}
          style={{ height: 70, marginBottom: 10, marginTop: 10 }}
          preview={preview}
        />
      ))}
    </div>
  );
};

export default FileUpload;
