import { makeAutoObservable, runInAction } from 'mobx';
import {
  getProjectTaskApi,
  postProjectTaskSubmitApi,
  getDeveloperProjectTaskApi,
  putDeveloperTaskApi,
  postApiInternalApi,
} from 'api/API';

class TodoStore {
  loading = false;
  messageConfirmAlert = false;

  todoList = [];
  doneList = [];
  devGroupList = [];
  projectId = '';

  constructor() {
    makeAutoObservable(this);
  }

  showLoading = () => {
    this.loading = true;
  };

  hideLoading = () => {
    this.loading = false;
  };

  toggleMessageConfirmAlert() {
    this.messageConfirmAlert = !this.messageConfirmAlert;
  }

  setProjectId = (param) => {
    this.projectId = param;
  };

  getProjectTask = async (id) => {
    await getProjectTaskApi(id)
      .then((res) => {
        runInAction(() => {
          this.todoList = res.data.todoList;
          this.doneList = res.data.doneList;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postProjectTaskSubmit = async (taskId, body) => {
    this.showLoading();
    let success = false;
    await postProjectTaskSubmitApi(taskId, body)
      .then((res) => {
        runInAction(() => {
          if (res.status === 200) {
            success = true;
          }
        });
      })
      .catch((error) => {
        console.log(error);
        this.hideLoading();
      });
    return success;
  };

  getDeveloperProjectTask = async (id) => {
    await getDeveloperProjectTaskApi(id)
      .then((res) => {
        runInAction(() => {
          // console.log(res.data.groupList, 'get developer');
          this.devGroupList = res.data.groupList;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  putDeveloperTask = async (id, body) => {
    await putDeveloperTaskApi(id, body)
      .then((res) => {
        runInAction(() => {
          // console.log(res);
          this.getDeveloperProjectTask(this.projectId);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postApiInternal = async (actionValue) => {
    await postApiInternalApi(actionValue)
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const todoStore = new TodoStore();

export default todoStore;
