import { useTranslation } from 'react-i18next';

export const Menu = ({ index }) => {
  const { t } = useTranslation('common');

  const menu = t('menu');

  return menu[index];
};

export const InfoMenu = ({ index }) => {
  const { t } = useTranslation('common');

  const infoMenu = t('infoMenu');

  return infoMenu[index];
};
