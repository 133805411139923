import React, { useMemo } from 'react';
import styled from 'styled-components';
import file from 'resources/file.png';
import DownloadBtn from 'components/atoms/DownloadBtn';
import FormatBytes from 'utils/FormatBytes';
import { ReactComponent as LinkIcon } from 'resources/linkIcon.svg';
import theme from 'styles/theme';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { ImageDataTypes } from 'utils/ImageUtils';

const File = ({ data }) => {
  const isImageType = useMemo(() => {
    return ImageDataTypes.includes(data.fileType);
  }, [data]);

  return isImageType ? (
    <PhotoProvider>
      <PhotoView src={data.fileUrl}>
        <DownloadContainer>
          <img src={file} alt="file" />
          <FileNameContainer>
            <FileName>{data.fileName}</FileName>
            <FileSize>{FormatBytes(data.fileSize)}</FileSize>
          </FileNameContainer>
          <DownloadBtn onDownloadLink={data.fileUrl} />
        </DownloadContainer>
      </PhotoView>
    </PhotoProvider>
  ) : (
    <DownloadContainer href={data.fileUrl}>
      <img src={file} alt="file" />
      <FileNameContainer>
        <FileName>{data.fileName}</FileName>
        <FileSize>{FormatBytes(data.fileSize)}</FileSize>
      </FileNameContainer>
      <DownloadBtn imageOnly />
    </DownloadContainer>
  );
};

const LinkFile = ({ data }) => {
  return (
    <DownloadContainer href={data.fileUrl} target="_blank">
      <img src={file} alt="file" />
      <FileNameContainer>
        <FileName>{data.fileName}</FileName>
      </FileNameContainer>
      <LinkIcon stroke={theme.colors.bodyText2} />
    </DownloadContainer>
  );
};

const DownloadableFile = ({ data }) => {
  return data.fileSize ? <File data={data} /> : <LinkFile data={data} />;
};

const DownloadContainer = styled.a`
  height: 73px;
  background: white;
  border: var(--border);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-right: 36px;
  padding-left: 31px;
  svg {
    stroke-width: 1.5;
  }

  :hover {
    background: var(--primaryLight);
    text-shadow: 0 0 0.5px var(--bodyText2);
    svg {
      stroke-width: 1.7;
    }
  }
  :hover #icon {
    display: none;
  }
  :hover #icon-hover {
    display: inline;
  }

  & + & {
    margin-top: 8px;
  }
`;
const FileNameContainer = styled.div`
  display: table-column;
  padding-left: 14px;
  /* width: 100%; */
  flex: 1;
`;
const FileName = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--bodyText2);
  margin-bottom: 5px;
`;
const FileSize = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--secondary);
`;

export default DownloadableFile;
