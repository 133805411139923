import styled from 'styled-components';
import { ReactComponent as Notification } from 'resources/notification.svg';

export const NotificationWrapper = styled.div`
  height: 72px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  right: 56px;
`;
export const NotificationDropdown = styled.div`
  position: relative;
  margin-right: 2vw;
  margin-left: 2vw;
  cursor: pointer;
`;
export const NotificationIcon = styled(Notification)`
  display: flex;
  cursor: ${(props) => (props.$active ? 'pointer' : 'default')};
`;
export const NotificationBadge = styled.p`
  margin: 0;
  position: absolute;
  top: -7px;
  left: 10px;
  height: 16px;
  background: #ff6161;
  border-radius: 32px;
  padding-inline-start: 4px;
  padding-inline-end: 5px;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  ${(props) => props.len < 10 && 'width: 16px;'}
`;
export const NotificationProfile = styled.div`
  position: relative;
`;
export const NotificationUser = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 16px;
    margin: 0;
    line-height: 19px;
    color: var(--bodyText);
  }
`;
