import {
  getAllDomainOptionsApi,
  getContainerDetailApi,
  getContainerImageCandidatesApi,
  getContainerSettingsAllApi,
  getContainerSettingsApi,
  getContainerSettingDetailApi,
  getDomainDetailApi,
  getDomainRecordsApi,
  getDomainsApi,
  getProductVolumesApi,
  searchDockerImageApi,
  getContainersSummaryByContainerSettingIdApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class CloudStore {
  loading = false;
  containerSettingPage = {};
  containerSettingDetail = {};
  containerDetail = {};
  containerCandidates = [];
  containerSettingList = [];
  containersSummaryBySettingId = [];
  candidateLoading = false;
  productVolumes = [];
  domainAllOptions = [];
  domainPage = {};
  domainDetail = {};
  domainRecordList = [];

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.loading = false;
    this.containerSettingPage = {};
    this.containerSettingDetail = {};
    this.containerDetail = {};
    this.containerCandidates = [];
    this.containerSettingList = [];
    this.containersSummaryBySettingId = [];
    this.candidateLoading = false;
    this.productVolumes = [];
    this.domainAll = [];
    this.domainPage = {};
    this.domainDetail = {};
    this.domainRecordList = [];
  };

  resetDomainDetail = () => {
    this.domainDetail = {};
    this.domainRecordList = [];
  };

  resetContainerDetail = () => {
    this.containerDetail = {};
  };

  resetContainerSettingDetail = () => {
    this.containerSettingDetail = {};
  };

  getContainerSettings = async (productId, pageNumber, pageSize) => {
    await getContainerSettingsApi(productId, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          if (res.data.responseCode === 'SUCCESS') {
            this.containerSettingPage = res.data.data;
          } else {
            this.containerSettingPage = [];
          }
        });
      })
      .catch((e) => console.log(e));
  };

  getContainerSettingsAll = async (productId) => {
    await getContainerSettingsAllApi(productId)
      .then((res) => {
        runInAction(() => {
          if (res.data.responseCode === 'SUCCESS') {
            this.containerSettingList = res.data.data;
          } else {
            this.containerSettingList = [];
          }
        });
      })
      .catch((e) => console.log(e));
  };

  getContainerDetail = async (id) => {
    await getContainerDetailApi(id)
      .then((res) => {
        runInAction(() => {
          if (res.data.responseCode === 'SUCCESS') {
            this.containerDetail = res.data.data;
          } else {
            this.containerDetail = {};
          }
        });
      })
      .catch((e) => console.log(e));
  };

  getContainerSettingDetail = async (id) => {
    await getContainerSettingDetailApi(id)
      .then((res) => {
        runInAction(() => {
          if (res.data.responseCode === 'SUCCESS') {
            this.containerSettingDetail = res.data.data;
          } else {
            this.containerSettingDetail = {};
          }
        });
      })
      .catch((e) => console.log(e));
  };

  getContainersSummaryBySettingId = async (id) => {
    await getContainersSummaryByContainerSettingIdApi(id)
      .then((res) => {
        runInAction(() => {
          if (res.data.responseCode === 'SUCCESS') {
            this.containersSummaryBySettingId = res.data.data;
          } else {
            this.containersSummaryBySettingId = [];
          }
        });
      })
      .catch((e) => console.log(e));
  };

  searchContainerImageCandidates = async (keyword) => {
    let result = {};
    await searchDockerImageApi(keyword)
      .then((res) => {
        runInAction(() => {
          if (res.data.responseCode === 'SUCCESS') {
            result = res.data.data;
          } else {
          }
        });
      })
      .catch((e) => console.error(e));
    return result;
  };

  getContainerImageCandidates = async (managedServiceId) => {
    this.candidateLoading = true;
    await getContainerImageCandidatesApi(managedServiceId)
      .then((res) => {
        runInAction(() => {
          if (res.data.responseCode === 'SUCCESS') {
            this.containerCandidates = res.data.data;
          } else {
            this.containerCandidates = [];
          }
        });
      })
      .catch((e) => console.error(e))
      .finally(() => (this.candidateLoading = false));
  };

  getProductVolumes = async (producId) => {
    await getProductVolumesApi(producId)
      .then((res) => {
        runInAction(() => {
          if (res.data.responseCode === 'SUCCESS') {
            this.productVolumes = res.data.data;
          } else {
            this.productVolumes = [];
          }
        });
      })
      .catch((e) => console.error(e));
  };

  getProductDomains = async (producId, pageNumber, pageSize) => {
    await getDomainsApi(producId, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          if (res.data.responseCode === 'SUCCESS') {
            this.domainPage = res.data.data;
          } else {
            this.domainPage = {};
          }
        });
      })
      .catch((e) => console.error(e));
  };

  getProductAllDomainOptions = async (productId) => {
    await getAllDomainOptionsApi(productId)
      .then((res) => {
        runInAction(() => {
          if (res.data.responseCode === 'SUCCESS') {
            this.domainAllOptions = res.data.data;
          } else {
            this.domainAllOptions = [];
          }
        });
      })
      .catch((e) => console.error(e));
  };

  getDomainDetail = async (productId, domainId) => {
    await getDomainDetailApi(productId, domainId)
      .then((res) => {
        runInAction(() => {
          if (res.data.responseCode === 'SUCCESS') {
            this.domainDetail = res.data.data;
          } else {
            this.domainDetail = {};
          }
        });
      })
      .catch((e) => console.error(e));
  };

  getDomainRecords = async (productId, domainId) => {
    this.loading = true;
    await getDomainRecordsApi(productId, domainId)
      .then((res) => {
        runInAction(() => {
          if (res.data.responseCode === 'SUCCESS') {
            this.domainRecordList = res.data.data;
          } else {
            this.domainRecordList = [];
          }
        });
      })
      .catch((e) => {
        console.error(e);
        this.domainRecordList = [];
      })
      .finally(() => {
        this.loading = false;
      });
  };

  showLoading = () => {
    this.loading = true;
  };

  hideLoading = () => {
    this.loading = false;
  };
}

const cloudStore = new CloudStore();

export default cloudStore;
