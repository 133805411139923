import styled, { css } from 'styled-components';
import theme, { fonts } from 'styles/theme';

export const Wrapper = styled.div`
  display: flex;
  background-color: var(--background);
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  @media ${theme.mobile} {
    min-height: calc(100vh - 62px);
  }
`;

export const TopContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 56px;
  background: var(--background);
  z-index: 2;
`;

export const MainContent = styled.main`
  height: 100%;
  width: 100%;
  max-width: ${(props) => (props.expandLayout ? '100%' : 'calc(100% - 280px)')};
  display: block;
  margin-left: ${(props) => (props.expandLayout ? '0px' : '280px')};
  transition: all 0.5s ease;
  padding: ${(props) => (props.backButton ? '77px 56px 100px' : '56px 56px 100px')};
  @media ${theme.tablet} {
    margin-left: 0px;
    max-width: 100%;
    transition: margin 0.5s ease;
  }
  @media ${theme.mobile} {
    ${(props) =>
      props.roworkPM
        ? css`
            padding: 40px 0 100px 0;
          `
        : css`
            padding: 40px 28px 100px 28px;
          `}
  }
  @media ${theme.mobileS} {
    ${(props) =>
      props.roworkPM
        ? css`
            padding: 40px 0 20px 0;
          `
        : css`
            padding: 40px 20px 20px 20px;
          `}
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 44px;
  @media ${theme.mobile} {
    margin-bottom: 0;
    border: 0;
    ${(props) => props.roworkPM && 'padding-left: 28px'}
  }
  @media ${theme.mobileS} {
    ${(props) => props.roworkPM && 'padding-left: 20px'}
  }
`;

export const ProjectTitle = styled.div`
  ${fonts.secondary}
  line-height: 21.6px;
`;

export const Title = styled.h1`
  ${fonts.bigTitle}
  padding-bottom: 52px;
  margin: 0;
  @media ${theme.mobile} {
    padding-bottom: 46px;
  }
`;

export const Desc = styled.div`
  ${fonts.secondary}
`;

export const ChildrenWrapper = styled.div`
  width: 100%;
  /* width: calc(100vw - 392px); */
  ${(props) => !props.fullWidth && !props.expandLayout && 'max-width: 1048px;'}
  position: relative;
`;
