import { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as Dropdown } from 'resources/dropdownIcon.svg';
import theme, { fonts } from 'styles/theme';
import useDetectOutsideClick from 'utils/useDetectOutsideClick';
import Label from './Label';
import { useTranslation } from 'react-i18next';
import { InputDesc } from './EtcComponents';

export const DropdownMilestone = ({ options, value, onSelect, style }) => {
  const wrapperRef = useRef(null);
  const [visible, setVisible] = useDetectOutsideClick(wrapperRef, false);

  const [optionsScroll, setOptionScroll] = useState(false);

  useEffect(() => {
    //optionScroll
    if (options.length > 4) {
      setOptionScroll(true);
    }
  }, [options.length]);

  return (
    <>
      <MilestoneWrapper
        ref={wrapperRef}
        onClick={() => setVisible(!visible)}
        style={style}
        align="center"
      >
        <MilestoneLabelWrapper>
          <MilestoneLabel>{value}</MilestoneLabel>
          <DropdownIcon focus={visible.toString() || undefined} fill="#C4C4C4" />
        </MilestoneLabelWrapper>
        {visible && (
          <MilestoneDropdown scroll={optionsScroll}>
            {options
              ? options.map((item, index) => (
                  <MilestoneItem
                    key={`${item}${index}`}
                    onClick={() => {
                      onSelect(item);
                      setVisible(false);
                    }}
                    border={index !== options.length - 1}
                  >
                    <MilestoneItemLabel>{item}</MilestoneItemLabel>
                  </MilestoneItem>
                ))
              : null}
          </MilestoneDropdown>
        )}
      </MilestoneWrapper>
    </>
  );
};

const MilestoneWrapper = styled.div`
  position: relative;
  /* display: table-cell; */
  min-width: 100px;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  cursor: pointer;
  ${(props) => (props.style ? props.style : null)}
`;

const MilestoneLabel = styled.p`
  margin: 0 5px 0 0;
  overflow: hidden;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #103063;
`;
const MilestoneLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 17px;
  position: relative;
  height: 47px;
  background: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  z-index: 1;
`;

const DropdownIcon = styled(Dropdown)`
  ${(props) => props.focus === 'true' && 'transform: rotate(180deg);'}
`;

const MilestoneDropdown = styled.ul`
  position: absolute;
  width: 100%;
  top: 47px;
  min-height: 47px;
  margin: 0;
  padding: 0;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  cursor: pointer;
  list-style: none;
  box-shadow: var(--boxShadow);
  z-index: 2;
  ${(props) =>
    props.scroll === true &&
    css`
      height: 200px;
      overflow-y: scroll;
    `}
`;
const MilestoneItem = styled.li`
  display: flex;
  align-items: center;
  padding-left: 17px;
  height: 47px;
  padding-right: 17px;
  border-bottom: ${(props) => (props.border ? '1px solid #DFDFDF' : 'none')};
  :hover {
    background: #eef2fa;
  }
`;

const MilestoneItemLabel = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #103063;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const DropdownInput = ({
  top,
  name,
  label,
  required,
  options,
  value,
  placeholder,
  type,
  desc,
  warning,
  onSelect,
  showDefault,
  disabled,
}) => {
  const { t } = useTranslation('common');
  const wrapperRef = useRef(null);
  const [visible, setVisible] = useDetectOutsideClick(wrapperRef, false);

  const [optionsScroll, setOptionScroll] = useState(false);

  useEffect(() => {
    //optionScroll
    if (options?.length > 4) {
      setOptionScroll(true);
    }
  }, [options?.length]);

  return (
    <>
      {label && <Label top={top} label={label} required={required} />}
      <InputWrapper ref={wrapperRef} onClick={() => !disabled && setVisible(!visible)}>
        <InputLabelWrapper
          focus={visible || undefined}
          className={[warning && 'fail', disabled && 'disabled']}
        >
          <InputLabel ellipsis name={name} value={typeof value} selected={value ? true : false}>
            {value ? value : placeholder}
          </InputLabel>
          <DropdownIcon focus={visible || undefined} fill="#C4C4C4" />
        </InputLabelWrapper>
        {visible && (
          <MilestoneDropdown scroll={optionsScroll}>
            {showDefault && (
              <InputItem
                key={`${label}_dropdown_default`}
                onClick={() => {
                  onSelect('');
                  setVisible(false);
                }}
                border
              >
                <InputItemLabel type={type} default>
                  {t('selectDefault')}
                </InputItemLabel>
              </InputItem>
            )}
            {options
              ? options.map((item, index) => (
                  <InputItem
                    key={`${item}${index}`}
                    onClick={() => {
                      onSelect(item, index);
                      setVisible(false);
                    }}
                    border={index !== options.length - 1}
                  >
                    <InputItemLabel type={type}>{!type ? item : item.name}</InputItemLabel>
                  </InputItem>
                ))
              : null}
          </MilestoneDropdown>
        )}
        {!warning && desc && <InputDesc>{desc}</InputDesc>}
        {warning && <Warning>{warning}</Warning>}
      </InputWrapper>
    </>
  );
};

const InputWrapper = styled(MilestoneWrapper)`
  width: 100%;
`;

const InputLabel = styled(MilestoneLabel)`
  font-size: 16px;
  ${(props) =>
    props.ellipsis &&
    css`
      ${theme.ellipsis}
    `}
  color: ${(props) =>
    props.name === 'priority' || props.selected
      ? 'var(--bodyText)'
      : props.value === 'number'
      ? 'var(--bodyText)'
      : 'var(--secondary)'};
`;

const InputLabelWrapper = styled(MilestoneLabelWrapper)`
  border: ${(props) => (props.focus ? '1px solid var(--primary)' : '1px solid #dfdfdf')};
  &.fail {
    color: var(--bodyText2);
    border: 1px solid var(--error);
    &:-webkit-autofill {
      -webkit-text-fill-color: var(--bodyText2) !important;
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
    }
  }
  &.disabled {
    cursor: auto;
    background: #efefef;
    color: var(--secondary);
  }
`;

const InputItem = styled(MilestoneItem)`
  :hover p {
    color: var(--primary);
    text-shadow: 0 0 1px var(--primary);
  }
`;

const InputItemLabel = styled(MilestoneItemLabel)`
  ${fonts.formA}
  ${(props) =>
    props.default &&
    css`
      color: var(--secondary);
    `}
`;

const Warning = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: var(--error);
  margin-top: 5px;
  text-align: left;
`;

export const DropdownConfirm = ({ options, value, onSelect, style }) => {
  const wrapperRef = useRef(null);
  const [visible, setVisible] = useDetectOutsideClick(wrapperRef, false);

  const [optionsScroll, setOptionScroll] = useState(false);

  useEffect(() => {
    //optionScroll
    if (options.length > 4) {
      setOptionScroll(true);
    }
  }, [options.length]);

  return (
    <>
      <MilestoneWrapper
        ref={wrapperRef}
        onClick={() => setVisible(!visible)}
        style={style}
        align="center"
      >
        <MilestoneLabelWrapper>
          <MilestoneLabel>{value}</MilestoneLabel>
          <DropdownIcon focus={visible.toString() || undefined} fill="#C4C4C4" />
        </MilestoneLabelWrapper>
        {visible && (
          <MilestoneDropdown scroll={optionsScroll}>
            {options
              ? options.map((item, index) => (
                  <MilestoneItem
                    key={`${item}${index}`}
                    onClick={() => {
                      onSelect(item);
                      setVisible(false);
                    }}
                    border={index !== options.length - 1}
                  >
                    <MilestoneItemLabel>{item}</MilestoneItemLabel>
                  </MilestoneItem>
                ))
              : null}
          </MilestoneDropdown>
        )}
      </MilestoneWrapper>
    </>
  );
};

export const DropdownWithImage = ({ options, value, onSelect, style }) => {
  const wrapperRef = useRef(null);
  const [visible, setVisible] = useDetectOutsideClick(wrapperRef, false);

  const [optionsScroll, setOptionScroll] = useState(false);

  useEffect(() => {
    //optionScroll
    if (options.length > 4) {
      setOptionScroll(true);
    }
  }, [options.length]);

  return (
    <>
      <MilestoneWrapper
        ref={wrapperRef}
        onClick={() => setVisible(!visible)}
        style={style}
        align="center"
      >
        <MilestoneLabelWrapper>
          <MilestoneInfo>
            <MilestoneItemImage>
              {value?.imageUrl && <img src={value?.imageUrl} />}
            </MilestoneItemImage>
            <MilestoneLabel>{value?.productName}</MilestoneLabel>
          </MilestoneInfo>
          <DropdownIcon focus={visible.toString() || undefined} fill="#C4C4C4" />
        </MilestoneLabelWrapper>
        {visible && (
          <MilestoneDropdown scroll={optionsScroll}>
            {options
              ? options.map((item, index) => (
                  <MilestoneItem
                    key={`${item}${index}`}
                    onClick={() => {
                      onSelect(item);
                      setVisible(false);
                    }}
                    border={index !== options.length - 1}
                  >
                    <MilestoneItemImage>
                      {item?.imageUrl && <img src={item?.imageUrl} />}
                    </MilestoneItemImage>
                    <MilestoneItemLabel>{item?.productName}</MilestoneItemLabel>
                  </MilestoneItem>
                ))
              : null}
          </MilestoneDropdown>
        )}
      </MilestoneWrapper>
    </>
  );
};

const MilestoneInfo = styled.div`
  display: flex;
  align-items: center;
`;

const MilestoneItemImage = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 70%;
  overflow: hidden;
  background-color: #eee;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
