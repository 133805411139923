import React, { useRef, useEffect } from 'react';
import Loading from '../components/atoms/Loading';

const FetchMore = ({ loading, setPage }) => {
  const fetchMoreTrigger = useRef(null);
  const fetchMoreObserver = new IntersectionObserver(([{ isIntersecting }]) => {
    if (isIntersecting) setPage((prev) => prev + 1);
  });

  useEffect(() => {
    fetchMoreObserver.observe(fetchMoreTrigger.current);
    return () => {
      fetchMoreObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={fetchMoreTrigger} style={{ height: '1px' }}>
      {loading && <Loading />}
    </div>
  );
};

export default FetchMore;
