/* Use top of App component */
/* <ThemeProvider theme={theme}>
  <GlobalStyle />
  <App />
</ThemeProvider> */
import { createGlobalStyle } from 'styled-components';
import theme from 'styles/theme';

const { colors } = theme;

const GlobalStyle = createGlobalStyle`

  html {
    font-size: 62.25%;
  }
  @media ${theme.laptop} {
    html {
      font-size: 50%;
    }
  }
  @media ${theme.tablet} {
    html {
      font-size: 40%;
    }
  }
  
  :root {
    --primary: ${colors.primary};
    --primaryLight: ${colors.primaryLight};
    --background: ${colors.background};
    --listBg: ${colors.listBg};
    --grayLine: ${colors.grayLine};
    --error: ${colors.error};
    --redLight: ${colors.redLight};
    --red: ${colors.red};
    --yellowLight: ${colors.yellowLight};
    --yellow: ${colors.yellow};
    --greenLight: ${colors.greenLight};
    --green: ${colors.green};
    --skyBlueLight: ${colors.skyBlueLight};
    --skyBlue: ${colors.skyBlue};
    --title: ${colors.title};
    --bodyText: ${colors.bodyText};
    --bodyText2: ${colors.bodyText2};
    --grayText: ${colors.grayText};
    --grayText2: ${colors.grayText2};
    --secondary: ${colors.secondary};
    --empty: ${colors.empty};

    --border: ${theme.border};
    --boxShadow: ${theme.boxShadow};
  }

`;

export default GlobalStyle;
