import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Row from 'components/atoms/Row';
import downloadImg from 'resources/downloadIcon.png';
import { useTranslation } from 'react-i18next';
import { fonts } from 'styles/theme';
import verifiedImg from 'resources/check-decagram.svg';
import { Link } from 'react-router-dom';

const ContainerImage = ({ image, showDetail }) => {
  const { t } = useTranslation('common');
  const [item, setItem] = useState(image);

  useEffect(() => {
    setItem(image);
  }, [image]);

  return (
    <ContainerImageWrap>
      <Row>
        {item?.name} {!!item?.logo_url?.small && <ContainerImageImg src={item?.logo_url.small} />}
        <ContainerImagePublisher>by {item?.publisher?.name}</ContainerImagePublisher>
        {item?.rate_plans[0]?.repositories[0]?.is_trusted && <VertifiedImg src={verifiedImg} />}
      </Row>
      <Row mt={8}>
        <DownloadImg src={downloadImg} />{' '}
        <ContainerImagePullCount>
          {item?.rate_plans?.length > 0 &&
            item?.rate_plans[0]?.repositories?.length > 0 &&
            item?.rate_plans[0]?.repositories[0]?.pull_count}
        </ContainerImagePullCount>
        <ContainerImageDesc>
          {t('lastUpdatedAt') + ': '} {moment(item?.updated_at).format('YY/MM/DD')}{' '}
        </ContainerImageDesc>
      </Row>
      <Row mt={8} spacebetween>
        <ContainerImageDesc>{item?.short_description}</ContainerImageDesc>
        {showDetail && (
          <DetailLink to={{ pathname: `https://hub.docker.com/r/${item.id}` }} target="_blank">
            {t('showDetail')}
          </DetailLink>
        )}
      </Row>
    </ContainerImageWrap>
  );
};

const ContainerImageWrap = styled.div`
  align-items: center;
`;

const DownloadImg = styled.img`
  width: 15px;
`;

const ContainerImageImg = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 8px;
`;

const ContainerImagePublisher = styled.div`
  ${fonts.secondary}
  align-content: center;
  margin-left: 8px;
`;

const ContainerImagePullCount = styled.div`
  ${fonts.m_secondary}
  align-content: center;
  margin-left: 8px;
  margin-right: 8px;
`;

const ContainerImageDesc = styled.div`
  ${fonts.m_secondary}
  align-content: center;
`;

const VertifiedImg = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 8px;
`;

const DetailLink = styled(Link)`
  min-width: 70px;
  text-align: right;
`;

export default ContainerImage;
