import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import stores from 'stores';
import { observer } from 'mobx-react';
import { Menu } from 'data/Menu';
import Tooltip from 'components/atoms/Tooltip';
import { ReactComponent as Home } from 'resources/navHome.svg';
import { ReactComponent as Management } from 'resources/navManagement.svg';
import { ReactComponent as TodoList } from 'resources/navTodolist.svg';
import { ReactComponent as RoworkPM } from 'resources/navRoworkPM.svg';
import { ReactComponent as Document } from 'resources/navDocument.svg';
import { ReactComponent as Payment } from 'resources/navPayment.svg';
import { ReactComponent as Member } from 'resources/group.svg';
import { ReactComponent as Operate } from 'resources/navOperate.svg';
import { ReactComponent as Cloud } from 'resources/cloud.svg';
import { ReactComponent as Issue } from 'resources/code.svg';
import { ReactComponent as Setting } from 'resources/navSetting.svg';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import paths from 'data/paths';
import { DropdownWithImage } from 'components/atoms/DropdownMilestone';
import { toJS } from 'mobx';

const NavigationMenu = observer(({ side, openInfoTab }) => {
  const { productTourStore, dashboardStore } = stores;
  const { setCurrentId, currentId, menuAvailable } = dashboardStore;

  const { projectId, productId } = useParams();
  /* maintain projectId */
  let history = useHistory();
  const currentProjectId =
    history.location.state?.projectId ||
    history.location.state?.productId ||
    projectId ||
    productId ||
    currentId;
  const menuActive = history.location.state?.menuActive || menuAvailable;

  const match = useRouteMatch();
  const currentTab = match.path.split('/')[3];

  const compareCurrentTab = (menu) => {
    return currentTab === menu;
  };

  const { t } = useTranslation('common');

  useEffect(() => {
    if (currentProjectId) setCurrentId(currentProjectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setTooltipVisible } = productTourStore;

  const menuState = [
    {
      to: {
        pathname: paths.home,
        state: { projectId: currentProjectId },
      },
      focus: match.path === paths.home,
      value: <Menu index={'home'} />,
      icon: (
        <Home
          stroke={match.path === paths.home ? (openInfoTab ? '#464646' : '#2C62BA') : '#464646'}
        />
      ),
    },
    {
      to: {
        pathname: paths.project(currentProjectId),
        state: { menuActive },
      },
      focus:
        (!currentTab && match.path.startsWith('/project')) || match.path.startsWith('/milestone'),
      value: <Menu index={'project'} />,
      disabled: !menuActive,
      icon: (
        <Management
          stroke={
            (!currentTab && match.path.startsWith('/project')) ||
            match.path.startsWith('/milestone')
              ? openInfoTab
                ? '#464646'
                : '#2C62BA'
              : '#464646'
          }
        />
      ),
      tooltip: {
        desc: t('tooltip')[0],
      },
      onClose: () => setTooltipVisible(1, 2),
    },
    {
      to: {
        pathname: paths.issues(currentProjectId),
        state: { menuActive },
      },
      focus: compareCurrentTab('issues'),
      disabled: !menuActive,
      value: <Menu index={'issues'} />,
      icon: (
        <Issue
          stroke={compareCurrentTab('issues') ? (openInfoTab ? '#464646' : '#2C62BA') : '#464646'}
        />
      ),
    },
    {
      to: {
        pathname: paths.todo(currentProjectId),
        state: { menuActive },
      },
      focus: compareCurrentTab('todo'),
      value: <Menu index={'todo'} />,
      disabled: !menuActive,
      icon: (
        <TodoList
          stroke={compareCurrentTab('todo') ? (openInfoTab ? '#464646' : '#2C62BA') : '#464646'}
        />
      ),
      tooltip: {
        desc: t('tooltip')[1],
      },
      onClose: () => setTooltipVisible(2, 4),
    },
    {
      to: {
        pathname: paths.communication(currentProjectId),
        state: { menuActive },
      },
      focus: compareCurrentTab('communication'),
      value: <Menu index={'communication'} />,
      disabled: !menuActive,
      icon: (
        <RoworkPM
          fill={
            compareCurrentTab('communication') ? (openInfoTab ? '#464646' : '#2C62BA') : '#464646'
          }
        />
      ),
      tooltip: {
        desc: t('tooltip')[2],
        confirm: t('tooltip')[3],
      },
      onClose: () => {
        setTooltipVisible(3);
        localStorage.setItem('firstVisit', false);
        history.push(menuState[3].to);
      },
    },
  ];

  const settingMenuState = [
    {
      to: {
        pathname: paths.contract(currentProjectId),
        state: { menuActive },
      },
      focus: compareCurrentTab('contract'),
      disabled: !menuActive,
      value: <Menu index={'contract'} />,
      icon: (
        <Document
          stroke={compareCurrentTab('contract') ? (openInfoTab ? '#464646' : '#2C62BA') : '#464646'}
        />
      ),
      tooltip: {
        desc: t('tooltip')[4],
      },
      onClose: () => {
        setTooltipVisible(4, 3);
        // history.push(menuState[3].to);
      },
    },
    {
      to: {
        pathname: paths.payment(currentProjectId),
        state: { menuActive },
      },
      focus: compareCurrentTab('payment'),
      disabled: !menuActive,
      value: <Menu index={'payment'} />,
      icon: (
        <Payment
          stroke={compareCurrentTab('payment') ? (openInfoTab ? '#464646' : '#2C62BA') : '#464646'}
        />
      ),
    },
    {
      to: {
        pathname: paths.member(currentProjectId),
        state: { menuActive },
      },
      focus: compareCurrentTab('member'),
      disabled: !menuActive,
      value: <Menu index={'member'} />,
      icon: (
        <Member
          stroke={compareCurrentTab('member') ? (openInfoTab ? '#464646' : '#2C62BA') : '#464646'}
        />
      ),
    },
    {
      to: {
        pathname: paths.setting(currentProjectId),
        state: { menuActive },
      },
      focus: compareCurrentTab('setting'),
      disabled: !menuActive,
      value: <Menu index={'setting'} />,
      icon: (
        <Setting
          stroke={compareCurrentTab('setting') ? (openInfoTab ? '#464646' : '#2C62BA') : '#464646'}
        />
      ),
    },
  ];

  const maintainMenuState = [
    {
      to: {
        pathname: paths.managedServices(currentProjectId),
        state: { menuActive },
      },
      focus: compareCurrentTab('managed-services'),
      disabled: !menuActive,
      value: <Menu index={'managed-services'} />,
      icon: (
        <Operate
          stroke={
            compareCurrentTab('managed-services')
              ? openInfoTab
                ? '#464646'
                : '#2C62BA'
              : '#464646'
          }
        />
      ),
    },
    {
      to: {
        pathname: paths.cloud(currentProjectId),
        state: { menuActive },
      },
      focus: compareCurrentTab('cloud'),
      disabled: !menuActive,
      value: <Menu index={'cloud'} />,
      icon: (
        <Cloud
          stroke={compareCurrentTab('cloud') ? (openInfoTab ? '#464646' : '#2C62BA') : '#464646'}
        />
      ),
    },
  ];

  return (
    <>
      {side ? (
        <SideNavigationMenu
          menuState={menuState}
          maintainMenuState={maintainMenuState}
          settingMenuState={settingMenuState}
        />
      ) : (
        <MobileNavigationMenu
          menuState={menuState}
          openInfoTab={openInfoTab}
          maintainMenuState={maintainMenuState}
          settingMenuState={settingMenuState}
        />
      )}
    </>
  );
});
export default NavigationMenu;

const SideNavigationMenu = observer(({ menuState, maintainMenuState, settingMenuState }) => {
  const { productTourStore, dashboardStore } = stores;
  const { inProgressProducts, getInProgressProducts, currentId } = dashboardStore;
  const { t } = useTranslation('common');

  const { projectId, productId } = useParams();
  /* maintain projectId */
  let history = useHistory();
  const currentProjectId =
    history.location.state?.projectId ||
    history.location.state?.productId ||
    projectId ||
    productId ||
    currentId;

  useEffect(() => {
    getInProgressProducts(currentProjectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectId]);

  const [selectedProduct, setSelectedProduct] = useState();
  const [remainProducts, setRemainProducts] = useState([]);

  useEffect(() => {
    const productIndex = inProgressProducts.findIndex(
      (item) => item.productId === currentProjectId,
    );
    const filteredProducts = inProgressProducts.filter(
      (item) => item.productId !== currentProjectId,
    );
    setSelectedProduct(inProgressProducts[productIndex]);
    setRemainProducts(filteredProducts);
  }, [currentProjectId, inProgressProducts]);

  const handleSelectProduct = (val) => {
    setSelectedProduct(val);
    history.push({ pathname: `/project/${val.productId}` });
    window.location.reload();
  };

  return (
    <SidebarMenu>
      <DropdownWrap>
        <DropdownWithImage
          value={selectedProduct}
          onSelect={handleSelectProduct}
          options={remainProducts}
        />
      </DropdownWrap>
      {menuState
        .filter((_, i) => i < 7)
        .map((item, i) => (
          <SidebarItem key={`side-menu__${i}`}>
            <SidebarLinks to={item.to} $focus={item.focus} disabled={item.disabled}>
              <SidebarIcon disabled={item.disabled}>{item.icon}</SidebarIcon>
              {item.value}
            </SidebarLinks>
            {item.tooltip && (
              <SideTooltip>
                <Tooltip
                  title={item.value}
                  desc={item.tooltip.desc}
                  visible={
                    JSON.parse(localStorage.getItem('firstVisit')) &&
                    productTourStore.tooltip[i].visible
                  }
                  confirm={item.tooltip.confirm}
                  onClose={item.onClose}
                  left
                />
              </SideTooltip>
            )}
          </SidebarItem>
        ))}
      <>
        <MenuSection>{t('maintenance')}</MenuSection>
        {maintainMenuState.map((item, i) => (
          <SidebarItem key={`side-menu-mt__${i}`}>
            <SidebarLinks to={item.to} $focus={item.focus} disabled={item.disabled}>
              <SidebarIcon disabled={item.disabled}>{item.icon}</SidebarIcon>
              {item.value}
            </SidebarLinks>
          </SidebarItem>
        ))}
      </>
      <>
        <MenuSection>{t('setting')}</MenuSection>
        {settingMenuState.map((item, i) => (
          <SidebarItem key={`side-menu-set__${i}`}>
            <SidebarLinks to={item.to} $focus={item.focus} disabled={item.disabled}>
              <SidebarIcon disabled={item.disabled}>{item.icon}</SidebarIcon>
              {item.value}
            </SidebarLinks>
          </SidebarItem>
        ))}
      </>
    </SidebarMenu>
  );
});

const SidebarMenu = styled.ul`
  padding: 0 24px 60px;
  width: 280px;
  margin-top: 70px;
  list-style: none;
  max-height: calc(100vh - 116px);
  overflow-y: auto;
  overscroll-behavior: none;
`;
const DropdownWrap = styled.div`
  margin-bottom: 40px;
`;
const MenuSection = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  color: var(--grayText);
  margin: 60px 0 12px 16px;
`;
const SidebarItem = styled.li`
  height: 48px;
  /* margin-bottom: 6px; */
  position: relative;
`;
const SideTooltip = styled.div`
  position: absolute;
  top: -46px;
  left: 180px;
`;
const SidebarLinks = styled(Link)`
  border-radius: 5px;
  color: ${theme.colors.grayText};
  padding-left: 16px;
  display: flex;
  outline: none;
  border: none;
  align-items: center;
  text-decoration: none;
  height: 100%;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  ${(props) =>
    props.$focus &&
    css`
      background-color: ${theme.colors.primaryLight};
      color: ${theme.colors.primary};
      font-weight: 600;
    `}
  &:hover:not([disabled]) {
    background-color: ${theme.colors.listBg};
    color: ${(props) => (props.$focus ? 'var(--primary)' : 'var(--grayText)')};
  }
  ${(props) => props.disabled && `pointer-events: none;`}
`;
const SidebarIcon = styled.div`
  margin: 0 16px 0 0;
  display: flex;
  align-items: center;
  ${(props) => props.disabled && `opacity: 0.25;`}
`;

const MobileNavigationMenu = ({ menuState, openInfoTab, maintainMenuState, settingMenuState }) => {
  const { t } = useTranslation('common');
  return (
    <MenuTab>
      <MenuWrap>
        {menuState
          .filter((_, i) => i < 7)
          .map((item, i) => (
            <MenuBox key={`mobile-navigation__${i}`} focus={openInfoTab ? 'false' : item.focus}>
              <MenuLink
                to={item.to}
                $focus={openInfoTab ? false : item.focus}
                disabled={item.disabled}
              >
                <MenuIcon disabled={item.disabled}>{item.icon}</MenuIcon>
                {item.value}
              </MenuLink>
            </MenuBox>
          ))}
        <>
          <MenuSection>{t('maintenance')}</MenuSection>
          {maintainMenuState.map((item, i) => (
            <MenuBox key={`mobile-navigation-mt__${i}`} $focus={openInfoTab ? false : item.focus}>
              <MenuLink
                to={item.to}
                $focus={openInfoTab ? false : item.focus}
                disabled={item.disabled}
              >
                <MenuIcon disabled={item.disabled}>{item.icon}</MenuIcon>
                {item.value}
              </MenuLink>
            </MenuBox>
          ))}
        </>
        <>
          <MenuSection>{t('setting')}</MenuSection>
          {settingMenuState.map((item, i) => (
            <MenuBox key={`mobile-navigation-set__${i}`} $focus={openInfoTab ? false : item.focus}>
              <MenuLink
                to={item.to}
                $focus={openInfoTab ? false : item.focus}
                disabled={item.disabled}
              >
                <MenuIcon disabled={item.disabled}>{item.icon}</MenuIcon>
                {item.value}
              </MenuLink>
            </MenuBox>
          ))}
        </>
      </MenuWrap>
    </MenuTab>
  );
};
export const MenuTab = styled.div`
  padding: 10px 0 0 0;
`;

export const MenuWrap = styled.ul`
  padding: 0;
  margin: 0;
`;

export const MenuBox = styled.li`
  list-style: none;
  height: 48px;
  border-radius: 5px;
  ${(props) =>
    props.$focus &&
    css`
      background-color: ${theme.colors.primaryLight};
    `}
`;

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0 0 16px;
  cursor: pointer;
  border-radius: 5px;
  color: ${theme.colors.grayText};
  font-weight: 400;
  font-size: 18px;
  ${(props) =>
    props.$focus &&
    css`
      color: ${theme.colors.primary};
      font-weight: 600;
      font-size: 18px;
    `}
  &:hover:not([disabled]) {
    background-color: ${theme.colors.listBg};
    color: ${(props) => (props.$focus ? 'var(--primary)' : 'var(--grayText)')};
  }
  ${(props) => props.disabled && `pointer-events: none;`}
`;
export const MenuIcon = styled.div`
  margin: 0 16px 0 0;
  display: flex;
  align-items: center;
  ${(props) => props.disabled && `opacity: 0.25;`}
`;
