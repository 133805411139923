import React from 'react';
import styled, { css } from 'styled-components';
import theme, { fonts } from 'styles/theme';
import { Mobile } from 'styles/MediaQuery';

const Tabs = ({ active, tabs, disabledTabIndexes, onTabClick }) => {
  return (
    <TabWrapper len={tabs.length}>
      {tabs.map((item, index) => (
        <TabContainer
          onClick={() => {
            if (disabledTabIndexes && disabledTabIndexes.includes(index)) {
              return;
            }
            onTabClick(index);
          }}
          key={item}
          active={index === active ? true : false}
          disabled={disabledTabIndexes && disabledTabIndexes.includes(index)}
          len={tabs.length}
        >
          {index === active ? (
            <TabTitleActive active={index === active ? true : false}>{item}</TabTitleActive>
          ) : (
            <TabTitle active={index === active ? true : false}>{item}</TabTitle>
          )}
        </TabContainer>
      ))}
      <Mobile>
        <MobileTabLine />
      </Mobile>
    </TabWrapper>
  );
};
export default Tabs;
const TabWrapper = styled.div`
  display: flex;
  @media ${theme.mobile} {
    position: relative;
    /* justify-content: ${(props) => (props.len === 3 ? 'space-between' : 'space-around')}; */
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const MobileTabLine = styled.div`
  position: absolute;
  width: 100vw;
  top: 36px;
  left: -28px;
  border-bottom: 1px solid ${theme.colors.grayLine};
  z-index: 0;
`;
const TabContainer = styled.div`
  cursor: pointer;
  min-width: 88px;
  padding-bottom: 15px;
  margin-right: 26px;
  border-bottom: ${(props) => (props.active ? `2px solid #2C62BA` : 0)};
  z-index: 1;
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.6;
    `}
`;

const TabTitleActive = styled.h2`
  margin-bottom: 0;
  ${fonts.formA}
  color: var(--primary);
  text-align: center;
  font-weight: 500;
`;
const TabTitle = styled.p`
  margin-bottom: 0;
  ${fonts.formA}
  color: var(--grayText2);
  text-align: center;
`;
