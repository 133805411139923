import { makeAutoObservable } from 'mobx';

class ProductTourStore {
  tooltip = {
    1: {
      visible: true,
    },
    2: {
      visible: false,
    },
    3: {
      visible: false,
    },
    4: {
      visible: false,
    },
  };

  setTooltipVisible = (current, next) => {
    this.tooltip[current].visible = false;
    if (next) this.tooltip[next].visible = true;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const productTourStore = new ProductTourStore();

export default productTourStore;
