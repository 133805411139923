import Navbar, { LoginNavbar } from 'components/templates/Navbar';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Route, Switch, useHistory } from 'react-router-dom';
import { analytics } from 'fBase';
import { useTranslation } from 'react-i18next';
import { SEO } from 'components/atoms/SEO';

import Service from 'components/pages/service/Service';
import Header from './Header';
import UserSelect from './UserSelect';
import LandingData from './LandingData';
import Footer from './Footer';

const Landing = () => {
  const { t } = useTranslation('landing');

  let history = useHistory();

  useEffect(() => {
    if (history.location.search) {
      const params = new URLSearchParams(history.location.search);

      if (params.get('type') === 'ad' && params.get('referrer')) {
        analytics.logEvent('ref_ad', { referrer: params.get('referrer'), id: params.get('id') });
      }
    }
  }, [history.location.search]);

  const handleOnClickEnterSystem = () => {
    history.push('/home');
    // if (JSON.parse(localStorage.getItem('firstVisit'))) history.push('/project');
    // else history.push('/home');
  };
  // user select
  const [user, setUser] = useState(0);
  const onUserSelect = (id) => {
    setUser(id);
  };
  const common = {
    registration: {
      offer: t('offer'),
      projectPath: '/proposalForm',
      projectBtn: t('pjRb'),
      projectOnClick: (position) => {
        analytics.logEvent('register_project', { position });
      },
      profilePath: '/devProfileForm',
      profileBtn: t('pfRb'),
      profileOnClick: (position) => {
        analytics.logEvent('register_profile', { position });
      },
    },
  };

  return (
    <>
      <SEO title="landing" landing />
      {localStorage.getItem('loggedIn') && JSON.parse(localStorage.getItem('loggedIn')) ? (
        <Navbar button onClick={handleOnClickEnterSystem} />
      ) : (
        <LoginNavbar />
      )}
      <Switch>
        <Route path={['/service', '/pricing', '/use-cases', '/tips']} component={Service} />
        <Route path="/">
          <Main>
            <Header data={common.registration} />
            <UserSelect user={user} onUserSelect={onUserSelect} />
            <LandingData user={user} commonData={common.registration} />
          </Main>
        </Route>
      </Switch>
      <Footer />
    </>
  );
};

const Main = styled.main`
  background: #f7f6f8;
`;

export default Landing;
