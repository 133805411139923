import React from 'react';
import styled from 'styled-components';
import theme, { fonts } from 'styles/theme';
import emptyDocument from 'resources/emptyPM.png';
import emptyTodo from 'resources/emptyTodo.png';
import emptyProj from 'resources/emptyProj.png';
import emptyMember from 'resources/emptyMember.png';
import reactImage from 'images/react.svg';
import nodejsImage from 'images/nodejs.svg';
import tsImage from 'images/language-typescript.svg';
import jsImage from 'images/language-javascript.svg';
import javaImage from 'images/language-java.svg';
import appleImage from 'images/apple.svg';
import androidImage from 'images/android.svg';
import repoImage from 'images/source-repository.svg';
import cloudKeyImage from 'images/cloud-key.svg';
import containerImage from 'images/train-car-container.svg';
import domainImage from 'resources/domain.svg';
import noteImage from 'resources/note-text-outline.svg';
import { useTranslation } from 'react-i18next';

const EmptyDocument = ({ children, ...rest }) => {
  return (
    <Container {...rest}>
      <Empty>
        <img src={emptyDocument} alt="empty-pm" />
        {children}
      </Empty>
    </Container>
  );
};

export const EmptyContract = () => {
  const { t } = useTranslation('common');

  return (
    <Container>
      <Empty>
        <img src={emptyDocument} alt="empty-project" />
        <p>{t('emptyContract')[0]}</p>
      </Empty>
    </Container>
  );
};

export const EmptyTodo = () => {
  const { t } = useTranslation('common');

  return (
    <Container>
      <Empty>
        <img src={emptyTodo} alt="empty-project" />
        <p>
          {t('emptyTodo')[0]} <br />
          {t('emptyTodo')[1]}
        </p>
      </Empty>
    </Container>
  );
};

export const EmptyResult = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      <Empty>
        <img src={emptyProj} alt="empty-project" />
        {children ? (
          children
        ) : (
          <p>
            {t('emptyResult')[0]} <br />
            {t('emptyResult')[1]}
          </p>
        )}
      </Empty>
    </Container>
  );
};

export const EmptyMember = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      <Empty>
        <img src={emptyMember} alt="empty-member" />
        {children ? (
          children
        ) : (
          <p>
            {t('emptyResult')[0]} <br />
            {t('emptyResult')[1]}
          </p>
        )}
      </Empty>
    </Container>
  );
};

export const EmptyComponent = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      <Empty>
        <Row>
          <LangImage src={reactImage} alt="react-image" />
          <LangImage src={nodejsImage} alt="nodejs-image" />
          <LangImage src={appleImage} alt="apple-image" />
          <LangImage src={androidImage} alt="android-image" />
          <LangImage src={javaImage} alt="java-image" />
          <LangImage src={jsImage} alt="js-image" />
          <LangImage src={tsImage} alt="ts-image" />
        </Row>
        {children ? (
          children
        ) : (
          <p>
            {t('emptyComponent')[0]} <br />
            {t('emptyComponent')[1]}
          </p>
        )}
      </Empty>
    </Container>
  );
};

export const EmptyGitRepo = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      <Empty>
        <Row>
          <LangImage src={repoImage} alt="react-image" />
        </Row>
        {children ? (
          children
        ) : (
          <p>
            {t('emptySourceRepo')[0]} <br />
            {t('emptySourceRepo')[1]}
          </p>
        )}
      </Empty>
    </Container>
  );
};

export const EmptyPublicSshKey = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      <Empty>
        <KeyImage src={cloudKeyImage} alt="empty-key" />
        {children ? (
          children
        ) : (
          <p>
            {t('emptyPublicSshKey')[0]} <br />
            {t('emptyPublicSshKey')[1]}
          </p>
        )}
      </Empty>
    </Container>
  );
};

export const EmptyPublicSshKeyText = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <div>
      {t('emptyPublicSshKey')[0]} {t('emptyPublicSshKey')[1]} <br />
      {t('emptyPublicSshKey')[2]}
    </div>
  );
};

export const EmptyContainer = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      <Empty>
        <ContainerImage src={containerImage} alt="empty-container" />
        {children ? (
          children
        ) : (
          <p>
            {t('emptyContainer')[0]} <br />
            {t('emptyContainer')[1]}
          </p>
        )}
      </Empty>
    </Container>
  );
};

export const EmptyDomain = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      <Empty>
        <DomainImage src={domainImage} alt="empty-domain" />
        {children ? (
          children
        ) : (
          <p>
            {t('emptyDomain')[0]} <br />
            {t('emptyDomain')[1]}
          </p>
        )}
      </Empty>
    </Container>
  );
};

export const EmptyReleaseNote = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      <Empty>
        <NoteImage src={noteImage} alt="empty-release-note" />
        {children ? (
          children
        ) : (
          <p>
            {t('emptyReleaseNotes')[0]} <br />
            {t('emptyReleaseNotes')[1]}
          </p>
        )}
      </Empty>
    </Container>
  );
};

export const EmptyDeployments = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      <Empty>
        <NoteImage src={noteImage} alt="empty-deployment" />
        {children ? (
          children
        ) : (
          <p>
            {t('emptyDeployments')[0]} <br />
            {t('emptyDeployments')[1]}
          </p>
        )}
      </Empty>
    </Container>
  );
};

export const EmptyDeploymentCompletedList = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      <Empty small>
        <NoteImage small src={noteImage} alt="empty-deployment" />
        {children ? children : <p>{t('emptyDeploymentsCompleted')}</p>}
      </Empty>
    </Container>
  );
};

export const EmptyTaskPendingReleaseList = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      <Empty small>
        <NoteImage small src={noteImage} alt="empty-tasks-pending-release" />
        {children ? children : <p>{t('emptyTasksPendingRelease')}</p>}
      </Empty>
    </Container>
  );
};

export const EmptyGitCommitList = ({ children, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Container {...rest}>
      <Empty small>
        <NoteImage small src={repoImage} alt="empty-git-commits" />
        {children ? children : <p>{t('emptyGitCommits')}</p>}
      </Empty>
    </Container>
  );
};

const Container = styled.div`
  /* width: calc(100vw - 416px); */
  ${(props) => !props.inner && `height: calc(100vh - 307px);`}
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${theme.tablet} {
    width: 100%;
  }
`;

const Empty = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: 23px;
    text-align: center;
    ${fonts.empty};
    @media ${theme.mobile} {
      font-size: 16px;
      line-height: 1.5;
    }
    ${(props) => props.small && 'font-size: 14px; margin-top: 5px;'}
  }
`;

const LangImage = styled.img`
  width: 45px;
  height: 45px;
  margin-left: 3px;
  margin-right: 3px;
  @media ${theme.mobile} {
    width: 30px;
    height: 30px;
  }
`;

const KeyImage = styled.img`
  width: 90px;
  height: 90px;
  margin-left: 3px;
  margin-right: 3px;
  @media ${theme.mobile} {
    width: 30px;
    height: 30px;
  }
`;

const ContainerImage = styled.img`
  width: 90px;
  height: 90px;
  margin-left: 3px;
  margin-right: 3px;
  opacity: 0.6;
  @media ${theme.mobile} {
    width: 30px;
    height: 30px;
  }
`;

const DomainImage = styled.img`
  width: 90px;
  height: 90px;
  margin-left: 3px;
  margin-right: 3px;
  opacity: 0.6;
  @media ${theme.mobile} {
    width: 30px;
    height: 30px;
  }
`;

const NoteImage = styled.img`
  width: 90px;
  height: 90px;
  margin-left: 3px;
  margin-right: 3px;
  opacity: 0.3;
  @media ${theme.mobile} {
    width: 30px;
    height: 30px;
  }
  ${(props) => props.small && 'width: 45px; height: 45px;'}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export default EmptyDocument;
