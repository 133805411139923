import Landing from 'components/pages/landing/Landing';
import { lazyRetry } from 'utils/lazy';

const SignUp = lazyRetry(() => import('components/pages/signup/SignUp'));
const SignIn = lazyRetry(() => import('components/pages/signup/SignIn'));
const Password = lazyRetry(() => import('components/pages/signup/Password'));
const PasswordReset = lazyRetry(() => import('components/pages/signup/PasswordReset'));
const ProposalFormCorrection = lazyRetry(() =>
  import('components/pages/proposal/ProposalFormCorrection'),
);

const ProposalFeedback = lazyRetry(() => import('components/pages/feedback/ProposalFeedback'));
const Confirm = lazyRetry(() => import('components/pages/proposal/Confirm'));

const JobDefinitionPreview = lazyRetry(() =>
  import('components/pages/devProposal/JobDefinitionPreview'),
);
const ProposalResult = lazyRetry(() => import('components/pages/devProposal/ProposalResult'));
const AlreadyAgree = lazyRetry(() => import('components/pages/devProposal/AlreadyAgree'));
const ProposalDetails = lazyRetry(() => import('components/pages/devProposal/ProposalDetails'));

const Myinfo = lazyRetry(() => import('components/pages/myInfo/Myinfo'));
const DevProfileForm = lazyRetry(() => import('components/pages/devProfile/DevProfileForm'));

const Home = lazyRetry(() => import('components/pages/home/Home'));
const Project = lazyRetry(() => import('components/pages/project/Project'));
const Todo = lazyRetry(() => import('components/pages/todo/Todo'));
const RoworkPM = lazyRetry(() => import('components/pages/roworkPM/RoworkPM'));

const Developers = lazyRetry(() => import('components/pages/contract/Developers'));
const ContractDocument = lazyRetry(() => import('components/pages/contract/ContractDocument'));
const ContractSign = lazyRetry(() => import('components/pages/contract/ContractSign'));
const Contract = lazyRetry(() => import('components/pages/contract/Contract'));
const Files = lazyRetry(() => import('components/pages/contract/Files'));

const Payment = lazyRetry(() => import('components/pages/payment/Payment'));
const Member = lazyRetry(() => import('components/pages/member/Member'));
const Setting = lazyRetry(() => import('components/pages/setting/Setting'));

const ManagedServices = lazyRetry(() => import('components/pages/managedServices/ManagedServices'));
const Issues = lazyRetry(() => import('components/pages/issues/Issues'));
const Cloud = lazyRetry(() => import('components/pages/cloud/Cloud'));

export const routes = [
  {
    path: [
      '/',
      '/service',
      '/pricing',
      '/pricing/:type',
      '/use-cases',
      '/use-cases/:type',
      '/tips',
      '/tips/:type',
    ],
    component: () => <Landing />,
  },
  { path: '/signUp', component: () => <SignUp /> },
  { path: '/login', component: () => <SignIn /> },
  { path: '/pwinquiry', component: () => <Password /> },
  { path: '/passwordReset/:code', component: () => <PasswordReset /> },
  { path: '/proposalForm', component: () => <ProposalFormCorrection /> },
];

export const routesGuard = [
  { path: '/home', component: () => <Home /> },
  {
    path: ['/proposal/feedback', '/proposal/feedback/:proposalId'],
    component: () => <ProposalFeedback />,
  },
  { path: '/confirm', component: () => <Confirm /> },
  {
    path: [
      '/project/:projectId',
      '/milestone/:id',
      '/milestone/:projectId/:id',
      '/product/:productId/milestone/form',
      '/product/:productId/milestone/:id/form',
      '/product-component-detail',
      '/product-component-detail/:productId',
      '/product-component-detail/:productId/:productComponentId',
      '/project-code-quality',
      '/project-code-quality/:projectId',
      '/project-report/:reportId',
      '/project-report/:projectId/:reportId',
      '/product-summary',
      '/product-summary/:projectId',
      '/create-repo',
      '/create-repo/:productId',
      '/manage-repo/:productId',
      '/repo-detail/:productId/:repoId',
    ],
    component: () => <Project />,
  },
  { path: '/project/:projectId/todo', component: () => <Todo /> },
  {
    path: ['/project/:projectId/communication', '/project/:projectId/communication/:requestId'],
    component: () => <RoworkPM />,
  },
  {
    path: [
      '/contract-developers',
      '/contract-developers/:contractId',
      '/contract/developers/:matchingId',
    ],
    component: () => <Developers />,
  },
  { path: '/contract/document', component: () => <ContractDocument /> },
  { path: ['/contract/sign', '/contract/sign/:contractId'], component: () => <ContractSign /> },
  { path: ['/contract/files', '/contract/files/:contractId'], component: () => <Files /> },
  {
    path: ['/project/:projectId/contract', '/contract-final-product'],
    component: () => <Contract />,
  },
  {
    path: ['/job-definition/preview', '/job-definition/preview/:proposalId'],
    component: () => <JobDefinitionPreview />,
  },
  { path: '/project/:projectId/payment', component: () => <Payment /> },
  { path: '/project/:projectId/member', component: () => <Member /> },
  { path: '/project/:projectId/setting', component: () => <Setting /> },
  {
    path: [
      '/project/:projectId/managed-services',
      '/product/:productId/release-notes/:releaseNoteId',
      '/product/:productId/release-notes/:releaseNoteId/form',
      '/product/:productId/release-note-form',
      '/product/:productId/deployment-run-form',
      '/product/:productId/managed-services-detail',
      '/product/:productId/managed-services-detail/:managedServiceId',
    ],
    component: () => <ManagedServices />,
  },
  {
    path: [
      '/product/:productId/cloud',
      '/product/:productId/cloud/containers',
      '/product/:productId/cloud/container-setting-detail',
      '/product/:productId/cloud/container-setting-detail/:containerId',
      '/product/:productId/cloud/domains',
      '/product/:productId/cloud/domain-form',
      '/product/:productId/cloud/domain-detail/:domainId',
    ],
    component: () => <Cloud />,
  },
  {
    path: ['/project/:projectId/issues', '/product/:projectId/issues-detail/:issueId'],
    component: () => <Issues />,
  },
  { path: '/myinfo', component: () => <Myinfo /> },
  { path: '/devProfileForm', component: () => <DevProfileForm /> },
  { path: '/project-request/result', component: () => <ProposalResult /> },
  { path: '/project-request/alreayAgree', component: () => <AlreadyAgree /> },
  {
    path: ['/project-request', '/project-request/:projectId'],
    component: () => <ProposalDetails />,
  },
];

export const routesDev = [
  // {
  //   path: [
  //     '/project/:projectId',
  //     '/milestone/:id',
  //     '/milestone/:projectId/:id',
  //     '/project-code-quality',
  //     '/project-code-quality/:projectId',
  //   ],
  //   component: () => <ProjectDev />,
  // },
  // { path: '/project/:projectId/todo', component: () => <TodoDev /> },
  // { path: '/project/:projectId/contract', component: () => <ContractDev /> },
];

export const accessRoutes = [
  { path: ['/proposal/feedback', '/proposal/feedback/:proposalId'] },
  { path: '/confirm' },
  {
    path: ['/project-report/:reportId', '/project-report/:projectId/:reportId'],
  },
  {
    path: [
      '/contract-developers',
      '/contract-developers/:contractId',
      '/contract/developers/:matchingId',
    ],
  },
  { path: ['/contract/sign', '/contract/sign/:contractId'] },
  { path: ['/contract/files', '/contract/files/:contractId'] },
  { path: '/contract-final-product' },
];
