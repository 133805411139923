import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import theme from 'styles/theme';

const Loading = ({ dark, ...props }) => {
  return (
    <Wrapper {...props}>
      <LoadingAnimation dark={dark}>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </LoadingAnimation>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  ${theme.flex.centerColumn};
  ${(props) =>
    props.small &&
    css`
      width: inherit;
      position: absolute;
      top: 10px;
      right: 10px;
    `}
`;

const wave = keyframes`
  50%,
  75% {
    transform: scale(2.5);
  }
  80%,
  100% {
    opacity: 0;
  }
`;

const LoadingAnimation = styled.div`
  display: flex;

  .dot {
    position: relative;
    width: 0.7em;
    height: 0.7em;
    margin: 0.6em;
    border-radius: 50%;

    &::before {
      position: absolute;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: inherit;
      border-radius: inherit;
      animation: ${wave} 2s ease-out infinite;
    }
    &:nth-child(1) {
      background: ${(props) => (props.dark ? '#c5f6fa' : '#7ef9ff')};
      &::before {
        animation-delay: calc(1 * 0.2s);
      }
    }
    &:nth-child(2) {
      background: ${(props) => (props.dark ? '#99e9f2' : '#89cff0')};
      &::before {
        animation-delay: calc(2 * 0.2s);
      }
    }
    &:nth-child(3) {
      background: ${(props) => (props.dark ? '#66d9e8' : '#2C62BA')};
      &::before {
        animation-delay: calc(3 * 0.2s);
      }
    }
  }
`;

export default Loading;
